import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useTagItem(collection, id) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (title) => {
      if (collection && id && title) {
        const existingTag = await api
          .post('pipeline/tags', {
            json: [
              { $match: { title } },
              { $project: { _id: false, identifier: true, items: true } },
            ],
          })
          .json();

        if (existingTag.length > 0) {
          return api
            .patch(`tags/${existingTag[0].identifier}`, {
              headers: { 'content-type': 'application/merge-patch+json' },
              json: {
                items: {
                  ...existingTag[0].items,
                  [collection]: [
                    ...new Set([
                      ...(existingTag[0].items?.[collection] ?? []),
                      id,
                    ]),
                  ],
                },
              },
            })
            .json();
        } else {
          return api
            .post('tags', {
              json: { title, items: { [collection]: [id] } },
            })
            .json();
        }
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['tags'] }),
  });
}
