import { mapStyleAtom } from '@/atoms';
import { MapBranding, MapButton } from '@/components/controls';
import { transformRequest } from '@/utils';
import { maxBounds, maxZoom, minZoom, styles } from '@/utils/config';
import {
  Layers as LayersIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@mui/icons-material';
import {
  Backdrop,
  CircularProgress,
  ListSubheader,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  useTheme,
} from '@mui/material';
import { bbox } from '@turf/turf';
import { useAtom } from 'jotai';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Layer, Map, ScaleControl, Source } from 'react-map-gl/maplibre';

export function EventPreviewMap({ hoveredChartPoint, point, path }) {
  const [style, setStyle] = useAtom(mapStyleAtom);
  const mapRef = useRef();
  const [styleMenuAnchorEl, setStyleMenuAnchorEl] = useState(null);
  const theme = useTheme();
  const bounds = useMemo(() => (path ? bbox(path) : null), [path]);
  const [viewState, setViewState] = useState({
    minZoom,
    maxZoom,
    maxBounds,
    initialViewState: {
      bounds,
      fitBoundsOptions: { padding: 40 },
    },
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const start = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: path.coordinates[0],
    },
  };
  const end = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: path.coordinates[path.coordinates.length - 1],
    },
  };

  useEffect(() => {
    if (bounds && mapRef.current) {
      mapRef.current.fitBounds(bounds, { padding: 40, duration: 1000 });
    }
  }, [bounds]);

  function handleMove(event) {
    setViewState(event.viewState);
  }

  function handleStyleClick(event) {
    setStyleMenuAnchorEl(event.currentTarget);
  }

  function handleStyleMenuClose() {
    setStyleMenuAnchorEl(null);
  }

  const handleStyleMenuClick = (path) => () => {
    setStyle(path);
    setStyleMenuAnchorEl(null);
  };

  function handleZoomInClick() {
    mapRef.current.zoomIn();
  }

  function handleZoomOutClick() {
    mapRef.current.zoomOut();
  }

  function handleFitClick() {
    mapRef.current.fitBounds(bounds, { padding: 40, duration: 1000 });
  }

  function handleLoad() {
    setIsLoaded(true);
  }

  function handleError(errorEvent) {
    setIsLoaded(true);

    throw errorEvent.error;
  }

  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={{ position: 'relative', width: 320, height: 320 }}
    >
      <Map
        {...viewState}
        mapStyle={style}
        onMove={handleMove}
        transformRequest={transformRequest(style)}
        cooperativeGestures={true}
        cursor="auto"
        ref={mapRef}
        onLoad={handleLoad}
        onError={handleError}
        style={{ borderRadius: 3 }}
        attributionControl={false}
      >
        <ScaleControl />
        <Stack sx={{ position: 'absolute', top: 8, left: 8 }}>
          <MapButton
            title="Zoom in"
            onClick={handleZoomInClick}
            disabled={
              mapRef.current &&
              mapRef.current.getZoom() === mapRef.current.getMaxZoom()
            }
          >
            <ZoomInIcon />
          </MapButton>
          <MapButton
            title="Zoom out"
            onClick={handleZoomOutClick}
            disabled={
              mapRef.current &&
              mapRef.current.getZoom() === mapRef.current.getMinZoom()
            }
          >
            <ZoomOutIcon />
          </MapButton>
          <MapButton
            title="Fit map"
            onClick={handleFitClick}
            disabled={!bounds}
          >
            <ZoomOutMapIcon />
          </MapButton>
          <MapButton title="Change map style" onClick={handleStyleClick}>
            <LayersIcon />
          </MapButton>
          <Menu
            anchorEl={styleMenuAnchorEl}
            open={Boolean(styleMenuAnchorEl)}
            onClose={handleStyleMenuClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <MenuList
              disablePadding
              dense
              subheader={<ListSubheader disableSticky>Type</ListSubheader>}
            >
              {styles.map((layer) => (
                <MenuItem
                  key={layer.path}
                  onClick={handleStyleMenuClick(layer.path)}
                  selected={layer.path === style}
                >
                  {layer.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Stack>
        <Source id="boundary" type="geojson" data={path}>
          <Layer
            type="line"
            paint={{
              'line-color': theme.palette.common.black,
              'line-width': 2,
            }}
            layout={{
              'line-cap': 'round',
              'line-join': 'round',
            }}
          />
        </Source>
        <Source id="start" type="geojson" data={start}>
          <Layer
            type="circle"
            paint={{
              'circle-color': theme.palette.success.main,
              'circle-radius': 4,
            }}
          />
        </Source>
        <Source id="end" type="geojson" data={end}>
          <Layer
            type="circle"
            paint={{
              'circle-color': theme.palette.error.main,
              'circle-radius': 4,
            }}
          />
        </Source>
        {point && (
          <Source id="point" type="geojson" data={point}>
            <Layer
              type="circle"
              paint={{
                'circle-color': theme.palette.primary.main,
                'circle-radius': 8,
              }}
            />
          </Source>
        )}
        {hoveredChartPoint && (
          <Source id="hovered" type="geojson" data={hoveredChartPoint}>
            <Layer
              type="circle"
              paint={{
                'circle-color': theme.palette.secondary.main,
                'circle-radius': 4,
              }}
            />
          </Source>
        )}
        <MapBranding style={style} />
      </Map>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'absolute',
        }}
        open={!isLoaded}
      >
        <CircularProgress />
      </Backdrop>
    </Paper>
  );
}
