import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function BackButton() {
  const navigate = useNavigate();

  function handleClick() {
    navigate('..');
  }

  return (
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      sx={{ mr: 1 }}
      onClick={handleClick}
    >
      <ArrowBackIcon />
    </IconButton>
  );
}
