import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useSetConfigurationState(imei, name) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value) => {
      return api.put(`telematicsBox/${imei}/${name}`, {
        json: { State: value },
      });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['configurationState'] }),
  });
}
