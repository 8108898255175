import { useDeleteNotifications } from '@/hooks';
import {
  Close as CloseIcon,
  Notifications as NotificationsIcon,
} from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { format, isSameDay } from 'date-fns';
import { Fragment } from 'react';

const NotificationListItem = styled(ListItem)`
  padding-right: 32px;

  & .MuiListItemButton-root {
    padding-right: 16px;
  }

  & .MuiListItemSecondaryAction-root {
    top: 24px;
  }
`;

function formatTime(time) {
  if (!time) {
    return '';
  }

  const date = new Date(time);
  return format(date, isSameDay(date, new Date()) ? 'HH:mm' : 'dd/MM');
}

export function NotificationList({ notifications }) {
  const { mutate: deleteNotifications } = useDeleteNotifications();

  function handleDismissAll() {
    const notificationIds = notifications.map(
      (notification) => notification.identifier,
    );
    deleteNotifications(notificationIds);
  }

  const handleDismiss = (id) => () => {
    deleteNotifications([id]);
  };

  return (
    <Fragment>
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          sx={{ flexGrow: 1 }}
        >
          Notifications
        </Typography>
        <Tooltip title="Dismiss all" placement="left">
          <IconButton onClick={handleDismissAll} size="small">
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Stack>
      <List dense disablePadding sx={{ maxWidth: 400 }}>
        {notifications.map((notification, index) => (
          <NotificationListItem
            key={notification.identifier}
            disableGutters
            alignItems="flex-start"
            divider={index !== notifications.length - 1}
            secondaryAction={
              <Tooltip title="Dismiss" placement="bottom">
                <IconButton
                  size="small"
                  onClick={handleDismiss(notification.identifier)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            }
          >
            <ListItemButton alignItems="flex-start">
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: `${notification.type ?? 'info'}.main` }}>
                  <NotificationsIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={
                  <Stack
                    direction="row"
                    sx={{ justifyContent: 'space-between' }}
                  >
                    <Typography variant="subtitle2">
                      {notification.title ??
                        'This is where the title should be'}
                    </Typography>
                    <Typography variant="caption">
                      {formatTime(notification.time)}
                    </Typography>
                  </Stack>
                }
                secondary={
                  <Typography variant="caption">
                    {notification.message ??
                      'This is where the message should be'}
                  </Typography>
                }
              />
            </ListItemButton>
          </NotificationListItem>
        ))}
      </List>
    </Fragment>
  );
}
