import '@fontsource/roboto/100-italic.css';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300-italic.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400-italic.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500-italic.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700-italic.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900-italic.css';
import '@fontsource/roboto/900.css';
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { browserTracingIntegration, init, setUser } from '@sentry/react';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { enGB } from 'date-fns/locale';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { WebStorageStateStore } from 'oidc-client-ts';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './css/index.css';
import { routes } from './routing';
import store from './store';
import { theme } from './theme';
import { populateDescendants } from './utils';
import {
  apiRootUrl,
  authenticationRootUrl,
  authenticationScheme,
  clientId,
  resource,
  sentry,
  tenantId,
} from './utils/config';
import { createIDBPersister } from './utils/createIDBPersister';

const router = createBrowserRouter(routes, {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_prependBasename: true,
    v7_relativeSplatPath: true,
    v7_skipActionErrorRevalidation: true,
  },
});

// connect app to the Sentry project
if (sentry.enable) {
  init({
    dsn: sentry.dsn,
    integrations: [browserTracingIntegration()],
    environment: import.meta.env.MODE,
    tracesSampleRate: sentry.tracesSampleRate,
    release: `v${import.meta.env.VITE_APP_VERSION}`,
  });
}
// MUI pickers dateFormats docs: https://mui.com/x/api/date-pickers/localization-provider/
// date-fns parse docs https://date-fns.org/docs/parse
const dateFormats = {
  year: 'yyyy',
  fullDateTime24h: 'dd/MM/yyyy HH:mm',
  hours24h: 'HH',
  fullDate: 'dd/MM/yyyy',
};

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: async (error) => {
      const response = await error?.response?.json();
      enqueueSnackbar(response?.message ?? error?.message, {
        variant: 'error',
      });
    },
  }),
  queryCache: new QueryCache({
    onError: async (error) => {
      const response = await error?.response?.json();
      enqueueSnackbar(response?.message ?? error?.message, {
        variant: 'error',
      });
    },
  }),
});
const persister = createIDBPersister();

const oidcConfig = {
  authority: `${authenticationRootUrl}/${tenantId}`,
  client_id: clientId,
  redirect_uri: location.origin,
  extraQueryParams: { resource },
  userStore: new WebStorageStateStore({ store: localStorage }),
  metadata: {
    authorization_endpoint: `${authenticationRootUrl}/${tenantId}/${authenticationScheme}/authorize`,
    token_endpoint: `${apiRootUrl}/${authenticationScheme}/token`,
  },
};

function onSigninCallback(user) {
  if (sentry.enable) {
    setUser({
      username: user?.profile?.unique_name,
      email: user?.profile?.email,
    });
  }

  populateDescendants();

  history.replaceState({}, document.title, location.pathname);
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme} defaultMode="light">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={enGB}
              dateFormats={dateFormats}
            >
              <CssBaseline />
              <SnackbarProvider>
                <PersistQueryClientProvider
                  client={queryClient}
                  persistOptions={{ persister }}
                >
                  <ReactQueryDevtools
                    initialIsOpen={false}
                    buttonPosition="top-right"
                  />
                  <RouterProvider
                    router={router}
                    future={{ v7_startTransition: true }}
                  />
                </PersistQueryClientProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </AuthProvider>
  </StrictMode>,
);
