import { DateTimePicker } from '@/components/controls';
import {
  IconButton,
  Stack,
  Tooltip,
  TextField,
  InputAdornment,
  Box,
} from '@mui/material';
import { useController } from 'react-hook-form';
import {
  AddCircle as AddIcon,
  RemoveCircle as RemoveIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';

export function OdometerReadingsController({ name, control, disabled }) {
  const {
    field: { value: readings, onChange },
  } = useController({
    name,
    control,
  });

  function handleAddClick() {
    onChange([
      { key: Math.max(...readings.map(({ key }) => key)) + 1 },
      ...(readings ?? []),
    ]);
  }

  const handleDeleteClick = (key) => () => {
    onChange(readings.filter((value) => value.key !== key));
  };

  const handleTimeChange = (key) => (time) => {
    onChange(
      readings.map((value) => (value.key === key ? { ...value, time } : value)),
    );
  };

  const handleMilesChange = (key) => (event) => {
    onChange(
      readings.map((value) =>
        value.key === key
          ? { ...value, miles: event.target.valueAsNumber }
          : value,
      ),
    );
  };

  const handleMilesClearClick = (key) => () => {
    onChange(
      readings.map(({ miles, ...value }) =>
        value.key === key ? value : { ...value, miles },
      ),
    );
  };

  return (
    <Stack sx={{ width: 1 }} spacing={1}>
      <Stack direction="row" spacing={1}>
        <Box sx={{ width: 488 }} />
        <Tooltip title="Add" placement="right">
          <Box component="span">
            <IconButton
              onClick={handleAddClick}
              size="small"
              disabled={disabled}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Stack>
      {(readings ?? []).map(({ time, miles, key }) => (
        <Stack key={key} direction="row" spacing={1}>
          <DateTimePicker
            label="Date & Time"
            value={time}
            onChange={handleTimeChange(key)}
            disabled={disabled}
            clearable
            sx={{ width: 240 }}
          />
          <TextField
            label="Miles"
            type="number"
            value={miles}
            onChange={handleMilesChange(key)}
            disabled={disabled}
            size="small"
            sx={{ width: 240 }}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment
                    position="end"
                    disableTypography
                    sx={{ alignItems: 'center' }}
                  >
                    <IconButton
                      size="small"
                      sx={{ visibility: miles ? 'visible' : 'hidden' }}
                      onClick={handleMilesClearClick(key)}
                    >
                      <ClearIcon fontSize="inherit" />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
          {!disabled && (
            <Tooltip title="Remove" placement="right">
              <IconButton onClick={handleDeleteClick(key)} size="small">
                <RemoveIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      ))}
    </Stack>
  );
}
