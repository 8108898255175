import { Stack } from '@mui/material';
import { FilterField } from './FilterField';

export function ObjectiveFilter({ value = {}, onChange, filterValues = {} }) {
  const handleFilterChange = (field) => (event, update) => {
    onChange({
      ...value,
      [field]: update,
    });
  };

  return (
    <Stack spacing={1} sx={{ maxHeight: 238, overflow: 'scroll', pt: 0.75 }}>
      <FilterField
        label="Microbeat"
        value={value.microbeats}
        onChange={handleFilterChange('microbeats')}
        options={filterValues['properties.microbeats'] ?? []}
      />
      <FilterField
        label="Occurrence #"
        value={value.occurrenceNumber}
        onChange={handleFilterChange('occurrenceNumber')}
        options={filterValues['properties.occurrenceNumber'] ?? []}
      />
    </Stack>
  );
}
