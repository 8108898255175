import { getStatusColor, getStatusForeColor } from '@/utils';
import { Person as PersonIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { RadioHandheld as RadioHandheldIcon } from 'mdi-material-ui';

export function PersonListItem({
  item: { size, start },
  feature,
  selected,
  ...props
}) {
  const primary = feature.properties.person?.code
    ? [feature.properties.person?.forenames, feature.properties.person?.surname]
        .filter(Boolean)
        .join(' ')
        .trim()
    : 'Unassigned radio';
  const secondary = feature.properties?.person?.code ?? feature.properties.ssi;

  return (
    <ListItem
      {...props}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: `${size}px`,
        transform: `translateY(${start}px)`,
      }}
      disablePadding
    >
      <ListItemButton dense selected={selected}>
        <ListItemAvatar>
          <Avatar
            sx={{
              color: getStatusForeColor('default'),
              bgcolor: getStatusColor('default'),
              borderColor: getStatusForeColor(feature.properties?.status),
              borderWidth: 2,
              borderStyle: 'solid',
            }}
          >
            {feature.properties?.person?.code ? (
              <PersonIcon />
            ) : (
              <RadioHandheldIcon />
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={primary}
          primaryTypographyProps={{ noWrap: true }}
          secondary={secondary}
          secondaryTypographyProps={{ noWrap: true }}
        />
        {feature.properties.person?.collarNumber && (
          <Tooltip title="Collar Number" placement="right">
            <Box
              sx={{
                fontSize: 10,
                color: 'white',
                bgcolor: blue[900],
                borderRadius: 1,
                px: 0.5,
                py: 0.25,
              }}
            >
              {feature.properties.person?.collarNumber}
            </Box>
          </Tooltip>
        )}
      </ListItemButton>
    </ListItem>
  );
}
