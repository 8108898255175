function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(file);
  });
}

export function getImagesFromFiles(files) {
  return Promise.all(
    files.map(async (file) => ({
      src: await getBase64(file),
      alt: file.name,
    })),
  );
}
