import {
  Navigation as NavigationIcon,
  VolumeUp as VolumeUpIcon,
  VpnKey as VpnKeyIcon,
} from '@mui/icons-material';
import { Box, Chip, Tooltip, useTheme } from '@mui/material';
import { amber } from '@mui/material/colors';
import {
  AlarmLight as AlarmLightIcon,
  CarLightHigh as HeadlightsFlashIcon,
} from 'mdi-material-ui';
import { Fragment } from 'react';
import { LiveListItem } from './LiveListItem';

export function TelematicsBoxLiveListItem({ item: telematicsBox, ...props }) {
  const {
    headingDegrees,
    ignitionOn,
    sirensOn,
    beaconsOn,
    headlightsFlashOn,
    speedKilometresPerHour,
    position,
  } = telematicsBox.mostRecentPoll;

  const theme = useTheme();

  const secondaryComponent = (
    <Fragment>
      {
        <Box style={{ display: 'flex' }}>
          <NavigationIcon
            // fromCharCode so degrees symbol doesn't stop chrome debugger mapping
            titleAccess={'Heading ' + headingDegrees + String.fromCharCode(176)}
            sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
            style={{
              transform: `rotate(${headingDegrees}deg)`,
            }}
            color="action"
          />
          {ignitionOn !== undefined && (
            <VpnKeyIcon
              titleAccess={'Ignition ' + (ignitionOn ? 'on' : 'off')}
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              htmlColor={
                ignitionOn ? amber[700] : theme.palette.action.disabled
              }
            />
          )}
          {sirensOn !== undefined && (
            <VolumeUpIcon
              titleAccess={'Siren ' + (sirensOn ? 'on' : 'off')}
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              htmlColor={sirensOn ? amber[700] : theme.palette.action.disabled}
            />
          )}
          {beaconsOn !== undefined && (
            <AlarmLightIcon
              titleAccess={'Beacons ' + (beaconsOn ? 'on' : 'off')}
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              htmlColor={beaconsOn ? amber[700] : theme.palette.action.disabled}
            />
          )}
          {headlightsFlashOn !== undefined && (
            <HeadlightsFlashIcon
              titleAccess={
                'Headlights flash ' + (headlightsFlashOn ? 'on' : 'off')
              }
              sx={{ fontSize: 16, verticalAlign: 'text-top', mr: 0.5 }}
              htmlColor={
                headlightsFlashOn ? amber[700] : theme.palette.action.disabled
              }
            />
          )}
          <Box
            style={{
              flex: 1,
            }}
          ></Box>
          <Tooltip>
            <Chip
              sx={{
                fontSize: '.6rem',
                height: 18,
                '.MuiChip-avatarSmall': {
                  bgcolor: 'background.paper',
                  height: 14,
                  width: 14,
                  marginLeft: 0.25,
                },
              }}
              size="small"
              label={
                isNaN(speedKilometresPerHour)
                  ? `${0} MPH`
                  : `${Math.round(speedKilometresPerHour * 0.62137119)} MPH`
              }
            />
          </Tooltip>
        </Box>
      }
    </Fragment>
  );

  return (
    <LiveListItem
      type="telematicsBoxes"
      primaryPath="imei"
      secondaryPath="type"
      secondaryComponent={secondaryComponent}
      item={telematicsBox}
      hideFollow={!position}
      {...props}
    />
  );
}
