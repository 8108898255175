import { speedRuleLabels } from '@/utils/config';
import {
  Error as ErrorIcon,
  Input as InputIcon,
  LocationSearching as LocationSearchingIcon,
  Navigation as NavigationIcon,
  VolumeUp as VolumeUpIcon,
  VpnKey as VpnKeyIcon,
  Warning as WarningIcon,
  WbIncandescent as WbIncandescentIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  // Typography,
  Chip,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction, // TODO: Remove
  ListItemText,
  ListSubheader,
  useTheme,
} from '@mui/material';
import { amber } from '@mui/material/colors';
import {
  AlarmLight as AlarmLightIcon,
  CarLightHigh as CarLightHighIcon,
  CarSide as CarSideIcon,
  Engine as EngineIcon,
  RadioHandheld as RadioHandheldIcon,
} from 'mdi-material-ui';
import { Fragment, cloneElement } from 'react';
import { EventReplayItemTypeIcon } from './EventReplayItemTypeIcon';

const groupHeaders = new Map([
  ['vehicles', 'Vehicles'],
  ['locations', 'Locations'],
  ['events', 'Events'],
  ['people', 'People'],
  ['incidents', 'Incidents'],
]);

export function EventReplayItemList({
  featureCollections,
  followedItemIndexes,
  selectedItemIndex,
  hoveredItemIndex,
  onHoverItem,
  onSelectItem,
  onFollowedItemsChange,
}) {
  const theme = useTheme();

  function handleFollowToggle(id, type) {
    const index = followedItemIndexes.findIndex(
      (followedItem) => followedItem.type === type && followedItem.id === id,
    );

    if (index === -1) {
      onFollowedItemsChange(followedItemIndexes.concat({ id, type }));
    } else {
      onFollowedItemsChange(
        followedItemIndexes
          .slice(0, index)
          .concat(followedItemIndexes.slice(index + 1)),
      );
    }
  }

  function Item({ item }) {
    const {
      type,
      registrationNumber,
      fleetNumber,
      telematicsBoxImei,
      driver,
      speedKilometresPerHour,
      headingDegrees,
      ignitionOn,
      sirensOn,
      beaconsOn,
      headlightsFlashOn,
      malfunctionIndicatorLightOn,
      accelerometerAlert,
      rearBlueLightsOn,
      rearRedLightsOn,
      strikeButtonOn,
      frontPWEOn,
      rearPWEOn,
      airwaveOn,
      ancillaryEquipmentOn,
      forenames,
      surname,
      collarNumber,
      name,
      subtype,
      reverseGeocode,
      speedRules,
    } = item.properties;

    const speedRulesTooltip = speedRules
      ?.map((r) => speedRuleLabels[r] ?? r)
      .join(', ');

    switch (type) {
      case 'vehicle': {
        const isSpeeding =
          speedKilometresPerHour > reverseGeocode?.speedLimitKilometresPerHour;
        const knownSpeedLimit = !(reverseGeocode?.unknownLimit ?? true); // assume unknown
        return (
          <ListItemButton
            key={item.id}
            sx={
              item.id === selectedItemIndex.id &&
              type === selectedItemIndex.type
                ? { bgcolor: 'action.selected' }
                : item.id === hoveredItemIndex.id &&
                    type === hoveredItemIndex.type
                  ? { bgcolor: 'action.hover' }
                  : null
            }
            onMouseEnter={() => onHoverItem({ id: item.id, type })}
            onMouseLeave={() => onHoverItem({})}
            onClick={() => {
              onSelectItem({ id: item.id, type });
              onHoverItem({});
            }}
          >
            <ListItemAvatar>
              <Avatar>
                <EventReplayItemTypeIcon type={type} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={registrationNumber || fleetNumber || telematicsBoxImei}
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <Fragment>
                  {driver &&
                    `${driver.forenames} ${driver.surname} [$
                      {driver.collarNumber}]`}
                  <Box sx={{ display: 'flex' }}>
                    <NavigationIcon
                      sx={{ fontSize: 16 }}
                      style={{
                        transform: `rotate(${headingDegrees}deg)`,
                      }}
                      color="action"
                    />
                    {ignitionOn !== undefined && (
                      <VpnKeyIcon
                        sx={{ fontSize: 16 }}
                        htmlColor={
                          ignitionOn
                            ? amber[700]
                            : theme.palette.action.disabled
                        }
                      />
                    )}
                    {sirensOn !== undefined && (
                      <VolumeUpIcon
                        sx={{ fontSize: 16 }}
                        htmlColor={
                          sirensOn ? amber[700] : theme.palette.action.disabled
                        }
                      />
                    )}
                    {beaconsOn !== undefined && (
                      <AlarmLightIcon
                        sx={{ fontSize: 16 }}
                        htmlColor={
                          beaconsOn ? amber[700] : theme.palette.action.disabled
                        }
                      />
                    )}
                    {headlightsFlashOn !== undefined && (
                      <CarLightHighIcon
                        sx={{ fontSize: 16 }}
                        htmlColor={
                          beaconsOn ? amber[700] : theme.palette.action.disabled
                        }
                      />
                    )}
                    {malfunctionIndicatorLightOn !== undefined && (
                      <EngineIcon
                        sx={{ fontSize: 16 }}
                        htmlColor={
                          malfunctionIndicatorLightOn
                            ? amber[700]
                            : theme.palette.action.disabled
                        }
                      />
                    )}
                    {accelerometerAlert !== undefined && (
                      <WarningIcon
                        sx={{ fontSize: 16 }}
                        htmlColor={
                          accelerometerAlert
                            ? amber[700]
                            : theme.palette.action.disabled
                        }
                      />
                    )}
                    {rearBlueLightsOn !== undefined && (
                      <WbIncandescentIcon
                        sx={{ fontSize: 16 }}
                        color={rearBlueLightsOn ? 'primary' : 'disabled'}
                      />
                    )}
                    {rearRedLightsOn !== undefined && (
                      <WbIncandescentIcon
                        sx={{ fontSize: 16 }}
                        color={rearRedLightsOn ? 'error' : 'disabled'}
                      />
                    )}
                    {strikeButtonOn !== undefined && (
                      <ErrorIcon
                        sx={{ fontSize: 16 }}
                        htmlColor={
                          strikeButtonOn
                            ? amber[700]
                            : theme.palette.action.disabled
                        }
                      />
                    )}
                    {(frontPWEOn !== undefined || rearPWEOn !== undefined) && (
                      <CarSideIcon
                        sx={{ fontSize: 16 }}
                        component={(svgProps) => {
                          return (
                            <svg {...svgProps}>
                              <defs>
                                <linearGradient id="gradient1">
                                  <stop
                                    offset="50%"
                                    stopColor={
                                      rearPWEOn
                                        ? theme.palette.primary.dark
                                        : theme.palette.action.disabled
                                    }
                                  />
                                  <stop
                                    offset="50%"
                                    stopColor={
                                      frontPWEOn
                                        ? theme.palette.primary.dark
                                        : theme.palette.action.disabled
                                    }
                                  />
                                </linearGradient>
                              </defs>
                              {cloneElement(svgProps.children[0], {
                                fill: 'url(#gradient1)',
                              })}
                            </svg>
                          );
                        }}
                      />
                    )}
                    {airwaveOn !== undefined && (
                      <RadioHandheldIcon
                        sx={{ fontSize: 16 }}
                        htmlColor={
                          airwaveOn ? amber[700] : theme.palette.action.disabled
                        }
                      />
                    )}
                    {ancillaryEquipmentOn !== undefined && (
                      <InputIcon
                        sx={{ fontSize: 16 }}
                        htmlColor={
                          ancillaryEquipmentOn
                            ? amber[700]
                            : theme.palette.action.disabled
                        }
                      />
                    )}
                    <Box sx={{ flex: 1 }}></Box>
                    {/* {speedRules?.length > 0 && (
                      <Box
                        style={{ color: red['700'], display: 'flex' }}
                        title={speedRulesTooltip}
                      >
                        <SpeedometerIcon
                          title={speedRulesTooltip}
                          sx={{ fontSize: 16 }}
                          htmlColor={red['700']}
                          style={{ marginRight: 0 }}
                        />
                        {speedRules?.length > 1 && 'x' + speedRules.length}
                      </Box>
                    )}
                    <Typography
                      style={{
                        color:
                          isSpeeding && knownSpeedLimit ? red[700] : 'inherit',
                      }}
                      className={classes.speed}
                    >
                      {`${Math.round(speedKilometresPerHour * 0.62137119)} MPH`}
                    </Typography>
                    {isSpeeding && knownSpeedLimit && (
                      <Avatar className={classes.speedingAvatar}>
                        {Math.round(
                          reverseGeocode.speedLimitKilometresPerHour *
                            0.62137119
                        )}
                      </Avatar>
                    )} */}
                  </Box>
                </Fragment>
              }
            />
            <ListItemSecondaryAction>
              <Chip
                title={speedRulesTooltip}
                sx={{
                  fontSize: '.6rem',
                  height: 18,
                  '.MuiChip-avatarSmall': {
                    bgcolor: 'background.paper',
                    color: isSpeeding ? 'error.main' : undefined,
                    height: 14,
                    width: 14,
                    marginLeft: 0.25,
                  },
                }}
                color={speedRules?.length ? 'error' : undefined}
                size="small"
                avatar={
                  knownSpeedLimit && (isSpeeding || speedRules?.length) ? (
                    <Avatar>
                      <Box
                        style={{
                          color: speedRules?.length
                            ? theme.palette.text.primary
                            : undefined,
                        }}
                      >
                        {Math.round(
                          reverseGeocode.speedLimitKilometresPerHour *
                            0.62137119,
                        )}
                      </Box>
                    </Avatar>
                  ) : undefined
                }
                label={`${Math.round(speedKilometresPerHour * 0.62137119)} MPH`}
              />
              <IconButton
                edge="end"
                aria-label="Follow"
                onClick={() => handleFollowToggle(item.id, type)}
                size="large"
              >
                <LocationSearchingIcon
                  fontSize="small"
                  color={
                    followedItemIndexes.some(
                      (followedItem) =>
                        followedItem.type === type &&
                        followedItem.id === item.id,
                    )
                      ? 'primary'
                      : 'disabled'
                  }
                />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItemButton>
        );
      }
      case 'person':
        return (
          <ListItemButton
            key={item.id}
            sx={
              item.id === selectedItemIndex.id &&
              type === selectedItemIndex.type
                ? { bgcolor: 'action.selected' }
                : item.id === hoveredItemIndex.id &&
                    type === hoveredItemIndex.type
                  ? { bgcolor: 'action.hover' }
                  : null
            }
            onMouseEnter={() => onHoverItem({ id: item.id, type })}
            onMouseLeave={() => onHoverItem({})}
            onClick={() => {
              onSelectItem({ id: item.id, type });
              onHoverItem({});
            }}
          >
            <ListItemAvatar>
              <Avatar>
                <EventReplayItemTypeIcon type={type} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                forenames &&
                `${forenames} ${surname} ${collarNumber && `[${collarNumber}]`}`
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="Follow"
                onClick={() => handleFollowToggle(item.id, type)}
                size="large"
              >
                <LocationSearchingIcon
                  fontSize="small"
                  color={
                    followedItemIndexes.some(
                      (followedItem) =>
                        followedItem.type === type &&
                        followedItem.id === item.id,
                    )
                      ? 'primary'
                      : 'disabled'
                  }
                />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItemButton>
        );
      case 'location':
        return (
          <ListItemButton
            key={item.id}
            sx={
              item.id === selectedItemIndex.id &&
              type === selectedItemIndex.type
                ? { bgcolor: 'action.selected' }
                : item.id === hoveredItemIndex.id &&
                    type === hoveredItemIndex.type
                  ? { bgcolor: 'action.hover' }
                  : null
            }
            onMouseEnter={() => onHoverItem({ id: item.id, type })}
            onMouseLeave={() => onHoverItem({})}
            onClick={() => {
              onSelectItem({ id: item.id, type });
              onHoverItem({});
            }}
          >
            <ListItemAvatar>
              <Avatar>
                <EventReplayItemTypeIcon type={type} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={name} secondary={subtype} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="Follow"
                onClick={() => handleFollowToggle(item.id, type)}
                size="large"
              >
                <LocationSearchingIcon
                  fontSize="small"
                  color={
                    followedItemIndexes.some(
                      (followedItem) =>
                        followedItem.type === type &&
                        followedItem.id === item.id,
                    )
                      ? 'primary'
                      : 'disabled'
                  }
                />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItemButton>
        );
      default:
        return '';
    }
  }

  return (
    <List dense disablePadding>
      {Object.entries(featureCollections || {}).map((group) => (
        <Box key={group[0]}>
          <ListSubheader disableSticky>
            {groupHeaders.get(group[0])}
          </ListSubheader>
          {group[1].features.map((item) => (
            <Item item={item} key={item.id} />
          ))}
        </Box>
      ))}
    </List>
  );
}
