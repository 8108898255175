import { DetailsPanel } from '@/components/controls';
import { useOptionLookup } from '@/hooks';
import { parseIfJSON, startCase } from '@/utils';
import { Stack, Typography } from '@mui/material';
import { Popup } from 'react-map-gl/maplibre';
import { Status } from './Status';

export function LocationPopup({
  hoverInfo: {
    longitude,
    latitude,
    feature: { properties },
  },
}) {
  const locationGroups = useOptionLookup('locationGroup');
  const groups = properties.groups ? parseIfJSON(properties.groups) : null;

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      offset={16}
      closeButton={false}
    >
      <Stack spacing={0.5}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {properties.name}
        </Typography>
        <Status status={properties.status} />
        <DetailsPanel
          entries={[
            { label: 'Type', value: properties.type },
            { label: 'Subtype', value: properties.subtype },
            ...Object.entries(groups ?? {}).map(([type, codes = []]) => ({
              label: locationGroups[type]?.label ?? startCase(type),
              value: codes.join?.(', ') ?? codes,
            })),
          ]}
        />
      </Stack>
    </Popup>
  );
}
