import { FilterField } from '@/components/fields';
import { startCase } from '@/utils';
import { Divider } from '@mui/material';
import { Fragment } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { sourceFilterMapping } from './constants';

export function SourceFilters({ type, name, filters = {} }) {
  const mapping = sourceFilterMapping[type];

  if (mapping) {
    return (
      <Fragment>
        {Object.entries(mapping)
          .filter(([key]) => key in filters)
          .map(([key, id], index) => {
            id = typeof id === 'string' ? id : key;

            return (
              <Fragment key={key}>
                {index > 0 && (
                  <Divider style={{ marginTop: 16, marginBottom: 8 }} />
                )}
                <FieldArray
                  label={startCase(`${id} Filters`)}
                  name={`${name}.${id}`}
                  filters={filters[key]}
                  component={FilterField}
                />
              </Fragment>
            );
          })}
      </Fragment>
    );
  } else {
    return <Fragment />;
  }
}
