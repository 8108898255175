import { startCase } from '@/utils';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListItemButton,
  ListItemSecondaryAction, // TODO: Remove
  ListItemText,
  Tooltip,
} from '@mui/material';
import { format } from 'date-fns';

export function QueryListItem({
  title,
  lastEditTime,
  interval,
  selected,
  onItemClick,
  onItemDoubleClick,
  onDeleteClick,
}) {
  return (
    <Tooltip
      title={`Last edited ${format(lastEditTime, 'dd/MM/yyyy HH:mm')}`}
      placement="right"
    >
      <ListItemButton
        onDoubleClick={onItemDoubleClick}
        onClick={onItemClick}
        selected={selected}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ListItemText
            primary={title}
            secondary={interval === '' ? 'Unscheduled' : startCase(interval)}
          />
          <ListItemSecondaryAction>
            <IconButton color="error" edge="end" onClick={onDeleteClick}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </Box>
      </ListItemButton>
    </Tooltip>
  );
}
