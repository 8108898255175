import { TextFieldController } from '@/components/controls';
import { ConfirmationDialog } from '@/components/dialogs';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ItemsController } from './ItemsController';

export function TagForm({ defaultValues, onSubmit, onDelete }) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const {
    control,
    formState: { dirtyFields, isSubmitting },
    handleSubmit,
    reset,
    watch,
  } = useForm({ defaultValues });
  const isDirty = Object.keys(dirtyFields).length > 0;

  function handleCancel() {
    reset();
  }

  function toggleDeleteDialog() {
    setDeleteOpen((prev) => !prev);
  }

  return (
    <Fragment>
      <form id="tag-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          <Typography variant="h6" sx={{ p: 1 }}>
            {defaultValues?._id ? defaultValues.title : 'New Tag'}
          </Typography>
          <TextFieldController
            name="title"
            label="Title"
            control={control}
            disabled={!!defaultValues?._id}
            rules={{ required: 'Required', maxLength: 50 }}
          />
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Description
            </Typography>
          </Divider>
          <TextFieldController
            name="description"
            control={control}
            fullWidth
            placeholder="Add description..."
            multiline
            minRows={4}
          />
          {watch('items') && (
            <Fragment>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Items
                </Typography>
              </Divider>
              <ItemsController name="items" control={control} />
            </Fragment>
          )}
          <Stack spacing={1} direction="row">
            <Button
              color="primary"
              type="submit"
              disabled={!isDirty || isSubmitting}
            >
              Save
            </Button>
            <Button
              color="primary"
              disabled={!isDirty || isSubmitting}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              color="error"
              onClick={toggleDeleteDialog}
              disabled={!watch('_id')}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </form>
      <ConfirmationDialog
        action="Delete"
        open={deleteOpen}
        itemId={defaultValues?.identifier}
        onOk={onDelete}
        onCancel={() => setDeleteOpen(false)}
      />
    </Fragment>
  );
}
