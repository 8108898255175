import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useDeleteLocation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => {
      if (id) {
        return api.delete(`locations/${id}`);
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['locations'] }),
  });
}
