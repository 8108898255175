import { shortHumanizer } from '@/utils';
import { speedRuleLabels } from '@/utils/config';
import { Stack } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { SpeedLimitBreakdown } from './SpeedLimitBreakdown';

const columns = [
  {
    header: 'Rule',
    id: 'code',
    accessorFn: ({ code }) => speedRuleLabels[code] ?? code,
  },
  {
    header: 'Duration',
    accessorKey: 'durationSeconds',
    Cell: ({ cell }) =>
      cell.getValue() ? shortHumanizer(cell.getValue() * 1000) : '',
  },
];

export function SpeedRules({ speedRules = [] }) {
  function renderDetailPanel(cell) {
    return (
      <SpeedLimitBreakdown breakdown={cell.row.original.speedLimitBreakdown} />
    );
  }

  return (
    <Stack direction="row">
      <MaterialReactTable
        data={speedRules}
        columns={columns}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableSorting={false}
        defaultColumn={{ size: 0 }}
        initialState={{ density: 'compact' }}
        muiTableBodyRowProps={{
          sx: { '&:last-child td, &:last-child th': { border: 0 } },
        }}
        renderDetailPanel={renderDetailPanel}
      />
    </Stack>
  );
}
