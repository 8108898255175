import {
  useDeleteRadio,
  useDocumentTitle,
  useRadio,
  useUpsertPerson,
  useUpsertRadio,
  useUserInfo,
} from '@/hooks';
import { isAuthorised } from '@/utils';
import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { RadioForm } from './RadioForm';

export function Radio() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: radio } = useRadio(id);
  const { mutate: upsertRadio } = useUpsertRadio();
  const { mutate: upsertPerson } = useUpsertPerson();
  const { mutate: deleteRadio } = useDeleteRadio();
  const { data: userInfo } = useUserInfo();
  const canEdit = isAuthorised(userInfo?.authorisation, 'people', true);
  useDocumentTitle(
    ['IR3', 'Radios', radio?._id ? radio?.ssi : 'New Radio']
      .filter(Boolean)
      .join(' | '),
  );

  function handleDelete() {
    deleteRadio(id, {
      onSuccess: () => {
        navigate('..', { replace: true });
      },
    });
  }

  function handleSubmit(values) {
    upsertRadio(values, {
      onSuccess: (data) => {
        navigate(`../${data.ssi}`, { replace: true });
      },
    });
  }

  function handleRemove(id) {
    upsertPerson(
      { _id: id, radioSsi: null },
      {
        onSuccess: () =>
          queryClient.invalidateQueries({ queryKey: ['radios'] }),
      },
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 1, bgcolor: 'background.paper' }}>
      <RadioForm
        key={[radio?._id, radio?.lastEdit?.time]}
        defaultValues={radio}
        canEdit={canEdit}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        onRemove={handleRemove}
      />
    </Box>
  );
}
