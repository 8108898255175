import { ReplayLink } from '@/components/controls';
import { round, shortHumanizer } from '@/utils';
import { useDallasKeys } from '@/utils/config';
import { Stack } from '@mui/material';
import { format } from 'date-fns';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

const columns = [
  {
    header: 'Staff ID',
    id: 'driver.code',
    accessorFn: ({ driver: { code } = {} } = {}) => code ?? '',
  },
  {
    header: 'Forenames',
    id: 'driver.forenames',
    accessorFn: ({ driver: { forenames } = {} } = {}) => forenames ?? '',
  },
  {
    header: 'Surname',
    id: 'driver.surname',
    accessorFn: ({ driver: { surname } = {} } = {}) => surname ?? '',
  },
  {
    header: 'Collar Number',
    id: 'driver.collarNumber',
    accessorFn: ({ driver: { collarNumber } = {} } = {}) => collarNumber ?? '',
  },
  {
    header: 'Role',
    id: 'driver.role',
    accessorFn: ({ driver: { role } = {} } = {}) => role ?? '',
  },
  {
    header: 'Warning Equipment',
    id: 'equipmentActivations.emergencyOn',
    accessorFn: ({ equipmentActivations: { emergencyOn } = {} } = {}) =>
      emergencyOn ? 'Yes' : 'No',
  },
  {
    header: useDallasKeys ? 'Dallas Key #' : 'RFID Card #',
    id: 'rfidCard.reference',
    accessorFn: ({ rfidCard: { reference } = {} } = {}) => reference ?? '',
  },
  {
    header: `${useDallasKeys ? 'Dallas Key' : 'RFID Card'} Type`,
    id: 'rfidCard.type',
    accessorFn: ({ rfidCard: { type } = {} } = {}) => type ?? '',
  },
  {
    header: `${useDallasKeys ? 'Dallas Key' : 'RFID Card'} Label`,
    id: 'rfidCard.label',
    accessorFn: ({ rfidCard: { label } = {} } = {}) => label ?? '',
  },
  {
    header: 'Start Time',
    accessorKey: 'startTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'End Time',
    accessorKey: 'endTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Duration',
    accessorKey: 'durationSeconds',
    Cell: ({ cell }) =>
      Number.isNaN(cell.getValue())
        ? ''
        : shortHumanizer(cell.getValue() * 1000),
  },
  {
    header: 'Distance',
    accessorKey: 'distanceMiles',
    Cell: ({ cell }) =>
      Number.isNaN(cell.getValue()) ? '' : `${round(cell.getValue(), 2)} mi`,
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
    muiTableFooterCellProps: { align: 'right' },
  },
];

export function VisitTrips({ trips = [] }) {
  function renderRowActions(cell) {
    return (
      <ReplayLink
        collection="trips"
        identifier={cell.row.original.identifier}
      />
    );
  }

  const table = useMaterialReactTable({
    columns,
    data: trips,
    initialState: {
      density: 'compact',
      sorting: [{ id: 'startTime', desc: false }],
    },
    defaultColumn: { size: 0 },
    renderRowActions,
    enableRowActions: true,
    enableSorting: false,
    positionActionsColumn: 'last',
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enablePagination: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    muiTableBodyRowProps: {
      sx: { '&:last-child td, &:last-child th': { border: 0 } },
    },
  });

  return (
    <Stack direction="row">
      <MaterialReactTable table={table} />
    </Stack>
  );
}
