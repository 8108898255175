import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertUserQuery() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ _id, created: _c, lastEdit: _l, ...json }) => {
      if (_id) {
        return api.put(`userQueries/${_id}`, { json }).json();
      } else {
        return api.post('userQueries', { json }).json();
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['userQueries'] }),
  });
}
