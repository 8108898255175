import { sideNavOpenAtom } from '@/atoms';
import { Menu as MenuIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useAtom } from 'jotai';

export function MenuButton() {
  const [, setIsSideNavOpen] = useAtom(sideNavOpenAtom);

  function toggleSideNav() {
    setIsSideNavOpen((value) => !value);
  }

  return (
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      sx={{ mr: 1 }}
      onClick={toggleSideNav}
    >
      <MenuIcon />
    </IconButton>
  );
}
