import { shortHumanizer } from '@/utils';
import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  sorting: [{ id: 'startTime', desc: false }],
  pagination: {
    pageIndex: 0,
    pageSize: 25,
  },
  query: {},
  parameters: {},
});

export const pipelineFn = (pagination, query, sorting) =>
  [
    { $match: query },
    {
      $project: {
        identifier: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        startLocation: {
          $ifNull: [
            { $first: '$startLocations' },
            { name: 'Elsewhere', type: 'None' },
          ],
        },
        endLocation: {
          $ifNull: [
            { $first: '$endLocations' },
            { name: 'Elsewhere', type: 'None' },
          ],
        },
        person: {
          code: true,
          forenames: true,
          surname: true,
          role: true,
          collarNumber: true,
          rank: { code: true },
          radioSsi: true,
        },
      },
    },
    sorting.length > 0 && {
      $sort: sorting.reduce(
        (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
        {},
      ),
    },
    { $skip: pagination.pageIndex * pagination.pageSize },
    { $limit: pagination.pageSize },
  ].filter(Boolean);

export const totalsPipelineFn = (query) => [
  { $match: query },
  {
    $group: {
      _id: null,
      total: { $sum: 1 },
      durationSeconds: { $sum: '$durationSeconds' },
    },
  },
  { $unset: '_id' },
];

export const downloadPipelineFn = (collection) => (query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: { $divide: ['$durationSeconds', 86400] },
      startLocation: {
        $ifNull: [
          { $first: '$startLocations' },
          { name: 'Elsewhere', type: 'None' },
        ],
      },
      endLocation: {
        $ifNull: [
          { $first: '$endLocations' },
          { name: 'Elsewhere', type: 'None' },
        ],
      },
      person: {
        code: true,
        forenames: true,
        surname: true,
        role: true,
        collarNumber: true,
        rank: { code: true },
      },
      link: {
        $concat: [
          `${window.location.origin}/eventreplay/${collection}/`,
          '$identifier',
        ],
      },
    },
  },
];

export const columnsFn = (footer) => [
  {
    header: 'Staff ID',
    id: 'person.code',
    accessorFn: ({ person: { code } = {} } = {}) => code ?? '',
  },
  {
    header: 'Forenames',
    id: 'person.forenames',
    accessorFn: ({ person: { forenames } = {} } = {}) => forenames ?? '',
  },
  {
    header: 'Surname',
    id: 'person.surname',
    accessorFn: ({ person: { surname } = {} } = {}) => surname ?? '',
  },
  {
    header: 'Role',
    id: 'person.role',
    accessorFn: ({ person: { role } = {} } = {}) => role ?? '',
  },
  {
    header: 'Collar Number',
    id: 'person.collarNumber',
    accessorFn: ({ person: { collarNumber } = {} } = {}) => collarNumber ?? '',
  },
  {
    header: 'Rank',
    id: 'person.rank.code',
    accessorFn: ({ person: { rank: { code } = {} } = {} } = {}) => code ?? '',
  },
  {
    header: 'Start Location Type',
    id: 'startLocation.type',
    accessorFn: ({ startLocation: { type } = {} } = {}) => type ?? '',
  },
  {
    header: 'Start Location Name',
    id: 'startLocation.name',
    accessorFn: ({ startLocation: { name } = {} } = {}) => name ?? '',
  },
  {
    header: 'End Location Type',
    id: 'endLocation.type',
    accessorFn: ({ endLocation: { type } = {} } = {}) => type ?? '',
  },
  {
    header: 'End Location Name',
    id: 'endLocation.name',
    accessorFn: ({ endLocation: { name } = {} } = {}) => name ?? '',
  },
  {
    header: 'Start Time',
    accessorKey: 'startTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'End Time',
    accessorKey: 'endTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Duration',
    accessorKey: 'durationSeconds',
    Cell: ({ cell }) =>
      Number.isNaN(cell.getValue())
        ? ''
        : shortHumanizer(cell.getValue() * 1000),
    footer: Number.isNaN(footer.durationSeconds)
      ? ''
      : shortHumanizer(footer.durationSeconds * 1000),
  },
];
