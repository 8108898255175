import {
  useDeletePerson,
  useDocumentTitle,
  useLocations,
  useOptions,
  useOptionValues,
  usePeople,
  usePerson,
  useRadios,
  useUpsertPerson,
  useUserInfo,
} from '@/hooks';
import { isAuthorised } from '@/utils';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PersonForm } from './PersonForm';

export function Person() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: person } = usePerson(id);
  const { data: people } = usePeople();
  const { data: radios } = useRadios();
  const { mutate: upsertPerson } = useUpsertPerson();
  const { mutate: deletePerson } = useDeletePerson();
  const { data: homeStationNames } = useLocations({ homeStations: true });
  const { data: wards } = useLocations({ type: 'Ward' });
  useDocumentTitle(
    `IR3 | People | ${
      person?._id ? `${person.forenames} ${person.surname}` : 'New Person'
    }`,
  );
  const typeOptions = useOptionValues('groupType');
  const personGroups = useOptionValues('personGroup');
  const { data: personRoles } = useOptions('personRole');
  const { data: personRanks } = useOptions('personRank');
  const { data: activeDirectoryGroups } = useOptions('activeDirectoryGroup');
  const { data: personAttributes } = useOptions('personAttribute');
  const personSkills = useOptionValues('personSkill');
  const { data: userInfo } = useUserInfo();
  const canEdit = isAuthorised(userInfo?.authorisation, 'people', true);
  const supervisors = useMemo(
    () =>
      people
        .sort(
          (a, b) =>
            (a.surname ?? '').localeCompare(b.surname ?? '') ||
            (a.forenames ?? '').localeCompare(b.forenames ?? ''),
        )
        .map((person) => ({
          label: `${person.forenames} ${person.surname}`.trim(),
          value: person.code,
        })),
    [people],
  );

  function handleDelete() {
    deletePerson(id, {
      onSuccess: () => {
        navigate('..', { replace: true });
      },
    });
  }

  function handleSubmit(values) {
    upsertPerson(values, {
      onSuccess: (data) => {
        navigate(`../${data.code}`, { replace: true });
      },
    });
  }

  return (
    <Box sx={{ flexGrow: 1, p: 1, bgcolor: 'background.paper' }}>
      <PersonForm
        key={[person?._id, person?.lastEdit?.time]}
        defaultValues={person}
        canEdit={canEdit}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        supervisors={supervisors}
        homeStationNames={homeStationNames}
        radios={radios}
        wards={wards}
        typeOptions={typeOptions}
        personAttributes={personAttributes}
        personRoles={personRoles}
        personRanks={personRanks}
        personSkills={personSkills}
        personGroups={personGroups}
        activeDirectoryGroups={activeDirectoryGroups}
      />
    </Box>
  );
}
