import { TagField, WeeklySchedulePicker } from '@/components/controls';
import { useDocumentTitle, useOptionValues } from '@/hooks';
import { startCase } from '@/utils';
import {
  Edit as EditIcon,
  LocationSearching as FollowIcon,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { groupsToLabelAccessors } from '../utils';
import { ItemAvatar } from './ItemAvatar';
import { ItemRows } from './ItemRows';

// example object
/*
$type: "add"
areas: Array(1)
  0:
    name: "City Centre"
    type: "district"
boundaryIdentifier: "OS_BRTN"
boundarySubtype: "Base"
boundaryType: "Location"
briefs: Array(2)
  0: "OPS"
  1: "AAA"
complianceSeconds: 1200
occurrenceNumber: "OCUR123"
days: Array(7)
  0: 0
  1: 1
  2: 2
  3: 3
  4: 4
  5: 5
  6: 6
description: "<p><img src="data:image/jpeg;base64,/9j/4AAQSkZJRg"
endTime: "2021-04-11T10:59:00.000Z"
hours: Array(12)
  0: 0
  1: 2
  2: 4
  3: 6
  4: 8
  5: 10
  6: 12
  7: 14
  8: 16
  9: 18
  10: 20
  11: 22
id: "FO"
identifier: "FO"
lastEdit:
  time: "2019-12-03T11:39:59.286Z"
  userId: "john.leonard@landaconsultants.com"
searchString: "[object object]+os_brtn+base+location+ops,aaa+1200"
startTime: "2018-09-09T23:00:00.000Z"
title: "First Objective"
type: "Patrol"
// visits have:
requiredFrequency: 'daily',
requiredVisits: 5,

*/

export function ObjectiveLiveItem({ item, onFollowToggle, followedIdsByType }) {
  useDocumentTitle(
    ['IR3', 'Live', 'Objectives', item?.title || item?.id]
      .filter(Boolean)
      .join(' | '),
  );
  const navigate = useNavigate();
  const showFollow = item.boundary || (item.microbeats || []).length > 0;
  const type = 'objectives';
  const following = followedIdsByType?.[type]?.[item.id];
  const vehicleGroups = useOptionValues('vehicleGroup');

  const rowItems = [
    !!item.occurrenceNumber && {
      label: 'Occurrence Number',
      value: item.occurrenceNumber,
    },
    {
      label: 'Start',
      value: item.startTime && format(new Date(item.startTime), 'dd/MM/yyyy'),
    },
    { label: 'End', value: format(new Date(item.endTime), 'dd/MM/yyyy') },
    item.complianceSeconds && {
      label: 'Compliant Minutes',
      value: `${item.complianceSeconds / 60} m`,
    },
    item.requiredVisits && {
      label: 'Visits',
      value: item.requiredVisits,
    },
    item.requiredFrequency && {
      label: 'Frequency',
      value: startCase(item.requiredFrequency),
    },
    (item.microbeats || []).length > 0 && {
      label: 'Microbeats',
      value: (item.microbeats || []).join(', '),
    },
    // item.briefs && { label: 'Briefs', value: item.briefs.join(', ') },
    //TODO change to appropriate objective config
    ...groupsToLabelAccessors(vehicleGroups, item.groups),
  ].filter(Boolean);

  const link = `/${type}/${item.id}`;

  return (
    <Card sx={{ m: 1 }}>
      <CardHeader
        avatar={<ItemAvatar item={item} type="objectives" />}
        title={item.title}
        subheader={item.id}
        action={
          <Fragment>
            <Tooltip title="Edit">
              <IconButton onClick={() => navigate(link)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            {showFollow && (
              <Tooltip title="Toggle follow">
                <IconButton onClick={() => onFollowToggle(type, item.id)}>
                  <FollowIcon
                    fontSize="small"
                    color={following ? 'primary' : 'inherit'}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Fragment>
        }
      />
      <CardContent>
        <TagField
          collection={type}
          id={item.id}
          linkPrefix="/live/tags"
          sx={{ pb: 2 }}
          label="Tags"
        />
        <Typography variant="subtitle2" color="textSecondary">
          Content
        </Typography>
        <Box
          sx={{ p: 1, '& img': { maxWidth: ['100%', '!important'] } }}
          dangerouslySetInnerHTML={{ __html: item.contentHTML }}
        />
        <Typography variant="subtitle2" color="textSecondary">
          Details
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>{ItemRows(rowItems, item)}</TableBody>
        </Table>
        <Typography variant="subtitle2" color="textSecondary">
          Schedule
        </Typography>
        {/* <div className={classes.timeContainer}> */}
        <Box sx={{ pt: 1 }}>
          <WeeklySchedulePicker value={item.schedule} disabled scale={0.46} />
        </Box>
        {/* </div> */}
      </CardContent>
    </Card>
  );
}
