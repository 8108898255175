import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  startTime: null,
  endTime: null,
  users: [],
  sections: [],
  pages: [],
  operations: [],
  types: [],
  sorting: [{ id: 'time', desc: true }],
  pagination: {
    pageIndex: 0,
    pageSize: 25,
  },
  errors: {
    startTime: null,
    endTime: null,
  },
});

export const columns = [
  { header: 'User', accessorKey: 'UserName' },
  { header: 'Section', accessorKey: 'Section' },
  { header: 'Page', accessorKey: 'WebPage' },
  { header: 'Operation', accessorKey: 'Operation' },
  { header: 'Type', accessorKey: 'EntityTypeName' },
  {
    header: 'Time',
    accessorKey: 'LocalAuditDate',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  { header: 'Description', accessorKey: 'Description' },
];

export const csvColumns = columns
  .map(({ header, key, accessorKey }) => ({
    header,
    key: key ?? accessorKey,
  }))
  .concat({ header: 'XML', key: 'XmlCustomProperties' });

export const downloadPipelineFn = (
  startTime,
  endTime,
  users = [],
  sections = [],
  pages = [],
  operations = [],
  types = [],
) => [
  {
    $match: {
      LocalAuditDate: { $gte: startTime, $lt: endTime },
      UserName: users.length > 0 ? { $in: users } : undefined,
      Section: sections.length > 0 ? { $in: sections } : undefined,
      WebPage: pages.length > 0 ? { $in: pages } : undefined,
      Operation: operations.length > 0 ? { $in: operations } : undefined,
      EntityTypeName: types.length > 0 ? { $in: types } : undefined,
    },
  },
  {
    $project: {
      _id: false,
      UserName: true,
      Section: true,
      WebPage: true,
      Operation: true,
      EntityTypeName: true,
      LocalAuditDate: true,
      Description: true,
      XmlCustomProperties: true,
    },
  },
];

export function getErrorDescription(reason) {
  switch (reason) {
    case 'maxDate':
      return 'After end';
    case 'minDate':
      return 'Before start';
    case 'disableFuture':
      return 'Future';
    case 'invalidDate':
      return 'Invalid';
    default:
      return null;
  }
}
