import { useGroupOptions } from '@/hooks';
import { Autocomplete, Chip, TextField } from '@mui/material';

export function GroupCodesPicker({
  value,
  onChange,
  label,
  placeholder,
  fullWidth,
  size,
  types,
  ancestors,
}) {
  const groupOptions = useGroupOptions({ types, ancestors });
  const groupBy = (option) => groupOptions?.[option].type;
  const getOptionLabel = (option) => groupOptions?.[option]?.name ?? option;

  function handleChange(_, selections) {
    onChange(selections);
  }

  function renderInput(params) {
    return (
      <TextField
        label={label}
        placeholder={
          (value ?? []).length > 0
            ? placeholder
              ? '...'
              : undefined
            : placeholder
        }
        {...params}
      />
    );
  }

  function renderTags(value, getTagProps) {
    return value.map((option, index) => {
      const { key, ...props } = getTagProps({ index });

      return (
        <Chip
          key={key}
          label={groupOptions?.[option]?.name}
          icon={
            <Chip
              color="primary"
              label={groupOptions?.[option]?.type}
              size="small"
            />
          }
          {...props}
        />
      );
    });
  }

  return (
    <Autocomplete
      value={value ?? []}
      fullWidth={fullWidth}
      size={size}
      multiple
      onChange={handleChange}
      options={Object.keys(groupOptions || {})}
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      // isOptionEqualToValue={(option, value) =>
      //   value ? option.value === value : false
      // }
      sx={{ minWidth: 360 }}
      renderInput={renderInput}
      renderTags={renderTags}
    />
  );
}
