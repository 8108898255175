import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useConfigurationState(imei, name) {
  return useQuery({
    queryKey: ['configurationState', imei, name],
    queryFn: () => {
      log('Read', 'Configuration State', { imei, name });

      return api.get(`telematicsBox/${imei}/${name}`).json();
    },
    staleTime: 1000 * 60 * 60,
  });
}
