import { ExportPicker } from '@/components/controls';
import { getPersonPolls, radioPollHeaders, shortPersonHeaders } from '@/utils';

export function DownloadPollsLink({ entry }) {
  const personTrailsHeaders = [...shortPersonHeaders, ...radioPollHeaders];

  async function fetchDataToConvert() {
    const polls = await getPersonPolls(
      entry.person.radioSsi,
      entry.startTime,
      entry.endTime,
    );

    const data = polls.map((trail) => ({
      ...trail,
      name: entry.person.forenames + ' ' + entry.person.surname,
      personRole: entry.person.role,
      collarNumber: entry.person.collarNumber,
      radioSsi: entry.person.radioSsi,
      time: new Date(trail.time),
      longitude: trail.position ? trail.position.coordinates[0] : 0,
      latitude: trail.position ? trail.position.coordinates[1] : 0,
    }));

    return data;
  }

  return (
    <ExportPicker
      fetchDataToConvert={fetchDataToConvert}
      filename="Person Trail"
      headers={personTrailsHeaders}
    />
  );
}
