import { shortHumanizer } from '@/utils';
import { useDallasKeys } from '@/utils/config';
import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  sorting: [{ id: 'startTime', desc: false }],
  pagination: {
    pageIndex: 0,
    pageSize: 25,
  },
  query: {},
  parameters: {},
});

export const pipelineFn = (pagination, query, sorting) =>
  [
    { $match: query },
    {
      $project: {
        identifier: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        point: true,
        driver: {
          code: true,
          forenames: true,
          surname: true,
          collarNumber: true,
          role: true,
        },
        vehicle: {
          registrationNumber: true,
          fleetNumber: true,
          role: true,
          homeStation: true,
          groups: {
            division: {
              $reduce: {
                input: '$vehicle.groups.division',
                initialValue: '',
                in: {
                  $concat: [
                    '$$value',
                    { $cond: [{ $eq: ['$$value', ''] }, '', ', '] },
                    '$$this',
                  ],
                },
              },
            },
          },
          type: true,
          telematicsBoxImei: true,
        },
        rfidCard: {
          reference: true,
          type: true,
          label: true,
        },
        location: {
          $ifNull: [
            { $first: '$locations' },
            { name: 'Elsewhere', type: 'None' },
          ],
        },
      },
    },
    sorting.length > 0 && {
      $sort: sorting.reduce(
        (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
        {},
      ),
    },
    { $skip: pagination.pageIndex * pagination.pageSize },
    { $limit: pagination.pageSize },
  ].filter(Boolean);

export const totalsPipelineFn = (query) => [
  { $match: query },
  {
    $group: {
      _id: null,
      total: { $sum: 1 },
      durationSeconds: { $sum: '$durationSeconds' },
    },
  },
  { $unset: '_id' },
];

export const downloadPipelineFn = (collection) => (query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: { $divide: ['$durationSeconds', 86400] },
      driver: true,
      vehicle: true,
      rfidCard: true,
      location: {
        $ifNull: [
          { $first: '$locations' },
          { name: 'Elsewhere', type: 'None' },
        ],
      },
      link: {
        $concat: [
          `${window.location.origin}/eventreplay/${collection}/`,
          '$identifier',
        ],
      },
    },
  },
];

export const columnsFn = (footer) => [
  {
    header: 'Registration',
    id: 'vehicle.registrationNumber',
    accessorFn: ({ vehicle: { registrationNumber } = {} } = {}) =>
      registrationNumber ?? '',
  },
  {
    header: 'Fleet Number',
    id: 'vehicle.fleetNumber',
    accessorFn: ({ vehicle: { fleetNumber } = {} } = {}) => fleetNumber ?? '',
  },
  {
    header: 'Role',
    id: 'vehicle.role',
    accessorFn: ({ vehicle: { role } = {} } = {}) => role ?? '',
  },
  {
    header: 'Division',
    id: 'vehicle.groups.division',
    accessorFn: ({ vehicle: { groups: { division } } = {} } = {}) =>
      division ?? '',
  },
  {
    header: 'Type',
    id: 'vehicle.type',
    accessorFn: ({ vehicle: { type } = {} } = {}) => type ?? '',
  },
  {
    header: 'Staff ID',
    id: 'lastDriver.code',
    accessorFn: ({ lastDriver: { code } = {} } = {}) => code ?? '',
  },
  {
    header: 'Forenames',
    id: 'lastDriver.forenames',
    accessorFn: ({ lastDriver: { forenames } = {} } = {}) => forenames ?? '',
  },
  {
    header: 'Surname',
    id: 'lastDriver.surname',
    accessorFn: ({ lastDriver: { surname } = {} } = {}) => surname ?? '',
  },
  {
    header: 'Collar Number',
    id: 'lastDriver.collarNumber',
    accessorFn: ({ lastDriver: { collarNumber } = {} } = {}) =>
      collarNumber ?? '',
  },
  {
    header: 'Driver Role',
    id: 'lastDriver.role',
    accessorFn: ({ lastDriver: { role } = {} } = {}) => role ?? '',
  },
  {
    header: 'IMEI',
    id: 'vehicle.telematicsBoxImei',
    accessorFn: ({ vehicle: { telematicsBoxImei } = {} } = {}) =>
      telematicsBoxImei ?? '',
  },
  {
    header: useDallasKeys ? 'Dallas Key' : 'RFID Card',
    id: 'lastRfidCard.reference',
    accessorFn: ({ lastRfidCard: { reference } = {} } = {}) => reference ?? '',
  },
  {
    header: `${useDallasKeys ? 'Dallas Key' : 'RFID Card'} Type`,
    id: 'lastRfidCard.type',
    accessorFn: ({ lastRfidCard: { type } = {} } = {}) => type ?? '',
  },
  {
    header: `${useDallasKeys ? 'Dallas Key' : 'RFID Card'} Label`,
    id: 'lastRfidCard.label',
    accessorFn: ({ lastRfidCard: { label } = {} } = {}) => label ?? '',
  },
  {
    header: 'Home Station',
    id: 'vehicle.homeStation',
    accessorFn: ({ vehicle: { homeStation } = {} } = {}) => homeStation ?? '',
  },
  {
    header: 'Location Name',
    id: 'location.name',
    accessorFn: ({ location: { name } = {} } = {}) => name ?? '',
  },
  {
    header: 'Location Type',
    id: 'location.type',
    accessorFn: ({ location: { type } = {} } = {}) => type ?? '',
  },
  {
    header: 'Start Time',
    accessorKey: 'startTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'End Time',
    accessorKey: 'endTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Duration',
    accessorKey: 'durationSeconds',
    Cell: ({ cell }) =>
      Number.isNaN(cell.getValue())
        ? ''
        : shortHumanizer(cell.getValue() * 1000),
    footer: Number.isNaN(footer.durationSeconds)
      ? ''
      : shortHumanizer(footer.durationSeconds * 1000),
  },
];
