import { MapLayerSwitcher } from '@/components/controls';
import { useEffectOnce } from '@/hooks';
import { mapExtent, mapLayers } from '@/utils/config';
import { getBaseLayers, getMapView, getReplayStyle } from '@/utils/mapStyles';
import {
  Label as LabelIcon,
  LabelOff as LabelOffIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@mui/icons-material';
import { alpha, Box, IconButton } from '@mui/material';
import { Map } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { click, pointerMove } from 'ol/events/condition';
import { applyTransform, extend } from 'ol/extent';
import { GeoJSON } from 'ol/format';
import {
  Select as SelectInteraction,
  defaults as defaultInteractions,
} from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { getTransform } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { useEffect, useRef, useState } from 'react';

const typeGroups = {
  location: 'locations',
  event: 'events',
  incident: 'incidents',
  vehicle: 'vehicles',
  person: 'people',
  objective: 'objectives',
};

const emptyFeatureCollection = {
  type: 'FeatureCollection',
  features: [],
};

export function EventReplayMap({
  selectedItemIndex,
  followedItemIndexes,
  hoveredItemIndex,
  featureCollections,
  paths,
  onSelectItem,
  onHoverItem,
}) {
  const [zoomInDisabled, setZoomInDisabled] = useState(false);
  const [zoomOutDisabled, setZoomOutDisabled] = useState(false);
  const [showLabelsIcon, setShowLabelsIcon] = useState(false);
  const [refitMap, setRefitMap] = useState(false);

  const mapDiv = useRef(null);
  const itemSources = useRef({});
  const itemLayers = useRef([]);
  const pathSources = useRef({});
  const pathLayers = useRef([]);
  const map = useRef(null);
  const select = useRef(null);
  const hover = useRef(null);
  const showLabels = useRef(false);

  function getDefaultStyle(feature) {
    return getReplayStyle(
      'default',
      feature,
      showLabels.current,
      map.current.getView().getZoom(),
    );
  }

  function getSelectStyle(feature) {
    return getReplayStyle(
      'select',
      feature,
      showLabels.current,
      map.current.getView().getZoom(),
    );
  }

  function getHoverStyle(feature) {
    return getReplayStyle(
      'hover',
      feature,
      showLabels.current,
      map.current.getView().getZoom(),
    );
  }

  function getPathStyle(feature) {
    return getReplayStyle(
      'path',
      feature,
      showLabels.current,
      map.current.getView().getZoom(),
    );
  }

  function handleSelect(event) {
    hover.current.getFeatures().clear();
    select.current.getFeatures().clear();

    if (event.selected.length > 0) {
      onSelectItem({
        id: event.selected[0].getId(),
        type: event.selected[0].get('type'),
      });
      onHoverItem({});
    } else {
      onSelectItem({});
    }
  }

  function handleHover(event) {
    if (event.selected.length > 0) {
      onHoverItem({
        id: event.selected[0].getId(),
        type: event.selected[0].get('type'),
      });
    } else {
      onHoverItem({});
    }
  }

  function resetMapControls() {
    setZoomInDisabled(
      map.current.getView().getZoom() === map.current.getView().getMaxZoom(),
    );
    setZoomOutDisabled(
      map.current.getView().getZoom() === map.current.getView().getMinZoom(),
    );
  }

  useEffect(() => {
    if (refitMap) {
      const extents =
        followedItemIndexes.length > 0
          ? followedItemIndexes.map((index) =>
              itemSources.current[typeGroups[index.type]]
                .getFeatureById(index.id)
                .getGeometry()
                .getExtent(),
            )
          : Object.values(pathSources.current).map((source) =>
              source.getExtent(),
            );

      let extent = null;
      extents.forEach((ex) => {
        if (extent) {
          extent = extend(extent, ex);
        } else {
          extent = ex;
        }
      });

      if (
        extent[0] === Infinity ||
        extent[1] === Infinity ||
        extent[2] === -Infinity ||
        extent[3] === -Infinity
      ) {
        map.current
          .getView()
          .fit(
            applyTransform(mapExtent, getTransform('EPSG:4326', 'EPSG:3857')),
          );
      } else {
        map.current.getView().fit(extent, {
          maxZoom:
            followedItemIndexes.length > 0
              ? map.current.getView().getZoom()
              : 17,
        });
      }

      resetMapControls();
      setRefitMap(false);
    }
  }, [followedItemIndexes, refitMap]);

  function fitMap() {
    setRefitMap(true);
  }

  useEffectOnce(() => {
    if (!map.current) {
      itemLayers.current = Object.values(typeGroups).map((group) => {
        itemSources.current[group] = featureCollections
          ? new VectorSource({
              features: new GeoJSON().readFeatures(
                featureCollections[group] || emptyFeatureCollection,
                {
                  featureProjection: 'EPSG:3857',
                },
              ),
            })
          : new VectorSource({ features: [] });
        return new VectorLayer({
          source: itemSources.current[group],
          style: getDefaultStyle,
        });
      });

      pathLayers.current = ['vehicles', 'people'].map((group) => {
        pathSources.current[group] = paths
          ? new VectorSource({
              features: new GeoJSON().readFeatures(
                paths[group] || emptyFeatureCollection,
                {
                  featureProjection: 'EPSG:3857',
                },
              ),
            })
          : new VectorSource({ features: [] });
        return new VectorLayer({
          source: pathSources.current[group],
          style: getPathStyle,
        });
      });

      select.current = new SelectInteraction({
        filter: (feature, layer) => itemLayers.current.includes(layer),
        style: getSelectStyle,
        condition: click,
      });
      select.current.on('select', handleSelect);

      hover.current = new SelectInteraction({
        filter: (feature, layer) => itemLayers.current.includes(layer),
        style: getHoverStyle,
        condition: pointerMove,
      });
      hover.current.on('select', handleHover);

      const baseLayers = getBaseLayers(mapLayers);

      map.current = new Map({
        target: mapDiv.current,
        layers: [...baseLayers, ...pathLayers.current, ...itemLayers.current],
        interactions: defaultInteractions({
          pinchRotate: false,
          altShiftDragRotate: false,
        }).extend([select.current, hover.current]),
        view: getMapView(),
        controls: defaultControls({
          attribution: false,
          rotate: false,
          zoom: false,
        }),
      });
    }
  });

  useEffect(() => {
    if (featureCollections) {
      Object.keys(featureCollections).forEach((group) => {
        itemSources.current[group].clear({ fast: true });
        itemSources.current[group].addFeatures(
          new GeoJSON().readFeatures(
            featureCollections[group] || emptyFeatureCollection,
            {
              featureProjection: 'EPSG:3857',
            },
          ),
        );
      });
    }
  }, [featureCollections]);

  useEffect(() => {
    select.current.getFeatures().clear();

    if (selectedItemIndex.id) {
      hover.current.getFeatures().clear();
      const feature = itemSources.current[
        typeGroups[selectedItemIndex.type]
      ]?.getFeatureById(selectedItemIndex.id);

      if (feature) {
        select.current.getFeatures().push(feature);
      }
    }
  }, [selectedItemIndex, featureCollections]);

  useEffect(() => {
    hover.current.getFeatures().clear();

    if (hoveredItemIndex.id) {
      const feature = itemSources.current[
        typeGroups[hoveredItemIndex.type]
      ]?.getFeatureById(hoveredItemIndex.id);

      if (feature) {
        hover.current.getFeatures().push(feature);
      }
    }
  }, [hoveredItemIndex, featureCollections]);

  useEffect(() => {
    if (paths) {
      Object.keys(paths).forEach((group) => {
        pathSources.current[group].clear({ fast: true });
        pathSources.current[group].addFeatures(
          new GeoJSON().readFeatures(paths[group] || emptyFeatureCollection, {
            featureProjection: 'EPSG:3857',
          }),
        );
      });

      fitMap();
    }
  }, [paths]);

  useEffect(() => {
    if (followedItemIndexes.length > 0) {
      fitMap();
    }
  }, [featureCollections, followedItemIndexes.length]);

  function fitMapToSelected() {
    if (selectedItemIndex.id) {
      map.current
        .getView()
        .fit(
          itemSources.current[typeGroups[selectedItemIndex.type]]
            .getFeatureById(selectedItemIndex.id)
            .getGeometry()
            .getExtent(),
          { maxZoom: 17 },
        );
    }
  }

  function handleLabelsToggleClick() {
    showLabels.current = !showLabels.current;
    setShowLabelsIcon(!showLabelsIcon);
    Object.values(itemSources.current).forEach((source) => source.changed());
    select.current.getFeatures().forEach((item) => item.changed());
    hover.current.getFeatures().forEach((item) => item.changed());
  }

  function handleZoomInClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() + 1);
      resetMapControls();
    }
  }

  function handleZoomOutClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() - 1);
      resetMapControls();
    }
  }

  return (
    <Box
      sx={{ position: 'relative', width: 1, height: 1, color: 'common.white' }}
    >
      <Box id="map" sx={{ width: 1, height: 1 }} ref={mapDiv} />
      <Box
        sx={(theme) => ({
          top: 8,
          left: 8,
          borderRadius: 1,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
        })}
      >
        <IconButton
          title="Zoom In"
          aria-label="Zoom In"
          disabled={zoomInDisabled}
          onClick={handleZoomInClick}
          size="large"
        >
          <ZoomInIcon />
        </IconButton>
        <IconButton
          title="Zoom Out"
          aria-label="Zoom Out"
          disabled={zoomOutDisabled}
          onClick={handleZoomOutClick}
          size="large"
        >
          <ZoomOutIcon />
        </IconButton>
        <IconButton
          title="Fit"
          aria-label="Fit"
          onClick={selectedItemIndex.id ? fitMapToSelected : fitMap}
          size="large"
        >
          <ZoomOutMapIcon />
        </IconButton>
        <IconButton
          title={showLabels ? 'Hide Labels' : 'Show Labels'}
          aria-label="Toggle Labels"
          onClick={handleLabelsToggleClick}
          size="large"
        >
          {showLabelsIcon ? <LabelOffIcon /> : <LabelIcon />}
        </IconButton>
      </Box>
      <MapLayerSwitcher mapRef={map} />
    </Box>
  );
}
