import { dioOptions } from '@/utils/config';
import { Cancel as CancelIcon } from '@mui/icons-material';
import { Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Fragment } from 'react/jsx-runtime';
import { ToggleSwitch } from './ToggleSwitch';
import { useDriverIdConfiguration } from './useDriverIdConfiguration';
import { useTrackingConfiguration } from './useTrackingConfiguration';

export function ConfigurationPanel({ imei }) {
  const driverId = useDriverIdConfiguration(imei);
  const tracking = useTrackingConfiguration(imei);

  return (
    <Stack
      spacing={1}
      sx={{
        p: 1,
        pl: 2,
        border: 1,
        borderColor: 'divider',
        borderRadius: 1,
        flexGrow: 1,
      }}
    >
      <Typography variant="caption" sx={{ fontWeight: 500 }}>
        Configuration
      </Typography>
      <Divider />
      {dioOptions.showDriverId && (
        <Fragment>
          <Stack
            direction="row"
            spacing={1}
            sx={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Driver ID
            </Typography>
            <ToggleSwitch configuration={driverId} />
          </Stack>
          {driverId.isPending && (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="caption">Update pending...</Typography>
              <Tooltip title="Cancel update">
                <IconButton
                  size="small"
                  color="error"
                  onClick={driverId.cancel}
                >
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
          <Divider />
        </Fragment>
      )}
      {dioOptions.showTracking && (
        <Fragment>
          <Stack
            direction="row"
            spacing={1}
            sx={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Tracking
            </Typography>
            <ToggleSwitch configuration={tracking} />
          </Stack>
          {tracking.isPending && (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="caption">Update pending...</Typography>
              <Tooltip title="Cancel update">
                <IconButton
                  size="small"
                  color="error"
                  onClick={tracking.cancel}
                >
                  <CancelIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
          <Divider />
        </Fragment>
      )}
    </Stack>
  );
}
