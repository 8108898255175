import { useAggregate } from '@/hooks';
import { decodeSignalStrength } from '@/utils';
import { Avatar, Box, Typography } from '@mui/material';
import { blue, green, red } from '@mui/material/colors';
import { format, subDays } from 'date-fns';
import { Fragment, useState } from 'react';
import {
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const lines = [
  {
    name: 'Battery',
    colour: red[500],
    dataKey: 'batteryVoltage',
    yAxisId: 'voltage',
    yLabel: 'Volts',
    type: 'monotone',
  },
  {
    name: 'Satellites',
    colour: green[500],
    dataKey: 'satelliteCount',
    yAxisId: 'count',
    yLabel: 'Count',
    type: 'stepAfter',
  },
  {
    name: 'GPRS',
    colour: blue[500],
    dataKey: (p) => decodeSignalStrength(p.signalStrength), //'internalCell',
    yAxisId: 'count',
    yLabel: 'Signal',
    type: 'stepAfter',
  },
];

export function TelematicsBoxChart({ imei, lastContact }) {
  const [hiddenLines, setHiddenLines] = useState([]);
  const { data: polls } = useAggregate(
    'telematicsBoxPolls',
    [
      {
        $match: {
          imei,
          time: { $gte: subDays(lastContact, 1), $lte: lastContact },
        },
      },
      {
        $project: {
          _id: false,
          time: true,
          batteryVoltage: '$deviceProperties.batteryVoltage',
          satelliteCount: '$deviceProperties.satelliteCount',
          signalStrength: '$deviceProperties.deviceSignalStrength',
        },
      },
      { $sort: { time: 1 } },
    ],
    true,
  );

  function handleLegendClick(selectedLine) {
    const index = hiddenLines.indexOf(selectedLine);

    if (index === -1) {
      setHiddenLines(hiddenLines.concat(selectedLine));
    } else {
      setHiddenLines(
        hiddenLines.slice(0, index).concat(hiddenLines.slice(index + 1)),
      );
    }
  }

  return (
    <Fragment>
      {polls.length > 0 ? (
        <Fragment>
          <Box
            sx={{
              pl: 8,
              pr: 2,
              pb: 1,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {lines.map((line) => (
              <Box
                key={line.name}
                sx={{
                  p: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => handleLegendClick(line.name)}
              >
                <Avatar
                  sx={{
                    width: 12,
                    height: 12,
                    mr: 0.5,
                    bgcolor: !hiddenLines.includes(line.name) && line.colour,
                  }}
                >
                  <Fragment />
                </Avatar>
                <Typography variant="caption">{line.name}</Typography>
              </Box>
            ))}
          </Box>
          <Box style={{ display: 'flex' }}>
            {lines
              .filter((line) => !hiddenLines.includes(line.name))
              .map((line) => (
                <Box key={line.name} style={{ flex: 'auto', width: 0 }}>
                  {/* width is 0 due to https://github.com/recharts/recharts/issues/172 (but workaround results in slow animation)
                    and https://stackoverflow.com/questions/7985021/css-flexbox-issue-why-is-the-width-of-my-flexchildren-affected-by-their-content 
                */}
                  <ResponsiveContainer height={280}>
                    <LineChart data={polls}>
                      <XAxis
                        dataKey="time"
                        tickFormatter={(value) =>
                          format(value, 'dd/MM HH:mm:ss')
                        }
                      >
                        <Label value="Time" position="bottom" offset={36} />
                      </XAxis>
                      <YAxis yAxisId={line.yAxisId}>
                        <Label
                          value={line.yLabel}
                          angle={-90}
                          position="insideLeft"
                          offset={24}
                        />
                      </YAxis>
                      <Tooltip
                        labelFormatter={(value) =>
                          format(value, 'dd/MM HH:mm:ss')
                        }
                      />
                      <Line
                        key={line.dataKey}
                        yAxisId={line.yAxisId}
                        type={line.type}
                        name={line.name}
                        dataKey={line.dataKey}
                        stroke={line.colour}
                        strokeWidth={1}
                        dot={false}
                        //hide={hiddenLines.includes(line.name)}
                        connectNulls={true}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              ))}
          </Box>
        </Fragment>
      ) : (
        <Box
          sx={{
            height: 320,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography variant="caption" sx={{ color: 'text.disabled' }}>
              No data
            </Typography>
          </Box>
        </Box>
      )}
    </Fragment>
  );
}
