import { Switch } from '@mui/material';

export function ToggleSwitch({
  configuration: { isEnabled, isPending, toggle } = {},
}) {
  function handleChange(event) {
    toggle(event.target.checked);
  }

  return (
    <Switch
      size="small"
      checked={isEnabled}
      onChange={handleChange}
      disabled={isPending}
    />
  );
}
