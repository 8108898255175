import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertGroup() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ _id, ancestors: _, ...json }) => {
      if (_id) {
        return api.put(`groups/${_id}`, { json }).json();
      } else {
        return api.post('groups', { json }).json();
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['groups'] }),
  });
}
