import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useUserQueries({ userId, collection }) {
  return useQuery({
    queryKey: ['userQueries', userId, collection],
    queryFn: () => {
      const json = [
        { $match: { 'created.userId': userId, collection } },
        { $sort: { lastEdit: -1 } },
      ];

      log('Read', 'User Queries');

      return api.post('pipeline/userQueries', { json }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !!userId,
  });
}
