import { acceptedAtom, sideNavOpenAtom } from '@/atoms';
import { titleRoutes, toolbarButtonRoutes, toolbarRoutes } from '@/routing';
import ir3Logo from '@/svg/white-logo.svg';
import { log } from '@/utils';
import { autoLogin, maintenance, promptText } from '@/utils/config';
import {
  AppBar,
  Box,
  Button,
  Snackbar as MuiSnackbar,
  Toolbar,
  Typography,
} from '@mui/material';
import { useAtom } from 'jotai';
import { Fragment, useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Outlet, useRoutes } from 'react-router-dom';
import { Maintenance } from './Maintenance';
import { SideNav } from './SideNav';
import { LoginAvatar } from './controls';

export function App() {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const [isSideNavOpen, setIsSideNavOpen] = useAtom(sideNavOpenAtom);
  const [accepted, setAccepted] = useAtom(acceptedAtom);
  const title = useRoutes(titleRoutes);
  const toolbar = useRoutes(toolbarRoutes);
  const toolbarButton = useRoutes(toolbarButtonRoutes);

  useEffect(() => {
    if (
      autoLogin &&
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  function toggleSideNav() {
    setIsSideNavOpen((value) => !value);
  }

  function handleAccept() {
    localStorage.setItem('accepted', true);
    setAccepted(true);

    log('Accept', 'Prompt');
  }

  if (maintenance) {
    return <Maintenance />;
  }

  return (
    <Fragment>
      {title && ( // get rid of this when live and retrospective are handled properly
        <Fragment>
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              borderBottom: 1,
              borderBottomStyle: 'solid',
              borderBottomColor: (theme) => theme.palette.divider,
            }}
          >
            <Toolbar variant="dense">
              {auth.isAuthenticated && toolbarButton}
              <img src={ir3Logo} alt="IR3 Logo" style={{ height: 24 }} />
              <Typography variant="h6" sx={{ pl: 0.5, fontWeight: 'bold' }}>
                IR3
              </Typography>
              <Typography variant="h6" sx={{ px: 0.5 }}>
                |
              </Typography>
              {title}
              <LoginAvatar />
            </Toolbar>
            {toolbar}
          </AppBar>
          <Toolbar variant="dense" />
        </Fragment>
      )}
      {toolbar && <Toolbar variant="dense" />}
      {auth.isAuthenticated && (
        <Fragment>
          <SideNav isOpen={isSideNavOpen} onClose={toggleSideNav} />
          {promptText && (
            <MuiSnackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={!accepted}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={
                <Box component="span" id="message-id">
                  {promptText}
                </Box>
              }
              action={
                <Button color="secondary" size="small" onClick={handleAccept}>
                  ACCEPT
                </Button>
              }
            />
          )}
        </Fragment>
      )}
      <Outlet />
    </Fragment>
  );
}
