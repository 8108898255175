import { AddCircle as AddIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { ValueList } from './ValueList';

export function ManageTypeValuesDialog({ open, name, initialValues, onClose }) {
  const [values, setValues] = useState(initialValues);

  function handleCancel() {
    onClose();
  }

  function handleSave() {
    onClose(Array.from(new Set(values.filter((value) => value !== ''))).sort());
  }

  const handleChange = (index) => (event) => {
    setValues((values) => {
      const newValues = [...values];
      newValues[index] = event.target.value;
      return newValues;
    });
  };

  const handleDeleteClick = (index) => () => {
    setValues((values) => {
      const newValues = [...values];
      newValues.splice(index, 1);
      return newValues;
    });
  };

  function handleAddClick() {
    setValues((values) => [...values, '']);
  }

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>{`Manage ${name} aliases`}</DialogTitle>
      <DialogContent>
        <ValueList
          values={values}
          handleChange={handleChange}
          handleDeleteClick={handleDeleteClick}
        />
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Add" placement="right">
            <IconButton onClick={handleAddClick} size="small">
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
