import { useMemo } from 'react';
import { useOptions } from './useOptions';

export function useOptionValueLookup(name, filter = {}) {
  const { data: options } = useOptions(name, filter);

  return useMemo(
    () =>
      options.reduce(
        (accumulator, { value: optionValue, label: optionLabel, values }) => ({
          ...accumulator,
          ...(values
            ? values.reduce(
                (acc, { value, label }) => ({
                  ...acc,
                  [value]: {
                    label,
                    type: { value: optionValue, label: optionLabel },
                  },
                }),
                {},
              )
            : {}),
        }),
        {},
      ),
    [options],
  );
}
