import { CustomTooltip, ScoreCard } from '@/components/controls';
import {
  useDocumentTitle,
  useGroupOptions,
  useLocationOptions,
  useOptionLookup,
  useOptions,
  useSetting,
} from '@/hooks';
import { downloadCSV, getTextWidth } from '@/utils';
import {
  Download as DownloadIcon,
  FilterList as FilterListIcon,
  NavigateNext as NavigateNextIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  CircularProgress,
  Collapse,
  Fade,
  IconButton,
  Link,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useAtom } from 'jotai';
import {
  MaterialReactTable,
  MRT_TablePagination,
  useMaterialReactTable,
} from 'material-react-table';
import { FilePdfBox as FilePdfBoxIcon } from 'mdi-material-ui';
import { useCallback, useMemo } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
  Bar,
  BarChart,
  Tooltip as ChartTooltip,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { FleetUtilisationPdfDocument } from './FleetUtilisationPdfDocument';
import { useVehicleUtilisationList } from './useVehicleUtilisationList';
import { useVehicleUtilisationSummary } from './useVehicleUtilisationSummary';
import {
  durationMonthsOptions,
  formatValue,
  measures,
  stateAtom,
} from './utils';

export function FleetUtilisation() {
  useDocumentTitle('IR3 | Fleet Utilisation');
  const { '*': wildcard } = useParams();
  const selectedGroups = wildcard ? wildcard.split('/').filter(Boolean) : [];
  const navigate = useNavigate();
  const [{ roles, durationMonths, showFilter }, setState] = useAtom(stateAtom);
  const { data: vehicleRoles } = useOptions('vehicleRole');
  const { data: fleetGroupHeirarchy } = useSetting('fleetGroupHeirarchy');
  const {
    data: summary,
    isFetching: summaryFetching,
    isLoading: summaryLoading,
  } = useVehicleUtilisationSummary(
    selectedGroups,
    selectedGroups.length === 0
      ? null
      : fleetGroupHeirarchy?.[selectedGroups.length - 1],
    durationMonths,
    roles.map((role) => role.value),
  );
  const {
    data: childSummary,
    isFetching: childrenFetching,
    isLoading: childrenLoading,
  } = useVehicleUtilisationSummary(
    selectedGroups,
    fleetGroupHeirarchy?.[selectedGroups.length],
    durationMonths,
    roles.map((role) => role.value),
  );
  const {
    data: vehicleList,
    isFetching: listFetching,
    isLoading: listLoading,
  } = useVehicleUtilisationList(
    selectedGroups,
    durationMonths,
    roles.map((role) => role.value),
  );
  const isLoading =
    summaryFetching ||
    childrenFetching ||
    listFetching ||
    summaryLoading ||
    childrenLoading ||
    listLoading;
  const typeOptions = useOptionLookup('groupType');
  const groupOptions = useGroupOptions();
  const locationOptions = useLocationOptions();
  const canDrillDown =
    fleetGroupHeirarchy &&
    selectedGroups.length < fleetGroupHeirarchy?.length - 1;
  const childType = useMemo(
    () =>
      fleetGroupHeirarchy?.[selectedGroups.length] === 'HOME_STATION'
        ? 'Home Station'
        : typeOptions?.[fleetGroupHeirarchy?.[selectedGroups.length]],
    [fleetGroupHeirarchy, selectedGroups.length, typeOptions],
  );
  const topAndBottom = useMemo(() => {
    return measures.reduce((acc, { key }) => {
      const sorted = vehicleList
        .slice()
        .sort((a, b) => b[key] - a[key])
        .map((item) => ({
          fleetNumber: item.fleetNumber,
          registrationNumber: item.registrationNumber,
          measure: item[key],
        }));
      return {
        ...acc,
        [key]: {
          top: sorted.slice(0, 10),
          bottom: sorted.slice(-10),
        },
      };
    }, {});
  }, [vehicleList]);
  const columns = useMemo(
    () => [
      {
        header: 'Fleet Number',
        accessorKey: 'fleetNumber',
      },
      {
        header: 'Registration',
        accessorKey: 'registrationNumber',
      },
      // {
      //   header: 'VIN',
      //   accessorKey: 'identificationNumber',
      // },
      {
        header: 'Role',
        id: 'role',
        accessorFn: ({ role }) =>
          vehicleRoles.find((r) => r.value === role)?.label ?? role ?? '',
      },
      {
        header: 'Home Base',
        id: 'homeStation',
        accessorFn: ({ homeStation }) =>
          locationOptions[homeStation]?.name ?? homeStation ?? '',
      },
      ...measures.map(({ label, key }) => ({
        header: label,
        accessorKey: key,
        muiTableHeadCellProps: { align: 'right' },
        muiTableBodyCellProps: { align: 'right' },
        Cell: ({ cell }) => formatValue(cell.getValue()),
      })),
    ],
    [locationOptions, vehicleRoles],
  );

  const formatLabel = useCallback(
    (code) => groupOptions[code]?.name ?? locationOptions[code]?.name ?? code,
    [groupOptions, locationOptions],
  );
  const xAxisHeight =
    Math.max(
      ...childSummary.map((item) =>
        getTextWidth(formatLabel(item.code), '12px Roboto'),
      ),
      0,
    ) + 16;

  function handleRolesChange(event, newRoles) {
    setState((prev) => ({ ...prev, roles: newRoles }));
  }

  function handleDurationMonthsChange(event, newDurationMonths) {
    setState((prev) => ({ ...prev, durationMonths: newDurationMonths }));
  }

  function handleFilterToggle() {
    setState((prev) => ({ ...prev, showFilter: !prev.showFilter }));
  }

  function handleBarClick(event) {
    const { code } = event.payload;
    navigate(`../fleetutilisation/${selectedGroups.join('/')}/${code}`);
  }

  function handleDownloadClick() {
    const headers = columns.map(({ header, accessorKey, id }) => ({
      label: header,
      key: accessorKey ?? id,
    }));

    downloadCSV(
      vehicleList,
      `fleet-utilisation-${durationMonths}m-${selectedGroups[selectedGroups.length - 1] ?? 'ALL'}.csv`,
      headers,
    );
  }

  const table = useMaterialReactTable({
    data: vehicleList,
    columns,
    state: {
      density: 'compact',
      isLoading: listLoading || listFetching,
    },
    defaultColumn: { size: 0 },
    enableTopToolbar: false,
    enableBottomToolbar: true,
    enablePagination: true,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableGlobalFilter: false,
    enableRowSelection: false,
    renderBottomToolbar: () => {
      return (
        <Stack
          direction="row"
          sx={{ pl: 1, justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box>
            <Tooltip title="Download data">
              <IconButton size="small" onClick={handleDownloadClick}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <MRT_TablePagination table={table} />
        </Stack>
      );
    },
  });

  return (
    <Stack
      spacing={1}
      sx={{
        backgroundColor: 'background.default',
        p: 1,
      }}
    >
      <Stack spacing={1} direction="row" sx={{ alignItems: 'center' }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          sx={{ pl: 1, flex: 1 }}
        >
          {selectedGroups.map((group, index) => (
            <Link
              key={group}
              underline="hover"
              component={RouterLink}
              to={`../fleetutilisation/${selectedGroups.slice(0, index).join('/')}`}
            >
              {formatLabel(group)}
            </Link>
          ))}
        </Breadcrumbs>
        <Fade
          in={isLoading}
          style={{ transitionDelay: isLoading ? '800ms' : '0ms' }}
          unmountOnExit
        >
          <CircularProgress size={16} color="inherit" />
        </Fade>
        <ToggleButtonGroup
          color="primary"
          size="small"
          value={durationMonths}
          exclusive
          onChange={handleDurationMonthsChange}
        >
          {durationMonthsOptions.map((option) => (
            <ToggleButton key={option} value={option}>
              {option} Months
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <Tooltip title={showFilter ? 'Hide filter' : 'Show filter'}>
          <ToggleButton
            value="filter"
            size="small"
            color="primary"
            selected={showFilter}
            onChange={handleFilterToggle}
          >
            <FilterListIcon />
          </ToggleButton>
        </Tooltip>
        <PDFDownloadLink
          document={
            <FleetUtilisationPdfDocument
              groupNames={selectedGroups.map((code) => formatLabel(code))}
              durationMonths={durationMonths}
              summary={summary?.[0]}
              childType={childType}
              childSummary={childSummary.map((row) => ({
                ...row,
                code: formatLabel(row.code),
              }))}
              topAndBottom={topAndBottom}
            />
          }
          fileName={`fleet-utilisation-${durationMonths}m-${selectedGroups.join('-')}.pdf`}
        >
          {({ loading }) =>
            loading ? (
              <CircularProgress size={16} color="inherit" />
            ) : (
              <Tooltip title="Download PDF">
                <ToggleButton value="pdf" size="small" color="primary">
                  <FilePdfBoxIcon />
                </ToggleButton>
              </Tooltip>
            )
          }
        </PDFDownloadLink>
      </Stack>
      <Collapse in={showFilter}>
        <Stack spacing={1}>
          <Autocomplete
            size="small"
            multiple
            fullWidth
            value={roles}
            onChange={handleRolesChange}
            options={vehicleRoles}
            renderInput={(params) => <TextField label="Roles" {...params} />}
          />
        </Stack>
      </Collapse>
      <Stack
        direction="row"
        spacing={1}
        sx={{ flexWrap: 'wrap', justifyContent: 'center' }}
      >
        {measures.map(({ key, label, color, Icon }) => (
          <ScoreCard
            key={key}
            score={summary?.[0]?.[key] ?? 0}
            title={label}
            unit="%"
            color={color}
            Icon={Icon}
            selected={true}
          />
        ))}
      </Stack>
      <Box sx={{ fontSize: '0.75rem' }}>
        <ResponsiveContainer height={400}>
          <BarChart data={childSummary} margin={{ bottom: 24 }}>
            <XAxis
              dataKey="code"
              tickFormatter={formatLabel}
              angle={-90}
              textAnchor="end"
              interval={0}
              height={xAxisHeight}
            >
              <Label
                value={childType}
                position="bottom"
                style={{ fontWeight: 'bold' }}
              />
            </XAxis>
            <YAxis unit="%" />
            {measures.map(({ key, label, color }) => (
              <Bar
                key={key}
                dataKey={key}
                name={label}
                stackId="a"
                fill={color}
                onClick={canDrillDown ? handleBarClick : undefined}
                cursor={canDrillDown ? 'pointer' : undefined}
              />
            ))}
            <ChartTooltip
              content={
                <CustomTooltip
                  labelFormatter={formatLabel}
                  valueFormatter={formatValue}
                />
              }
              wrapperStyle={{ pointerEvents: 'auto' }}
              cursor={false}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <MaterialReactTable table={table} />
    </Stack>
  );
}
