import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertTag() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ _id, ...json }) => {
      if (_id) {
        return api
          .patch(`tags/${_id}`, {
            headers: { 'content-type': 'application/merge-patch+json' },
            json,
          })
          .json();
      } else {
        const tagsWithSameTitle = await api
          .post('pipeline/tags', {
            json: [
              { $match: { title: json.title } },
              { $project: { _id: true } },
            ],
          })
          .json();

        if (tagsWithSameTitle.length > 0) {
          throw new Error('A tag with this title already exists');
        } else {
          return api.post('tags', { json }).json();
        }
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['tags'] }),
  });
}
