import { useDallasKeys } from '@/utils/config';

function transformNumericValue(value) {
  const { condition, measure, modifier } = value;

  if (measure === '' || measure === undefined) {
    return null;
  }

  const computed = measure * (modifier ?? 1);

  switch (condition) {
    case 'equal':
      return { $eq: computed };
    case 'notEqual':
      return { $ne: computed };
    case 'greater':
      return { $gt: computed };
    case 'greaterOrEqual':
      return { $gte: computed };
    case 'less':
      return { $lt: computed };
    case 'lessOrEqual':
      return { $lte: computed };
    default:
      return null;
  }
}

function transformSelectValue(value) {
  const { condition, selection } = value;

  if (
    ['includes', 'excludes'].includes(condition) &&
    (selection === '' || selection === undefined || selection.length === 0)
  ) {
    return null;
  }

  const isArray = Array.isArray(selection);

  switch (condition) {
    case 'includes':
      return isArray ? { $in: selection } : { $eq: selection };
    case 'excludes':
      return isArray ? { $nin: selection } : { $ne: selection };
    case 'any':
      return { $nin: [null, '', []] };
    case 'none':
      return { $in: [null, '', []] };
    default:
      return null;
  }
}

export function createQuery(
  { startTime, endTime, ...parameters },
  hideTimePeriod,
  pointEvent,
  visibleFilters,
  options,
  eventFilters = [],
) {
  const query = {};

  if (!hideTimePeriod) {
    if (pointEvent) {
      query.time = { $gte: startTime, $lt: endTime };
    } else {
      query.startTime = { $lt: endTime };
      query.endTime = { $gte: startTime };
    }
  }

  eventFilters.forEach((filter) => {
    if (parameters[filter.name]) {
      switch (filter.type) {
        case 'number': {
          const filterValue = transformNumericValue(parameters[filter.name]);
          if (filterValue !== null) {
            query[filter.name] = filterValue;
          }
          break;
        }
        case 'select': {
          const filterValue = transformSelectValue(parameters[filter.name]);
          if (filterValue !== null) {
            query[filter.name] = filterValue;
          }
          break;
        }
        default:
      }
    }
  });

  Object.entries(options)
    .filter(([type]) => visibleFilters[type])
    .forEach(([type, { fields }]) => {
      Object.keys(fields).forEach((name) => {
        if (parameters[`${type}.${name}`]) {
          const filterValue = transformSelectValue(
            parameters[`${type}.${name}`],
          );
          if (filterValue !== null) {
            query[`${type}.${name}`] = filterValue;
          }
        }
      });
    });

  return query;
}

export async function awaitObjectPromises(obj) {
  const entries = Object.entries(obj);
  const resolvedEntries = await Promise.all(
    entries.map(async ([key, promise]) => [key, await promise]),
  );
  return Object.fromEntries(resolvedEntries);
}

export const collections = {
  vehicle: 'vehicles',
  person: 'people',
  location: 'locations',
  objective: 'objectives',
  rfidCard: 'rfidCards',
  incident: 'incidents',
};

export const sections = {
  vehicle: { label: 'Vehicle', actual: 'vehicle' },
  person: { label: 'Person', actual: 'person' },
  driver: { label: 'Driver', actual: 'person' },
  people: { label: 'People', actual: 'person' },
  lastDriver: { label: 'Driver', actual: 'person' },
  location: { label: 'Location', actual: 'location' },
  objective: { label: 'Objective', actual: 'objective' },
  rfidCard: {
    label: useDallasKeys ? 'Dallas Key' : 'RFID Card',
    actual: 'rfidCard',
  },
  incident: { label: 'Incident', actual: 'incident' },
};
