import { NavLink, SearchBox } from '@/components/controls';
import { useDocumentTitle, useTags } from '@/hooks';
import { Add as AddIcon, More as MoreIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';

export function Tags() {
  useDocumentTitle('IR3 | Tags');
  const { id } = useParams();
  const { data: tags } = useTags();
  const [searchText, setsearchText] = useState('');
  const filteredList = useMemo(
    () =>
      tags
        .filter(
          ({ title }) =>
            title.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
            searchText === '',
        )
        .sort((a, b) => `${a.identifier}`.localeCompare(`${b.identifier}`)),
    [tags, searchText],
  );

  function handleSearchChange(event) {
    setsearchText(event.target.value);
  }

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 48px)' }}>
      <Stack
        sx={{
          width: 280,
          borderRight: 1,
          borderColor: 'divider',
          flexShrink: 0,
          height: 1,
        }}
      >
        <Box>
          <Stack
            direction="row"
            spacing={1}
            sx={{ p: 1, alignItems: 'center' }}
          >
            <SearchBox
              value={searchText}
              onChange={handleSearchChange}
              sx={{ flexGrow: 1 }}
              count={`${filteredList.length}/${tags.length}`}
            />
            <Tooltip title="Add new">
              <IconButton component={Link} to="new" size="small">
                <Avatar
                  sx={{
                    color: 'secondary.contrastText',
                    backgroundColor: 'secondary.main',
                    width: 24,
                    height: 24,
                    fontSize: 16,
                  }}
                >
                  <AddIcon fontSize="inherit" />
                </Avatar>
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
        <Divider />
        <List
          dense
          disablePadding
          sx={{
            height: 1,
            overflowY: 'auto',
          }}
        >
          {filteredList.map((tag) => (
            <ListItemButton
              dense
              key={tag.identifier}
              to={`/tags/${encodeURIComponent(tag.identifier)}`}
              component={NavLink}
              selected={id === tag.identifier}
            >
              <ListItemAvatar>
                <Avatar>
                  <MoreIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={tag.title} />
            </ListItemButton>
          ))}
        </List>
      </Stack>
      <Box sx={{ overflow: 'auto', flex: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
}
