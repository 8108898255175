import { shortHumanizer } from '@/utils';
import { Stack } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';

const columns = [
  {
    header: 'Speed Limit',
    accessorKey: 'kilometresPerHour',
    Cell: ({ cell, row }) =>
      row.original.unknownLimit
        ? '?'
        : `${(cell.getValue() * 0.62137119).toFixed()} mph`,
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
  },
  {
    header: 'Duration',
    accessorKey: 'durationSeconds',
    Cell: ({ cell }) =>
      cell.getValue() ? shortHumanizer(cell.getValue() * 1000) : '',
  },
  {
    header: 'Maximum Speed',
    accessorKey: 'maxSpeedKilometresPerHour',
    Cell: ({ cell }) =>
      cell.getValue() ? `${(cell.getValue() * 0.62137119).toFixed()} mph` : '',
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
  },
];

export function SpeedLimitBreakdown({ breakdown = [] }) {
  return (
    <Stack direction="row">
      <MaterialReactTable
        data={breakdown}
        columns={columns}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableSorting={false}
        defaultColumn={{ size: 0 }}
        initialState={{ density: 'compact' }}
        muiTableBodyRowProps={{
          sx: { '&:last-child td, &:last-child th': { border: 0 } },
        }}
      />
    </Stack>
  );
}
