import { shortHumanizer } from '@/utils';
import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  sorting: [{ id: 'startTime', desc: false }],
  pagination: {
    pageIndex: 0,
    pageSize: 25,
  },
  query: {},
  parameters: {},
});

export const pipelineFn = (pagination, query, sorting) =>
  [
    { $match: query },
    {
      $project: {
        _id: false,
        identifier: true,
        startTime: true,
        endTime: true,
        objective: true,
        durationSeconds: true,
        person: true,
        compliant: true,
      },
    },
    sorting.length > 0 && {
      $sort: sorting.reduce(
        (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
        {},
      ),
    },
    { $skip: pagination.pageIndex * pagination.pageSize },
    { $limit: pagination.pageSize },
  ].filter(Boolean);

export const totalsPipelineFn = (query) => [
  { $match: query },
  {
    $group: {
      _id: null,
      total: { $sum: 1 },
      durationSeconds: { $sum: '$durationSeconds' },
    },
  },
  { $unset: '_id' },
];

export const downloadPipelineFn = (query) => [
  { $match: query },
  {
    $project: {
      _id: false,
      identifier: true,
      startTime: true,
      endTime: true,
      objective: true,
      durationSeconds: true,
      person: true,
      compliant: true,
    },
  },
];

export const columnsFn = (footer) => [
  {
    header: 'Collar Number',
    id: 'person.collarNumber',
    accessorFn: ({ person: { collarNumber } = {} } = {}) => collarNumber ?? '',
  },
  {
    header: 'Forenames',
    id: 'person.forenames',
    accessorFn: ({ person: { forenames } = {} } = {}) => forenames ?? '',
  },
  {
    header: 'Surname',
    id: 'person.surname',
    accessorFn: ({ person: { surname } = {} } = {}) => surname ?? '',
  },
  {
    header: 'SSI',
    id: 'person.radioSsi',
    accessorFn: ({ person: { radioSsi } = {} } = {}) => radioSsi ?? '',
  },
  {
    header: 'Role',
    id: 'person.role',
    accessorFn: ({ person: { role } = {} } = {}) => role ?? '',
  },
  {
    header: 'Team',
    id: 'groups.team',
    accessorFn: ({ groups: { team } = {} } = {}) => team ?? '',
  },
  {
    header: 'Area',
    id: 'groups.area',
    accessorFn: ({ groups: { area } = {} } = {}) => area ?? '',
  },
  {
    header: 'Specialist',
    id: 'groups.specialist',
    accessorFn: ({ groups: { specialist } = {} } = {}) => specialist ?? '',
  },
  {
    header: 'Sector',
    id: 'groups.sector',
    accessorFn: ({ groups: { sector } = {} } = {}) => sector ?? '',
  },
  {
    header: 'Operation',
    id: 'groups.operation',
    accessorFn: ({ groups: { operation } = {} } = {}) => operation ?? '',
  },
  {
    header: 'BCU',
    id: 'groups.bcu',
    accessorFn: ({ groups: { bcu } = {} } = {}) => bcu ?? '',
  },
  {
    header: 'Force',
    id: 'groups.force',
    accessorFn: ({ groups: { force } = {} } = {}) => force ?? '',
  },
  {
    header: 'Objective Title',
    id: 'objective.title',
    accessorFn: ({ objective: { title } = {} } = {}) => title ?? '',
  },
  {
    header: 'Start Time',
    accessorKey: 'startTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'End Time',
    accessorKey: 'endTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Duration',
    accessorKey: 'durationSeconds',
    Cell: ({ cell }) =>
      cell.getValue() ? shortHumanizer(cell.getValue() * 1000) : '',
    footer: footer.durationSeconds
      ? shortHumanizer(footer.durationSeconds * 1000)
      : '',
  },
  {
    header: 'Compliant',
    id: 'compliant',
    accessorFn: ({ compliant }) => (compliant ? 'Yes' : 'No'),
  },
];
