import { api } from '@/apis';
import { log, startCase } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

/**
 * A hook that fetches the result of an aggregation pipeline.
 * @param {string} collection - The collection to aggregate.
 * @param {Array.<object>} pipeline - The pipeline to use for aggregation.
 * @param {boolean} [enabled] - Whether the query is enabled.
 * @param {number} [staleTime] - The stale time for the query.
 * @param {number} [cacheTime] - The cache time for the query.
 * @returns {import('@tanstack/react-query').UseQueryResult<object[]> & { cancel: () => Promise<void> }}
 */
export function useAggregate(
  collection,
  pipeline,
  enabled,
  staleTime,
  cacheTime,
) {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: [collection, 'aggregate', pipeline],
    queryFn: ({ signal }) => {
      log('Read', startCase(collection), { pipeline });

      return api
        .post(`pipeline/${collection}`, { json: pipeline, signal })
        .json();
    },
    placeholderData: (previousData) => previousData ?? [],
    staleTime,
    cacheTime,
    enabled: !!enabled,
  });

  return {
    ...query,
    cancel: () =>
      queryClient.cancelQueries({
        queryKey: [collection, 'aggregate', pipeline],
      }),
  };
}
