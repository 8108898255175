import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useAudits(
  startTime,
  endTime,
  users = [],
  dataTypes = [],
  actions = [],
  sorting,
  pagination,
) {
  const queryKey = [
    'audits',
    startTime,
    endTime,
    users,
    dataTypes,
    actions,
    sorting,
    pagination,
  ];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const json = [
        {
          $match: {
            time: { $gte: startTime, $lt: endTime },
            user: users.length > 0 ? { $in: users } : undefined,
            dataType: dataTypes.length > 0 ? { $in: dataTypes } : undefined,
            action: actions.length > 0 ? { $in: actions } : undefined,
          },
        },
        {
          $project: {
            user: true,
            dataType: true,
            time: true,
            action: true,
            parameters: true,
          },
        },
        sorting.length > 0 && {
          $sort: sorting.reduce(
            (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
            {},
          ),
        },
        { $skip: pagination.pageIndex * pagination.pageSize },
        { $limit: pagination.pageSize },
      ].filter(Boolean);

      log('Read', 'Audit Log Entries', {
        startTime,
        endTime,
        users,
        dataTypes,
        actions,
        sorting,
        pagination,
      });

      return api.post('pipeline/audits', { json, signal }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !!startTime && !!endTime,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
