import { useOptionLookup, useVehicle } from '@/hooks';
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Zoom,
} from '@mui/material';

export function VehicleCustomTooltip({ active, activityType, payload }) {
  const { identificationNumber, percentageInExcess } =
    active && payload.length ? payload[0].payload : {};
  const { data: vehicle } = useVehicle(active && identificationNumber);
  const vehicleGroups = useOptionLookup('vehicleGroup');

  if (active) {
    return (
      <Paper sx={{ overflowY: 'auto' }} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan="2" sx={{ textAlign: 'center' }}>
                {identificationNumber}
              </TableCell>
            </TableRow>
          </TableHead>

          {vehicle ? (
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Registration</Typography>
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  <Typography variant="caption">
                    {vehicle.registrationNumber}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">IMEI</Typography>
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  <Typography variant="caption">
                    {vehicle.telematicsBoxImei}
                  </Typography>
                </TableCell>
              </TableRow>
              {vehicle.type && (
                <TableRow>
                  <TableCell>
                    <Typography variant="caption">Type</Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    <Typography variant="caption">{vehicle.type}</Typography>
                  </TableCell>
                </TableRow>
              )}
              {Object.entries(vehicle.groups || []).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>
                    <Typography variant="caption">
                      {vehicleGroups[key]?.label ?? key}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    <Typography variant="caption">
                      {value.flat().join(', ')}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <Zoom
              in={true}
              style={{ transitionDelay: '250ms', transitionDuration: '800ms' }}
            >
              <TableBody>
                {[...Array(4).keys()].map((r) => (
                  <TableRow key={r}>
                    <TableCell>
                      <Skeleton
                        height={20}
                        width={76}
                        sx={{ fontSize: '1rem' }}
                      />
                    </TableCell>
                    <TableCell>
                      <Skeleton
                        height={20}
                        width={102}
                        sx={{ fontSize: '1rem' }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Zoom>
          )}
        </Table>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan="2" sx={{ textAlign: 'center' }}>
                Readings
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: activityType === '' ? 'bold' : 'normal' }}
                >
                  Any
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: activityType === '' ? 'bold' : 'normal' }}
                >
                  {`${percentageInExcess?.total.toFixed(2)}%`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight:
                      activityType === 'acceleration' ? 'bold' : 'normal',
                  }}
                >
                  Acceleration
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight:
                      activityType === 'acceleration' ? 'bold' : 'normal',
                  }}
                >
                  {percentageInExcess?.acceleration.toFixed(2)}%
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: activityType === 'braking' ? 'bold' : 'normal',
                  }}
                >
                  Braking
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: activityType === 'braking' ? 'bold' : 'normal',
                  }}
                >
                  {percentageInExcess?.braking.toFixed(2)}%
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight:
                      activityType === 'cornering' ? 'bold' : 'normal',
                  }}
                >
                  Cornering
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight:
                      activityType === 'cornering' ? 'bold' : 'normal',
                  }}
                >
                  {percentageInExcess?.cornering.toFixed(2)}%
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }
  return null;
}
