import { BoundaryEditor, Parameters } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { downloadCSV } from '@/utils';
import { Download as DownloadIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import {
  MaterialReactTable,
  MRT_TablePagination,
  useMaterialReactTable,
} from 'material-react-table';
import { useCallback, useMemo, useState } from 'react';
import { Layer, Source } from 'react-map-gl/maplibre';
import { DownloadPollsLink } from './DownloadPollsLink';
import { usePersonBoundaryVisits } from './usePersonBoundaryVisits';
import { columnsFn, stateAtom } from './utils';

export function PersonBoundaryVisits() {
  useDocumentTitle('IR3 | Person Boundary Visits');
  const theme = useTheme();
  const [{ boundary, query, parameters }, setState] = useAtom(stateAtom);
  const {
    data: features,
    isFetching,
    isLoading,
    cancel,
  } = usePersonBoundaryVisits({
    boundary,
    query,
  });
  const aggregated = useMemo(
    () =>
      features.reduce(
        (acc, { properties: { durationSeconds = 0 } = {} }) => ({
          durationSeconds: acc.durationSeconds + durationSeconds,
        }),
        { durationSeconds: 0 },
      ),
    [features],
  );
  const lineStringFeatureCollection = useMemo(
    () => ({
      type: 'FeatureCollection',
      features: features.filter(
        (feature) => feature.geometry.type === 'LineString',
      ),
    }),
    [features],
  );
  const pointFeatureCollection = useMemo(
    () => ({
      type: 'FeatureCollection',
      features: features.filter((feature) => feature.geometry.type === 'Point'),
    }),
    [features],
  );
  const columns = useMemo(() => columnsFn(aggregated), [aggregated]);
  const [error, setError] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const rowSelection = useMemo(
    () => (selectedId ? { [selectedId]: true } : {}),
    [selectedId],
  );
  const { path, start, end } = useMemo(() => {
    if (!selectedId) {
      return {};
    }

    const path = features.find((feature) => +feature.id === selectedId);
    const start = path?.geometry.coordinates[0] && {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: path.geometry.coordinates[0],
      },
    };
    const end = path?.geometry.coordinates[
      path.geometry.coordinates.length - 1
    ] && {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates:
          path.geometry.coordinates[path.geometry.coordinates.length - 1],
      },
    };

    return { path, start, end };
  }, [features, selectedId]);

  function handleCancel() {
    cancel();
    setState((state) => ({ ...state, query: {} }));
  }

  function handleParametersChange(value) {
    setState((state) => ({ ...state, parameters: value }));
  }

  function handleFetch(event, value) {
    if (boundary) {
      setError(null);
      setState((state) => ({ ...state, query: value }));
    } else {
      setError('Required');
    }
  }

  function handleBoundaryChange(value) {
    setState((state) => ({ ...state, query: {}, boundary: value }));
  }

  const handleMapClick = useCallback((event) => {
    setSelectedId(event.features?.[0]?.id);
  }, []);

  function renderRowActions(cell) {
    return <DownloadPollsLink entry={cell.row.original} />;
  }

  function handleDownloadClick() {
    const headers = columns.map(({ header, id, accessorKey }) => ({
      label: header,
      key: id ?? accessorKey,
    }));

    downloadCSV(
      features.map((feature) => ({
        ...feature,
        properties: {
          ...feature.properties,
          durationSeconds: feature.properties.durationSeconds / 86400,
        },
      })),
      'person-boundary-visits.csv',
      headers,
    );
  }

  function renderBottomToolbar() {
    return (
      <Stack
        direction="row"
        sx={{ pl: 1, justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box>
          {features?.length > 0 && (
            <Tooltip title="Download visits">
              <IconButton size="small" onClick={handleDownloadClick}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <MRT_TablePagination table={table} />
      </Stack>
    );
  }

  const handleRowClick = (id) => () => {
    setSelectedId((value) => (value === id ? null : id));
  };

  const table = useMaterialReactTable({
    columns,
    data: features,
    initialState: {
      sorting: [{ id: 'properties.startTime', desc: false }],
    },
    state: {
      density: 'compact',
      isLoading: isFetching || isLoading,
      rowSelection,
    },
    defaultColumn: { size: 0 },
    getRowId: (row) => +row.id,
    renderRowActions,
    renderBottomToolbar,
    positionActionsColumn: 'last',
    positionToolbarAlertBanner: 'none',
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableTopToolbar: true,
    enableSorting: true,
    enableBottomToolbar: true,
    enablePagination: true,
    enableRowActions: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiTableContainerProps: {
      sx: {
        width: 'calc(100vw - 298px)',
        maxHeight: 'calc(100vh - 178px)', // 48 app header + 56 top bar + 56 bottom bar
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: handleRowClick(row.id),
      sx: { cursor: 'pointer' },
    }),
  });

  return (
    <Stack direction="row" sx={{ height: 'calc(100vh - 48px)' }}>
      <Box
        sx={{
          width: 280,
          height: 'calc(100vh - 48px)',
          borderRight: 1,
          borderColor: 'divider',
          flexShrink: 0,
        }}
      >
        <Parameters
          collection="personBoundaryVisits"
          onFetch={handleFetch}
          onCancel={handleCancel}
          isFetching={isFetching || isLoading}
          value={parameters}
          onChange={handleParametersChange}
          sx={{ pt: 1, height: 1 }}
          person
          pointEvent
        />
      </Box>
      <Stack spacing={1} sx={{ p: 1, overflow: 'auto' }}>
        <BoundaryEditor
          value={boundary}
          onChange={handleBoundaryChange}
          interactiveLayerIds={['line-visit-layer', 'point-visit-layer']}
          onClick={handleMapClick}
          error={error}
          sx={{ minWidth: 240, height: 640, flexShrink: 0 }}
        >
          <Source
            id="line-visits"
            type="geojson"
            data={lineStringFeatureCollection}
          >
            <Layer
              id="line-visit-layer"
              type="line"
              paint={{
                'line-color': theme.palette.text.primary,
                'line-width': 2,
              }}
              layout={{
                'line-cap': 'round',
                'line-join': 'round',
              }}
            />
          </Source>
          <Source
            id="point-visits"
            type="geojson"
            data={pointFeatureCollection}
          >
            <Layer
              id="point-visit-layer"
              type="circle"
              paint={{
                'circle-color': theme.palette.text.primary,
                'circle-radius': 2,
              }}
            />
          </Source>
          {path && (
            <Source id="selected-path" type="geojson" data={path}>
              <Layer
                id="selected-path-layer"
                type="line"
                paint={{
                  'line-color': theme.palette.primary.main,
                  'line-width': 2,
                }}
                layout={{
                  'line-cap': 'round',
                  'line-join': 'round',
                }}
              />
            </Source>
          )}
          {start && (
            <Source id="start" type="geojson" data={start}>
              <Layer
                id="start-layer"
                type="circle"
                paint={{
                  'circle-color': theme.palette.success.main,
                  'circle-radius': 4,
                }}
              />
            </Source>
          )}
          {end && (
            <Source id="end" type="geojson" data={end}>
              <Layer
                type="circle"
                paint={{
                  'circle-color': theme.palette.error.main,
                  'circle-radius': 4,
                }}
              />
            </Source>
          )}
        </BoundaryEditor>
        <Box>
          <MaterialReactTable table={table} />
        </Box>
      </Stack>
    </Stack>
  );
}
