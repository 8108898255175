import { round, shortHumanizer } from '@/utils';
import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  sorting: [{ id: 'startTime', desc: false }],
  pagination: {
    pageIndex: 0,
    pageSize: 25,
  },
  query: {},
  parameters: {},
});

export const pipelineFn = (pagination, query, sorting) =>
  [
    { $match: query },
    {
      $project: {
        identifier: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        distanceMiles: { $multiply: ['$distanceKilometres', 0.62137119] },
        location: {
          name: true,
          type: true,
          subtype: true,
        },
        vehicle: {
          registrationNumber: true,
          fleetNumber: true,
          role: true,
          groups: {
            division: {
              $reduce: {
                input: '$vehicle.groups.division',
                initialValue: '',
                in: {
                  $concat: [
                    '$$value',
                    { $cond: [{ $eq: ['$$value', ''] }, '', ', '] },
                    '$$this',
                  ],
                },
              },
            },
          },
          type: true,
          telematicsBoxImei: true,
        },
      },
    },
    sorting.length > 0 && {
      $sort: sorting.reduce(
        (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
        {},
      ),
    },
    { $skip: pagination.pageIndex * pagination.pageSize },
    { $limit: pagination.pageSize },
  ].filter(Boolean);

export const totalsPipelineFn = (query) => [
  { $match: query },
  {
    $group: {
      _id: null,
      total: { $sum: 1 },
      durationSeconds: { $sum: '$durationSeconds' },
      distanceKilometres: { $sum: '$distanceKilometres' },
    },
  },
  {
    $set: {
      distanceMiles: {
        $round: [{ $multiply: ['$distanceKilometres', 0.62137119] }, 2],
      },
    },
  },
  { $unset: '_id' },
];

export const downloadPipelineFn = (collection) => (query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: { $divide: ['$durationSeconds', 86400] },
      distanceMiles: {
        $round: [{ $multiply: ['$distanceKilometres', 0.62137119] }, 2],
      },
      location: true,
      vehicle: true,
      link: {
        $concat: [
          `${window.location.origin}/eventreplay/${collection}/`,
          '$identifier',
        ],
      },
    },
  },
];

export const columnsFn = (footer) => [
  {
    header: 'Registration',
    id: 'vehicle.registrationNumber',
    accessorFn: ({ vehicle: { registrationNumber } = {} } = {}) =>
      registrationNumber ?? '',
  },
  {
    header: 'Fleet Number',
    id: 'vehicle.fleetNumber',
    accessorFn: ({ vehicle: { fleetNumber } = {} } = {}) => fleetNumber ?? '',
  },
  {
    header: 'Role',
    id: 'vehicle.role',
    accessorFn: ({ vehicle: { role } = {} } = {}) => role ?? '',
  },
  {
    header: 'Division',
    id: 'vehicle.groups.division',
    accessorFn: ({ vehicle: { groups: { division } = {} } = {} } = {}) =>
      division ?? '',
  },
  {
    header: 'Type',
    id: 'vehicle.type',
    accessorFn: ({ vehicle: { type } = {} } = {}) => type ?? '',
  },
  {
    header: 'IMEI',
    id: 'vehicle.telematicsBoxImei',
    accessorFn: ({ vehicle: { telematicsBoxImei } = {} } = {}) =>
      telematicsBoxImei ?? '',
  },
  {
    header: 'Location Type',
    id: 'location.type',
    accessorFn: ({ location: { type } = {} } = {}) => type ?? '',
  },
  {
    header: 'Location Subtype',
    id: 'location.subtype',
    accessorFn: ({ location: { subtype } = {} } = {}) => subtype ?? '',
  },
  {
    header: 'Location Name',
    id: 'location.name',
    accessorFn: ({ location: { name } = {} } = {}) => name ?? '',
  },
  {
    header: 'Start Time',
    accessorKey: 'startTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'End Time',
    accessorKey: 'endTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Duration',
    accessorKey: 'durationSeconds',
    Cell: ({ cell }) =>
      Number.isNaN(cell.getValue())
        ? ''
        : shortHumanizer(cell.getValue() * 1000),
    footer: Number.isNaN(footer.durationSeconds)
      ? ''
      : shortHumanizer(footer.durationSeconds * 1000),
  },
  {
    header: 'Distance',
    accessorKey: 'distanceMiles',
    Cell: ({ cell }) =>
      Number.isNaN(cell.getValue()) ? '' : `${round(cell.getValue(), 2)} mi`,
    footer: Number.isNaN(footer.distanceMiles)
      ? ''
      : `${round(footer.distanceMiles, 2)} mi`,
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
    muiTableFooterCellProps: { align: 'right' },
  },
];
