import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

export function useTag(id) {
  return useQuery({
    queryKey: ['tags', id],
    queryFn: async () => {
      if (id === 'new') {
        return {
          title: '',
        };
      }

      const json = [
        { $match: { identifier: id } },
        {
          $project: {
            identifier: true,
            title: true,
            description: true,
            items: true,
            lastEdit: true,
          },
        },
      ];

      const tags = await api.post('pipeline/tags', { json }).json();

      log('Read', 'Tags', { id });

      return tags[0] ?? null;
    },
    staleTime: 1000 * 60 * 60,
  });
}
