import { useLocationTypes } from '@/hooks';
import { containsKeyIgnoreCase, startCase } from '@/utils';
import { isFleet, liveOptions } from '@/utils/config';
import {
  commonResourceColours,
  statusIconColoursByType,
} from '@/utils/constants';
import {
  Avatar,
  AvatarGroup,
  Box,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  useMediaQuery,
} from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { getTypeIcons } from './constants';
import { clipPathForType } from './utils';

const { vehiclesHaveCallsigns, showCallsignsOnLegend, callSignStatusCategory } =
  liveOptions;

function andJoin(strings) {
  return strings
    .map((s) => s.toLowerCase())
    .map(startCase)
    .join(', ')
    .replace(/, ([^,]*)$/, ' and $1');
}

function sectionLength(s) {
  return s.types?.length ?? Object.keys(s.statusColours).length;
}

function getDynamicStausColors(statusColor, statuses = []) {
  const customStatusColor = {};
  const customStatuses = [...statuses, 'stale'];

  customStatuses.forEach((status) => {
    if (containsKeyIgnoreCase(statusColor, status)) {
      customStatusColor[status] =
        statusColor[status] ?? statusColor[status.toLowerCase()];
    }
  });
  return customStatusColor;
}

function assembleLegend(authorisedTypes, dynamicStatus, locationTypes) {
  const callSigns = showCallsignsOnLegend ? 'callSigns' : undefined;
  // start with callsigns, then vehicles (- callsigns),
  // then the others
  let statusColoursForIcons = [
    {
      statusColours: commonResourceColours,
      icons: isFleet
        ? []
        : vehiclesHaveCallsigns
          ? [callSigns, 'vehicles'].filter(Boolean)
          : [callSigns].filter(Boolean),
    },
    {
      /* statusColours: difference(
        statusIconColoursByType['vehicle'],
        commonResourceColours
      ), */
      statusColours: getDynamicStausColors(
        statusIconColoursByType['vehicle'],
        dynamicStatus['vehicle'],
      ),
      icons: ['vehicles', 'MOTORCYCLE'],
      authType: 'objectives', // override auth checking for MOTORCYCLE
    },
    {
      title: 'People',
      statusColours: getDynamicStausColors(
        statusIconColoursByType['person'],
        dynamicStatus['person'],
      ),
      icons: ['people'],
    },
    {
      // title: 'Incidents (number is grade)',
      title: 'Incidents',
      statusColours: getDynamicStausColors(
        statusIconColoursByType['incident'],
        dynamicStatus['incident'],
      ),
      icons: ['incidents'],
    },
    {
      title: 'Objectives',
      statusColours: getDynamicStausColors(
        statusIconColoursByType['objective'],
        dynamicStatus['objective'],
      ),
      icons: ['objectives'],
      authType: 'objectives', // override auth checking for Patrol, Visit etc
    },
    {
      title: 'Locations',
      types: Object.values(locationTypes)
        .filter((type) => !type.isBoundary)
        .map((type) => type.value),
      icons: ['locations', 'Workshop'],
      authType: 'locations', // override auth checking for sub types
    },
    {
      title: 'Events',
      types: ['Accelerometer'],
      icons: ['events'],
      authType: 'events', // override auth checking for sub types
    },
    {
      title: 'Radios',
      statusColours: getDynamicStausColors(
        statusIconColoursByType['radio'],
        dynamicStatus['radio'],
      ),
      icons: ['radios'],
    },
    {
      title: 'Telematics Boxes',
      statusColours: getDynamicStausColors(
        statusIconColoursByType['telematicsBox'],
        dynamicStatus['telematicsBox'],
      ),
      icons: ['telematicsBoxes'],
    },
    // {
    //   title: 'Common',
    //   statusColours: statusIconColoursByType.default,
    //   icons: [
    //     'vehicles',
    //     'people',
    //     'locations',
    //     'objectives',
    //     'events',
    //   ],
    // },
  ];

  // go through these and eliminate the unauthorised types
  statusColoursForIcons.forEach((s) => {
    // if a list has a general auth type use that
    if (s.authType) {
      if (!authorisedTypes.includes(s.authType)) {
        s.icons = [];
      }
    } else {
      s.icons = s.icons.filter((i) => authorisedTypes.includes(i));
    }
  });

  // filter out the ones that have no icons left, or have no status/types
  statusColoursForIcons = statusColoursForIcons.filter(
    (s) => s.icons.length > 0 && sectionLength(s) > 0,
  );

  // if any have the same icons left, merge them
  let coloursByUniqueIcons = {};
  statusColoursForIcons.forEach((s) => {
    const key = s.icons.join();
    // if it exists, merge it
    if (coloursByUniqueIcons[key]) {
      coloursByUniqueIcons[key] = {
        ...coloursByUniqueIcons[key],
        statusColours: {
          ...(coloursByUniqueIcons[key].statusColours || {}),
          ...s.statusColours,
        },
      };
    } else {
      // otherwise add it as new
      coloursByUniqueIcons[key] = s;
    }
  });
  statusColoursForIcons = Object.values(coloursByUniqueIcons);

  // sort these by # of colours so hopefully legend looks ok
  return _.orderBy(statusColoursForIcons, sectionLength, ['asc']);
}

export function LiveLegend({ authorisedTypes }) {
  const vehicles = useSelector((state) => state.live.vehicles);
  const telematicsBoxes = useSelector((state) => state.live.telematicsBoxes);
  const radios = useSelector((state) => state.live.radios);
  const people = useSelector((state) => state.live.people);
  const incidents = useSelector((state) => state.live.incidents);
  const objectives = useSelector((state) => state.live.objectives);
  const { data: locationTypes } = useLocationTypes();

  const vehicleStatus = [
    ...new Set(
      Object.keys(vehicles || [])
        .map((key) => vehicles[key])
        .filter((r) => !r.stale)
        .map((v) => v.status)
        .filter((s) => s !== undefined),
    ),
  ];

  const telematicsBoxStatus = [
    ...new Set(
      Object.keys(telematicsBoxes || [])
        .map((key) => telematicsBoxes[key])
        .filter((r) => !r.stale)
        .map((v) => v.status)
        .filter((s) => s !== undefined),
    ),
  ];

  const peopleCallsignStatus = [
    ...new Set(
      Object.keys(people || {})
        .map((key) => people[key])
        .filter((r) => !r.stale)
        .map((p) => p.assignments?.callSign?.status)
        .map((s) => callSignStatusCategory[s])
        .filter((v) => v !== undefined),
    ),
  ];

  const radioStatus = [
    ...new Set(
      Object.keys(radios || [])
        .map((key) => radios[key])
        .filter((r) => !r.stale)
        .map((v) => v.status)
        .filter((s) => s !== undefined),
    ),
  ];

  const incidentStatus = [
    ...new Set(
      Object.keys(incidents || {})
        .map((key) => incidents[key])
        .map((i) => i?.status)
        .filter((v) => v !== undefined),
    ),
  ];

  const objectiveStatus = [
    ...new Set(
      Object.keys(objectives || {})
        .map((key) => objectives[key])
        .map((o) => o?.status)
        .filter((v) => v !== undefined),
    ),
  ];

  const dynamicStatus = {
    person: peopleCallsignStatus,
    radio: radioStatus,
    incident: incidentStatus,
    objective: objectiveStatus,
    vehicle: vehicleStatus,
    telematicsBox: telematicsBoxStatus,
  };

  const legend = assembleLegend(authorisedTypes, dynamicStatus, locationTypes);

  let columns = 1;
  if (useMediaQuery((theme) => theme.breakpoints.up('sm'))) {
    columns++;
  }
  if (useMediaQuery((theme) => theme.breakpoints.up('md'))) {
    columns++;
  }
  if (useMediaQuery((theme) => theme.breakpoints.up('lg'))) {
    columns++;
  }

  let table = [...Array(columns)].map(() => []);
  let section = legend.pop();
  while (section) {
    // find whichever has the min columns
    let min = undefined;
    let minIndex = 0;
    for (let i = 0; i < columns; i++) {
      const height = table[i].reduce((total, s) => total + sectionLength(s), 0);
      min = min ?? height;
      if (height < min) {
        min = height;
        minIndex = i;
      }
    }

    // add this section to that
    table[minIndex].push(section);
    section = legend.pop();
  }

  function iconify(key) {
    const ti = getTypeIcons(locationTypes);
    const Icon = ti[key];
    if (Icon) {
      return <Icon sx={{ width: 16, height: 16 }} />;
    }

    return key;
  }

  const itemHeight = 48;
  const max = table.reduce((max, col) => {
    const height =
      col.length + col.reduce((total, s) => total + sectionLength(s), 0);
    return height > max ? height : max;
  }, 0);
  const maxHeight = itemHeight * max;

  function getAvatar({ key, type, elementKey, colours, content }) {
    const [borderColor, background, color] = colours;

    // if there's a clipPath it's an odd shape and we'll have to
    // fudge an outline/border as css border won't work
    const clipPath =
      clipPathForType(key) ||
      clipPathForType(type, key) ||
      clipPathForType(type);

    return (
      <Avatar
        variant={clipPath ? 'square' : 'circular'}
        key={elementKey}
        style={{
          borderColor,
          background: clipPath ? 'unset' : background,
          color,
          border: clipPath ? 'revert' : [2, 'solid', borderColor],
          width: clipPath ? 32 : 28,
          height: clipPath ? 32 : 28,
        }}
      >
        {clipPath ? (
          <svg width="32px" height="32px">
            <g>
              <g>
                <rect
                  style={{ clipPath }}
                  height="32px"
                  width="32px"
                  fill={borderColor}
                ></rect>
              </g>
              <g transform="translate(2,2)">
                <rect
                  style={{ clipPath }}
                  height="28px"
                  width="28px"
                  fill={background}
                ></rect>
              </g>
            </g>
            {typeof content === 'string' ? (
              <g transform="scale(1,1),translate(10,22)">
                <text fill={color}>{content}</text>
              </g>
            ) : (
              <g transform="scale(.5,.5),translate(16,17)">{content}</g>
            )}
          </svg>
        ) : (
          content
        )}
      </Avatar>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {table.map((column, i) => (
        <List
          key={i}
          dense
          sx={{ display: 'flex', flexFlow: 'column wrap', overflow: 'auto' }}
          style={maxHeight ? { maxHeight } : {}}
        >
          {column.map((section, i) => (
            <Box key={i}>
              <ListSubheader sx={{ lineHeight: '40px' }} disableSticky>
                {section.title || andJoin(section.icons)}
              </ListSubheader>
              {section.types &&
                section.types.map((key) => {
                  const mainType = section.icons[0];

                  return (
                    <ListItem key={key}>
                      {getAvatar({
                        key,
                        type: mainType,
                        elementKey: key,
                        colours:
                          section.statusColours?.[key] ||
                          statusIconColoursByType.default.default,
                        content: iconify(
                          getTypeIcons(locationTypes)[key] ? key : mainType,
                        ),
                      })}
                      <ListItemText sx={{ ml: 1 }} primary={startCase(key)} />
                    </ListItem>
                  );
                })}
              {section.statusColours &&
                Object.keys(section.statusColours).map((key) => {
                  return (
                    <ListItem key={key}>
                      <AvatarGroup max={7}>
                        {(section.content || section.icons).map((i) => {
                          const mainType = section.content ?? section.icons[0];

                          return getAvatar({
                            key: i,
                            type: mainType,
                            elementKey: i + key,
                            colours: section.statusColours[key],
                            content: iconify(i),
                          });
                        })}
                      </AvatarGroup>
                      <ListItemText sx={{ ml: 1 }} primary={startCase(key)} />
                    </ListItem>
                  );
                })}
            </Box>
          ))}
        </List>
      ))}
    </Box>
  );
}
