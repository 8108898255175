import { ExportPicker } from '@/components/controls';
import { getPersonPolls, radioPollHeaders, shortPersonHeaders } from '@/utils';

export function DownloadPollsLink({ entry }) {
  async function fetchDataToConvert() {
    const polls = await getPersonPolls(
      entry.properties.ssi,
      entry.properties.startTime,
      entry.properties.endTime,
    );
    const data = polls.map((poll) => ({
      ...poll,
      name: [entry.person.forenames, entry.person.surname].join(' '),
      personRole: entry.person.role,
      collarNumber: entry.person.collarNumber,
      time: poll.time,
      radioSsi: poll.ssi,
      longitude: poll.position ? poll.position.coordinates[0] : 0,
      latitude: poll.position ? poll.position.coordinates[1] : 0,
    }));

    return data;
  }

  return (
    <ExportPicker
      fetchDataToConvert={fetchDataToConvert}
      filename="person-boundary-visit-polls"
      headers={[...shortPersonHeaders, ...radioPollHeaders]}
    />
  );
}
