import { round } from '@/utils';
import { Avatar, Box, Tooltip, Typography } from '@mui/material';

const errors = {
  R01: 'Not enough polls',
  R02: 'No IMEI',
  R03: 'Too long',
};

function OccupantRows({ occupants }) {
  return occupants
    .sort((a, b) => b.ratio - a.ratio)
    .map(
      ({
        collarNumber,
        forenames,
        surname,
        radioSsi,
        ratio,
        distanceKilometres,
      }) => {
        const name = [forenames, surname, collarNumber && `[${collarNumber}]`]
          .filter(Boolean)
          .join(' ')
          .trim();

        function orRadioSsi(text) {
          return text || radioSsi;
        }

        const percent = round(100 * ratio);
        const distance = round(distanceKilometres * 0.62137119, 2);

        return (
          <tr key={name}>
            <td style={{ whiteSpace: 'nowrap' }} align="left">
              {orRadioSsi(name)}
            </td>
            <td style={{ whiteSpace: 'nowrap' }} align="right">
              {`${percent}%`}
            </td>
            <td style={{ whiteSpace: 'nowrap' }} align="right">
              {`${distance} mi`}
            </td>
          </tr>
        );
      },
    );
}

export function Occupants({ entry }) {
  if (!entry.occupants) {
    return entry.occupantsReasonCode ? (
      <Box sx={{ display: 'inline-block' }}>
        <Tooltip title={errors[entry.occupantsReasonCode]}>
          <Avatar sx={{ width: 22, height: 22, bgcolor: 'error.main' }}>
            <Typography sx={{ fontSize: 14 }}>!</Typography>
          </Avatar>
        </Tooltip>
      </Box>
    ) : (
      <Box sx={{ display: 'inline-block' }}>
        <Tooltip title="Unprocessed">
          <Avatar sx={{ width: 22, height: 22, bgcolor: 'warning.main' }}>
            <Typography sx={{ fontSize: 14 }}>?</Typography>
          </Avatar>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'inline-block' }}>
      <Tooltip
        title={
          entry.occupants.length ? (
            <table>
              <thead>
                <tr>
                  <th align="left">Name</th>
                  <th align="right">Portion</th>
                  <th align="right">Distance</th>
                </tr>
              </thead>
              <tbody>
                <OccupantRows occupants={entry.occupants} />
              </tbody>
            </table>
          ) : (
            'No occupants detected'
          )
        }
      >
        <Avatar
          sx={{
            width: 22,
            height: 22,
            bgcolor: entry.occupants.length ? 'primary.main' : undefined,
          }}
        >
          <Typography sx={{ fontSize: 14 }}>
            {entry.occupants.length}
          </Typography>
        </Avatar>
      </Tooltip>
    </Box>
  );
}
