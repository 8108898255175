import { Box, Tooltip } from '@mui/material';

export function InputStatusIndicator({
  value = false,
  onColor = 'success.main',
  offColor = 'divider',
  onText = 'On',
  offText = 'Off',
}) {
  return (
    <Tooltip title={value ? onText : offText} arrow>
      <Box
        sx={{
          width: 12,
          height: 12,
          bgcolor: value ? onColor : offColor,
          borderRadius: 6,
        }}
      />
    </Tooltip>
  );
}
