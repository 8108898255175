import {
  useDeleteRfidCard,
  useDocumentTitle,
  useRfidCard,
  useUpsertPerson,
  useUpsertRfidCard,
  useUserInfo,
} from '@/hooks';
import { isAuthorised } from '@/utils';
import { useDallasKeys } from '@/utils/config';
import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { RfidCardForm } from './RfidCardForm';

export function RfidCard() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: rfidCard } = useRfidCard(id);
  const { mutate: upsertRfidCard } = useUpsertRfidCard();
  const { mutate: upsertPerson } = useUpsertPerson();
  const { mutate: deleteRfidCard } = useDeleteRfidCard();
  const { data: userInfo } = useUserInfo();
  const canEdit = isAuthorised(userInfo?.authorisation, 'people', true);
  useDocumentTitle(
    `IR3 | ${useDallasKeys ? 'Dallas Key' : 'RFID Card'} | ${
      rfidCard?._id
        ? rfidCard.reference
        : `New ${useDallasKeys ? 'Dallas Key' : 'RFID Card'}`
    }`,
  );

  function handleDelete() {
    deleteRfidCard(id, {
      onSuccess: () => {
        navigate('..', { replace: true });
      },
    });
  }

  function handleSubmit(values) {
    upsertRfidCard(values, {
      onSuccess: (data) => {
        navigate(`../${data.reference}`, { replace: true });
      },
    });
  }

  function handleRemove(id, rfidCards) {
    upsertPerson(
      { _id: id, rfidCards },
      {
        onSuccess: () =>
          queryClient.invalidateQueries({ queryKey: ['rfidCards'] }),
      },
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 1, bgcolor: 'background.paper' }}>
      <RfidCardForm
        key={[rfidCard?._id, rfidCard?.lastEdit?.time]}
        defaultValues={rfidCard}
        canEdit={canEdit}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        onRemove={handleRemove}
      />
    </Box>
  );
}
