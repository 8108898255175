import { Avatar, Tooltip } from '@mui/material';

import { startCase } from '@/utils';
import { getStatusIconColours } from '@/utils/mapStyles';
import { LiveIcon } from '../LiveIcon';
import { pluralToSingularTypeMap } from '../constants';

function AvatarContent({ item, type }) {
  switch (type) {
    case 'incident':
      // explicitly requested that it's not EME PRI etc.
      // "In case it wasn't clear the circles with numbers in them are incidents. The number is the grade."
      return item.grade || '?';
    case 'location':
    case 'vehicle':
    case 'objective':
    case 'tag':
      return <LiveIcon type={type} subtype={item.type} />;
    default:
      return <LiveIcon type={type} />;
  }
}

export function ItemAvatar({ item, type }) {
  let status = item.iconStatus || item.status;

  // for consistency elsewhere outside live - I might change this -
  // the type is singular not plural
  type = pluralToSingularTypeMap[type] || type;
  // outline isnt used
  const [, background, color] = getStatusIconColours(type, status);

  return (
    <Tooltip title={startCase(status)}>
      <Avatar
        sx={{
          background,
          color,
          borderColor: color,
          borderWidth: 2,
          borderStyle: 'solid',
        }}
      >
        <AvatarContent item={item} type={type} />
      </Avatar>
    </Tooltip>
  );
}
