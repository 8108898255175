import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { AccelerometerChart } from '../AccelerometerChart';
import { EventPreviewMap } from './EventPreviewMap';

export function EventDetail({ event }) {
  const [hoveredChartPoint, setHoveredChartPoint] = useState(null);

  return (
    <Stack spacing={2} direction="row">
      <EventPreviewMap
        path={event.path}
        point={
          event.accelerometerData?.[event.deviceProperties.triggerPoint * 10]
            ?.position
        }
        hoveredChartPoint={hoveredChartPoint}
        mapType="accelerometerEvents"
      />
      <Box sx={{ flex: 1, minWidth: 320, height: 320 }}>
        {event.accelerometerData ? (
          <AccelerometerChart
            data={event.accelerometerData}
            triggerPoint={event.deviceProperties.triggerPoint}
            onChartHover={setHoveredChartPoint}
          />
        ) : (
          <Box
            sx={{
              height: 320,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box>
              <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                No data
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Stack>
  );
}
