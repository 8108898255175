import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export function ConfirmationDialog({
  itemId,
  open,
  onCancel,
  onOk,
  action,
  title,
  text,
  cancelButtonText = 'Cancel',
  okButtonText,
}) {
  const colors = {
    delete: 'error',
    remove: 'error',
    default: 'primary',
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title || `${action}?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text ||
            `Are you sure you want to ${action?.toLowerCase()} ${
              itemId ? `"${itemId}"` : 'this'
            }?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" autoFocus>
          {cancelButtonText}
        </Button>
        <Button
          onClick={onOk}
          color={colors[action?.toLowerCase() || 'default']}
        >
          {okButtonText || action}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
