import {
  DarkMode as DarkModeIcon,
  LightMode as LightModeIcon,
} from '@mui/icons-material';
import { IconButton, Tooltip, useColorScheme } from '@mui/material';

export function ColorSchemeButton() {
  const { mode, setMode } = useColorScheme();

  function handleModeToggle() {
    if (mode === 'dark') {
      setMode('light');
    } else {
      setMode('dark');
    }
  }

  return (
    <Tooltip title={mode === 'dark' ? 'Light mode' : 'Dark mode'}>
      <IconButton size="small" color="warning" onClick={handleModeToggle}>
        {mode === 'dark' ? (
          <LightModeIcon color="secondary" />
        ) : (
          <DarkModeIcon color="primary" />
        )}
      </IconButton>
    </Tooltip>
  );
}
