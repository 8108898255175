import { useDallasKeys } from '@/utils/config';
import { useCancelConfigurationStateChange } from './useCancelConfigurationStateChange';
import { useConfigurationState } from './useConfigurationState';
import { useSetConfigurationState } from './useSetConfigurationState';

function getEnabledValue(code) {
  switch (code) {
    case 'DallasKey':
    case 'Rfid':
      return true;
    case 'DriverIdDisabled':
      return false;
    default:
      return null;
  }
}

export function useDriverIdConfiguration(imei) {
  const { data: state, refetch: refresh } = useConfigurationState(
    imei,
    'driverId',
  );
  const { mutate: setState } = useSetConfigurationState(imei, 'driverId');
  const { mutate: cancel } = useCancelConfigurationStateChange(
    imei,
    'driverId',
  );

  function toggle(enabled) {
    setState({
      State: enabled
        ? useDallasKeys
          ? 'DallasKey'
          : 'Rfid'
        : 'DriverIdDisabled',
    });
  }

  return {
    isEnabled: getEnabledValue(state?.CurrentState),
    isPending: state?.isPending,
    toggle,
    refresh,
    cancel,
  };
}
