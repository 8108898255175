import { shortHumanizer } from '@/utils';
import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  sorting: [{ id: 'startTime', desc: false }],
  pagination: {
    pageIndex: 0,
    pageSize: 25,
  },
  query: {},
  parameters: {},
});

export const pipelineFn = (pagination, query, sorting) =>
  [
    { $match: query },
    {
      $project: {
        identifier: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        people: true,
      },
    },
    sorting.length > 0 && {
      $sort: sorting.reduce(
        (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
        {},
      ),
    },
    { $skip: pagination.pageIndex * pagination.pageSize },
    { $limit: pagination.pageSize },
  ].filter(Boolean);

export const totalsPipelineFn = (query) => [
  { $match: query },
  {
    $group: {
      _id: null,
      total: { $sum: 1 },
      durationSeconds: { $sum: '$durationSeconds' },
    },
  },
  { $unset: '_id' },
];

export const downloadPipelineFn = (collection) => (query) => [
  { $match: query },
  { $unwind: '$people' },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: { $divide: ['$durationSeconds', 86400] },
      person: {
        code: '$people.code',
        forenames: '$people.forenames',
        surname: '$people.surname',
        role: '$people.role',
        collarNumber: '$people.collarNumber',
        rank: '$people.rank',
      },
      link: {
        $concat: [
          `${window.location.origin}/eventreplay/${collection}/`,
          '$identifier',
        ],
      },
    },
  },
];

export const columnsFn = (footer) => [
  {
    header: 'Start Time',
    accessorKey: 'startTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'End Time',
    accessorKey: 'endTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Duration',
    accessorKey: 'durationSeconds',
    Cell: ({ cell }) =>
      Number.isNaN(cell.getValue())
        ? ''
        : shortHumanizer(cell.getValue() * 1000),
    footer: Number.isNaN(footer.durationSeconds)
      ? ''
      : shortHumanizer(footer.durationSeconds * 1000),
  },
];

export const peopleColumns = [
  {
    header: 'Staff ID',
    id: 'code',
    accessorFn: ({ code } = {}) => code ?? '',
  },
  {
    header: 'Forenames',
    id: 'forenames',
    accessorFn: ({ forenames } = {}) => forenames ?? '',
  },
  {
    header: 'Surname',
    id: 'surname',
    accessorFn: ({ surname } = {}) => surname ?? '',
  },
  {
    header: 'Role',
    id: 'role',
    accessorFn: ({ role } = {}) => role ?? '',
  },
  {
    header: 'Collar Number',
    id: 'collarNumber',
    accessorFn: ({ collarNumber } = {}) => collarNumber ?? '',
  },
  {
    header: 'Rank',
    id: 'rank.code',
    accessorFn: ({ rank: { code } = {} } = {}) => code ?? '',
  },
];

export const csvColumns = [
  {
    header: 'Identifier',
    key: 'identifier',
  },
  {
    header: 'Start Time',
    key: 'startTime',
  },
  {
    header: 'End Time',
    key: 'endTime',
  },
  {
    header: 'Duration',
    key: 'durationSeconds',
  },
  {
    header: 'Staff ID',
    key: 'person.code',
  },
  {
    header: 'Forenames',
    key: 'person.forenames',
  },
  {
    header: 'Surname',
    key: 'person.surname',
  },
  {
    header: 'Role',
    key: 'person.role',
  },
  {
    header: 'Collar Number',
    key: 'person.collarNumber',
  },
  {
    header: 'Rank',
    key: 'person.rank.code',
  },
  {
    header: 'Link',
    key: 'link',
  },
];
