import { round, shortHumanizer } from '@/utils';
import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  boundary: null,
  query: {},
  parameters: {},
});

export const columnsFn = (footer) => [
  {
    header: 'Registration',
    id: 'properties.vehicle.registrationNumber',
    accessorFn: ({
      properties: { vehicle: { registrationNumber } = {} } = {},
    } = {}) => registrationNumber ?? '',
  },
  {
    header: 'Fleet Number',
    id: 'properties.vehicle.fleetNumber',
    accessorFn: ({ properties: { vehicle: { fleetNumber } = {} } = {} } = {}) =>
      fleetNumber ?? '',
  },
  {
    header: 'Role',
    id: 'properties.vehicle.role',
    accessorFn: ({ properties: { vehicle: { role } = {} } = {} } = {}) =>
      role ?? '',
  },
  {
    header: 'Type',
    id: 'properties.vehicle.type',
    accessorFn: ({ properties: { vehicle: { type } = {} } = {} } = {}) =>
      type ?? '',
  },
  {
    header: 'IMEI',
    id: 'properties.imei',
    accessorFn: ({ properties: { imei } = {} } = {}) => imei ?? '',
  },
  {
    header: 'Start Time',
    accessorKey: 'properties.startTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'End Time',
    accessorKey: 'properties.endTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Duration',
    accessorKey: 'properties.durationSeconds',
    Cell: ({ cell }) =>
      Number.isNaN(cell.getValue())
        ? ''
        : shortHumanizer(cell.getValue() * 1000),
    footer: Number.isNaN(footer.durationSeconds)
      ? ''
      : shortHumanizer(footer.durationSeconds * 1000),
  },
  {
    header: 'Distance',
    accessorKey: 'properties.distanceMiles',
    Cell: ({ cell }) =>
      Number.isNaN(cell.getValue()) ? '' : `${round(cell.getValue(), 2)} mi`,
    footer: Number.isNaN(footer.distanceMiles)
      ? ''
      : `${round(footer.distanceMiles, 2)} mi`,
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
    muiTableFooterCellProps: { align: 'right' },
  },
  {
    header: 'Maximum Speed',
    accessorKey: 'properties.maxSpeedMilesPerHour',
    Cell: ({ cell }) =>
      Number.isNaN(cell.getValue()) ? '' : `${round(cell.getValue(), 2)} mph`,
    footer: Number.isNaN(footer.maxSpeedMilesPerHour)
      ? ''
      : `${round(footer.maxSpeedMilesPerHour, 2)} mph`,
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
    muiTableFooterCellProps: { align: 'right' },
  },
];
