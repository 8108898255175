import { DetailsPanel } from '@/components/controls';
import { TimelinePanel } from '@/components/events/panels';
import { getStatusColor, parseIfJSON } from '@/utils';
import { Chip, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Popup } from 'react-map-gl/maplibre';

export function IncidentPopup({
  hoverInfo: {
    longitude,
    latitude,
    feature: { properties },
  },
}) {
  const entries = [
    properties.openedTime && {
      label: 'Opened',
      time: new Date(properties.openedTime),
      bgcolor: getStatusColor('opened'),
    },
    properties.assignedTime && {
      label: 'Assigned',
      time: new Date(properties.assignedTime),
      bgcolor: getStatusColor('assigned'),
    },
    properties.attendedTime && {
      label: 'Attended',
      time: new Date(properties.attendedTime),
      bgcolor: getStatusColor('attended'),
    },
    properties.closedTime && {
      label: 'Closed',
      time: new Date(properties.closedTime),
      bgcolor: getStatusColor('closed'),
    },
  ].filter(Boolean);
  const type = parseIfJSON(properties.type);
  const date = properties.date
    ? format(new Date(properties.date), 'dd/MM/yyyy')
    : '';

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      offset={16}
      closeButton={false}
      maxWidth="360px"
    >
      <Stack spacing={0.5}>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            {properties.reference ?? ''}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: 'text.secondary', flex: 1 }}
          >
            {date}
          </Typography>
          <Chip color="secondary" size="small" label={properties.grade} />
        </Stack>
        <DetailsPanel
          entries={[
            { label: 'Code', value: type?.code },
            { label: 'Type', value: type?.name },
          ]}
        />
        <Typography variant="caption" sx={{ p: 1 }}>
          {properties.description}
        </Typography>
        <TimelinePanel entries={entries} />
      </Stack>
    </Popup>
  );
}
