import { useOptionValueLookup } from '@/hooks';
import { Autocomplete, TextField } from '@mui/material';

export function GroupedOptionsField({
  input: { value, onChange },
  label,
  optionName,
}) {
  const options = useOptionValueLookup(optionName);

  function handleChange(event, newValue) {
    onChange(newValue);
  }

  return (
    <Autocomplete
      value={value || []}
      fullWidth
      size="small"
      multiple
      onChange={handleChange}
      options={Object.keys(options || {})}
      groupBy={(option) => options?.[option]?.type.label}
      getOptionLabel={(option) => options?.[option]?.label ?? option}
      renderInput={(params) => <TextField label={label} {...params} />}
    />
  );
}
