import { useMemo } from 'react';
import { useOptions } from '../option';

export function useFilterFields() {
  const { data: vehicleGroups } = useOptions('vehicleGroup');
  const { data: personGroups } = useOptions('personGroup');
  const { data: locationGroups } = useOptions('locationGroup');

  return useMemo(
    () => ({
      vehicles: [
        'properties.vehicle.registrationNumber',
        'properties.vehicle.fleetNumber',
        'properties.vehicle.role',
        'properties.vehicle.homeStation',
        'properties.vehicle.groupCodes',
        ...vehicleGroups.map(
          ({ value }) => `properties.vehicle.groups.${value}`,
        ),
      ],
      people: [
        'properties.person.code',
        'properties.person.collarNumber',
        'properties.person.rank.code',
        'properties.person.role',
        'properties.person.groupCodes',
        ...personGroups.map(({ value }) => `properties.person.groups.${value}`),
      ],
      incidents: [
        'properties.grade',
        'properties.type.code',
        'properties.type.name',
      ],
      events: [
        'properties.vehicle.registrationNumber',
        'properties.vehicle.fleetNumber',
        'properties.vehicle.role',
        'properties.vehicle.homeStation',
        'properties.vehicle.groupCodes',
        ...vehicleGroups.map(
          ({ value }) => `properties.vehicle.groups.${value}`,
        ),
      ],
      locations: [
        'properties.type',
        'properties.subtype',
        'properties.groupCodes',
        ...locationGroups.map(({ value }) => `properties.groups.${value}`),
      ],
      objectives: ['properties.microbeats', 'properties.occurrenceNumber'],
    }),
    [locationGroups, personGroups, vehicleGroups],
  );
}
