import { getStatusColor, getStatusForeColor } from '@/utils';
import {
  AccountBalance as AccountBalanceIcon,
  Business as BusinessIcon,
  CarRepair as CarRepairIcon,
  LocalHospital as LocalHospitalIcon,
  LocalPolice as LocalPoliceIcon,
  School as SchoolIcon,
} from '@mui/icons-material';
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
// import { Link } from 'react-router-dom';

function Icon({ type }) {
  switch (type) {
    case 'Base':
      return <BusinessIcon />;
    case 'Court':
      return <AccountBalanceIcon />;
    case 'Hospital':
      return <LocalHospitalIcon />;
    case 'Police Station':
      return <LocalPoliceIcon />;
    case 'School':
      return <SchoolIcon />;
    case 'Workshop':
      return <CarRepairIcon />;
    case 'Boundary':
    case 'Force Boundary':
    case 'Local Authority':
    case 'Region':
    case 'Sector':
    case 'Ward':
    default:
      return <BusinessIcon />;
  }
}

export function LocationListItem({ item: { size, start }, feature, ...props }) {
  const primary = feature.properties.name;
  const secondary = feature.properties.subtype;

  return (
    <ListItemButton
      {...props}
      dense
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: `${size}px`,
        transform: `translateY(${start}px)`,
      }}
      // component={Link}
      // to={'.'}
    >
      <ListItemAvatar>
        <Avatar
          sx={{
            color: getStatusForeColor('default'),
            bgcolor: getStatusColor('default'),
            borderColor: getStatusForeColor(feature.properties?.status),
            borderWidth: 2,
            borderStyle: 'solid',
          }}
        >
          <Icon type={feature.properties.type} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={primary}
        primaryTypographyProps={{ noWrap: true }}
        secondary={secondary}
        secondaryTypographyProps={{ noWrap: true }}
      />
    </ListItemButton>
  );
}
