import { ADD_TAG_ITEM_SUCCESS, DELETE_TAG_ITEM_SUCCESS } from '@/actions';
import { useTagItem, useTags, useUntagItem } from '@/hooks';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function TagField({ collection, id, label, disabled, linkPrefix, sx }) {
  const { data: tags } = useTags();
  const options = tags?.map((tag) => tag.title).sort();
  const value = tags
    ?.filter((tag) => tag.items?.[collection]?.includes(id))
    .map((tag) => tag.title)
    .sort();
  const { mutate: tagItem } = useTagItem(collection, id);
  const { mutate: untagItem } = useUntagItem(collection, id);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleChange(event, titles) {
    const addedTags = titles.filter((title) => !value.includes(title));
    const removedTags = value.filter((title) => !titles.includes(title));

    addedTags.forEach((title) =>
      tagItem(title, {
        // TODO: remove this when redux goes away
        onSuccess: ({ identifier }) => {
          dispatch({
            type: ADD_TAG_ITEM_SUCCESS,
            payload: {
              type: collection,
              value: id,
              id: identifier,
              title,
            },
          });
        },
      }),
    );
    removedTags.forEach((title) =>
      untagItem(title, {
        // TODO: remove this when redux goes away
        onSuccess: ({ identifier }) => {
          dispatch({
            type: DELETE_TAG_ITEM_SUCCESS,
            payload: {
              type: collection,
              value: id,
              id: identifier,
            },
          });
        },
      }),
    );
  }

  const handleTagClick = (title) => () => {
    if (linkPrefix) {
      const { identifier } = tags.find((tag) => tag.title === title);
      navigate(`${linkPrefix}/${identifier}`);
    }
  };

  const renderInput = (params) => (
    <TextField {...params} placeholder="Add tags..." label={label} />
  );

  const renderTags = (value, getTagProps) =>
    value.map((option, index) => {
      const { key, ...props } = getTagProps({ index });

      return (
        <Chip
          key={key}
          size="small"
          color="secondary"
          label={option}
          {...props}
          onClick={handleTagClick(option)}
        />
      );
    });

  return (
    <Autocomplete
      size="small"
      disableClearable
      fullWidth
      freeSolo
      multiple
      value={value}
      onChange={handleChange}
      options={options}
      sx={sx}
      disabled={disabled}
      renderInput={renderInput}
      renderTags={renderTags}
    />
  );
}
