import { round, shortHumanizer } from '@/utils';
import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  sorting: [{ id: 'startTime', desc: false }],
  pagination: {
    pageIndex: 0,
    pageSize: 25,
  },
  query: {},
  parameters: {},
});

export const pipelineFn = (pagination, query, sorting) =>
  [
    { $match: query },
    {
      $project: {
        identifier: true,
        startTime: true,
        endTime: true,
        durationSeconds: true,
        distanceMiles: { $multiply: ['$distanceKilometres', 0.62137119] },
        startLocation: {
          $ifNull: [
            { $first: '$startLocations' },
            [{ name: 'Elsewhere', type: 'None' }],
          ],
        },
        endLocation: {
          $ifNull: [
            { $first: '$endLocations' },
            [{ name: 'Elsewhere', type: 'None' }],
          ],
        },
        vehicle: true,
      },
    },
    sorting.length > 0 && {
      $sort: sorting.reduce(
        (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
        {},
      ),
    },
    { $skip: pagination.pageIndex * pagination.pageSize },
    { $limit: pagination.pageSize },
  ].filter(Boolean);

export const totalsPipelineFn = (query) => [
  { $match: query },
  {
    $group: {
      _id: null,
      total: { $sum: 1 },
      durationSeconds: { $sum: '$durationSeconds' },
      distanceKilometres: { $sum: '$distanceKilometres' },
    },
  },
  {
    $set: {
      distanceMiles: {
        $round: [{ $multiply: ['$distanceKilometres', 0.62137119] }, 2],
      },
    },
  },
  { $unset: '_id' },
];

export const downloadPipelineFn = (query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      startTime: true,
      endTime: true,
      durationSeconds: { $divide: ['$durationSeconds', 86400] },
      distanceMiles: {
        $round: [{ $multiply: ['$distanceKilometres', 0.62137119] }, 2],
      },
      startLocation: {
        $ifNull: [
          { $first: '$startLocations' },
          [{ name: 'Elsewhere', type: 'None' }],
        ],
      },
      endLocation: {
        $ifNull: [
          { $first: '$endLocations' },
          [{ name: 'Elsewhere', type: 'None' }],
        ],
      },
      vehicle: true,
    },
  },
];

export const columnsFn = (footer) => [
  {
    header: 'IMEI',
    id: 'vehicle.telematicsBoxImei',
    accessorFn: ({ vehicle: { telematicsBoxImei } = {} } = {}) =>
      telematicsBoxImei ?? '',
  },
  {
    header: 'Registration',
    id: 'vehicle.registrationNumber',
    accessorFn: ({ vehicle: { registrationNumber } = {} } = {}) =>
      registrationNumber ?? '',
  },
  {
    header: 'Fleet Number',
    id: 'vehicle.fleetNumber',
    accessorFn: ({ vehicle: { fleetNumber } = {} } = {}) => fleetNumber ?? '',
  },
  {
    header: 'Role',
    id: 'vehicle.role',
    accessorFn: ({ vehicle: { role } = {} } = {}) => role ?? '',
  },
  {
    header: 'Start Location Type',
    id: 'startLocation.type',
    accessorFn: ({ startLocation: { type } = {} } = {}) => type ?? '',
  },
  {
    header: 'Start Location Name',
    id: 'startLocation.name',
    accessorFn: ({ startLocation: { name } = {} } = {}) => name ?? '',
  },
  {
    header: 'End Location Type',
    id: 'endLocation.type',
    accessorFn: ({ endLocation: { type } = {} } = {}) => type ?? '',
  },
  {
    header: 'End Location Name',
    id: 'endLocation.name',
    accessorFn: ({ endLocation: { name } = {} } = {}) => name ?? '',
  },
  {
    header: 'Start Time',
    accessorKey: 'startTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'End Time',
    accessorKey: 'endTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Duration',
    accessorKey: 'durationSeconds',
    Cell: ({ cell }) =>
      Number.isNaN(cell.getValue())
        ? ''
        : shortHumanizer(cell.getValue() * 1000),
    footer: Number.isNaN(footer.durationSeconds)
      ? ''
      : shortHumanizer(footer.durationSeconds * 1000),
  },
  {
    header: 'Distance',
    accessorKey: 'distanceMiles',
    Cell: ({ cell }) =>
      Number.isNaN(cell.getValue()) ? '' : `${round(cell.getValue(), 2)} mi`,
    footer: Number.isNaN(footer.distanceMiles)
      ? ''
      : `${round(footer.distanceMiles, 2)} mi`,
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
    muiTableFooterCellProps: { align: 'right' },
  },
];
