import { useAggregate, useDocumentTitle, useOptions } from '@/hooks';
import { lastContactDefaultDays } from '@/utils/config';
import {
  Autorenew as AutorenewIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { DrillDown } from './DrillDown';
import {
  MRT_TablePagination,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { columnsFn, pipelineFn } from './utils';
import { downloadCSV } from '@/utils';
import { format } from 'date-fns';

export function LastContact() {
  useDocumentTitle('IR3 | Last Contact');
  const [lastContactDays, setLastContactDays] = useState(
    lastContactDefaultDays ?? 7,
  );
  const { data, isLoading, isFetching, refetch } = useAggregate(
    'telematicsBoxes',
    pipelineFn(lastContactDays),
    false,
    1000 * 60 * 60,
  );
  const telematicsBoxes = useMemo(
    () =>
      data.map(({ groupAncestors = [], ...box }) => ({
        ...box,
        groupAncestors: groupAncestors.reduce(
          (accumulator, { type, name }) => ({
            ...accumulator,
            [type]: [...(accumulator[type] ?? []), name],
          }),
          {},
        ),
      })),
    [data],
  );
  const { data: typeOptions } = useOptions('groupType');

  function handleLastContactDaysChange(event) {
    setLastContactDays(event.target.value);
  }

  function handleRefreshClick() {
    refetch();
  }

  function renderDetailPanel(cell) {
    return <DrillDown entry={cell.row.original} />;
  }

  function renderTopToolbarCustomActions() {
    return (
      <Stack
        spacing={1}
        direction="row"
        sx={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <TextField
          size="small"
          id="contactDays"
          value={lastContactDays}
          onChange={handleLastContactDaysChange}
          type="number"
          sx={{ width: 140 }}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">&gt;</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">days</InputAdornment>
              ),
            },
          }}
        />
        <Tooltip title={isLoading || isFetching ? 'Loading' : 'Fetch'}>
          <Box component="span">
            <IconButton
              onClick={handleRefreshClick}
              disabled={isLoading || isFetching}
              size="small"
            >
              <AutorenewIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Stack>
    );
  }

  function handleDownloadClick() {
    const data = telematicsBoxes.map((box) => ({
      ...box,
      mostRecentTime: box.mostRecentTime
        ? format(box.mostRecentTime, 'dd/MM/yyyy HH:mm:ss')
        : '',
      disposalDate: box.disposalDate
        ? format(box.disposalDate, 'dd/MM/yyyy')
        : '',
      ignitionOn: box.ignitionOn ? 'On' : 'Off',
    }));
    const csvColumns = [
      {
        label: 'IMEI',
        key: 'imei',
      },
      {
        label: 'Last Contact Time',
        key: 'mostRecentTime',
      },
      {
        label: 'Disposal Date',
        key: 'disposalDate',
      },
      {
        label: 'Fleet Number',
        key: 'fleetNumber',
      },
      {
        label: 'Registration',
        key: 'registrationNumber',
      },
      {
        label: 'Ignition',
        key: 'ignitionOn',
      },
      {
        label: 'Battery Voltage',
        key: 'batteryVoltage',
      },
      {
        label: 'Signal Strength',
        key: 'signalStrength',
      },
      {
        label: 'Valid GPS Rate (%)',
        key: 'gpsValidPollPercentage',
      },
      {
        label: 'Location Type',
        key: 'location.type',
      },
      {
        label: 'Location Name',
        key: 'location.name',
      },
      ...(typeOptions.map(({ label, value }) => ({
        label,
        key: `groupAncestors.${value}`,
      })) ?? []),
    ];

    downloadCSV(data, 'last-contact.csv', csvColumns);
  }

  const table = useMaterialReactTable({
    data: telematicsBoxes,
    columns: columnsFn(typeOptions),
    state: {
      density: 'compact',
      isLoading: isLoading || isFetching,
    },
    defaultColumn: { size: 0 },
    muiTableContainerProps: {
      sx: {
        height: 'calc(100vh - 160px)', // 48 app header + 56 top bar + 56 bottom bar
      },
    },
    enableStickyHeader: true,
    // enableBottomToolbar: false,
    // enablePagination: true,
    // enableColumnActions: false,
    // enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableFacetedValues: true,
    // enableRowActions: true,
    // positionActionsColumn: 'last',
    renderTopToolbarCustomActions,
    // renderRowActions,
    renderDetailPanel,
    renderBottomToolbar: () => {
      return (
        <Stack
          direction="row"
          sx={{ pl: 1, justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box>
            <Tooltip title="Download data">
              <IconButton size="small" onClick={handleDownloadClick}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <MRT_TablePagination table={table} />
        </Stack>
      );
    },
  });

  return <MaterialReactTable table={table} />;
}
