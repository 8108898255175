import { useLocationOptions } from '@/hooks';
import {
  Box,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { MaterialReactTable } from 'material-react-table';
import { useMemo } from 'react';
import { useUncheckedVehicles } from './useUncheckedVehicles';

export function UncheckedVehiclesCard({ groupCode }) {
  const { data } = useUncheckedVehicles(groupCode);
  const locationOptions = useLocationOptions();
  const columns = useMemo(
    () => [
      {
        accessorKey: 'registrationNumber',
        header: 'Registration',
        size: 0,
        Cell: ({ row: { original } }) => (
          <Tooltip
            title={
              <table>
                <tbody>
                  <tr>
                    <td>Fleet Number</td>
                    <td>{original.fleetNumber}</td>
                  </tr>
                  <tr>
                    <td>VIN</td>
                    <td>{original.identificationNumber}</td>
                  </tr>
                </tbody>
              </table>
            }
            placement="right"
          >
            <Box component="span">{original.registrationNumber}</Box>
          </Tooltip>
        ),
      },
      {
        accessorKey: 'homeStation',
        header: 'Home Station',
        size: 0,
        format: (value) => locationOptions[value]?.name ?? value,
      },
      {
        accessorKey: 'lastPollTime',
        header: 'Last Poll Date',
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        size: 0,
        Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy'),
      },
      {
        accessorKey: 'lastCheck.time',
        header: 'Last Check Date',
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        size: 0,
        Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy'),
      },
    ],
    [locationOptions],
  );

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ color: 'text.secondary' }}
          >
            Unchecked Vehicles
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            in the last 14 days
          </Typography>
        </Stack>
        <MaterialReactTable
          data={data}
          columns={columns}
          initialState={{ density: 'compact' }}
          enableTopToolbar={false}
          enableColumnActions={false}
        />
      </CardContent>
    </Card>
  );
}
