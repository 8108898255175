import { FETCH_LIVE_PERSON } from '@/actions/types';
import { TagField } from '@/components/controls';
import {
  useDocumentTitle,
  useOptionLookup,
  useOptionValueLookup,
  useOptionValues,
  usePrevious,
} from '@/hooks';
import { startCase } from '@/utils';
import { liveOptions, useDallasKeys } from '@/utils/config';
import {
  Edit as EditIcon,
  LocationSearching as FollowIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Table,
  TableBody,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toLabelAccessors } from '../utils';
import { CallSignLiveListItem } from './CallSignLiveListItem';
import { IncidentLiveListItem } from './IncidentLiveListItem';
import { ItemRows } from './ItemRows';
import { StatusDot } from './StatusDot';

const { offDutyNames = [] } = liveOptions;

function dutiesToLabelAccessors(duties) {
  return (duties || []).map((d) => ({
    label: format(new Date(d.startTime), 'EEE d/M'),
    value: d.name,
    disabled: offDutyNames.some(
      (o) => 0 === o.localeCompare(d.name, undefined, { sensitivity: 'base' }),
    ),
  }));
}

export function PersonLiveItem({
  item,
  onFollowToggle,
  followedIdsByType,
  onSubItemClick,
  onSubItemHover,
  hoveredId,
}) {
  const title = item.name || item.radioSsi;
  useDocumentTitle(
    ['IR3', 'Live', 'People', title].filter(Boolean).join(' | '),
  );
  const hideFollow = !item.position;
  const personRecord = useSelector(
    (state) => state.live.personRecordsById[item.id],
  );
  const personGroups = useOptionValues('personGroup');
  const personSkills = useOptionValueLookup('personSkill');
  const groupTypes = useOptionLookup('groupType');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const prevId = usePrevious(item.id);
  useEffect(() => {
    if (prevId !== item.id && !personRecord) {
      dispatch({
        type: FETCH_LIVE_PERSON,
        payload: item.id,
      });
    }
  }, [dispatch, item.id, prevId, personRecord]);

  const incidents = useSelector((state) => state.live.incidents);
  const incident =
    item.assignments?.incident?.number &&
    (incidents[item.assignments.incident.number] || {
      id: item.assignments.incident.number,
    });

  const callSigns = useSelector((state) => state.live.callSigns);
  const callSign = callSigns[item.assignments?.callSign?.code];

  const type = 'people';
  const following = followedIdsByType?.[type]?.[item.id];

  const sections = {
    '': [
      {
        label: 'Status',
        value: <StatusDot item={item} type={type} />,
      },
      {
        label: 'Last poll time',
        value: item.lastPollTime
          ? format(new Date(item.lastPollTime), 'dd/MM/yyyy HH:mm:ss')
          : '-',
      },
    ],
    details: [
      { label: 'Role', value: item.role },
      {
        label: 'Home Station',
        value: item.homeStation,
      },
      { label: 'Radio SSI', value: item.radioSsi },
      {
        label: 'Emergency Button',
        value: item.emergencyButtonOn ? 'On' : 'Off',
      },

      item.rfidCards?.length > 0 && {
        label: useDallasKeys ? 'Dallas Keys' : 'RFIDs',
        value:
          item.rfidCards.map(({ reference }) => reference).join(', ') || '',
      },
    ].filter(Boolean),
    groups: personRecord?.groupAncestors?.map((group) => ({
      label: groupTypes[group.type],
      value: group.name,
    })),
    // .sort((a, b) => a.label.localeCompare(b.label)),
    categories: Object.entries(item.groups ?? []).map(([type, values]) => ({
      label: type in personGroups ? personGroups[type].label : startCase(type),
      value: values
        .map(
          (value) =>
            personGroups[type]?.values.find((entry) => entry.value === value)
              ?.label ?? value,
        )
        .join(', '),
    })),
    skills: item.skills?.map(({ type, name }) =>
      name in personSkills
        ? {
            label: personSkills[name].type?.label,
            value: personSkills[name].label,
          }
        : { label: type, value: name },
    ),
    duties: dutiesToLabelAccessors(item.duties),
    locations: toLabelAccessors(item.currentLocations, 'type', 'name'),
  };

  const link = `/resources/${type}/${item.id}`;

  return (
    <Card sx={{ m: 1 }}>
      <CardHeader
        avatar={<Avatar src={personRecord?.picture} />}
        title={title}
        // subheader={item.collarNumber}
        action={
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <Tooltip title="Edit">
              <IconButton onClick={() => navigate(link)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {!hideFollow && (
              <Tooltip title={following ? 'Stop following' : 'Follow'}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onFollowToggle('people', item.id);
                  }}
                >
                  <FollowIcon
                    fontSize="small"
                    color={following ? 'primary' : 'disabled'}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        }
      />
      <CardContent>
        <TagField
          collection={type}
          id={item.id}
          linkPrefix="/live/tags"
          sx={{ pb: 2 }}
          label="Tags"
        />
        {item.assignments && (
          <Fragment>
            {callSign && (
              <Fragment>
                <Typography variant="subtitle2" color="textSecondary">
                  Call Sign
                </Typography>
                <CallSignLiveListItem
                  onClick={onSubItemClick}
                  onHoverItem={onSubItemHover}
                  item={callSign}
                />
              </Fragment>
            )}
            {incident && (
              <Fragment>
                <Typography variant="subtitle2" color="textSecondary">
                  Assigned Incident
                </Typography>
                <IncidentLiveListItem
                  onClick={onSubItemClick}
                  onHoverItem={onSubItemHover}
                  item={incident}
                  highlighted={hoveredId === incident.id}
                />
              </Fragment>
            )}
          </Fragment>
        )}
        {Object.entries(sections)
          .filter(([, rows]) => rows?.length > 0)
          .map(([key, rows]) => (
            <Fragment key={key}>
              <Typography variant="subtitle2" color="textSecondary">
                {startCase(key)}
              </Typography>
              <Table size="small" sx={{ mt: 1, mb: 2 }}>
                <TableBody>{ItemRows(rows, item)}</TableBody>
              </Table>
            </Fragment>
          ))}
      </CardContent>
    </Card>
  );
}
