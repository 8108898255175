import { Stack, Typography } from '@mui/material';
import { useController } from 'react-hook-form';
import { HtmlEditor } from '../HtmlEditor';

export function HtmlEditorController({
  name,
  placeholder,
  control,
  disabled,
  rules,
  sx,
}) {
  const {
    field: { value, onChange },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <Stack spacing={1} sx={sx}>
      <HtmlEditor
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
      />
      {invalid && (
        <Typography variant="caption" sx={{ color: 'error.main' }}>
          {error?.message}
        </Typography>
      )}
    </Stack>
  );
}
