import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { round } from 'mathjs';

export function useUpsertVehicle() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      _id,
      groupAncestors: _,
      odometerReadings = [],
      ...json
    }) => {
      if (_id) {
        return api
          .patch(`vehicles/${_id}`, {
            headers: { 'content-type': 'application/merge-patch+json' },
            json: {
              ...json,
              odometerReadings: odometerReadings
                .filter(({ time, miles }) => time && (miles || miles === 0))
                .map(({ time, miles }) => ({
                  time,
                  kilometres: round(miles / 0.62137119, 3),
                })),
            },
          })
          .json();
      } else {
        return api
          .post('vehicles', {
            json: {
              ...json,
              odometerReadings: odometerReadings
                .filter(({ time, miles }) => time && (miles || miles === 0))
                .map(({ time, miles }) => ({
                  time,
                  kilometres: round(miles / 0.62137119, 3),
                })),
            },
          })
          .json();
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['vehicles'] }),
  });
}
