import { Parameters, ReplayLink } from '@/components/controls';
import { ClassificationDialog, PersonDialog } from '@/components/dialogs';
import {
  useAggregate,
  useDocumentTitle,
  usePeople,
  useTripClassifications,
  useUpdateTrip,
  useUserInfo,
} from '@/hooks';
import { getPerson, isEmpty } from '@/utils';
import { allowDriverChange, events } from '@/utils/config';
import { Label as LabelIcon, Person as PersonIcon } from '@mui/icons-material';
import { Box, Chip, IconButton, Stack, Tooltip } from '@mui/material';
import { useAtom } from 'jotai';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useMemo, useState } from 'react';
import { DownloadPollsLink } from './DownloadPollsLink';
import { TripDetail } from './TripDetail';
import {
  columnsFn,
  downloadPipelineFn,
  pipelineFn,
  stateAtom,
  totalsPipelineFn,
} from './utils';

const {
  eventFilters: { trips: eventFilters },
} = events;

export function Trips() {
  useDocumentTitle('IR3 | Trips');
  const [{ sorting, pagination, rowSelection, query, parameters }, setState] =
    useAtom(stateAtom);
  const [classificationOpen, setClassificationOpen] = useState(false);
  const [driverOpen, setDriverOpen] = useState(false);
  const collection = 'trips';
  const pipeline = useMemo(
    () => pipelineFn(pagination, query, sorting),
    [pagination, query, sorting],
  );
  const totalsPipeline = useMemo(() => totalsPipelineFn(query), [query]);
  const { data, isFetching, cancel } = useAggregate(
    collection,
    pipeline,
    !isEmpty(query),
    1000 * 60 * 60,
  );
  const {
    data: aggregated,
    isFetching: totalsFetching,
    cancel: cancelTotals,
  } = useAggregate(collection, totalsPipeline, !isEmpty(query), 1000 * 60 * 60);
  const { data: people } = usePeople();
  const { mutate: updateTrip } = useUpdateTrip();
  const { data: userInfo } = useUserInfo();
  const canEdit = userInfo?.authorisation?.tripEvents?.write ?? false;
  const { data: tripClassifications } = useTripClassifications();
  const columns = useMemo(
    () => columnsFn(aggregated[0] ?? {}, canEdit, tripClassifications),
    [aggregated, canEdit, tripClassifications],
  );
  const csvColumns = columns
    .map(({ header, id, accessorKey }) => ({
      header,
      key: id ?? accessorKey,
    }))
    .concat({ header: 'Link', key: 'link' });

  function handleClearSelectionClick() {
    setState((state) => ({ ...state, rowSelection: {} }));
  }

  function renderTopToolbarCustomActions() {
    return canEdit && Object.keys(rowSelection).length > 0 ? (
      <Stack
        spacing={0.5}
        direction="row"
        sx={{ py: '3px', alignItems: 'center' }}
      >
        <Tooltip title="Set classification">
          <IconButton onClick={handleClassificationClick} size="small">
            <LabelIcon />
          </IconButton>
        </Tooltip>
        {allowDriverChange && (
          <Tooltip title="Set driver">
            <IconButton onClick={handleDriverClick} size="small">
              <PersonIcon />
            </IconButton>
          </Tooltip>
        )}
        <Chip
          label={`${Object.keys(rowSelection).length} selected`}
          size="small"
          onDelete={handleClearSelectionClick}
        />
      </Stack>
    ) : undefined;
  }

  function renderRowActions(cell) {
    return (
      <Stack spacing={0.5} direction="row">
        <ReplayLink
          collection={collection}
          identifier={cell.row.original.identifier}
        />
        <DownloadPollsLink entry={cell.row.original} />
      </Stack>
    );
  }

  function renderDetailPanel(cell) {
    return <TripDetail trip={cell.row.original} />;
  }

  function handleClassificationClick() {
    setClassificationOpen(true);
  }

  function handleClassificationClose(classification) {
    if (classification || classification === null) {
      Object.entries(rowSelection)
        .filter(([, selected]) => selected)
        .forEach(([id]) => updateTrip({ id, values: { classification } }));
    }
    setClassificationOpen(false);
  }

  function handleDriverClick() {
    setDriverOpen(true);
  }

  async function handleDriverClose(driverId) {
    if (driverId) {
      const driver = await getPerson(driverId);

      Object.entries(rowSelection)
        .filter(([, selected]) => selected)
        .forEach(([id]) =>
          updateTrip({
            id,
            values: { driver: { assigned: true, ...driver } },
          }),
        );
    }
    setDriverOpen(false);
  }

  function handleCancel() {
    cancel();
    cancelTotals();
    setState((state) => ({ ...state, query: {} }));
  }

  const handleStateChange = (name) => (value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  const handleStateChangeWithEvent = (name) => (_, value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  const handleTableStateChange = (name) => (changeFn) => {
    setState((state) => ({ ...state, [name]: changeFn(state[name]) }));
  };

  const handleFieldBlur = (cell) => (event) => {
    updateTrip({
      id: cell.row.original.identifier,
      values: { [event.target.name]: event.target.value },
    });
  };

  const table = useMaterialReactTable({
    columns,
    data,
    state: {
      density: 'compact',
      sorting,
      pagination,
      rowSelection,
      isLoading: isFetching,
    },
    defaultColumn: { size: 0, enableEditing: false },
    getRowId: (row) => row.identifier,
    rowCount: aggregated[0]?.total ?? 0,
    onPaginationChange: handleTableStateChange('pagination'),
    onSortingChange: handleTableStateChange('sorting'),
    onRowSelectionChange: handleTableStateChange('rowSelection'),
    renderTopToolbarCustomActions,
    renderRowActions,
    renderDetailPanel,
    enableExpandAll: false,
    positionToolbarAlertBanner: 'none',
    positionActionsColumn: 'last',
    editDisplayMode: 'cell',
    enableEditing: canEdit,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableTopToolbar: true,
    enableSorting: true,
    enableBottomToolbar: true,
    enablePagination: true,
    enableRowSelection: true,
    enableRowActions: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiEditTextFieldProps: ({ cell }) => ({
      variant: 'outlined',
      size: 'small',
      slotProps: { htmlInput: { sx: { fontSize: 14 } } },
      onBlur: handleFieldBlur(cell),
    }),
    muiTablePaperProps: { elevation: 0, variant: 'elevation' },
    muiTableContainerProps: {
      sx: {
        width: 'calc(100vw - 280px)',
        maxHeight: 'calc(100vh - 160px)', // 48 app header + 56 top bar + 56 bottom bar
      },
    },
  });

  return (
    <Stack direction="row">
      <Box
        sx={{
          width: 280,
          height: 'calc(100vh - 48px)',
          borderRight: 1,
          borderColor: 'divider',
          flexShrink: 0,
        }}
      >
        <Parameters
          collection={collection}
          onFetch={handleStateChangeWithEvent('query')}
          onCancel={handleCancel}
          isFetching={isFetching || totalsFetching}
          value={parameters}
          onChange={handleStateChange('parameters')}
          sx={{ pt: 1, height: 1 }}
          vehicle
          driver
          rfidCard
          eventFilters={eventFilters}
          pipelineFn={downloadPipelineFn(collection)}
          columns={csvColumns}
        />
      </Box>
      <MaterialReactTable table={table} />
      <ClassificationDialog
        open={classificationOpen}
        onClose={handleClassificationClose}
      />
      <PersonDialog
        open={driverOpen}
        onClose={handleDriverClose}
        people={people}
      />
    </Stack>
  );
}
