import {
  AdminPanelSettings as AdminPanelSettingsIcon,
  AdsClick as AdsClickIcon,
  HowToReg as AttendanceIcon,
  BarChart as BarChartIcon,
  ChromeReaderMode as ChromeReaderModeIcon,
  DataUsage as DataUsageIcon,
  DirectionsCar as DirectionsCarIcon,
  EmojiTransportation as EmojiTransportationIcon,
  Event as EventIcon,
  ExploreOff as ExploreOffIcon,
  Fence as FenceIcon,
  Fluorescent as FluorescentIcon,
  Group as GroupIcon,
  Groups as GroupsIcon,
  History as HistoryIcon,
  Home as HomeIcon,
  Inventory as InventoryIcon,
  Language as LanguageIcon,
  LocationCity as LocationCityIcon,
  More as MoreIcon,
  NewReleases as NewReleasesIcon,
  Nfc as NfcIcon,
  People as PeopleIcon,
  Person as PersonIcon,
  Replay as ReplayIcon,
  Reply as ReplyIcon,
  Route as RouteIcon,
  Router as RouterIcon,
  Schedule as ScheduleIcon,
  Snooze as SnoozeIcon,
  Star as StarIcon,
  Subject as SubjectIcon,
  Timeline as TimelineIcon,
  Timer as TimerIcon,
  Tour as TourIcon,
  VpnKey as VpnKeyIcon,
} from '@mui/icons-material';
import {
  AxisArrow as AxisArrowIcon,
  CarBrakeParking as CarBrakeParkingIcon,
  CarSide as CarSideIcon,
  CarTractionControl as CarTractionControlIcon,
  CastAudioVariant as CastAudioVariantIcon,
  Counter as CounterIcon,
  Engine as EngineIcon,
  Garage as GarageIcon,
  Gauge as GaugeIcon,
  AccountCancel as PeopleAvailabilityIcon,
  RadioHandheld as RadioHandheldIcon,
  Speedometer as SpeedometerIcon,
  Steering as SteeringIcon,
  Stethoscope as StethoscopeIcon,
} from 'mdi-material-ui';
import { Navigate } from 'react-router-dom';
import {
  Audit,
  DataRetention,
  Engagement,
  GroupOptions,
  LegacyAudit,
  LocationOptions,
  Options,
  OptionsToolbar,
  PersonOptions,
  RadioOptions,
  RfidCardOptions,
  TelematicsBoxOptions,
  VehicleOptions,
} from './components/admin';
import { App } from './components/App';
import { BackButton, MenuButton, TextHeader } from './components/controls';
import { ErrorBoundary } from './components/ErrorBoundary';
import {
  AccelerometerEvents,
  DoubleCrews,
  Idles,
  IncidentResponses,
  MalfunctionIndicatorLight,
  OnBoardDiagnostics,
  Outages,
  PersonBoundaryVisits,
  PersonLocationVisits,
  PersonObjectiveAttendances,
  SpeedInfractions,
  Stops,
  Trails,
  Trip,
  Trips,
  VehicleBoundaryVisits,
  VehicleEquipmentActivations,
  VehicleLocationVisits,
} from './components/events';
import { Group, Groups } from './components/groups';
import {
  BasicCommandUnit,
  Fleet,
  Highlights,
  HighlightsToolbar,
  Sector,
  Team,
} from './components/Highlights';
import { Home } from './components/Home';
import { Live } from './components/live';
import { Location, Locations, LocationsToolbar } from './components/locations';
import { Objective, Objectives } from './components/objectives';
import { ProtectedRoute } from './components/ProtectedRoute';
import { EventReplay, Replay } from './components/replays';
import {
  DailyActivity,
  DailyUtilisation,
  DriverBehaviour,
  FleetUtilisation,
  HourlyActivity,
  HourlyInLocations,
  HourlyUtilisation,
  LastContact,
  Mileage,
  PersonAvailability,
  TimelineInLocations,
  VehicleAvailability,
} from './components/reports';
import {
  People,
  Person,
  Radio,
  Radios,
  RfidCard,
  RfidCards,
  TelematicsBox,
  TelematicsBoxes,
  TelematicsBoxTest,
  Vehicle,
  Vehicles,
} from './components/resources';
import { Retrospective } from './components/retrospective';
import { Tag, Tags } from './components/tags';
import { hiddenRoutes, showLegacyAudit, useDallasKeys } from './utils/config';

export const routes = [
  {
    path: '*',
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      { index: true, element: <Home /> },
      {
        path: 'live/:type?/:id?/:subId?',
        element: <ProtectedRoute component={Live} />,
      },
      {
        path: 'retrospective/:id?/:layerIndex?/:itemIndex?/:subItemIndex?',
        element: <ProtectedRoute component={Retrospective} />,
      },
      {
        path: 'highlights',
        element: <ProtectedRoute component={Highlights} />,
        children: [
          {
            index: true,
            element: (
              <Navigate replace={true} to="/highlights/basiccommandunit" />
            ),
          },
          { path: 'basiccommandunit', element: <BasicCommandUnit /> },
          { path: 'sector', element: <Sector /> },
          { path: 'team', element: <Team /> },
          { path: 'fleet', element: <Fleet /> },
        ],
      },
      {
        path: 'eventreplay/:type/:id',
        element: <ProtectedRoute component={EventReplay} />,
      },
      {
        path: 'replay',
        element: <ProtectedRoute component={Replay} />,
      },
      {
        path: 'tags',
        element: <ProtectedRoute component={Tags} />,
        children: [{ path: ':id', element: <Tag /> }],
      },
      {
        path: 'objectives',
        element: <ProtectedRoute component={Objectives} />,
        children: [{ path: ':id', element: <Objective /> }],
      },
      {
        path: 'groups',
        element: <ProtectedRoute component={Groups} />,
        children: [{ path: ':code', element: <Group /> }],
      },
      {
        path: 'locations',
        children: [
          {
            path: ':locationType?',
            element: <ProtectedRoute component={Locations} />,
            children: [{ path: ':id', element: <Location /> }],
          },
        ],
      },
      {
        path: 'resources',
        children: [
          {
            path: 'vehicles',
            element: <ProtectedRoute component={Vehicles} />,
            children: [
              {
                path: ':id',
                element: <Vehicle />,
              },
            ],
          },
          {
            path: 'people',
            element: <ProtectedRoute component={People} />,
            children: [{ path: ':id', element: <Person /> }],
          },
          {
            path: 'telematicsboxes',
            element: <ProtectedRoute component={TelematicsBoxes} />,
            children: [
              {
                path: ':id',
                element: <TelematicsBox />,
              },
              {
                path: ':id/test',
                element: <TelematicsBoxTest />,
              },
            ],
          },
          {
            path: 'radios',
            element: <ProtectedRoute component={Radios} />,
            children: [{ path: ':id', element: <Radio /> }],
          },
          {
            path: `${useDallasKeys ? 'dallaskeys' : 'rfidcards'}`,
            element: <ProtectedRoute component={RfidCards} />,
            children: [{ path: ':id', element: <RfidCard /> }],
          },
        ],
      },
      {
        path: 'reports',
        children: [
          {
            path: 'vehicles',
            children: [
              {
                path: 'dailyutilisation',
                element: <ProtectedRoute component={DailyUtilisation} />,
              },
              {
                path: 'hourlyutilisation',
                element: <ProtectedRoute component={HourlyUtilisation} />,
              },
              {
                path: 'hourlyinlocations',
                element: <ProtectedRoute component={HourlyInLocations} />,
              },
              {
                path: 'timelineinlocations',
                element: <ProtectedRoute component={TimelineInLocations} />,
              },
              {
                path: 'mileage',
                element: <ProtectedRoute component={Mileage} />,
              },
              {
                path: 'availability',
                element: <ProtectedRoute component={VehicleAvailability} />,
              },
              {
                path: 'fleetutilisation/*',
                element: <ProtectedRoute component={FleetUtilisation} />,
              },
            ],
          },
          {
            path: 'people',
            children: [
              {
                path: 'dailyactivity',
                element: <ProtectedRoute component={DailyActivity} />,
              },
              {
                path: 'hourlyactivity',
                element: <ProtectedRoute component={HourlyActivity} />,
              },
              {
                path: 'availability',
                element: <ProtectedRoute component={PersonAvailability} />,
              },
              {
                path: 'driverbehaviour/:year?/:month?/:day?',
                element: <ProtectedRoute component={DriverBehaviour} />,
              },
            ],
          },
          {
            path: 'telematicsboxes',
            children: [
              {
                path: 'lastcontact',
                element: <ProtectedRoute component={LastContact} />,
              },
            ],
          },
        ],
      },
      {
        path: 'events',
        children: [
          {
            path: 'vehicles',
            children: [
              {
                path: 'trips',
                children: [
                  {
                    index: true,
                    element: <ProtectedRoute component={Trips} />,
                  },
                  {
                    path: ':id',
                    element: <ProtectedRoute component={Trip} />,
                  },
                ],
              },
              {
                path: 'stops',
                element: <ProtectedRoute component={Stops} />,
              },
              {
                path: 'idles',
                element: <ProtectedRoute component={Idles} />,
              },
              {
                path: 'speedinfractions',
                element: <ProtectedRoute component={SpeedInfractions} />,
              },
              {
                path: 'accelerometerevents',
                element: <ProtectedRoute component={AccelerometerEvents} />,
              },
              {
                path: 'locationvisits',
                element: <ProtectedRoute component={VehicleLocationVisits} />,
              },
              {
                path: 'boundaryvisits',
                element: <ProtectedRoute component={VehicleBoundaryVisits} />,
              },
              {
                path: 'equipmentactivations',
                element: (
                  <ProtectedRoute component={VehicleEquipmentActivations} />
                ),
              },
              {
                path: 'malfunctionindicatorlight',
                element: (
                  <ProtectedRoute component={MalfunctionIndicatorLight} />
                ),
              },
              {
                path: 'onboarddiagnostics',
                element: <ProtectedRoute component={OnBoardDiagnostics} />,
              },
            ],
          },
          {
            path: 'people',
            children: [
              {
                path: 'trails',
                element: <ProtectedRoute component={Trails} />,
              },
              {
                path: 'locationvisits',
                element: <ProtectedRoute component={PersonLocationVisits} />,
              },
              {
                path: 'boundaryvisits',
                element: <ProtectedRoute component={PersonBoundaryVisits} />,
              },
              {
                path: 'doublecrews',
                element: <ProtectedRoute component={DoubleCrews} />,
              },
              {
                path: 'attendances',
                element: (
                  <ProtectedRoute component={PersonObjectiveAttendances} />
                ),
              },
              {
                path: 'responses',
                element: <ProtectedRoute component={IncidentResponses} />,
              },
            ],
          },
          {
            path: 'telematicsboxes',
            children: [
              {
                path: 'outages',
                element: <ProtectedRoute component={Outages} />,
              },
            ],
          },
        ],
      },
      {
        path: 'admin',
        children: [
          {
            path: 'audit',
            element: <ProtectedRoute component={Audit} />,
          },
          {
            path: 'legacyaudit',
            element: <ProtectedRoute component={LegacyAudit} />,
          },
          {
            path: 'retention',
            element: <ProtectedRoute component={DataRetention} />,
          },
          {
            path: 'engagement',
            element: <ProtectedRoute component={Engagement} />,
          },
          {
            path: 'options',
            element: <ProtectedRoute component={Options} />,
            children: [
              {
                index: true,
                element: <Navigate replace={true} to="/admin/options/people" />,
              },
              {
                path: 'people',
                element: <PersonOptions />,
              },
              {
                path: 'vehicles',
                element: <VehicleOptions />,
              },
              {
                path: 'locations',
                element: <LocationOptions />,
              },
              {
                path: 'groups',
                element: <GroupOptions />,
              },
              {
                path: 'telematicsboxes',
                element: <TelematicsBoxOptions />,
              },
              {
                path: 'radios',
                element: <RadioOptions />,
              },
              {
                path: `${useDallasKeys ? 'dallaskeys' : 'rfidcards'}`,
                element: <RfidCardOptions />,
              },
            ],
          },
        ],
      },
    ],
  },
];

// NOTE: The path value should start with '/' to support to build authorised siteMap. Please do not remove '/' from start of the path value.
export const siteMap = [
  { label: 'Home', Icon: HomeIcon, path: '/' },
  {
    label: 'Highlights',
    Icon: StarIcon,
    path: '/highlights',
  },
  { label: 'Live', Icon: LanguageIcon, path: '/live' },
  {
    label: 'Retrospective',
    Icon: HistoryIcon,
    path: '/retrospective',
  },
  {
    label: 'Replay',
    Icon: ReplayIcon,
    path: '/replay',
  },
  { label: 'Tags', Icon: MoreIcon, path: '/tags' },
  {
    label: 'Objectives',
    Icon: DataUsageIcon,
    path: '/objectives',
  },
  { label: 'Groups & Areas', Icon: GroupsIcon, path: '/groups' },
  {
    label: 'Locations',
    Icon: LocationCityIcon,
    path: '/locations',
  },
  {
    label: 'Resources',
    Icon: GroupIcon,
    group: '/resources',
    children: [
      {
        label: 'Vehicles',
        Icon: DirectionsCarIcon,
        path: '/resources/vehicles',
      },
      {
        label: 'People',
        Icon: PersonIcon,
        path: '/resources/people',
      },
      {
        label: 'Telematics Boxes',
        Icon: RouterIcon,
        path: '/resources/telematicsboxes',
      },
      {
        label: 'Radios',
        Icon: RadioHandheldIcon,
        path: '/resources/radios',
      },
      {
        label: useDallasKeys ? 'Dallas Keys' : 'RFID Cards',
        Icon: useDallasKeys ? VpnKeyIcon : NfcIcon,
        path: `/resources/${useDallasKeys ? 'dallaskeys' : 'rfidcards'}`,
      },
    ],
  },
  {
    label: 'Reports',
    Icon: BarChartIcon,
    group: '/reports',
    children: [
      {
        label: 'Vehicles',
        Icon: DirectionsCarIcon,
        group: '/reports/vehicles',
        children: [
          {
            label: 'Daily Utilisation',
            Icon: EventIcon,
            path: '/reports/vehicles/dailyutilisation',
          },
          {
            label: 'Hourly Utilisation',
            Icon: ScheduleIcon,
            path: '/reports/vehicles/hourlyutilisation',
          },
          {
            label: 'Hourly in Location',
            Icon: GarageIcon,
            path: '/reports/vehicles/hourlyinlocations',
          },
          {
            label: 'Timeline in Location',
            Icon: TimelineIcon,
            path: '/reports/vehicles/timelineinlocations',
          },
          {
            label: 'Mileage',
            Icon: CounterIcon,
            path: '/reports/vehicles/mileage',
          },
          {
            label: 'Availability',
            Icon: EmojiTransportationIcon,
            path: '/reports/vehicles/availability',
          },
          {
            label: 'Fleet Utilisation',
            Icon: GaugeIcon,
            path: '/reports/vehicles/fleetutilisation',
          },
        ],
      },
      {
        label: 'People',
        Icon: PersonIcon,
        group: '/reports/people',
        children: [
          {
            label: 'Driver Behaviour',
            Icon: SteeringIcon,
            path: '/reports/people/driverbehaviour',
          },
          {
            label: 'Daily Activity',
            Icon: EventIcon,
            path: '/reports/people/dailyactivity',
          },
          {
            label: 'Hourly Activity',
            Icon: ScheduleIcon,
            path: '/reports/people/hourlyactivity',
          },
          {
            label: 'Availability',
            Icon: PeopleAvailabilityIcon,
            path: '/reports/people/availability',
          },
        ],
      },
      {
        label: 'Telematics Boxes',
        Icon: RouterIcon,
        group: '/reports/telematicsboxes',
        children: [
          {
            key: 'lastContact',
            label: 'Last Contact',
            Icon: TimerIcon,
            path: '/reports/telematicsboxes/lastcontact',
          },
        ],
      },
    ],
  },
  {
    label: 'Events',
    Icon: NewReleasesIcon,
    group: '/events',
    children: [
      {
        label: 'Vehicles',
        Icon: DirectionsCarIcon,
        group: '/events/vehicles',
        children: [
          {
            label: 'Trips',
            Icon: CarTractionControlIcon,
            path: '/events/vehicles/trips',
          },
          {
            label: 'Stops',
            Icon: CarBrakeParkingIcon,
            path: '/events/vehicles/stops',
          },
          {
            label: 'Idles',
            Icon: SnoozeIcon,
            path: '/events/vehicles/idles',
          },
          {
            label: 'Speed Infractions',
            Icon: SpeedometerIcon,
            path: '/events/vehicles/speedinfractions',
          },
          {
            label: 'Accelerometer Events',
            Icon: AxisArrowIcon,
            path: '/events/vehicles/accelerometerevents',
          },
          {
            label: 'Location Visits',
            Icon: CarSideIcon,
            path: '/events/vehicles/locationvisits',
          },
          {
            label: 'Boundary Visits',
            Icon: FenceIcon,
            path: '/events/vehicles/boundaryvisits',
          },
          {
            label: 'Equipment Activations',
            Icon: FluorescentIcon,
            path: '/events/vehicles/equipmentactivations',
          },
          {
            label: 'Malfunction Indicator Light',
            Icon: EngineIcon,
            path: '/events/vehicles/malfunctionindicatorlight',
          },
          {
            label: 'On-Board Diagnostics',
            Icon: StethoscopeIcon,
            path: '/events/vehicles/onboarddiagnostics',
          },
        ],
      },
      {
        label: 'People',
        Icon: PersonIcon,
        group: '/events/people',
        children: [
          {
            label: 'Trails',
            Icon: RouteIcon,
            path: '/events/people/trails',
          },
          {
            label: 'Location Visits',
            Icon: TourIcon,
            path: '/events/people/locationvisits',
          },
          {
            label: 'Boundary Visits',
            Icon: FenceIcon,
            path: '/events/people/boundaryvisits',
          },
          {
            label: 'Double Crews',
            Icon: PeopleIcon,
            path: '/events/people/doublecrews',
          },
          {
            label: 'Objective Attendances',
            Icon: AttendanceIcon,
            path: '/events/people/attendances',
          },
          {
            label: 'Incident Responses',
            Icon: ReplyIcon,
            path: '/events/people/responses',
          },
        ],
      },
      {
        label: 'Telematics Boxes',
        Icon: RouterIcon,
        group: '/events/telematicsboxes',
        children: [
          {
            label: 'Outages',
            Icon: ExploreOffIcon,
            path: '/events/telematicsboxes/outages',
          },
        ],
      },
    ],
  },
  {
    label: 'Admin',
    Icon: AdminPanelSettingsIcon,
    group: '/admin',
    children: [
      {
        label: 'Audit',
        Icon: ChromeReaderModeIcon,
        path: '/admin/audit',
      },
      showLegacyAudit && {
        label: 'Legacy Audit',
        Icon: CastAudioVariantIcon,
        path: '/admin/legacyaudit',
      },
      {
        label: 'Data Retention',
        Icon: InventoryIcon,
        path: '/admin/retention',
      },
      {
        label: 'Engagement',
        Icon: AdsClickIcon,
        path: '/admin/engagement',
      },
      {
        label: 'Options',
        Icon: SubjectIcon,
        path: '/admin/options',
      },
    ].filter(Boolean),
  },
];

export function isAuthorised(path, auth, authorisation) {
  if (!auth || !auth.isAuthenticated) {
    return false;
  }

  const { routes, hiddenRoutes: serverSideHiddenRoutes } = authorisation ?? {};
  const allHiddenRoutes = [
    ...(hiddenRoutes || []),
    ...(serverSideHiddenRoutes || []),
  ];

  return (
    ((routes || []).some((route) => route.startsWith(path)) ||
      (routes || []).some((route) => path.startsWith(route))) &&
    (allHiddenRoutes || []).every((route) => !path.startsWith(route))
  );
}

export const titleRoutes = [
  { path: '/', element: <TextHeader title="Home" /> },
  { path: 'highlights/*', element: <TextHeader title="Highlights" /> },
  { path: 'replay', element: <TextHeader title="Replay" /> },
  { path: 'tags/*', element: <TextHeader title="Tags" /> },
  { path: 'objectives/*', element: <TextHeader title="Objectives" /> },
  { path: 'groups/*', element: <TextHeader title="Groups & Areas" /> },
  { path: 'locations/*', element: <TextHeader title="Locations" /> },
  {
    path: 'resources',
    children: [
      { path: 'vehicles/*', element: <TextHeader title="Vehicles" /> },
      { path: 'people/*', element: <TextHeader title="People" /> },
      {
        path: 'telematicsboxes/*',
        element: <TextHeader title="Telematics Boxes" />,
      },
      { path: 'radios/*', element: <TextHeader title="Radios" /> },
      {
        path: `${useDallasKeys ? 'dallaskeys' : 'rfidcards'}/*`,
        element: (
          <TextHeader title={useDallasKeys ? 'Dallas Keys' : 'RFID Cards'} />
        ),
      },
    ],
  },
  {
    path: 'reports',
    children: [
      {
        path: 'vehicles',
        children: [
          {
            path: 'dailyutilisation',
            element: <TextHeader title="Daily Utilisation" />,
          },
          {
            path: 'hourlyutilisation',
            element: <TextHeader title="Hourly Utilisation" />,
          },
          {
            path: 'hourlyinlocations',
            element: <TextHeader title="Hourly in Location" />,
          },
          {
            path: 'timelineinlocations',
            element: <TextHeader title="Timeline in Location" />,
          },
          { path: 'mileage', element: <TextHeader title="Mileage" /> },
          {
            path: 'availability',
            element: <TextHeader title="Vehicle Availability" />,
          },
          {
            path: 'fleetutilisation/*',
            element: <TextHeader title="Fleet Utilisation" />,
          },
        ],
      },
      {
        path: 'people',
        children: [
          {
            path: 'dailyactivity',
            element: <TextHeader title="Daily Activity" />,
          },
          {
            path: 'hourlyactivity',
            element: <TextHeader title="Hourly Activity" />,
          },
          {
            path: 'availability',
            element: <TextHeader title="People Availability" />,
          },
          {
            path: 'driverbehaviour/*',
            element: <TextHeader title="Driver Behaviour" />,
          },
        ],
      },
      {
        path: 'telematicsboxes',
        children: [
          { path: 'lastcontact', element: <TextHeader title="Last Contact" /> },
        ],
      },
    ],
  },
  {
    path: 'events',
    children: [
      {
        path: 'vehicles',
        children: [
          {
            path: 'trips',
            children: [
              { index: true, element: <TextHeader title="Trips" /> },
              { path: ':id', element: <TextHeader title="Trip" /> },
            ],
          },
          { path: 'stops', element: <TextHeader title="Stops" /> },
          { path: 'idles', element: <TextHeader title="Idles" /> },
          {
            path: 'speedinfractions',
            element: <TextHeader title="Speed Infractions" />,
          },
          {
            path: 'accelerometerevents',
            element: <TextHeader title="Accelerometer Events" />,
          },
          {
            path: 'locationvisits',
            element: <TextHeader title="Vehicle Location Visits" />,
          },
          {
            path: 'boundaryvisits',
            element: <TextHeader title="Vehicle Boundary Visits" />,
          },
          {
            path: 'equipmentactivations',
            element: <TextHeader title="Vehicle Equipment Activations" />,
          },
          {
            path: 'malfunctionindicatorlight',
            element: <TextHeader title="Malfunction Indicator Light" />,
          },
          {
            path: 'onboarddiagnostics',
            element: <TextHeader title="On-Board Diagnostics" />,
          },
        ],
      },
      {
        path: 'people',
        children: [
          { path: 'trails', element: <TextHeader title="Trails" /> },
          {
            path: 'locationvisits',
            element: <TextHeader title="Person Location Visits" />,
          },
          {
            path: 'boundaryvisits',
            element: <TextHeader title="Person Boundary Visits" />,
          },
          {
            path: 'doublecrews',
            element: <TextHeader title="Double Crews" />,
          },
          {
            path: 'attendances',
            element: <TextHeader title="Objective Attendances" />,
          },
          {
            path: 'responses',
            element: <TextHeader title="Incident Responses" />,
          },
        ],
      },
      {
        path: 'telematicsboxes',
        children: [
          { path: 'outages', element: <TextHeader title="Outages" /> },
        ],
      },
    ],
  },
  {
    path: 'admin',
    children: [
      { path: 'audit', element: <TextHeader title="Audit" /> },
      { path: 'legacyaudit', element: <TextHeader title="Legacy Audit" /> },
      { path: 'retention', element: <TextHeader title="Data Retention" /> },
      { path: 'engagement', element: <TextHeader title="Engagement" /> },
      { path: 'options/*', element: <TextHeader title="Options" /> },
    ],
  },
];

export const toolbarRoutes = [
  {
    path: 'highlights/*',
    element: <HighlightsToolbar />,
  },
  {
    path: 'admin/options/*',
    element: <OptionsToolbar />,
  },
  {
    path: 'locations/*',
    element: <LocationsToolbar />,
  },
];

export const toolbarButtonRoutes = [
  {
    path: 'resources',
    children: [
      {
        index: true,
        element: <MenuButton />,
      },
      {
        path: 'telematicsboxes',
        children: [
          {
            index: true,
            element: <MenuButton />,
          },
          {
            path: ':id',
            children: [
              {
                index: true,
                element: <MenuButton />,
              },
              { path: 'test', element: <BackButton /> },
            ],
          },
        ],
      },
    ],
  },
  { path: '*', element: <MenuButton /> },
];
