import {
  useDeleteLocation,
  useDocumentTitle,
  useLocation,
  useLocationTypes,
  useOptions,
  useOptionValues,
  useUpsertLocation,
  useUserInfo,
} from '@/hooks';
import { isAuthorised } from '@/utils';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { LocationForm } from './LocationForm';

export function Location() {
  const { data: locationTypes } = useLocationTypes();
  const { id, locationType = Object.keys(locationTypes)[0] } = useParams();
  const navigate = useNavigate();
  const { data: location } = useLocation(id);
  const { mutate: upsertLocation } = useUpsertLocation();
  const { mutate: deleteLocation } = useDeleteLocation();
  const typeOptions = useOptionValues('groupType');
  const locationGroups = useOptionValues('locationGroup');
  const { data: locationAttributes } = useOptions('locationAttribute');
  const { data: userInfo } = useUserInfo();
  const canEdit = isAuthorised(userInfo?.authorisation, 'locations', true);
  useDocumentTitle(
    ['IR3', 'Locations', location?.name].filter(Boolean).join(' | '),
  );

  function handleDelete() {
    deleteLocation(id, {
      onSuccess: () => {
        navigate('..', { replace: true });
      },
    });
  }

  function handleSubmit(values) {
    upsertLocation(
      {
        type: locationTypes[locationType]?.value,
        ...values,
      },
      {
        onSuccess: (data) => {
          navigate(`../${data.code}`, { replace: true });
        },
      },
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 1, bgcolor: 'background.paper' }}>
      <LocationForm
        key={[location?._id, location?.lastEdit?.time]}
        defaultValues={location}
        canEdit={canEdit}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        locationType={locationType}
        typeOptions={typeOptions}
        locationGroups={locationGroups}
        locationAttributes={locationAttributes}
        locationTypes={locationTypes}
      />
    </Box>
  );
}
