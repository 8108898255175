import { round } from '@/utils';
import { atom } from 'jotai';

export const stateAtom = atom({
  query: {},
  parameters: {},
});

export const formatLabel = (hour) => `${String(hour).padStart(2, '0')}:00`;

export const formatValue = (measure) => round(measure, 2);
