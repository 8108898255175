import { api } from '@/apis';
import { useMutation } from '@tanstack/react-query';

export function useCancelConfigurationStateChange(imei, name) {
  return useMutation({
    mutationFn: () => {
      return api.delete(`telematicsBox/${imei}/${name}`);
    },
  });
}
