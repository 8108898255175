import { Chip, Stack, Typography } from '@mui/material';

export function GroupAncestors({ groupAncestors, typeOptions }) {
  return groupAncestors?.length > 0 ? (
    <Stack spacing={1} direction="row" sx={{ flexWrap: 'wrap' }}>
      {groupAncestors.map((ancestor) => (
        <Chip
          key={ancestor.code}
          label={ancestor.name}
          icon={
            <Chip
              color="primary"
              label={typeOptions[ancestor.type]?.label}
              size="small"
            />
          }
        />
      ))}
    </Stack>
  ) : (
    <Typography sx={{ px: 1 }} variant="caption" color="textSecondary">
      None
    </Typography>
  );
}
