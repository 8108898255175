import grade1Glyph from '@/img/1.png';
import grade2Glyph from '@/img/2.png';
import grade3Glyph from '@/img/3.png';
import grade4Glyph from '@/img/4.png';
import grade5Glyph from '@/img/5.png';
import grade6Glyph from '@/img/6.png';
import grade7Glyph from '@/img/7.png';
import axisArrowGlyph from '@/img/axis-arrow.png';
import locationGlyph from '@/img/baseline_business_white_24dp.png';
import objectiveGlyph from '@/img/baseline_data_usage_white_24dp.png';
import directionsCarGlyph from '@/img/baseline_directions_car_white_24dp.png';
import directionsWalkGlyph from '@/img/baseline_directions_walk_white_24dp.png';
import feedbackGlyph from '@/img/baseline_feedback_white_24dp.png';
import gestureGlyph from '@/img/baseline_gesture_white_24dp.png';
import motorbikeGlyph from '@/img/baseline_motorbike_white_24dp.png';
import myLocationGlyph from '@/img/baseline_my_location_black_24dp.png';
import personGlyph from '@/img/baseline_person_white_24dp.png';
import playArrowGlyph from '@/img/baseline_play_arrow_white_24dp.png';
import radioGlyph from '@/img/baseline_radio_white_24dp.png';
import telematicsBoxGlyph from '@/img/baseline_router_white_24dp.png';
import snoozeGlyph from '@/img/baseline_snooze_white_24dp.png';
import stopGlyph from '@/img/baseline_stop_white_24dp.png';
import carBrakeParkingGlyph from '@/img/car-brake-parking.png';
import carSideGlyph from '@/img/car-side.png';
import carTractionControlGlyph from '@/img/car-traction-control.png';
import courtGlyph from '@/img/court_white_24dp.png';
import hospitalGlyph from '@/img/hospital_white_24dp.png';
import defaultGlyph from '@/img/map-marker.png';
import clusterGlyph from '@/img/multiple_locations_white_24dp.png';
import schoolGlyph from '@/img/school_white_24dp.png';
import workshopGlyph from '@/img/workshop_white_24dp.png';
import { green, grey, orange, red } from '@mui/material/colors';
import { format, isValid } from 'date-fns';
import { dioStates, isFleet, liveOptions, useDallasKeys } from './config';

export const positionGlyph = myLocationGlyph;

export const idleGlyph = snoozeGlyph;
export const vehicleStopGlyph = stopGlyph;
export const eventGlyph = axisArrowGlyph;

export const accelerometerGlyph = axisArrowGlyph;

export const incidentFilters = {
  types: {
    'ABAND CALL': 'Abandoned Call',
    ABSENT: 'Absent',
    AUDIBLE: 'Alarm Audible Only',
    ALAPOLICE: 'Alarm Police Installed',
    ANIMAL: 'Animal Problems',
    ARSON: 'Arson',
    'ASB DIS AU': 'ASB Dispersal Authority',
    'ASB DIS NO': 'ASB Dispersal Notice',
    'ASB ENVIR': 'ASB Environmental',
    'ASB NUIS': 'ASB Nuisance',
    'ASB PER': 'ASB Personal',
    CTI: 'Aspire Default',
    AUTOCRIME: 'Autocrime Tumv',
    AUTOCRIME2: 'Autocrime Veh Interference',
    AWOL: 'AWOL/Absconders',
    'BURG DIST': 'Burg Dwell Bogus Distraction',
    BURGCOM: 'Burglary Commercial Premises',
    BURGDWELL: 'Burglary Dwelling',
    BURGOP2IN1: 'Burglary Dwelling Op 2 In 1',
    BURGOTH: 'Burglary Other',
    'BURG BOAT': 'Burglary Other Caravan/Boat',
    'BURG GARAG': 'Burglary Other Garage',
    'BURG SHED': 'Burglary Other Shed',
    CIVIL: 'Civil Dispute',
    COLLAPSE: 'Collapse/Injury/Illness/Trappe',
    COMPLAINT: 'Complaints Against Police',
    CONCERN: 'Concern For Safety',
    'CONC CHILD': 'Concern For Safety Of Child',
    ADVICE: 'Contact Record',
    'CRIM-RAP': 'Crime - Rape',
    DAMAGE: 'Criminal Damage',
    DOMESTIC: 'Domestic Incident',
    DOMVIO: 'Domestic Violence',
    DRUGS: 'Drugs',
    'EC CRIME': 'Economic Crime',
    FIREARMS: 'Firearms Inc',
    FRAUD: 'Fraud & Forgery',
    RTDISRUPT: 'Highway Disruption',
    'HOAX CALL': 'Hoax Calls Emergency Services',
    ASYLUM: 'Immigration',
    'HSE ACC': 'Industrial Incident/Accident',
    INSPREM: 'Insecure Premises/Vehicles',
    LICENSING: 'Licensing',
    LONEWKR: 'Lone Worker Alarm',
    LOSTFOUND: 'Lost/Found Property & Persons',
    MESSAGE: 'Messages',
    MISPER: 'Mfh - Pending Risk Asssessment',
    'MFH CARE': 'Missing Unauthorised Care',
    'MFH HIGH': 'Missing Person - High Risk',
    'MFH LOW': 'Missing Person - Low Risk',
    'MFH MED': 'Missing Person - Medium Risk',
    'MFH FELL': 'Missing Person Fell Rescue',
    DISASTER: 'Natural Disaster/Warning',
    OFFASSIST: 'Officer Assistance',
    OTHOFFENCE: 'Other Offences',
    PETS: 'Pets/Domesticated Animals',
    TASKING: 'Police Generated Activity',
    'PLAN EVENT': 'Pre Planned Events/Lamping',
    PROTEST: 'Protest/Demonstration',
    RTOFFENCE: 'Road Related Offence',
    ROBBERY: 'Robbery',
    'RTC DAMAGE': 'RTC Damage Only',
    'RTC DEATH': 'RTC Death',
    'RTC MINOR': 'RTC Minor',
    RTCSERIOUS: 'RTC Serious',
    SEXUAL: 'Sexual Offence',
    SMV: 'SMV',
    SMVRECOVD: 'SMV Recovered',
    SUDDEATH: 'Sudden Death',
    'SUS CIRCS': 'Suspect Circumstances',
    'SUS MUNITN': 'Suspect Object Munitions',
    'SUS OBJECT': 'Suspect Object/Package',
    'TERR BOMB': 'Terrorism Bomb Call',
    TERRORISM: 'Terrorism General',
    'TERR KIDNA': 'Terrorism Kidnap/Abduction',
    'TERR CYCLA': 'Terrorism Op Cyclamen',
    TEST: 'Test/Training',
    THEFT: 'Theft General',
    MOBTHEFT: 'Theft Mobile',
    'THEFT SHOP': 'Theft Shop',
    TRANSPORT: 'Transport Rail/Marine/Air',
    'URN ALARM': 'Urn Alarm',
    VIOLENCE: 'Violence',
    'WANT/BAIL': 'Wanted Person/Court Ord/Bail',
    WILDLIFE: 'Wildlife',
  },
  closingCodes: {
    ADMIN03: 'Cancel/Exit/Error',
    ADMIN04: 'Comp Pol./Dissatisfaction Serv',
    ADMIN05: 'Duplicate',
    ADMIN06: 'Lost/Found Property & Persons',
    ADMIN08: 'Messages',
    ADMIN10: 'Police Generated Activity',
    ADMIN11: 'Pre Planned Events',
    ADMIN12: 'Test/Training',
    ADMIN14: 'Contact Record',
    ASB17: 'Personal',
    ASB18: 'Nuisance',
    ASB19: 'Environmental',
    CRIME01: 'Arson',
    CRIME02: 'Burglary Dwelling',
    CRIME03: 'Burglary Other',
    CRIME04: 'Criminal Damage',
    CRIME05: 'Drugs',
    CRIME06: 'Fraud & Forgery',
    CRIME07: 'Robbery',
    CRIME08: 'Sexual Offence',
    CRIME09: 'Theft General',
    CRIME10: 'Autocrime',
    CRIME11: 'SMV',
    CRIME12: 'Terrorism',
    CRIME14: 'Violence',
    CRIME15: 'Other Offences',
    CRIME16: 'Crime Related Incident',
    CRIME17: 'Burglary Commercial Premises',
    CRIME18: 'Economic Crime',
    PSW05: 'Abandoned Call',
    PSW08: 'Civil Dispute',
    PSW11: 'Domestic Incident',
    PSW12: 'Firearms Inc',
    PSW13: 'Immigration',
    PSW14: 'Industrial Incident/Accident',
    PSW16: 'Licensing',
    PSW22: 'Natural Disaster/Warning',
    PSW24: 'Protest/Demonstration',
    PSW25: 'Sudden Death',
    PSW27: 'Suspect Object/Package',
    PSW30: 'Missing Person',
    PSW32: 'Alarm',
    PSW33: 'Hoax Calls',
    PSW34: 'Absc/AWOL/Wanted/Bail',
    PSW35: 'Conc Safety/Trapped/Inj/Ill',
    PSW36: 'Sus Circs/Insecure Prem/Veh',
    PSW37: 'Animals/Wildlife',
    PSW38: 'Absent',
    TRANSP01: 'RTC Damage Only',
    TRANSP05: 'Highway Disruption',
    TRANSP06: 'Road Related Offence',
    TRANSP07: 'Transport Rail/Marine/Air',
    TRANSP09: 'RTC Death/Injury',
  },
  categories: [
    'Immediate Urban',
    'Immediate Rural',
    'Priority',
    'Scheduled',
    'Unknown',
  ],
  status: ['Opened', 'Assigned', 'Deployed', 'Attended', 'Closed'],
};

export const incidentStatusColours = {
  opened: red[500],
  assigned: orange[500],
  attended: green[500],
  closed: grey[500],
};

export const retrospectiveTypeGlyphs = {
  vehicleTrips: carTractionControlGlyph,
  vehicleStops: carBrakeParkingGlyph,
  vehicleStopCount: carBrakeParkingGlyph,
  vehicleIdles: snoozeGlyph,
  vehicleIdleCount: snoozeGlyph,
  vehiclePolls: directionsCarGlyph,
  vehicleCustomVisits: carSideGlyph,
  vehicleVisits: carSideGlyph,
  vehicleTime: carSideGlyph,
  vehicleVisitCount: carSideGlyph,
  incidents: feedbackGlyph,
  incidentCount: feedbackGlyph,
  personTrails: gestureGlyph,
  personCustomVisits: directionsWalkGlyph,
  personVisits: directionsWalkGlyph,
  personTime: directionsWalkGlyph,
  personVisitCount: directionsWalkGlyph,
  personPolls: personGlyph,
  pathStart: playArrowGlyph,
  pathEnd: stopGlyph,
  clusters: clusterGlyph,
  accelerometerEvents: axisArrowGlyph,
  accelerometerAlerts: axisArrowGlyph,
  accelerometerAlertCount: axisArrowGlyph,
  default: defaultGlyph,
};

export const replayTypeGlyphs = {
  incident: feedbackGlyph,
  vehicle: directionsCarGlyph,
  person: personGlyph,
  event: axisArrowGlyph,
  location: locationGlyph,
  objective: objectiveGlyph,
};

// function expandConfigFilters(group, path) {
//   return Object.fromEntries(
//     Object.keys(group).map((key) => [
//       key,
//       {
//         name: group[key].label,
//         value: path + key,
//         isArray: true,
//       },
//     ]),
//   );
// }

export const FOLLOW_TAG = 'Followed items';

const { filterOptionsOverrides } = liveOptions;
function arrayToOptions(optionsArray) {
  if (!optionsArray || optionsArray.length === 0) {
    return undefined;
  }

  return optionsArray.map((value) => ({ label: value, value }));
}

const onOffOptions = [
  { label: 'On', value: true },
  { label: 'Off', value: false },
];

const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const activeOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

// some common filters
const tagsArray = {
  name: 'Tags',
  value: 'tags',
  isArray: true,
  excludeSort: true,
};

const stale = {
  name: 'Stale',
  value: 'stale',
  excludeSort: true,
  options: yesNoOptions,
};

const locationAndTypeArray = {
  locationNamesArray: {
    name: 'Location',
    value: 'locationNamesArray',
    isArray: true,
  },
  locationTypesArray: {
    name: 'Location Type',
    value: 'locationTypesArray',
    isArray: true,
  },
};

export const liveFilters = {
  vehicles: {
    fleetNumber: {
      name: 'Fleet Number',
      value: 'fleetNumber',
    },
    registrationNumber: {
      name: 'Registration Number',
      value: 'registrationNumber',
    },
    role: {
      name: 'Role',
      value: 'role',
    },
    driverCode: {
      name: 'Code',
      value: 'driver.code',
      prefix: 'Driver',
    },
    driverRole: {
      name: 'Role',
      value: 'driver.role',
      prefix: 'Driver',
    },
    homeStation: {
      name: 'Home Station',
      value: 'homeStation',
    },
    type: {
      name: 'Type',
      value: 'type',
    },
    colour: {
      name: 'Colour',
      value: 'colour',
    },
    make: {
      name: 'Make',
      value: 'make',
    },
    model: {
      name: 'Model',
      value: 'model',
    },
    ...Object.fromEntries(
      Object.keys(dioStates)
        .filter((key) => !key.startsWith('unused'))
        .map((key) => [
          key,
          {
            name: dioStates[key],
            value: key,
            options: onOffOptions,
            type: 'autocomplete',
            excludeSort: true,
          },
        ]),
    ),
    groupCodes: {
      name: 'Groups & Areas',
      value: 'groupCodes',
      type: 'groups',
    },
    groups: {
      name: 'Driver Categories',
      value: 'groups',
      isDictionary: true,
      // prefix: 'Driver',
    },
    speedKilometresPerHour: {
      name: 'Speed',
      value: 'speedKilometresPerHour',
      type: 'miles',
      unit: 'mph',
    },
    speeding: {
      name: 'Speeding',
      value: 'speeding',
      excludeSort: true,
      options: yesNoOptions,
    },
    stale,
    emergencyEquipmentOn: {
      name: 'Warning Equipment',
      value: 'emergencyEquipmentOn',
      options: onOffOptions,
      type: 'autocomplete',
      excludeSort: true,
    },
    status: {
      name: 'Status',
      value: 'assignments.callSign.status',
      options: arrayToOptions(filterOptionsOverrides?.callSignStatuses),
      changeful: true,
    },
    driverCollarNumber: {
      name: 'Collar Number',
      value: 'driver.collarNumber',
      prefix: 'Driver',
    },
    driverName: {
      name: 'Name',
      value: 'driverName', // derived so not in driver object
      prefix: 'Driver',
    },
    driverSkillsArray: {
      name: 'Skills',
      // derived from skills object array[{name: 'Skill 1', type: 'skill'}] to ['Skill 1']
      value: 'driverSkillsArray',
      type: 'GROUPED_OPTION',
      optionName: 'personSkill',
      isArray: true,
      excludeSort: true,
      prefix: 'Driver',
    },
    driverIdentificationReference: {
      name: useDallasKeys ? 'Dallas Keys' : 'RFIDs',
      value: 'driver.identificationReference',
      prefix: 'Driver',
    },
    callSign: {
      name: 'Call Sign',
      value: 'assignments.callSign.code',
      changeful: true,
    },
    incident: {
      name: 'Incident',
      value: 'assignments.incident.number',
      changeful: true,
    },
    ignitionOn: {
      name: 'Ignition',
      value: 'ignitionOn',
      options: onOffOptions,
      type: 'autocomplete',
      excludeSort: true,
    },
    lastIgnitionOffTime: {
      name: 'Last Ignition Off Time',
      value: 'lastIgnitionOffTime',
      type: 'datetime',
    },
    lastPollTime: {
      name: 'Last Poll Time',
      value: 'lastPollTime',
      type: 'datetime',
    },
    imei: {
      name: 'IMEI',
      value: 'telematicsBoxImei',
    },
    ...locationAndTypeArray,
    equipmentArray: {
      name: 'Equipment',
      // derived from skills object array[{name: 'Skill 1', type: 'skill'}] to ['Skill 1']
      value: 'equipment',
      isArray: true,
      excludeSort: true,
    },
    malfunctionIndicatorLightOn: {
      name: 'Malfunction Indicator Light',
      value: 'malfunctionIndicatorLightOn',
      options: onOffOptions,
      type: 'autocomplete',
      excludeSort: true,
    },
    tagsArray,
  },
  locations: {
    name: {
      name: 'Name',
      value: 'name',
    },
    type: {
      name: 'Type',
      value: 'type',
    },
    subtype: {
      name: 'Subtype',
      value: 'subtype',
    },
    groupCodes: {
      name: 'Groups & Areas',
      value: 'groupCodes',
      type: 'groups',
    },
    tagsArray,
    people: {
      name: 'People',
      value: 'people',
      type: 'number',
      unit: 'total',
    },
    vehicles: {
      name: 'Vehicles',
      value: 'vehicles',
      type: 'number',
      unit: 'total',
    },
  },
  people: {
    name: {
      name: 'Name',
      value: 'name',
    },
    role: {
      name: 'Role',
      value: 'role',
    },
    rank: {
      name: 'Rank',
      value: 'rank.name',
    },
    code: {
      name: 'Code',
      value: 'code',
    },
    collarNumber: {
      name: 'Collar Number',
      value: 'collarNumber',
    },
    radio: {
      name: 'Radio SSI',
      value: 'radioSsi',
    },
    homeStation: {
      name: 'Home Station',
      value: 'homeStation',
    },
    category: {
      name: 'Category',
      value: 'category',
    },
    groupCodes: {
      name: 'Groups & Areas',
      value: 'groupCodes',
      type: 'groups',
    },
    stale,
    skills: {
      name: 'Skills',
      value: 'skillsArray',
      type: 'GROUPED_OPTION',
      optionName: 'personSkill',
      isArray: true,
      excludeSort: true,
    },
    ...locationAndTypeArray,
    driverKeys: {
      name: useDallasKeys ? 'Dallas Keys' : 'RFIDs',
      value: 'driverKeysArray',
      isArray: true,
    },
    status: {
      name: 'Status',
      value: 'assignments.callSign.status',
      options: arrayToOptions(filterOptionsOverrides?.callSignStatuses),
      changeful: true,
    },
    callSignStatus: {
      name: 'Status',
      value: 'callSignStatus',
      changeful: true,
    },
    callSign: {
      name: 'Call Sign',
      value: 'assignments.callSign.code',
      changeful: true,
    },
    callSignCategory: {
      name: 'Category',
      value: 'callSignCategory',
      changeful: true,
    },
    incident: {
      name: 'Incident',
      value: 'assignments.incident.number',
      changeful: true,
    },
    emergencyButtonOn: {
      name: 'Emergency Button',
      value: 'emergencyButtonOn',
      type: 'autocomplete',
      options: onOffOptions,
    },
    lastPollTime: {
      name: 'Last Poll Time',
      value: 'lastPollTime',
      type: 'datetime',
    },
    tagsArray,
  },
  events: {
    fleetNumber: {
      name: 'Fleet Number',
      value: 'vehicle.fleetNumber',
    },
    registrationNumber: {
      name: 'Registration Number',
      value: 'vehicle.registrationNumber',
    },
    homeStation: {
      name: 'Home Station',
      value: 'vehicle.homeStation',
    },
    role: {
      name: 'Role',
      value: 'vehicle.role',
    },
    type: {
      name: 'Type',
      value: 'vehicle.type',
    },
    time: {
      name: 'Date',
      value: 'datetime',
      type: 'date',
    },
    ...locationAndTypeArray,
    tagsArray,
  },
  incidents: {
    number: {
      name: 'Number',
      value: 'number',
      excludeSort: true,
    },
    group: {
      name: 'Group',
      value: 'group',
    },
    type: {
      name: 'Type',
      value: 'type.name',
    },
    category: {
      name: 'Category',
      value: 'category.name',
    },
    responseCategory: {
      name: 'Response Category',
      value: 'responseCategory.name',
    },
    status: {
      name: 'Status',
      value: 'status',
    },
    ...locationAndTypeArray,
    closingCodes: {
      name: 'Closing Codes',
      value: 'closingCodesArray',
      isArray: true,
    },
    openedTime: {
      name: 'Opened Time',
      value: 'openedTime',
      type: 'datetime',
    },
    assignedTime: {
      name: 'Assigned Time',
      value: 'assignedTime',
      type: 'datetime',
    },
    attendedTime: {
      name: 'Attended Time',
      value: 'attendedTime',
      type: 'datetime',
    },
    date: {
      name: 'Date',
      value: 'date',
      type: 'date',
      onlyEqual: true,
      parse: (x) => {
        const date = new Date(x);
        if (isValid(date)) {
          return format(date, 'yyyy-MM-dd');
        }
        return '';
      },
    },
    reference: {
      name: 'Reference',
      value: 'reference',
    },
    ward: {
      name: 'Ward',
      value: 'ward.name',
    },
    tagsArray,
    groupCodes: {
      name: 'Groups & Areas',
      value: 'groupCodes',
      type: 'groups',
    },
  },
  objectives: {
    type: {
      name: 'Type',
      value: 'type',
    },
    identifier: {
      name: 'Identifier',
      value: 'identifier',
    },
    title: {
      name: 'Title',
      value: 'title',
    },
    startTime: {
      name: 'Start Date',
      value: 'startTime',
      type: 'date',
    },
    endTime: {
      name: 'End Date',
      value: 'endTime',
      type: 'date',
    },
    status: {
      name: 'Status',
      value: 'status',
      options: activeOptions,
    },
    wards: {
      name: 'Wards',
      value: 'wards',
      isArray: true,
    },
    tagsArray,
  },
  tags: {
    id: {
      name: 'Name',
      value: 'id',
    },
  },
  callSigns: {
    id: {
      name: 'Name',
      value: 'id',
    },
    status: {
      name: 'Status',
      options: arrayToOptions(filterOptionsOverrides?.callSignStatuses),
      value: 'status',
    },
    incident: {
      name: 'Incident',
      value: 'incident.number',
    },
  },
  telematicsBoxes: {
    imei: {
      name: 'IMEI',
      value: 'imei',
    },
    type: {
      name: 'Type',
      value: 'type',
    },
    lastIgnitionOffTime: {
      name: 'Last Ignition Off Time',
      value: 'lastIgnitionOffTime',
      type: 'datetime',
    },
    time: {
      name: 'Last Poll Time',
      value: 'mostRecentPoll.time',
      type: 'datetime',
    },
    firstContactTime: {
      name: 'First Contact Time',
      value: 'firstContactTime',
      type: 'datetime',
    },
    ignitionOn: {
      name: 'Ignition',
      value: 'mostRecentPoll.ignitionOn',
      options: onOffOptions,
      type: 'autocomplete',
      excludeSort: true,
    },
    emergencyEquipmentOn: {
      name: 'Warning Equipment',
      value: 'mostRecentPoll.emergencyEquipmentOn',
      options: onOffOptions,
      type: 'autocomplete',
      excludeSort: true,
    },
    sirensOn: {
      name: 'Sirens On',
      value: 'mostRecentPoll.sirensOn',
      options: onOffOptions,
      type: 'autocomplete',
      excludeSort: true,
    },
    headlightsFlashOn: {
      name: 'Headlights Flash On',
      value: 'mostRecentPoll.headlightsFlashOn',
      options: onOffOptions,
      type: 'autocomplete',
      excludeSort: true,
    },
    speedKilometresPerHour: {
      name: 'Speed',
      value: 'mostRecentPoll.speedKilometresPerHour',
      type: 'miles',
      unit: 'mph',
    },
    ...locationAndTypeArray,
  },
  radios: {
    ssi: {
      name: 'SSI',
      value: 'ssi',
    },
    type: {
      name: 'Type',
      value: 'type',
    },
    ...locationAndTypeArray,
  },
};

export const liveTypes = {
  vehicle: { listName: 'vehicles', route: '/live/vehicles' },
  location: { listName: 'locations', route: '/live/locations' },
  person: { listName: 'people', route: '/live/people' },
  event: { listName: 'events', route: '/live/events' },
  incident: { listName: 'incidents', route: '/live/incidents' },
};

export const liveGroups = {
  vehicles: 'vehicle',
  locations: 'location',
  people: 'person',
  events: 'event',
  incidents: 'incident',
};

export const livePaths = {
  vehicle:
    'M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z',
  person:
    'M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z',
  ignition:
    'M7,14A2,2 0 0,1 5,12A2,2 0 0,1 7,10A2,2 0 0,1 9,12A2,2 0 0,1 7,14M12.65,10C11.83,7.67 9.61,6 7,6A6,6 0 0,0 1,12A6,6 0 0,0 7,18C9.61,18 11.83,16.33 12.65,14H17V18H21V14H23V10H12.65Z',
  malfunctionIndicatorLight:
    'M7,4V6H10V8H7L5,10V13H3V10H1V18H3V15H5V18H8L10,20H18V16H20V19H23V9H20V12H18V8H12V6H15V4H7Z',
  light:
    'M12,6A6,6 0 0,1 18,12C18,14.22 16.79,16.16 15,17.2V19A1,1 0 0,1 14,20H10A1,1 0 0,1 9,19V17.2C7.21,16.16 6,14.22 6,12A6,6 0 0,1 12,6M14,21V22A1,1 0 0,1 13,23H11A1,1 0 0,1 10,22V21H14M20,11H23V13H20V11M1,11H4V13H1V11M13,1V4H11V1H13M4.92,3.5L7.05,5.64L5.63,7.05L3.5,4.93L4.92,3.5M16.95,5.63L19.07,3.5L20.5,4.93L18.37,7.05L16.95,5.63Z',
  trailer:
    'M22,15V17H10A3,3 0 0,1 7,20A3,3 0 0,1 4,17H2V6A2,2 0 0,1 4,4H17A2,2 0 0,1 19,6V15H22M7,16A1,1 0 0,0 6,17A1,1 0 0,0 7,18A1,1 0 0,0 8,17A1,1 0 0,0 7,16Z',
  beacon:
    'M6,6.9L3.87,4.78L5.28,3.37L7.4,5.5L6,6.9M13,1V4H11V1H13M20.13,4.78L18,6.9L16.6,5.5L18.72,3.37L20.13,4.78M4.5,10.5V12.5H1.5V10.5H4.5M19.5,10.5H22.5V12.5H19.5V10.5M6,20H18A2,2 0 0,1 20,22H4A2,2 0 0,1 6,20M12,5A6,6 0 0,1 18,11V19H6V11A6,6 0 0,1 12,5Z',
  siren:
    'M14,3.23V5.29C16.89,6.15 19,8.83 19,12C19,15.17 16.89,17.84 14,18.7V20.77C18,19.86 21,16.28 21,12C21,7.72 18,4.14 14,3.23M16.5,12C16.5,10.23 15.5,8.71 14,7.97V16C15.5,15.29 16.5,13.76 16.5,12M3,9V15H7L12,20V4L7,9H3Z',
  driver:
    'M13,19.92C14.8,19.7 16.35,18.95 17.65,17.65C18.95,16.35 19.7,14.8 19.92,13H16.92C16.7,14 16.24,14.84 15.54,15.54C14.84,16.24 14,16.7 13,16.92V19.92M10,8H14L17,11H19.92C19.67,9.05 18.79,7.38 17.27,6C15.76,4.66 14,4 12,4C10,4 8.24,4.66 6.73,6C5.21,7.38 4.33,9.05 4.08,11H7L10,8M11,19.92V16.92C10,16.7 9.16,16.24 8.46,15.54C7.76,14.84 7.3,14 7.08,13H4.08C4.3,14.77 5.05,16.3 6.35,17.6C7.65,18.9 9.2,19.67 11,19.92M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12C22,14.75 21,17.1 19.05,19.05C17.1,21 14.75,22 12,22C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z',
  follow:
    'M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z',
};

export const sourceTypes = {
  incidents: 'Incidents',
  crimes: 'Crimes',
  intelligence: 'Intelligence',
  stopChecks: 'Stop Checks',
};

export const mapPolygonIconsByTypeAndSubtype = {
  // e.g.
  // person: 7,
  // event: 3,
  // objective: 6
  ...(liveOptions?.mapPolygonIconsByTypeAndSubtype || {}),
};

export const mapGlyphsByTypeAndSubtype = {
  incident: {
    default: feedbackGlyph,
    1: grade1Glyph,
    2: grade2Glyph,
    3: grade3Glyph,
    4: grade4Glyph,
    5: grade5Glyph,
    6: grade6Glyph,
    7: grade7Glyph,
  },
  vehicle: { default: directionsCarGlyph, MOTORCYCLE: motorbikeGlyph },
  person: { default: personGlyph },
  event: { default: axisArrowGlyph },
  location: {
    default: locationGlyph,
    Workshop: workshopGlyph,
    Court: courtGlyph,
    Hospital: hospitalGlyph,
    School: schoolGlyph,
  },
  objective: {
    default: objectiveGlyph,
  },
  radio: {
    default: radioGlyph,
  },
  telematicsBox: {
    default: telematicsBoxGlyph,
  },
};

const outline = 'rgba(255,255,255,1.0)';
const { statusColours } = liveOptions;
// from outside to in these arrays are colours for [outline, fill, glyph]
export const commonResourceColours = {
  ...(statusColours?.callSigns || {
    assigned: [outline, 'rgba(67,160,71,1.0)', 'rgba(255,255,255,1.0)'],
    unavailable: [outline, 'rgba(156,39,176,1.0)', 'rgba(255,255,255,1.0)'],
  }),
  // default: [outline, 'rgba(0,0,0,1.0)', 'rgba(255,255,255,1.0)'],
};

export const statusIconColoursByType = {
  default: {
    default: [outline, 'rgba(0,0,0,1.0)', 'rgba(255,255,255,1.0)'],
    hover: [
      'rgba(117,117,117,1.0)',
      'rgba(255,255,255,1.0)',
      'rgba(117,117,117,1.0)',
    ],
    select: ['rgba(0,0,0,1.0)', 'rgba(255,255,255,1.0)', 'rgba(0,0,0,1.0)'],
  },
  person: {
    emergency: ['rgba(0,0,0,1.0)', 'rgba(244,67,54,1.0)', 'rgba(0,0,0,1.0)'],
    ...commonResourceColours,
    ...(statusColours?.people || {}),
    stale: [outline, 'rgba(153,153,153,1.0)', 'rgba(102,102,102,1.0)'],
  },
  vehicle: {
    // emergency: ['rgba(0,0,0,1.0)', 'rgba(244,67,54,1.0)', 'rgba(0,0,0,1.0)'],
    ...(isFleet ? {} : commonResourceColours),
    ...(statusColours?.vehicles || {}),
    'warning equipment': [outline, 'rgb(0,188,212)', 'rgba(255,255,255,1.0)'],
    malfunctioning: [
      'rgba(0,0,0,1.0)',
      'rgba(255,193,7,1.0)',
      'rgba(0,0,0,1.0)',
    ],
    parked: [outline, 'rgba(117,117,117,1.0)', 'rgba(255,255,255,1.0)'],
    stale: [outline, 'rgba(153,153,153,1.0)', 'rgba(102,102,102,1.0)'],
  },
  callSign: {
    ...commonResourceColours,
  },
  location: {
    default: [outline, 'rgba(0,0,0,1.0)', 'rgba(255,255,255,1.0)'],
  },
  incident: {
    ...Object.fromEntries(
      Object.keys(incidentStatusColours).map((status) => [
        status,
        [outline, incidentStatusColours[status], 'rgba(255,255,255,1.0)'],
      ]),
    ),
    ...(statusColours?.incidents || {}),
  },
  event: {
    ...(statusColours?.events || {}),
  },
  objective: {
    active: [outline, 'rgba(33,150,243,1.0)', 'rgba(255,255,255,1.0)'],
    inactive: [outline, 'rgba(189,189,189,1.0)', 'rgba(255,255,255,1.0)'],
    ...(statusColours?.objectives || {}),
  },
  radio: {
    default: [outline, 'rgba(0,0,0,1.0)', 'rgba(255,255,255,1.0)'],
    stale: [outline, 'rgba(153,153,153,1.0)', 'rgba(102,102,102,1.0)'],
  },
  telematicsBox: {
    ...commonResourceColours,
    ...(statusColours?.telematicsBox || {}),
    default: [outline, 'rgba(0,0,0,1.0)', 'rgba(255,255,255,1.0)'],
    'warning equipment': [outline, 'rgb(0,188,212)', 'rgba(255,255,255,1.0)'],
    parked: [outline, 'rgba(117,117,117,1.0)', 'rgba(255,255,255,1.0)'],
    stale: [outline, 'rgba(153,153,153,1.0)', 'rgba(102,102,102,1.0)'],
  },
  // bit of a hack so they are all in one place
};

// without having to retype everything above use the order
// to assume the zIndex
export const statusZIndexByType = Object.fromEntries(
  Object.entries(statusIconColoursByType).map(([type, statusColours]) => [
    type,
    Object.fromEntries(
      Object.keys(statusColours)
        .reverse()
        .map((status, index) => [status, index]),
    ),
  ]),
);

export const unitsOfTime = ['days', 'weeks', 'months'];

export function easeInOutQuint(t) {
  return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
}
