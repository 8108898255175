import { useOptionLookup } from '@/hooks';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useMemo } from 'react';
import { AttendeesTable } from './AttendeesTable';
import { useCurrentObjectiveCounts } from './useCurrentObjectiveCounts';

export function CurrentObjectivesCard({ groupType, groupCode }) {
  const { data } = useCurrentObjectiveCounts(groupType, groupCode);
  const typeOptions = useOptionLookup('groupType');
  const columns = useMemo(
    () => [
      { accessorKey: 'title', header: 'Title' },
      {
        accessorKey: 'totalAttendances',
        header: 'Total',
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'groupAttendances',
        header: typeOptions[groupType],
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
      },
    ],
    [groupType, typeOptions],
  );
  const table = useMaterialReactTable({
    columns,
    data,
    state: { density: 'compact' },
    enableTopToolbar: false,
    enableColumnActions: false,
    renderDetailPanel: ({ row }) => (
      <AttendeesTable
        objectiveId={row.original.identifier}
        groupType={groupType}
        groupCode={groupCode}
      />
    ),
    renderEmptyRowsFallback: () => (
      <Box sx={{ p: 1 }}>
        <Typography variant="caption" color="textSecondary">
          None
        </Typography>
      </Box>
    ),
  });

  return (
    <Card variant="outlined" sx={{ flex: 1 }}>
      <CardContent>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ color: 'text.secondary' }}
          >
            Current Objectives
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            with attendances this week
          </Typography>
        </Stack>
        <MaterialReactTable table={table} />
      </CardContent>
    </Card>
  );
}
