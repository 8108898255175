import { useOptionLookup } from '@/hooks';
import { shortHumanizer } from '@/utils';
import { Box, Typography } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { Fragment } from 'react';
import { useObjectiveAttendeeCounts } from './useObjectiveAttendeeCounts';

const columns = [
  {
    accessorKey: 'collarNumber',
    header: 'Collar Number',
  },
  {
    accessorKey: 'attendances',
    header: 'Attendances',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
  },
  {
    accessorKey: 'durationSeconds',
    header: 'Time',
    muiTableHeadCellProps: {
      align: 'right',
    },
    muiTableBodyCellProps: {
      align: 'right',
    },
    Cell: (props) => shortHumanizer(props.cell.getValue() * 1000),
  },
];

export function AttendeesTable({ objectiveId, groupType, groupCode }) {
  const { data } = useObjectiveAttendeeCounts(
    objectiveId,
    groupType,
    groupCode,
  );
  const typeOptions = useOptionLookup('groupType');
  return (
    data.length > 0 && (
      <Fragment>
        <Typography
          variant="subtitle2"
          gutterBottom
          sx={{ color: 'text.secondary' }}
        >
          {`${typeOptions[groupType]} Attendees`}
        </Typography>
        <MaterialReactTable
          columns={columns}
          data={data}
          state={{ density: 'compact' }}
          enableTopToolbar={false}
          enableColumnActions={false}
          enableSorting={true}
          enableBottomToolbar={true}
          enablePagination={true}
          muiTableBodyRowProps={{
            sx: { '&:last-child td, &:last-child th': { border: 0 } },
          }}
          renderEmptyRowsFallback={() => (
            <Box sx={{ p: 1 }}>
              <Typography variant="caption" color="textSecondary">
                None
              </Typography>
            </Box>
          )}
        />
      </Fragment>
    )
  );
}
