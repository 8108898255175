import { Stack } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { peopleColumns } from './utils';

export function PeopleDetail({ people = [] }) {
  return (
    <Stack direction="row">
      <MaterialReactTable
        data={people}
        columns={peopleColumns}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enableColumnActions={false}
        enableColumnFilters={false}
        defaultColumn={{ size: 0 }}
        initialState={{ density: 'compact' }}
        muiTableBodyRowProps={{
          sx: { '&:last-child td, &:last-child th': { border: 0 } },
        }}
      />
    </Stack>
  );
}
