import { startCase } from '@/utils';
import {
  AddCircle as AddIcon,
  Check as CheckIcon,
  RemoveCircle as RemoveIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  FormControl,
  FormLabel,
  IconButton,
  Paper,
  Popover,
  Radio,
  Slider,
  Tooltip,
  Typography,
} from '@mui/material';
import * as colors from '@mui/material/colors';
import { Fragment, useState } from 'react';

// Object.keys(colors) is ordered by name in production so slice doesn't work
//const hues = Object.keys(colors).slice(1);
// this works but the order of the colors is odd, so I'll just be explicit
//const hues = Object.keys(colors).filter((hue) => hue !== 'common');
const hues = [
  'red',
  'pink',
  'purple',
  'deepPurple',
  'indigo',
  'blue',
  'lightBlue',
  'cyan',
  'teal',
  'green',
  'lightGreen',
  'lime',
  'yellow',
  'amber',
  'orange',
  'deepOrange',
  'brown',
  'grey',
  'blueGrey',
];
const shades = [
  '900',
  '800',
  '700',
  '600',
  '500',
  '400',
  '300',
  '200',
  '100',
  '50',
  'A700',
  'A400',
  'A200',
  'A100',
];
const hueAndShade = Object.assign(
  {},
  ...[].concat(
    ...Object.entries(colors)
      .filter(([color]) => color !== 'common')
      .map((hue) =>
        Object.entries(hue[1]).map((shade) => ({
          [shade[1]]: { hue: hue[0], shade: shade[0] },
        })),
      ),
  ),
);
const grey = colors.grey[500];

function getArray(value, min) {
  if (Array.isArray(value)) {
    if (value.length < (min || 1)) {
      return Array(min || 1)
        .fill(grey)
        .map((item, index) => value[index] || item);
    } else {
      return value;
    }
  } else {
    return Array(min || 1).fill(grey);
  }
}

export function ColorsField({ input, label, min, max, ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const array = getArray(input.value, min);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleChangeHue(event) {
    const {
      target: { value: hue },
    } = event;

    const index = anchorEl ? parseInt(anchorEl.id) : 0;
    const shade = hueAndShade[input.value[index] || grey].shade;
    const color = colors[hue][shade];

    input.onChange(
      array.map((item, itemIndex) => (itemIndex === index ? color : item)),
    );
  }

  function handleChangeShade(event, shadeIndex) {
    const index = anchorEl ? parseInt(anchorEl.id) : 0;
    const hue = hueAndShade[input.value[index] || grey].hue;
    const color = colors[hue][shades[shadeIndex]];

    input.onChange(
      array.map((item, itemIndex) => (itemIndex === index ? color : item)),
    );
  }

  function handleAdd() {
    input.onChange(array.concat(grey));
  }

  function handleRemove() {
    input.onChange(array.slice(0, -1));
  }

  const open = Boolean(anchorEl);
  const id = open ? 'color-popper' : undefined;

  return (
    <Fragment>
      <FormControl {...props}>
        <FormLabel sx={{ fontSize: 2, marginBottom: -2 }} variant="standard">
          <Typography variant="caption">{label}</Typography>
        </FormLabel>
        <Box sx={{ display: 'flex' }}>
          {array.map((value, index) => (
            <Avatar
              key={index}
              id={index}
              onClick={handleClick}
              sx={(theme) => ({
                mt: 2.5,
                mr: 0.5,
                width: 24,
                height: 24,
                cursor: 'pointer',
                bgcolor: value || grey,
                color: theme.palette.getContrastText(value || grey),
              })}
            >
              <Fragment />
            </Avatar>
          ))}
          {array.length > (min || 1) && (
            <Tooltip title="Remove">
              <IconButton
                sx={{ mt: 2.5, p: 0 }}
                onClick={handleRemove}
                size="large"
              >
                <RemoveIcon />
              </IconButton>
            </Tooltip>
          )}
          {array.length < (max || 10) && (
            <Tooltip title="Add">
              <IconButton
                sx={{ mt: 2.5, p: 0 }}
                onClick={handleAdd}
                size="large"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </FormControl>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper sx={{ width: 144, padding: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Shade">
              <Slider
                value={shades.indexOf(
                  hueAndShade[
                    input.value[anchorEl ? parseInt(anchorEl.id) : 0] || grey
                  ].shade,
                )}
                min={0}
                max={13}
                step={1}
                onChange={handleChangeShade}
              />
            </Tooltip>
          </Box>
          <Box>
            {hues.map((hue) => {
              const index = anchorEl ? parseInt(anchorEl.id) : 0;
              const shade = hueAndShade[input.value[index] || grey].shade;
              const backgroundColor = colors[hue][shade];

              return (
                <Tooltip key={hue} title={startCase(hue)}>
                  <Radio
                    sx={{ p: 0.5 }}
                    checked={(input.value[index] || grey) === backgroundColor}
                    onChange={handleChangeHue}
                    value={hue}
                    icon={
                      <Avatar sx={{ width: 24, height: 24, backgroundColor }}>
                        <Fragment />
                      </Avatar>
                    }
                    checkedIcon={
                      <Avatar sx={{ width: 24, height: 24, backgroundColor }}>
                        <CheckIcon
                          sx={(theme) => ({
                            color:
                              theme.palette.getContrastText(backgroundColor),
                            fontSize: 16,
                          })}
                        />
                      </Avatar>
                    }
                  />
                </Tooltip>
              );
            })}
          </Box>
        </Paper>
      </Popover>
    </Fragment>
  );
}
