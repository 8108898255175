import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertRfidCard() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ _id, people: _, ...json }) => {
      if (_id) {
        return api
          .patch(`rfidCards/${_id}`, {
            headers: { 'content-type': 'application/merge-patch+json' },
            json,
          })
          .json();
      } else {
        return api.post('rfidCards', { json }).json();
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['rfidCards'] }),
  });
}
