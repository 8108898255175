import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertRadio() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ _id, people: _p, lastPoll: _lp, ...json }) => {
      if (_id) {
        return api.patch(`radios/${_id}`, {
          headers: { 'content-type': 'application/merge-patch+json' },
          json,
        });
      } else {
        return api.post('radios', { json });
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['radios'] }),
  });
}
