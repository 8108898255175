import { PointMap } from '@/components/controls';
import { Box } from '@mui/material';

export function StopDetail({ stop }) {
  return (
    <Box sx={{ width: 320, height: 320 }}>
      {stop.point && <PointMap point={stop.point} />}
    </Box>
  );
}
