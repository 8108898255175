import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {Object} Value
 * @property {string} value - The value of the option.
 * @property {string} label - The label of the option.
 */

/**
 * @typedef {Object} Option
 * @property {string} value - The value of the option.
 * @property {string} label - The label of the option.
 * @property {Value[]} [values] - The values of the option.
 */

/**
 * A hook that fetches options.
 * @param {string} name - The name of the options to fetch.
 * @param {Object} [filter={}] - The filter to apply to the options.
 * @returns {import('@tanstack/react-query').UseQueryResult<Option[]>}
 */
export function useOptions(name, filter = {}) {
  return useQuery({
    queryKey: ['options', name, filter],
    queryFn: async () => {
      const json = [
        { $match: { name, ...filter } },
        { $project: { _id: false, value: true, label: true, values: true } },
        { $sort: { label: 1 } },
      ];

      return api.post('pipeline/options', { json }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
  });
}
