import { downloadCSV, getFilenameForDownload } from '@/utils';
import { GetApp as GetAppIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { getMilliseconds } from 'date-fns';

export function DownloadAccelerometerDataLink({ entry }) {
  function handleClick() {
    const filename = getFilenameForDownload('Accelerometer Event Data', 'csv');
    const eventHeaders = [
      { label: 'Longitude', key: 'longitude' },
      { label: 'Latitude', key: 'latitude' },
      { label: 'Heading (degrees)', key: 'headingDegrees' },
      { label: 'Speed (mph)', key: 'speedMilesPerHour' },
      { label: 'Time', key: 'time' },
      { label: 'Horizontal Force (g)', key: 'horizontalForce' },
      { label: 'Vertical Force (g)', key: 'verticalForce' },
      { label: 'Lateral Force (g)', key: 'lateralForce' },
    ];
    const data = (entry.accelerometerData || []).map(
      ({ position: { coordinates }, headingDegrees, time, ...event }) => ({
        ...event,
        time,
        headingDegrees: getMilliseconds(time) === 0 ? headingDegrees : '',
        longitude: getMilliseconds(time) === 0 ? coordinates[0] : '',
        latitude: getMilliseconds(time) === 0 ? coordinates[1] : '',
      }),
    );

    downloadCSV(data, filename, eventHeaders);
  }

  return (
    <Tooltip title="Download accelerometer data">
      <IconButton size="small" onClick={handleClick}>
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
}
