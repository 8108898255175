import { api } from '@/apis';
import { useQuery } from '@tanstack/react-query';

export function useSetting(id, defaultValue) {
  return useQuery({
    queryKey: ['settings', id],
    queryFn: async () => {
      const response = await api.get(`settings/${id}`).json();

      return response.value;
    },
    placeholderData: (previousData) => previousData ?? defaultValue,
    staleTime: 1000 * 60 * 60 * 24,
  });
}
