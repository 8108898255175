import { api } from '@/apis';
import { isEmpty, log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function usePersonHourlyActivities(match, days, offsets = ['all']) {
  const queryKey = ['personHourlyActivities', match, days, offsets];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      const json = [
        {
          $match: {
            ...match,
            ...(days
              ? {
                  $expr: {
                    $in: [{ $dayOfWeek: '$time' }, days.map((day) => day + 1)],
                  },
                }
              : {}),
          },
        },
        {
          $group: {
            _id: null,
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_loggedInSeconds`]: {
                  $avg: { $ifNull: [`$loggedIn.${offset}.durationSeconds`, 0] },
                },
              }),
              {},
            ),
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_baseSeconds`]: {
                  $avg: {
                    $ifNull: [`$visiting.${offset}.baseSeconds`, 0],
                  },
                },
              }),
              {},
            ),
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_respondingSeconds`]: {
                  $avg: {
                    $ifNull: [`$responding.${offset}.durationSeconds`, 0],
                  },
                },
              }),
              {},
            ),
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_attendingSeconds`]: {
                  $avg: {
                    $ifNull: [`$attending.${offset}.durationSeconds`, 0],
                  },
                },
              }),
              {},
            ),
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_drivingSeconds`]: {
                  $avg: { $ifNull: [`$driving.${offset}.durationSeconds`, 0] },
                },
              }),
              {},
            ),
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_homeWardSeconds`]: {
                  $avg: { $ifNull: [`$visiting.${offset}.homeWardSeconds`, 0] },
                },
              }),
              {},
            ),
            ...offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [`${offset}_doubleCrewingSeconds`]: {
                  $avg: {
                    $ifNull: [`$doubleCrewing.${offset}.durationSeconds`, 0],
                  },
                },
              }),
              {},
            ),
          },
        },
        {
          $project: {
            offsets: offsets.reduce(
              (acc, offset) => ({
                ...acc,
                [offset]: {
                  offset: { $toInt: offset },
                  loggedInMinutes: {
                    $round: [
                      { $divide: [`$${offset}_loggedInSeconds`, 60] },
                      2,
                    ],
                  },
                  baseMinutes: {
                    $round: [{ $divide: [`$${offset}_baseSeconds`, 60] }, 2],
                  },
                  respondingMinutes: {
                    $round: [
                      { $divide: [`$${offset}_respondingSeconds`, 60] },
                      2,
                    ],
                  },
                  attendingMinutes: {
                    $round: [
                      { $divide: [`$${offset}_attendingSeconds`, 60] },
                      2,
                    ],
                  },
                  drivingMinutes: {
                    $round: [{ $divide: [`$${offset}_drivingSeconds`, 60] }, 2],
                  },
                  homeWardMinutes: {
                    $round: [
                      { $divide: [`$${offset}_homeWardSeconds`, 60] },
                      2,
                    ],
                  },
                  doubleCrewingMinutes: {
                    $round: [
                      { $divide: [`$${offset}_doubleCrewingSeconds`, 60] },
                      2,
                    ],
                  },
                },
              }),
              {},
            ),
          },
        },
        { $project: { offsets: { $objectToArray: '$offsets' } } },
        { $unwind: '$offsets' },
        { $replaceRoot: { newRoot: '$offsets.v' } },
      ];

      log('Read', 'Person Hourly Activities', {
        match,
        days,
        offsets,
      });

      return api
        .post('pipeline/personDailyActivities', { json, signal })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !isEmpty(match),
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
