import { startCase } from '@/utils';
import { mapPolygonIconsByTypeAndSubtype } from '@/utils/constants';
import { pluralToSingularTypeMap } from './constants';

export function filterFullyDefined(f) {
  return (
    !!f.field &&
    (Array.isArray(f.value)
      ? f.value.length > 0
      : typeof f.value !== 'undefined' && f.value !== null)
  );
}

export function addTagItem(tags, type, id, tag, title) {
  return {
    ...tags,
    [tag]: {
      id: tag,
      title,
      searchString: tag.toLowerCase(),
      itemsByType: {
        ...(tags[tag]?.itemsByType || {}),
        [type]: {
          ...(tags[tag]?.itemsByType?.[type] || {}),
          [id]: true,
        },
      },
    },
  };
}

export function deleteTagItem(tags, type, id, tag) {
  if (tags[tag]?.itemsByType?.[type]?.[id]) {
    delete tags[tag].itemsByType[type][id];

    const remaining = Object.keys(tags[tag].itemsByType).reduce(
      (total, type) => total + Object.keys(tags[tag].itemsByType[type]).length,
      0,
    );
    if (remaining === 0) {
      delete tags[tag];
    }
  }

  return { ...tags };
}

export function createLiveTag(tags, type, id, tag, title) {
  return {
    id: tag,
    title,
    searchString: tag.toLowerCase(),
    itemsByType: {
      ...(tags[tag]?.itemsByType || {}),
      [type]: {
        ...(tags[tag]?.itemsByType?.[type] || {}),
        [id]: true,
      },
    },
  };
}

export function getUserTags(tags) {
  const tagObjs = [];
  if (Array.isArray(tags)) {
    tags.forEach((tag) => {
      if (tag.items) {
        Object.keys(tag?.items).forEach((itemKey) => {
          tag?.items[itemKey]?.forEach((tagItem) => {
            const liveTag = {
              type: itemKey,
              id: tagItem,
              title: tag.title,
              tag: tag.identifier,
            };
            tagObjs.push(liveTag);
          });
        });
      }
    });
  }
  return tagObjs;
}

export const getTypesInLocation = (
  typeItems,
  locationCode,
  type,
  showStale,
) => {
  const relatedItems = {};
  const types = [];

  Object.keys(typeItems).forEach((key) => {
    const exists = typeItems[key]?.currentLocations?.some(
      (obj) => obj.code === locationCode,
    );
    if (exists) {
      types.push(typeItems[key]);
    }
  });

  if (!showStale) {
    relatedItems[type] = types.filter((item) => !item.stale);
  } else {
    relatedItems[type] = types;
  }

  return relatedItems;
};

export function addResourceInLocations(state, type) {
  const showStale =
    !('stale' in state.layerVisibilities) || state.layerVisibilities['stale'];
  Object.keys(state.locations).forEach((key) => {
    const staleResourcesInLocations = [];
    const nonStaleResourcesInLocations = [];
    Object.keys(state[type]).forEach((typeKey) => {
      state[type][typeKey]?.currentLocations?.forEach((l) => {
        if (l.code === key) {
          if (showStale) {
            staleResourcesInLocations.push(typeKey);
          }

          if (!showStale && state[type][typeKey]?.stale === showStale) {
            nonStaleResourcesInLocations.push(typeKey);
          }
        }
      });
    });

    const allResourcesInLocations = [
      ...staleResourcesInLocations,
      ...nonStaleResourcesInLocations,
    ];
    state.locations[key][type] = allResourcesInLocations.length;
  });
}

export function toLabelAccessors(arr, labelKey, valueKey) {
  return (arr || []).map((a) => ({
    label: startCase(a[labelKey]),
    value: a[valueKey],
  }));
}

export function groupsToLabelAccessors(lookup, groups) {
  return Object.entries(groups || {}).map(([type, values]) => ({
    label: type in lookup ? lookup[type].label : startCase(type),
    value: (values || [])
      .map(
        (value) =>
          lookup[type]?.values.find((entry) => entry.value === value)?.label ??
          value,
      )
      .join(', '), // TODO: Might not be needed
  }));
}

export function clipPathForType(type, subtype) {
  type = pluralToSingularTypeMap[type] || type;
  const sides =
    mapPolygonIconsByTypeAndSubtype[type]?.[subtype] ||
    mapPolygonIconsByTypeAndSubtype[type]?.default ||
    mapPolygonIconsByTypeAndSubtype[type];

  if (sides > 2) {
    const angle = (2 * Math.PI) / sides;
    const startAngle = sides % 2 === 0 ? angle / 2 : Math.PI * 1.5 - angle;

    let points = [];

    let shapeScale = sides < 5 ? 1.2 : 1;
    const translate = sides === 3 ? 0.15 : 0;

    const transform = (trig) => {
      return `${100 * ((1 + shapeScale * trig) / 2)}%`;
    };

    for (let i = 0; i < sides; i++) {
      const a = startAngle + angle * i;
      points.push(
        `${transform(Math.cos(a))} ${transform(Math.sin(a) + translate)}`,
      );
    }

    return `polygon(${points.join()})`;
  }
}
