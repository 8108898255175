import { BoundaryEditor, Parameters } from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { downloadCSV, round } from '@/utils';
import { Download as DownloadIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { differenceInSeconds, max, min } from 'date-fns';
import { useAtom } from 'jotai';
import {
  MaterialReactTable,
  MRT_TablePagination,
  useMaterialReactTable,
} from 'material-react-table';
import { useCallback, useMemo, useState } from 'react';
import { Layer, Source } from 'react-map-gl/maplibre';
import { DownloadPollsLink } from './DownloadPollsLink';
import { useVehicleBoundaryVisits } from './useVehicleBoundaryVisits';
import { columnsFn, stateAtom } from './utils';
import { VisitTrips } from './VisitTrips';

export function VehicleBoundaryVisits() {
  useDocumentTitle('IR3 | Vehicle Boundary Visits');
  const theme = useTheme();
  const [{ boundary, query, parameters }, setState] = useAtom(stateAtom);
  const {
    data: features,
    isFetching,
    isLoading,
    cancel,
  } = useVehicleBoundaryVisits({
    boundary,
    query,
  });
  const aggregated = useMemo(
    () =>
      features.reduce(
        (
          acc,
          {
            properties: {
              durationSeconds = 0,
              distanceMiles = 0,
              maxSpeedMilesPerHour = 0,
            } = {},
          },
        ) => ({
          durationSeconds: acc.durationSeconds + durationSeconds,
          distanceMiles: acc.distanceMiles + distanceMiles,
          maxSpeedMilesPerHour: Math.max(
            acc.maxSpeedMilesPerHour,
            maxSpeedMilesPerHour,
          ),
        }),
        {
          durationSeconds: 0,
          distanceMiles: 0,
          maxSpeedMilesPerHour: 0,
        },
      ),
    [features],
  );
  const lineStringFeatureCollection = useMemo(
    () => ({
      type: 'FeatureCollection',
      features: features.filter(
        (feature) => feature.geometry.type === 'LineString',
      ),
    }),
    [features],
  );
  const pointFeatureCollection = useMemo(
    () => ({
      type: 'FeatureCollection',
      features: features.filter((feature) => feature.geometry.type === 'Point'),
    }),
    [features],
  );
  const columns = useMemo(() => columnsFn(aggregated), [aggregated]);
  const [error, setError] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const rowSelection = useMemo(
    () => (selectedId ? { [selectedId]: true } : {}),
    [selectedId],
  );
  const { path, start, end } = useMemo(() => {
    if (!selectedId) {
      return {};
    }

    const path = features.find((feature) => +feature.id === selectedId);
    const start = path?.geometry.coordinates[0] && {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: path.geometry.coordinates[0],
      },
    };
    const end = path?.geometry.coordinates[
      path.geometry.coordinates.length - 1
    ] && {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates:
          path.geometry.coordinates[path.geometry.coordinates.length - 1],
      },
    };

    return { path, start, end };
  }, [features, selectedId]);

  function handleCancel() {
    cancel();
    setState((state) => ({ ...state, query: {} }));
  }

  function handleParametersChange(value) {
    setState((state) => ({ ...state, parameters: value }));
  }

  function handleFetch(event, value) {
    if (boundary) {
      setError(null);
      setState((state) => ({ ...state, query: value }));
    } else {
      setError('Required');
    }
  }

  function handleBoundaryChange(value) {
    setState((state) => ({ ...state, query: {}, boundary: value }));
  }

  const handleMapClick = useCallback((event) => {
    setSelectedId(event.features?.[0]?.id);
  }, []);

  function renderDetailPanel(cell) {
    return (
      cell.row.original.properties.trips?.length > 0 && (
        <VisitTrips trips={cell.row.original.properties.trips} />
      )
    );
  }

  function renderRowActions(cell) {
    return <DownloadPollsLink entry={cell.row.original} />;
  }

  function handleDownloadClick() {
    const headers = [
      {
        key: 'driver.code',
        label: 'Staff ID',
        type: 'text',
      },
      {
        key: 'driver.forenames',
        label: 'Forenames',
        type: 'text',
      },
      {
        key: 'driver.surname',
        label: 'Surname',
        type: 'text',
      },
      {
        key: 'driver.collarNumber',
        label: 'Collar Number',
        type: 'text',
      },
      {
        key: 'driver.role',
        label: 'Role',
        type: 'text',
      },
      {
        key: 'rfidCard.reference',
        label: 'RFID Card #',
        type: 'text',
      },
      {
        key: 'rfidCard.type',
        label: 'RFID Card Type',
        type: 'text',
      },
      {
        key: 'rfidCard.label',
        label: 'RFID Card Label',
        type: 'text',
      },
      {
        key: 'vehicle.registrationNumber',
        label: 'Registration',
        type: 'text',
      },
      {
        key: 'vehicle.fleetNumber',
        label: 'Fleet Number',
        type: 'text',
      },
      {
        key: 'vehicle.role',
        label: 'Vehicle Role',
        type: 'text',
      },
      {
        key: 'vehicle.type',
        label: 'Type',
        type: 'text',
      },
      {
        key: 'imei',
        label: 'IMEI',
        type: 'text',
      },
      {
        key: 'startTime',
        label: 'Start Time',
        type: 'date',
      },
      {
        key: 'endTime',
        label: 'End Time',
        type: 'date',
      },
      {
        key: 'durationDays',
        label: 'Duration',
      },
    ];

    const rows = features
      .map((feature) =>
        (feature.properties.trips.length === 0
          ? [
              {
                startTime: new Date('2000-01-01'),
                endTime: new Date('2100-01-01'),
              },
            ]
          : feature.properties.trips
        ).map((trip) => ({
          imei: feature.properties.imei,
          vehicle: feature.properties.vehicle,
          driver: trip.driver,
          rfidCard: trip.rfidCard,
          startTime: max([trip.startTime, feature.properties.startTime]),
          endTime: min([trip.endTime, feature.properties.endTime]),
          durationDays: round(
            differenceInSeconds(
              min([trip.endTime, feature.properties.endTime]),
              max([trip.startTime, feature.properties.startTime]),
            ) / 86400,
            8,
          ),
        })),
      )
      .flat()
      .sort((a, b) => a.startTime - b.startTime);

    downloadCSV(rows, 'vehicle-boundary-visits-split-by-trip.csv', headers);
  }

  function renderBottomToolbar() {
    return (
      <Stack
        direction="row"
        sx={{ pl: 1, justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box>
          {features?.length > 0 && (
            <Tooltip title="Download visits (split by trip)">
              <IconButton size="small" onClick={handleDownloadClick}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <MRT_TablePagination table={table} />
      </Stack>
    );
  }

  const handleRowClick = (id) => () => {
    setSelectedId((value) => (value === id ? null : id));
  };

  const table = useMaterialReactTable({
    columns,
    data: features,
    initialState: {
      sorting: [{ id: 'properties.startTime', desc: false }],
    },
    state: {
      density: 'compact',
      isLoading: isFetching || isLoading,
      rowSelection,
    },
    defaultColumn: { size: 0 },
    getRowId: (row) => +row.id,
    renderDetailPanel,
    renderRowActions,
    renderBottomToolbar,
    positionActionsColumn: 'last',
    positionToolbarAlertBanner: 'none',
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableTopToolbar: true,
    enableSorting: true,
    enableBottomToolbar: true,
    enablePagination: true,
    enableRowActions: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiTableContainerProps: {
      sx: {
        width: 'calc(100vw - 298px)',
        maxHeight: 'calc(100vh - 178px)', // 48 app header + 56 top bar + 56 bottom bar
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: handleRowClick(row.id),
      sx: { cursor: 'pointer' },
    }),
  });

  return (
    <Stack direction="row" sx={{ height: 'calc(100vh - 48px)' }}>
      <Box
        sx={{
          width: 280,
          height: 'calc(100vh - 48px)',
          borderRight: 1,
          borderColor: 'divider',
          flexShrink: 0,
        }}
      >
        <Parameters
          collection="vehicleBoundaryVisits"
          onFetch={handleFetch}
          onCancel={handleCancel}
          isFetching={isFetching || isLoading}
          value={parameters}
          onChange={handleParametersChange}
          sx={{ pt: 1, height: 1 }}
          vehicle
        />
      </Box>
      <Stack spacing={1} sx={{ p: 1, overflow: 'auto' }}>
        <BoundaryEditor
          value={boundary}
          onChange={handleBoundaryChange}
          interactiveLayerIds={['line-visit-layer', 'point-visit-layer']}
          onClick={handleMapClick}
          error={error}
          sx={{ minWidth: 240, height: 640, flexShrink: 0 }}
        >
          <Source
            id="line-visits"
            type="geojson"
            data={lineStringFeatureCollection}
          >
            <Layer
              id="line-visit-layer"
              type="line"
              paint={{
                'line-color': theme.palette.text.primary,
                'line-width': 2,
              }}
              layout={{
                'line-cap': 'round',
                'line-join': 'round',
              }}
            />
          </Source>
          <Source
            id="point-visits"
            type="geojson"
            data={pointFeatureCollection}
          >
            <Layer
              id="point-visit-layer"
              type="circle"
              paint={{
                'circle-color': theme.palette.text.primary,
                'circle-radius': 2,
              }}
            />
          </Source>
          {path && (
            <Source id="selected-path" type="geojson" data={path}>
              <Layer
                id="selected-path-layer"
                type="line"
                paint={{
                  'line-color': theme.palette.primary.main,
                  'line-width': 2,
                }}
                layout={{
                  'line-cap': 'round',
                  'line-join': 'round',
                }}
              />
            </Source>
          )}
          {start && (
            <Source id="start" type="geojson" data={start}>
              <Layer
                id="start-layer"
                type="circle"
                paint={{
                  'circle-color': theme.palette.success.main,
                  'circle-radius': 4,
                }}
              />
            </Source>
          )}
          {end && (
            <Source id="end" type="geojson" data={end}>
              <Layer
                type="circle"
                paint={{
                  'circle-color': theme.palette.error.main,
                  'circle-radius': 4,
                }}
              />
            </Source>
          )}
        </BoundaryEditor>
        <Box>
          <MaterialReactTable table={table} />
        </Box>
      </Stack>
    </Stack>
  );
}
