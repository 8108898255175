import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpsertTelematicsBox() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ _id, vehicles: _v, lastPoll: _lp, ...json }) => {
      if (_id) {
        return api.patch(`telematicsBoxes/${_id}`, {
          headers: { 'content-type': 'application/merge-patch+json' },
          json,
        });
      } else {
        return api.post('telematicsBoxes', { json });
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['telematicsBoxes'] }),
  });
}
