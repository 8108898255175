import { getUser } from '@/utils';
import { wsRootUrl } from '@/utils/config';
import { enqueueSnackbar } from 'notistack';
import useWebSocket from 'react-use-websocket';

export function useTelematicsBoxPollStream(imei) {
  return useWebSocket(wsRootUrl, {
    shouldReconnect: () => true,
    onOpen: (event) => {
      const user = getUser();

      event.target.send(
        JSON.stringify({
          action: 'SUBSCRIBE',
          authorization: `Bearer ${user?.access_token}`,
          payload: {
            telematicsBoxes: {
              query: { imei },
              projection: {
                mostRecentPoll: {
                  identifier: true,
                  time: true,
                  position: true,
                  ignitionOn: true,
                  headingDegrees: true,
                  speedKilometresPerHour: true,
                  distanceKilometres: true,
                  bufferCount: true,
                  deviceProperties: {
                    isValidated: true,
                    enqueuedTimestamp: true,
                    deviceSignalStrength: true,
                    batteryVoltage: true,
                    canBusStatus: true,
                    resetReason: true,
                    driverId: true,
                    satelliteCount: true,
                    softwareId: true,
                  },
                },
              },
            },
          },
        }),
      );
    },
    onError: () => {
      enqueueSnackbar('Telematics box poll socket error', {
        variant: 'error',
      });
    },
  });
}
