import { decodeSignalStrength } from '@/utils';
import {
  SignalCellular0Bar as SignalCellular0BarIcon,
  SignalCellular1Bar as SignalCellular1BarIcon,
  SignalCellular2Bar as SignalCellular2BarIcon,
  SignalCellular3Bar as SignalCellular3BarIcon,
  SignalCellular4Bar as SignalCellular4BarIcon,
  SignalCellularNull as SignalCellularNullIcon,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';

export function SignalStrengthIcon({ signalStrength, isDecoded = false }) {
  if (!isDecoded) {
    signalStrength = decodeSignalStrength(signalStrength);
  }

  switch (signalStrength) {
    case -1:
      return (
        <Tooltip title="No signal">
          <SignalCellularNullIcon color="error" />
        </Tooltip>
      );
    case 0:
      return (
        <Tooltip title="0 bars">
          <SignalCellular0BarIcon color="error" />
        </Tooltip>
      );
    case 1:
      return (
        <Tooltip title="1 bar">
          <SignalCellular1BarIcon color="error" />
        </Tooltip>
      );
    case 2:
      return (
        <Tooltip title="2 bars">
          <SignalCellular2BarIcon color="warning" />
        </Tooltip>
      );
    case 3:
      return (
        <Tooltip title="3 bars">
          <SignalCellular3BarIcon />
        </Tooltip>
      );

    default:
      return (
        <Tooltip title="Full bars">
          <SignalCellular4BarIcon />
        </Tooltip>
      );
  }
}
