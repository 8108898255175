import {
  ExportPicker,
  RetrospectiveTypeIcon,
  TagField,
} from '@/components/controls';
import { useDocumentTitle } from '@/hooks';
import { getVehiclePolls, round, startCase, vehiclePollHeaders } from '@/utils';
import {
  Flag as FlagIcon,
  OutlinedFlag as OutlinedFlagIcon,
  Person as PersonIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import humanizeDuration from 'humanize-duration';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

export function VehicleTrip({
  color,
  background,
  identifier,
  driver,
  vehicle,
  startTime,
  endTime,
  maxSpeedKilometresPerHour,
  distanceKilometres,
}) {
  const navigate = useNavigate();

  function handleViewClick() {
    navigate(`/eventreplay/trips/${identifier}`);
  }

  async function fetchDataToConvert() {
    const polls = await getVehiclePolls(
      vehicle.telematicsBoxImei,
      startTime,
      endTime,
    );

    const data = polls.map((poll) => ({
      ...poll,
      name: driver.forenames + ' ' + driver.surname,
      personRole: driver.role,
      collarNumber: driver.collarNumber,
      identificationNumber: vehicle.identificationNumber,
      registrationNumber: vehicle.registrationNumber,
      fleetNumber: vehicle.fleetNumber,
      vehicleRole: vehicle.role,
      time: new Date(poll.time),
      speedLimitMilesPerHour: poll.speedLimitMilesPerHour,
    }));

    return data;
  }

  const cardHeaderButtons = (
    <Box>
      <Tooltip title="View Replay">
        <IconButton onClick={handleViewClick} size="large">
          <PlayArrowIcon />
        </IconButton>
      </Tooltip>
      <ExportPicker
        fetchDataToConvert={fetchDataToConvert}
        filename="Vehicle Trip"
        headers={vehiclePollHeaders}
      />
    </Box>
  );

  function VehicleFields() {
    const vehicleFieldsToShow = [
      { label: 'Make', name: 'make' },
      { label: 'Model', name: 'model' },
      { label: 'Role', name: 'role' },
      { label: 'Home Station', name: 'homeStation' },
      { label: 'Groups', name: 'groups' },
    ];

    return (
      <Table size="small" sx={{ mt: 1, mb: 2 }}>
        <TableBody>
          {vehicleFieldsToShow.map((field, index) => {
            if (field.name === 'groups') {
              return Object.entries(vehicle.groups ?? {}).map(
                ([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Typography variant="caption" color="textSecondary">
                        {startCase(key)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {(value || []).join(', ')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ),
              );
            }
            return (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    {field.label}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {vehicle[field.name]}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  const { title, subheader } = vehicle?.unassociated
    ? {
        title: 'Unassociated IMEI',
        subheader: vehicle?.telematicsBoxImei,
      }
    : {
        title: vehicle?.registrationNumber,
        subheader: vehicle?.fleetNumber,
      };
  useDocumentTitle(['IR3', 'Trip', title].filter(Boolean).join(' | '));

  return (
    <Card sx={{ m: 1, mr: 0 }}>
      <CardHeader
        avatar={
          <Avatar
            sx={{
              background,
              color,
            }}
            title="Vehicle Trip"
          >
            <RetrospectiveTypeIcon type="vehicleTrips" />
          </Avatar>
        }
        title={title}
        subheader={subheader}
        action={cardHeaderButtons}
      />
      <CardContent>
        <TagField
          collection="trips"
          id={identifier}
          linkPrefix="/live/tags"
          sx={{ pb: 2 }}
          label="Tags"
        />
        <Typography variant="subtitle2" color="textSecondary">
          Vehicle
        </Typography>
        <VehicleFields />
        <Fragment>
          <Typography variant="subtitle2" color="textSecondary">
            Driver
          </Typography>
          {driver?.code ? (
            <ListItem component="div" sx={{ my: 1 }}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${driver.forenames || ''}  ${driver.surname || ''}`}
                secondary={driver.collarNumber}
              />
            </ListItem>
          ) : (
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color="textSecondary">
                      {driver?.identificationReference
                        ? 'Unrecognised Key'
                        : 'Unknown Driver'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {driver?.identificationReference && (
                      <Typography variant="caption">
                        {driver.identificationReference}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Fragment>
        <Typography variant="subtitle2" color="textSecondary">
          Timeline
        </Typography>
        <Stepper orientation="vertical">
          <Step active>
            <StepLabel
              icon={
                <Avatar sx={{ width: 30, height: 30 }}>
                  <FlagIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {format(new Date(startTime), 'dd/MM/yyyy HH:mm:ss')}
                </Typography>
              }
            >
              Start
            </StepLabel>
            <StepContent />
          </Step>
          <Step active>
            <StepLabel
              icon={
                <Avatar sx={{ width: 30, height: 30 }}>
                  <OutlinedFlagIcon fontSize="inherit" />
                </Avatar>
              }
              optional={
                <Typography variant="caption">
                  {format(new Date(endTime), 'dd/MM/yyyy HH:mm:ss')}
                </Typography>
              }
            >
              End
            </StepLabel>
            <StepContent />
          </Step>
        </Stepper>
        <Typography variant="subtitle2" color="textSecondary">
          Metrics
        </Typography>
        <Table size="small" sx={{ mt: 1, mb: 2 }}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Distance
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {`${round(distanceKilometres * 0.62137119, 2)} miles`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Maximum Speed
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {`${round(maxSpeedKilometresPerHour * 0.62137119, 2)} MPH`}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color="textSecondary">
                  Duration
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {humanizeDuration(new Date(endTime) - new Date(startTime))}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
