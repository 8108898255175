import { useLocationTypes } from '@/hooks';
import {
  Business as BusinessIcon,
  GroupWork as CallSignIcon,
  DataUsage,
  DirectionsCar as DirectionsCarIcon,
  Feedback as FeedbackIcon,
  LocationSearching as FollowIcon,
  TwoWheeler as MotorcycleIcon,
  Person as PersonIcon,
  Router as RouterIcon,
  More as TagIcon,
} from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import {
  AxisArrow as AxisArrowIcon,
  RadioHandheld as RadioHandheldIcon,
} from 'mdi-material-ui';

export function LiveIcon({ type, subtype }) {
  const { data: locationTypes } = useLocationTypes();

  const liveIconsByTypeAndSubtype = {
    incident: { default: <FeedbackIcon /> },
    vehicle: { default: <DirectionsCarIcon />, MOTORCYCLE: <MotorcycleIcon /> },
    person: { default: <PersonIcon /> },
    event: { default: <AxisArrowIcon /> },
    location: Object.values(locationTypes).reduce(
      (acc, { value, d }) => ({
        ...acc,
        [value]: (
          <SvgIcon>
            <path d={d} />
          </SvgIcon>
        ),
      }),
      { default: <BusinessIcon /> },
    ),
    objective: { default: <DataUsage /> },
    tag: {
      default: <TagIcon />,
      follow: <FollowIcon />,
    },
    callSign: { default: <CallSignIcon /> },
    radio: { default: <RadioHandheldIcon /> },
    telematicsBox: { default: <RouterIcon /> },
    default: { default: '?' },
  };

  type = type in liveIconsByTypeAndSubtype ? type : 'default';
  subtype = subtype in liveIconsByTypeAndSubtype[type] ? subtype : 'default';

  return liveIconsByTypeAndSubtype[type][subtype];
}
