import { groupsStateAtom } from '@/atoms';
import { SearchBox, SortField } from '@/components/controls';
import { useDocumentTitle, useGroups, useOptionLookup } from '@/hooks';
import { downloadCSV, downloadJSON } from '@/utils';
import {
  Add as AddIcon,
  ContentCopy as ContentCopyIcon,
  Groups as GroupsIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useAtom } from 'jotai';
import { useMemo, useRef } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';

const sortOptions = [
  { label: 'Name', value: 'name' },
  { label: 'Type', value: 'type' },
];

const parentCountOptions = ['0', '1', '2+'];

export function Groups() {
  useDocumentTitle('IR3 | Groups & Areas');
  const { code } = useParams();
  const [
    { searchText, sortBy, sortDesc, filter, showCopyButtons, showSettings },
    setState,
  ] = useAtom(groupsStateAtom);
  const { data: groups } = useGroups();
  const typeOptions = useOptionLookup('groupType');
  const aliasTypeOptions = useOptionLookup('aliasType');
  const parentRef = useRef();
  const types = useMemo(
    () =>
      [...new Set(groups.map((group) => group.type))]
        .map((type) => ({
          label: typeOptions[type] ?? '',
          value: type,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [groups, typeOptions],
  );
  const ancestors = useMemo(
    () =>
      groups
        .map((group) => ({
          label: group.name,
          value: group.code,
          type: typeOptions[group.type] ?? '',
        }))
        .sort(
          (a, b) =>
            a.type.localeCompare(b.type) || a.label.localeCompare(b.label),
        ),
    [groups, typeOptions],
  );
  const filteredGroups = useMemo(() => {
    function filterGroup(group) {
      if (
        filter.ancestor &&
        !group.ancestorCodes.includes(filter.ancestor.value)
      ) {
        return false;
      }

      if (filter.type && group.type !== filter.type) {
        return false;
      }

      if (filter.parentCount) {
        if (
          filter.parentCount === '0' &&
          (group.parentCodes ?? []).length > 0
        ) {
          return false;
        }

        if (
          filter.parentCount === '1' &&
          (group.parentCodes ?? []).length !== 1
        ) {
          return false;
        }

        if (
          filter.parentCount === '2+' &&
          (group.parentCodes ?? []).length < 2
        ) {
          return false;
        }
      }

      if (
        filter.missingAliasType &&
        (group.aliasTypes ?? []).includes(filter.missingAliasType)
      ) {
        return false;
      }

      if (
        searchText &&
        !group.name.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return false;
      }

      return true;
    }

    return groups
      .filter(filterGroup)
      .sort((a, b) =>
        sortDesc
          ? b[sortBy].localeCompare(a[sortBy])
          : a[sortBy].localeCompare(b[sortBy]),
      );
  }, [filter, groups, searchText, sortBy, sortDesc]);

  const rowVirtualizer = useVirtualizer({
    count: filteredGroups.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 56,
    overscan: 10,
  });

  function handleSearchChange(event) {
    setState((state) => ({
      ...state,
      searchText: event.target.value,
    }));
  }

  function handleSettingsToggle() {
    setState((state) => ({
      ...state,
      showSettings: !state.showSettings,
    }));
  }

  function handleSortByChange(event) {
    setState((state) => ({
      ...state,
      sortBy: event.target.value,
    }));
  }

  function handleSortToggle() {
    setState((state) => ({
      ...state,
      sortDesc: !state.sortDesc,
    }));
  }

  const handleFilterChange = (field) => (event) => {
    setState((state) => ({
      ...state,
      filter: {
        ...state.filter,
        [field]: event.target.value,
      },
    }));
  };

  function handleAncestorFilterChange(event, option) {
    setState((state) => ({
      ...state,
      filter: {
        ...state.filter,
        ancestor: option,
      },
    }));
  }

  function handleParentCountFilterChange(event) {
    setState((state) => ({
      ...state,
      filter: {
        ...state.filter,
        parentCount: event.target.value,
      },
    }));
  }

  function handleCsvClick() {
    const data = filteredGroups.map((group) => ({
      ...group,
      type: types.find((type) => group.type === type.value).label,
      ancestors: group.ancestorCodes
        .map((code) => groups.find((group) => group.code === code))
        .reduce(
          (accumulator, group) => ({
            ...accumulator,
            [group.type]: [...(accumulator[group.type] ?? []), group.name],
          }),
          {},
        ),
    }));

    downloadCSV(data, 'groups.csv', [
      { key: 'name', label: 'Name' },
      { key: 'type', label: 'Type' },
      ...(types.map(({ label, value }) => ({
        key: `ancestors.${value}`,
        label: `${label}s`,
      })) || []),
    ]);
  }

  function handleJsonClick() {
    downloadJSON(filteredGroups, 'groups.json');
  }

  function handleCopyToggle() {
    setState((state) => ({
      ...state,
      showCopyButtons: !state.showCopyButtons,
    }));
  }

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 48px)' }}>
      <Stack
        sx={{
          width: 280,
          borderRight: 1,
          borderColor: 'divider',
          flexShrink: 0,
          height: 1,
        }}
      >
        <Box>
          <Stack
            direction="row"
            spacing={1}
            sx={{ p: 1, alignItems: 'center' }}
          >
            <SearchBox
              value={searchText}
              onChange={handleSearchChange}
              count={`${filteredGroups.length}/${groups.length}`}
              sx={{ flexGrow: 1 }}
            />
            <Box>
              <Tooltip title={showSettings ? 'Hide settings' : 'Show settings'}>
                <IconButton onClick={handleSettingsToggle} size="small">
                  <SettingsIcon
                    fontSize="inherit"
                    color={showSettings ? 'primary' : 'inherit'}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title="Add new">
                <IconButton component={Link} to="new" size="small">
                  <Avatar
                    sx={{
                      color: 'secondary.contrastText',
                      bgcolor: 'secondary.main',
                      width: 24,
                      height: 24,
                      fontSize: 16,
                    }}
                  >
                    <AddIcon fontSize="inherit" />
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
          <Collapse in={showSettings} unmountOnExit>
            <Stack sx={{ p: 1 }} spacing={1}>
              <SortField
                sortBy={sortBy}
                onSortByChange={handleSortByChange}
                sortDesc={sortDesc}
                onSortDescToggle={handleSortToggle}
                options={sortOptions}
              />
            </Stack>
            <Stack sx={{ px: 1 }} spacing={1}>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Filters
                </Typography>
              </Divider>
              <TextField
                size="small"
                fullWidth
                select
                label="Type"
                value={filter.type}
                onChange={handleFilterChange('type')}
              >
                <MenuItem value="">
                  <em>Any</em>
                </MenuItem>
                {types.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
              <Autocomplete
                value={filter.ancestor}
                onChange={handleAncestorFilterChange}
                options={ancestors}
                groupBy={(option) => option.type}
                getOptionLabel={(option) => option?.label ?? option}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                sx={{ minWidth: 200 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={`Ancestor ${
                      filter.ancestor?.type ? filter.ancestor.type : ''
                    }`}
                  />
                )}
              />
              <TextField
                size="small"
                fullWidth
                select
                label="Parent Count"
                value={filter.parentCount}
                onChange={handleParentCountFilterChange}
              >
                <MenuItem value="">
                  <em>Any</em>
                </MenuItem>
                {parentCountOptions.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                size="small"
                fullWidth
                select
                label="Missing Alias Type"
                value={filter.missingAliasType}
                onChange={handleFilterChange('missingAliasType')}
              >
                <MenuItem value="">
                  <em>Unfiltered</em>
                </MenuItem>
                {Object.entries(aliasTypeOptions).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Export
                </Typography>
              </Divider>
              <Stack
                direction="row"
                // sx={{ p: 1 }}
                spacing={1}
                sx={{ justifyContent: 'center' }}
              >
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={handleCsvClick}
                >
                  CSV
                </Button>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  disableElevation
                  onClick={handleJsonClick}
                >
                  JSON
                </Button>
              </Stack>
              <FormControlLabel
                label="Show copy buttons"
                control={
                  <Switch
                    checked={showCopyButtons}
                    onChange={handleCopyToggle}
                  />
                }
              />
            </Stack>
          </Collapse>
        </Box>
        <Divider />
        <Box ref={parentRef} sx={{ overflow: 'auto', height: 1 }}>
          <Box
            sx={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {rowVirtualizer.getVirtualItems().map(({ index, size, start }) => {
              const group = filteredGroups[index];

              return (
                <ListItem
                  key={index}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${size}px`,
                    transform: `translateY(${start}px)`,
                  }}
                  secondaryAction={
                    showCopyButtons && (
                      <Tooltip title="Copy">
                        <IconButton
                          component={Link}
                          to={`${filteredGroups[index].code}?copy=true`}
                          state={{ copy: true }}
                          size="small"
                          edge="end"
                        >
                          <ContentCopyIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                  disablePadding
                >
                  <ListItemButton
                    dense
                    component={Link}
                    to={filteredGroups[index].code}
                    selected={filteredGroups[index].code === code}
                  >
                    <ListItemAvatar>
                      <Badge
                        badgeContent={group.parentCodes?.length}
                        color="primary"
                      >
                        <Avatar>
                          <GroupsIcon />
                        </Avatar>
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText
                      primary={group.name}
                      primaryTypographyProps={{ noWrap: true }}
                      secondary={typeOptions[group.type]}
                      secondaryTypographyProps={{ noWrap: true }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </Box>
        </Box>
      </Stack>
      <Box sx={{ overflow: 'auto', flex: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
}
