import { ExportPicker } from '@/components/controls';
import { getPersonPolls, radioPollHeaders, shortPersonHeaders } from '@/utils';

const personPollsHeaders = [...shortPersonHeaders, ...radioPollHeaders];

export function DownloadPollsLink({ entry }) {
  async function fetchDataToConvert() {
    const polls = await getPersonPolls(
      entry.person.radioSsi,
      entry.startTime,
      entry.endTime,
    );

    const data = polls.map((poll) => ({
      ...poll,
      name: entry.person.forenames + ' ' + entry.person.surname,
      collarNumber: entry.person.collarNumber,
      personRole: entry.person.role,
      radioSsi: entry.person.radioSsi,
    }));

    return data;
  }

  return (
    <ExportPicker
      fetchDataToConvert={fetchDataToConvert}
      filename="Attendance Polls"
      headers={personPollsHeaders}
    />
  );
}
