import { featureSortFn, filterType, getAllValues, log } from '@/utils';
import { startOfHour } from 'date-fns';
import { useMemo } from 'react';
import { useFilterFields } from './useFilterFields';
import { useLocationSnapshot } from './useLocationSnapshot';
import { useResourceSnapshot } from './useResourceSnapshot';

export function useFilteredSnapshot(time, search, sort, filter) {
  const { data: locationSnapshot, isFetching: fetchingLocations } =
    useLocationSnapshot(startOfHour(time));
  const { data: resourceSnapshot, isFetching: fetchingResources } =
    useResourceSnapshot(time);
  const filterFields = useFilterFields();
  const featureCollections = useMemo(
    () => ({
      ...locationSnapshot,
      ...resourceSnapshot,
    }),
    [locationSnapshot, resourceSnapshot],
  );

  const data = useMemo(() => {
    log('View', 'Snapshot', { time, search, sort, filter });

    return Object.entries(featureCollections).reduce(
      (accumulator, [type, featureCollection]) => ({
        ...accumulator,
        [type]: {
          ...featureCollection,
          features: featureCollection.features
            .filter(({ properties }) =>
              filterType(type, filter[type], properties),
            )
            .filter(({ properties }) =>
              JSON.stringify(properties)
                .toLowerCase()
                .includes(search[type].toLowerCase()),
            )
            .sort(featureSortFn(sort[type])),
          total: featureCollection.features.length,
        },
      }),
      {},
    );
  }, [time, search, sort, filter, featureCollections]);

  const filterValues = useMemo(
    () =>
      Object.entries(filterFields).reduce(
        (acc, [type, fields]) => ({
          ...acc,
          [type]: fields.reduce(
            (acc, field) => ({
              ...acc,
              [field]: getAllValues(featureCollections[type].features, field),
            }),
            {},
          ),
        }),
        {},
      ),
    [featureCollections, filterFields],
  );

  return {
    data,
    filterValues,
    isFetching: fetchingLocations || fetchingResources,
  };
}
