import { getTextWidth } from '@/utils';
import {
  Autocomplete,
  Popper,
  TextField,
  Typography,
  autocompleteClasses,
  styled,
} from '@mui/material';
import { createContext, forwardRef, useContext } from 'react';
import { FixedSizeList } from 'react-window';

const LISTBOX_PADDING = 8; // px

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const getComputedWidthPopper = (options, font, padding) =>
  function ComputedWidthPopper({ style: { width, ...style }, key, ...props }) {
    const computedWidth =
      Math.ceil(
        Math.max(
          ...(options || []).map((option) =>
            getTextWidth(
              option.label ?? options.find((o) => o.value === option).label,
              font,
            ),
          ),
        ),
      ) + padding;

    return (
      <StyledPopper
        key={key}
        {...props}
        style={{ width: Math.max(computedWidth, width), ...style }}
        placement="bottom-start"
      />
    );
  };

function renderRow({ data, index, style }) {
  const [{ key, ...props }, option] = data[index];

  return (
    <Typography
      key={key}
      component="li"
      {...props}
      noWrap
      style={{
        ...style,
        top: style.top + LISTBOX_PADDING,
      }}
    >
      {option.label}
    </Typography>
  );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef(function OuterElementType(props, ref) {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const VirtualisedListbox = forwardRef(function VirtualisedListbox(
  { children, ...other },
  ref,
) {
  const itemCount = children.length;
  const itemSize = 36;
  const height = Math.min(8, itemCount) * itemSize;

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={children}
          height={height + 2 * LISTBOX_PADDING}
          width="100%"
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export function FilterField({ value, onChange, options, ...props }) {
  function handleSelectionChange(event, selection) {
    onChange(selection.map((option) => option.value ?? option));
  }

  return (
    <Autocomplete
      value={value ?? []}
      onChange={handleSelectionChange}
      multiple
      size="small"
      options={options}
      getOptionLabel={(option) =>
        option.label ?? options.find((o) => o.value === option).label
      }
      isOptionEqualToValue={(option, value) => option.value === value}
      renderInput={(params) => <TextField {...params} {...props} />}
      PopperComponent={getComputedWidthPopper(options, '16px Roboto', 44)}
      disableListWrap
      ListboxComponent={VirtualisedListbox}
      renderOption={(props, option) => [props, option]}
    />
  );
}
