import { useOptions } from '@/hooks';
import { Stack } from '@mui/material';
import { FilterField } from './FilterField';

export function LocationFilter({ value = {}, onChange, filterValues = {} }) {
  const { data: locationGroups } = useOptions('locationGroup');

  const handleFilterChange = (field) => (event, update) => {
    onChange({
      ...value,
      [field]: update,
    });
  };

  const handleGroupFilterChange = (field) => (event, update) => {
    onChange({
      ...value,
      groups: {
        ...value.groups,
        [field]: update,
      },
    });
  };

  return (
    <Stack spacing={1} sx={{ maxHeight: 238, overflow: 'scroll', pt: 0.75 }}>
      <FilterField
        label="Type"
        value={value.type}
        onChange={handleFilterChange('type')}
        options={filterValues['properties.type'] ?? []}
      />
      <FilterField
        label="Subtype"
        value={value.subtype}
        onChange={handleFilterChange('subtype')}
        options={filterValues['properties.subtype'] ?? []}
      />
      {locationGroups.map(({ value: type, label }) => (
        <FilterField
          key={type}
          label={label}
          value={value.groups?.[type]}
          onChange={handleGroupFilterChange(type)}
          options={filterValues[`properties.groups.${type}`] ?? []}
        />
      ))}
    </Stack>
  );
}
