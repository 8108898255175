import { Box } from '@mui/material';
import { TelematicsBoxChart } from './TelematicsBoxChart';
import { TelematicsBoxMap } from './TelematicsBoxMap';

export function DrillDown({ entry }) {
  return (
    <Box sx={{ display: 'flex' }}>
      {entry.lastPosition && (
        <Box sx={{ width: 320, height: 320 }}>
          <TelematicsBoxMap point={entry.lastPosition} />
        </Box>
      )}
      <Box sx={{ flex: 1, height: 320, minWidth: 320 }}>
        <TelematicsBoxChart
          imei={entry.imei}
          lastContact={entry.mostRecentTime}
        />
      </Box>
    </Box>
  );
}
