import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { forwardRef, useImperativeHandle } from 'react';
import { useControl } from 'react-map-gl/maplibre';

export const DrawControl = forwardRef(function DrawControl(props, ref) {
  const drawRef = useControl(
    () => new MapboxDraw(props),
    ({ map }) => {
      if (props.onCreate) {
        map.on('draw.create', props.onCreate);
      }
      if (props.onUpdate) {
        map.on('draw.update', props.onUpdate);
      }
      if (props.onDelete) {
        map.on('draw.delete', props.onDelete);
      }
      if (props.onModeChange) {
        map.on('draw.modechange', props.onModeChange);
      }
    },
    ({ map }) => {
      if (props.onCreate) {
        map.off('draw.create', props.onCreate);
      }
      if (props.onUpdate) {
        map.off('draw.update', props.onUpdate);
      }
      if (props.onDelete) {
        map.off('draw.delete', props.onDelete);
      }
      if (props.onModeChange) {
        map.off('draw.modechange', props.onModeChange);
      }
    },
    { position: props.position },
  );

  useImperativeHandle(ref, () => drawRef, [drawRef]);

  return null;
});
