import { api } from '@/apis';
import { isEmpty, log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useVehicleDailyActivities(
  match,
  days,
  offsets = ['all'],
  groupBy,
) {
  const queryKey = ['vehicleDailyActivities', match, days, offsets, groupBy];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      const json = [
        {
          $match: {
            ...match,
            ...(days
              ? {
                  $expr: {
                    $in: [{ $dayOfWeek: '$time' }, days.map((day) => day + 1)],
                  },
                }
              : {}),
          },
        },
        {
          $project: {
            time: true,
            vehicle: true,
            distanceKilometres: {
              $sum: offsets.map((offset) => ({
                $ifNull: [`$moving.${offset}.distanceKilometres`, 0],
              })),
            },
            tripStarts: {
              $sum: offsets.map((offset) => ({
                $ifNull: [`$moving.${offset}.eventStarts`, 0],
              })),
            },
            movingSeconds: {
              $subtract: [
                {
                  $sum: offsets.map((offset) => ({
                    $ifNull: [`$moving.${offset}.durationSeconds`, 0],
                  })),
                },
                {
                  $sum: offsets
                    .map((offset) => ({
                      $ifNull: [`$idle.${offset}.homeBaseSeconds`, 0],
                    }))
                    .concat(
                      offsets.map((offset) => ({
                        $ifNull: [`$idle.${offset}.otherBaseSeconds`, 0],
                      })),
                    )
                    .concat(
                      offsets.map((offset) => ({
                        $ifNull: [`$idle.${offset}.workshopSeconds`, 0],
                      })),
                    )
                    .concat(
                      offsets.map((offset) => ({
                        $ifNull: [`$idle.${offset}.elsewhereSeconds`, 0],
                      })),
                    ),
                },
              ],
            },
            idleHomeBaseSeconds: {
              $sum: offsets.map((offset) => ({
                $ifNull: [`$idle.${offset}.homeBaseSeconds`, 0],
              })),
            },
            idleOtherBaseSeconds: {
              $sum: offsets.map((offset) => ({
                $ifNull: [`$idle.${offset}.otherBaseSeconds`, 0],
              })),
            },
            idleWorkshopSeconds: {
              $sum: offsets.map((offset) => ({
                $ifNull: [`$idle.${offset}.workshopSeconds`, 0],
              })),
            },
            idleElsewhereSeconds: {
              $sum: offsets.map((offset) => ({
                $ifNull: [`$idle.${offset}.elsewhereSeconds`, 0],
              })),
            },
            stoppedHomeBaseSeconds: {
              $sum: offsets.map((offset) => ({
                $ifNull: [`$stopped.${offset}.homeBaseSeconds`, 0],
              })),
            },
            stoppedOtherBaseSeconds: {
              $sum: offsets.map((offset) => ({
                $ifNull: [`$stopped.${offset}.otherBaseSeconds`, 0],
              })),
            },
            stoppedWorkshopSeconds: {
              $sum: offsets.map((offset) => ({
                $ifNull: [`$stopped.${offset}.workshopSeconds`, 0],
              })),
            },
            stoppedElsewhereSeconds: {
              $sum: offsets.map((offset) => ({
                $ifNull: [`$stopped.${offset}.elsewhereSeconds`, 0],
              })),
            },
            unaccountableSeconds: {
              $sum: offsets.map((offset) => ({
                $ifNull: [`$unaccountable.${offset}.durationSeconds`, 0],
              })),
            },
          },
        },
        groupBy?.startsWith?.('$vehicle.groups.') && { $unwind: groupBy },
        {
          $group: {
            _id: groupBy,
            identificationNumbers: {
              $addToSet: '$vehicle.identificationNumber',
            },
            totalKilometres: { $sum: '$distanceKilometres' },
            dailyKilometres: { $avg: '$distanceKilometres' },
            totalTrips: { $sum: '$tripStarts' },
            dailyTrips: { $avg: '$tripStarts' },
            movingSeconds: { $avg: '$movingSeconds' },
            idleHomeBaseSeconds: { $avg: '$idleHomeBaseSeconds' },
            idleOtherBaseSeconds: { $avg: '$idleOtherBaseSeconds' },
            idleWorkshopSeconds: { $avg: '$idleWorkshopSeconds' },
            idleElsewhereSeconds: { $avg: '$idleElsewhereSeconds' },
            stoppedHomeBaseSeconds: { $avg: '$stoppedHomeBaseSeconds' },
            stoppedOtherBaseSeconds: { $avg: '$stoppedOtherBaseSeconds' },
            stoppedWorkshopSeconds: { $avg: '$stoppedWorkshopSeconds' },
            stoppedElsewhereSeconds: { $avg: '$stoppedElsewhereSeconds' },
            unaccountableSeconds: { $avg: '$unaccountableSeconds' },
          },
        },
        {
          $project: {
            _id: false,
            group: '$_id',
            resourceCount: { $size: '$identificationNumbers' },
            totalMiles: {
              $round: [{ $multiply: ['$totalKilometres', 0.62137119] }, 2],
            },
            dailyMiles: {
              $round: [{ $multiply: ['$dailyKilometres', 0.62137119] }, 2],
            },
            averageMiles: {
              $round: [
                {
                  $divide: [
                    { $multiply: ['$totalKilometres', 0.62137119] },
                    { $size: '$identificationNumbers' },
                  ],
                },
                2,
              ],
            },
            totalTrips: { $round: ['$totalTrips', 2] },
            dailyTrips: { $round: ['$dailyTrips', 2] },
            averageTrips: {
              $round: [
                {
                  $divide: ['$totalTrips', { $size: '$identificationNumbers' }],
                },
                2,
              ],
            },
            movingHours: {
              $round: [{ $divide: ['$movingSeconds', 3600] }, 2],
            },
            idleHomeBaseHours: {
              $round: [{ $divide: ['$idleHomeBaseSeconds', 3600] }, 2],
            },
            idleOtherBaseHours: {
              $round: [{ $divide: ['$idleOtherBaseSeconds', 3600] }, 2],
            },
            idleWorkshopHours: {
              $round: [{ $divide: ['$idleWorkshopSeconds', 3600] }, 2],
            },
            idleElsewhereHours: {
              $round: [{ $divide: ['$idleElsewhereSeconds', 3600] }, 2],
            },
            stoppedHomeBaseHours: {
              $round: [{ $divide: ['$stoppedHomeBaseSeconds', 3600] }, 2],
            },
            stoppedOtherBaseHours: {
              $round: [{ $divide: ['$stoppedOtherBaseSeconds', 3600] }, 2],
            },
            stoppedWorkshopHours: {
              $round: [{ $divide: ['$stoppedWorkshopSeconds', 3600] }, 2],
            },
            stoppedElsewhereHours: {
              $round: [{ $divide: ['$stoppedElsewhereSeconds', 3600] }, 2],
            },
            unaccountableHours: {
              $round: [{ $divide: ['$unaccountableSeconds', 3600] }, 2],
            },
          },
        },
      ].filter(Boolean);

      log('Read', 'Vehicle Daily Activities', {
        match,
        days,
        offsets,
        groupBy,
      });

      return api
        .post('pipeline/vehicleDailyActivities', { json, signal })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !isEmpty(match),
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
