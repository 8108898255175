import { CustomTooltip, Parameters, ToggleList } from '@/components/controls';
import { useDocumentTitle, useOptions, useSetting } from '@/hooks';
import {
  activityCompareFn,
  dayOptions,
  downloadCSV,
  formatGroupBy,
  getTextWidth,
  hourOptions,
  humanizeHours,
  measureTypes,
  startCase,
} from '@/utils';
import {
  Download as DownloadIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  alpha,
  Avatar,
  Box,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { indigo } from '@mui/material/colors';
import { useAtom } from 'jotai';
import {
  MRT_TablePagination,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Fragment, useMemo, useState } from 'react';
import {
  Bar,
  BarChart,
  Tooltip as ChartTooltip,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { usePersonDailyActivities } from './usePersonDailyActivities';
import { bars, columnsFn, stateAtom, staticGroupByOptions } from './utils';

export function DailyActivity() {
  useDocumentTitle('IR3 | People | Daily Activity');
  const [{ groupBy, sorting, hours, days, query, parameters }, setState] =
    useAtom(stateAtom);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hiddenBars, setHiddenBars] = useState([]);
  const [mileageType, setMileageType] = useState('total');
  const [baseVisitCountType, setBaseVisitCountType] = useState('total');
  const { data: personGroups } = useOptions('personGroup');
  const { data: offsetHours = 0 } = useSetting('offsetHours');
  const groupByOptions = useMemo(
    () => ({
      ...staticGroupByOptions,
      ...(personGroups ?? []).reduce(
        (acc, { label, value }) => ({
          ...acc,
          [`personGroup_${value}`]: {
            label,
            value: `$person.groups.${value}`,
          },
        }),
        [],
      ),
    }),
    [personGroups],
  );
  const {
    data: rawData,
    isLoading,
    isFetching,
    cancel,
  } = usePersonDailyActivities(
    query,
    days,
    hours.map((hour) => (hour - offsetHours + 24) % 24),
    groupByOptions[groupBy].value,
  );
  const columns = useMemo(() => columnsFn(groupBy), [groupBy]);
  const data = useMemo(
    () => [
      ...rawData.sort(activityCompareFn(sorting[0]?.id, sorting[0]?.desc)),
    ],
    [rawData, sorting],
  );
  const xAxisHeight =
    Math.max(
      ...data.map((item) =>
        getTextWidth(formatGroupBy(groupBy)(item.group), '12px Roboto'),
      ),
      0,
    ) + 16;

  const handleStateChange = (name) => (value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  const handleGroupByClick = (value) => () => {
    setState((state) => ({ ...state, groupBy: value }));
    handleSettingsClose();
  };

  const handleSortByClick = (value) => () => {
    setState((state) => ({ ...state, sorting: value }));
    handleSettingsClose();
  };

  function handleFetch(event, query) {
    setState((state) => ({ ...state, query }));
  }

  function handleCancel() {
    cancel();
    setState((state) => ({ ...state, query: {} }));
  }

  function handleSettingsClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleSettingsClose() {
    setAnchorEl(null);
  }

  function handleSortingChange(changeFn) {
    setState((state) => ({ ...state, sorting: changeFn(state.sorting) }));
  }

  const handleLegendClick = (selectedBar) => () => {
    const index = hiddenBars.indexOf(selectedBar);

    if (index === -1) {
      setHiddenBars(hiddenBars.concat(selectedBar));
    } else {
      setHiddenBars(
        hiddenBars.slice(0, index).concat(hiddenBars.slice(index + 1)),
      );
    }
  };

  function handleMileageTypeChange(event, value) {
    setMileageType(value);
  }

  function handleBaseVisitCountTypeChange(event, value) {
    setBaseVisitCountType(value);
  }

  function handleDownloadClick() {
    const tableColumns = columns.map(({ header, accessorKey }) => ({
      key: accessorKey,
      label: header,
      type: accessorKey === 'group' ? 'text' : 'number',
      precision: accessorKey === 'group' ? undefined : 6,
    }));

    downloadCSV(
      data.map((record) => ({
        group: record.group,
        resourceCount: record.resourceCount,
        loggedInHours: record.loggedInHours / 24,
        drivingHours: record.drivingHours / 24,
        baseHours: record.baseHours / 24,
        homeWardHours: record.homeWardHours / 24,
        respondingHours: record.respondingHours / 24,
        attendingHours: record.attendingHours / 24,
        doubleCrewingHours: record.doubleCrewingHours / 24,
        totalMiles: record.totalMiles,
        averageMiles: record.averageMiles,
        dailyMiles: record.dailyMiles,
        totalBaseVisits: record.totalBaseVisits,
        averageBaseVisits: record.averageBaseVisits,
        dailyBaseVisits: record.dailyBaseVisits,
      })),
      'utilisation.csv',
      tableColumns,
    );
  }

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      density: 'compact',
    },
    state: { isLoading: isFetching || isLoading, sorting },
    defaultColumn: { size: 0, enableEditing: false },
    getRowId: (row) => row.identifier,
    positionToolbarAlertBanner: 'none',
    enableTopToolbar: true,
    enableSorting: true,
    manualSorting: true,
    onSortingChange: handleSortingChange,
    enableBottomToolbar: true,
    enablePagination: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiTableContainerProps: {
      sx: { width: 'calc(100vw - 280px)' },
    },
    renderBottomToolbar: () => {
      return (
        <Stack
          direction="row"
          sx={{ pl: 1, justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Tooltip title="Download data">
            <Box component="span">
              <IconButton
                size="small"
                onClick={handleDownloadClick}
                disabled={(data ?? []).length === 0}
              >
                <DownloadIcon />
              </IconButton>
            </Box>
          </Tooltip>
          <MRT_TablePagination table={table} />
        </Stack>
      );
    },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 48px)',
      }}
    >
      <Parameters
        collection="personDailyActivities"
        onFetch={handleFetch}
        onCancel={handleCancel}
        isFetching={isLoading || isFetching}
        value={parameters}
        onChange={handleStateChange('parameters')}
        sx={{ mt: 1, width: 264 }}
        pointEvent
        dateOnly
        person
      />
      <Box
        sx={{ height: 'calc(100vh - 48px)', overflowY: 'auto', pb: 1, pr: 1 }}
      >
        <Stack spacing={1}>
          <Stack
            direction="row"
            spacing={1}
            sx={(theme) => ({
              pt: 1.5,
              justifyContent: 'space-between',
              alignItems: 'start',
              position: 'sticky',
              top: 0,
              backgroundColor: alpha(theme.palette.background.paper, 0.8),
              zIndex: 700,
            })}
          >
            <ToggleList
              disabled={isFetching}
              value={days}
              onChange={handleStateChange('days')}
              values={dayOptions}
              label={'Days'}
            />
            <ToggleList
              disabled={isFetching}
              value={hours}
              onChange={handleStateChange('hours')}
              values={hourOptions}
              label={'Hours'}
            />
            <Tooltip title="Settings">
              <IconButton onClick={handleSettingsClick}>
                <SettingsIcon />
              </IconButton>
            </Tooltip>
            <Menu
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleSettingsClose}
              anchor="right"
              MenuListProps={{ dense: true }}
            >
              <ListSubheader disableSticky>Group by</ListSubheader>
              {Object.entries(groupByOptions).map(([key, { label }]) => (
                <MenuItem
                  key={key}
                  value={key}
                  selected={key === groupBy}
                  onClick={handleGroupByClick(key)}
                >
                  {label}
                </MenuItem>
              ))}
              <ListSubheader disableSticky>Sort by</ListSubheader>
              {columns.map(({ header, accessorKey }) => (
                <MenuItem
                  key={accessorKey}
                  value={accessorKey}
                  selected={accessorKey === sorting[0]?.id}
                  onClick={handleSortByClick([
                    { id: accessorKey, desc: !sorting[0]?.desc },
                  ])}
                >
                  {header}
                </MenuItem>
              ))}
            </Menu>
          </Stack>
          <Paper sx={{ p: 2, minWidth: 240, fontSize: 12 }}>
            <Stack
              direction="row"
              sx={{ justifyContent: 'space-between', pb: 1 }}
            >
              <Typography
                variant="subtitle2"
                gutterBottom
                sx={{ color: 'text.secondary' }}
              >
                Activity
              </Typography>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                Average time spent at each activity, per person, per day
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              sx={{ justifyContent: 'center' }}
            >
              {bars.map((bar) => (
                <Box
                  key={bar.name}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    pb: 2,
                  }}
                  onClick={handleLegendClick(bar.name)}
                >
                  <Avatar
                    sx={{
                      width: 12,
                      height: 12,
                      mr: 0.5,
                      bgcolor: !hiddenBars.includes(bar.name) && bar.colour,
                    }}
                  >
                    <Fragment />
                  </Avatar>
                  <Typography variant="caption">{bar.label}</Typography>
                </Box>
              ))}
            </Stack>
            <ResponsiveContainer width="100%" height={520}>
              <BarChart
                data={data}
                margin={{ top: 16, right: 8, left: 8, bottom: 24 }}
                barGap={0}
                barCategoryGap={4}
              >
                <XAxis
                  dataKey="group"
                  tickFormatter={formatGroupBy(groupBy)}
                  angle={-90}
                  textAnchor="end"
                  interval={0}
                  height={xAxisHeight}
                >
                  <Label
                    value={groupByOptions[groupBy]?.label}
                    position="bottom"
                    style={{ fontWeight: 'bold' }}
                  />
                </XAxis>
                <YAxis>
                  <Label
                    value="Hours"
                    angle={-90}
                    position="left"
                    style={{ fontWeight: 'bold' }}
                  />
                </YAxis>
                <ChartTooltip
                  cursor={false}
                  content={
                    <CustomTooltip
                      labelFormatter={formatGroupBy(groupBy)}
                      valueFormatter={humanizeHours}
                    />
                  }
                />
                {bars.map((bar) => (
                  <Bar
                    key={bar.name}
                    dataKey={bar.name}
                    name={bar.label}
                    fill={bar.colour}
                    hide={hiddenBars.includes(bar.name)}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Paper>
          <Paper sx={{ p: 2, minWidth: 240, fontSize: 12 }}>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{ color: 'text.secondary' }}
            >
              Mileage
            </Typography>
            <Stack direction="row" sx={{ justifyContent: 'center' }}>
              <ToggleButtonGroup
                color="primary"
                value={mileageType}
                exclusive
                onChange={handleMileageTypeChange}
                size="small"
              >
                {measureTypes.map((measureType) => (
                  <ToggleButton key={measureType} value={measureType}>
                    {measureType}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Stack>
            <ResponsiveContainer width="100%" height={520}>
              <BarChart
                data={data}
                margin={{ top: 16, right: 8, left: 8, bottom: 24 }}
                barCategoryGap={4}
              >
                <XAxis
                  dataKey="group"
                  tickFormatter={formatGroupBy(groupBy)}
                  angle={-90}
                  textAnchor="end"
                  interval={0}
                  height={xAxisHeight}
                >
                  <Label
                    value={groupByOptions[groupBy]?.label}
                    position="bottom"
                    style={{ fontWeight: 'bold' }}
                  />
                </XAxis>
                <YAxis>
                  <Label
                    value="Miles"
                    angle={-90}
                    position="left"
                    style={{ fontWeight: 'bold' }}
                  />
                </YAxis>
                <ChartTooltip
                  cursor={false}
                  content={
                    <CustomTooltip labelFormatter={formatGroupBy(groupBy)} />
                  }
                />
                {measureTypes.map((measureType) => (
                  <Bar
                    key={measureType}
                    dataKey={`${measureType}Miles`}
                    name={`${startCase(measureType)} Miles`}
                    fill={indigo[800]}
                    hide={measureType !== mileageType}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Paper>
          <Paper sx={{ p: 2, minWidth: 240, fontSize: 12 }}>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{ color: 'text.secondary' }}
            >
              Base Visits
            </Typography>
            <Stack direction="row" sx={{ justifyContent: 'center' }}>
              <ToggleButtonGroup
                color="primary"
                value={baseVisitCountType}
                exclusive
                onChange={handleBaseVisitCountTypeChange}
                size="small"
              >
                {measureTypes.map((measureType) => (
                  <ToggleButton key={measureType} value={measureType}>
                    {measureType}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Stack>
            <ResponsiveContainer width="100%" height={520}>
              <BarChart
                data={data}
                margin={{ top: 16, right: 8, left: 8, bottom: 24 }}
                barCategoryGap={4}
              >
                <XAxis
                  dataKey="group"
                  tickFormatter={formatGroupBy(groupBy)}
                  angle={-90}
                  textAnchor="end"
                  interval={0}
                  height={xAxisHeight}
                >
                  <Label
                    value={groupByOptions[groupBy]?.label}
                    position="bottom"
                    style={{ fontWeight: 'bold' }}
                  />
                </XAxis>
                <YAxis>
                  <Label
                    value="Visits"
                    angle={-90}
                    position="left"
                    style={{ fontWeight: 'bold' }}
                  />
                </YAxis>
                <ChartTooltip
                  cursor={false}
                  content={
                    <CustomTooltip labelFormatter={formatGroupBy(groupBy)} />
                  }
                />
                {measureTypes.map((measureType) => (
                  <Bar
                    key={measureType}
                    dataKey={`${measureType}BaseVisits`}
                    name={`${startCase(measureType)} Visits`}
                    fill={indigo[800]}
                    hide={measureType !== baseVisitCountType}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Paper>
          <MaterialReactTable table={table} />
        </Stack>
      </Box>
    </Box>
  );
}
