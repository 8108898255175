import { decodeSignalStrength } from '@/utils';
import {
  SignalCellular0Bar as SignalCellular0BarIcon,
  SignalCellular1Bar as SignalCellular1BarIcon,
  SignalCellular2Bar as SignalCellular2BarIcon,
  SignalCellular3Bar as SignalCellular3BarIcon,
  SignalCellular4Bar as SignalCellular4BarIcon,
  SignalCellularNull as SignalCellularNullIcon,
} from '@mui/icons-material';
import { forwardRef } from 'react';

export const SignalStrengthIcon = forwardRef(function SignalStrengthIcon(
  { strength, ...props },
  ref,
) {
  const bars = decodeSignalStrength(strength);

  switch (bars) {
    case 0:
      return <SignalCellular0BarIcon color="error" {...props} ref={ref} />;
    case 1:
      return <SignalCellular1BarIcon color="error" {...props} ref={ref} />;
    case 2:
      return <SignalCellular2BarIcon color="warning" {...props} ref={ref} />;
    case 3:
      return <SignalCellular3BarIcon color="success" {...props} ref={ref} />;
    case 4:
    case 5:
      return <SignalCellular4BarIcon color="success" {...props} ref={ref} />;
    default:
      return <SignalCellularNullIcon color="error" {...props} ref={ref} />;
  }
});
