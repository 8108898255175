import { api } from '@/apis';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useDeleteVehicle() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => {
      if (id) {
        return api.delete(`vehicles/${id}`);
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['vehicles'] }),
  });
}
