import { startCase } from '@/utils';
import { Chip, Stack, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useController } from 'react-hook-form';

export function ItemsController({ name, control, disabled, rules }) {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    disabled,
    rules,
    defaultValue: [],
  });

  const handleDelete = (type, deletedEntry) => () => {
    onChange({
      ...value,
      [type]: value[type].filter((entry) => entry !== deletedEntry),
    });
  };

  return (
    <Stack spacing={1}>
      {Object.entries(value)
        .filter(([, items]) => items?.length > 0)
        .map(([type, items]) => (
          <Fragment key={type}>
            <Typography variant="caption" color="textSecondary">
              {startCase(type)}
            </Typography>
            <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
              {items.map((item) => (
                <Chip
                  size="small"
                  key={item}
                  label={item}
                  onDelete={handleDelete(type, item)}
                />
              ))}
            </Stack>
          </Fragment>
        ))}
    </Stack>
  );
}
