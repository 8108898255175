import { DatePicker, Parameters } from '@/components/controls';
import { useAggregate, useDocumentTitle } from '@/hooks';
import {
  downloadCSV,
  getBackgroundColor,
  getHoverBackgroundColor,
} from '@/utils';
import { useTheme } from '@emotion/react';
import { Download as DownloadIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import {
  MaterialReactTable,
  MRT_TablePagination,
  useMaterialReactTable,
} from 'material-react-table';
import { useMemo } from 'react';
import { columns, pipelineFn, stateAtom } from './utils';

export function Mileage() {
  useDocumentTitle('IR3 | Mileage');
  const theme = useTheme();
  const [{ query, parameters, time }, setState] = useAtom(stateAtom);
  const collection = 'vehicles';
  const pipeline = useMemo(() => pipelineFn(query, time), [query, time]);
  const { data, isFetching, cancel } = useAggregate(
    collection,
    pipeline,
    true,
    1000 * 60 * 60,
  );
  const errors = useMemo(
    () => ({
      NO_ODOMETER_READING: {
        description: 'Vehicle has no odometer reading',
        color: theme.palette.secondary.main,
      },
      NO_POLLS: {
        description: 'Vehicle has not polled',
        color: theme.palette.error.main,
      },
      NO_POLL_WITHIN_7_DAYS_OF_ODOMETER_READING: {
        description: 'No poll within 7 days of odometer reading',
        color: theme.palette.warning.main,
      },
    }),
    [theme.palette],
  );

  function handleDownloadClick() {
    const csvColumns = columns
      .map(({ header, id, accessorKey }) => ({
        label: header,
        key: id ?? accessorKey,
      }))
      .concat({ label: 'Error', key: 'error' });

    downloadCSV(
      data.map((record) => ({
        ...record,
        error: errors[record.error]?.description ?? '',
      })),
      'mileage.csv',
      csvColumns,
    );
  }

  function renderTopToolbarCustomActions() {
    return (
      <Stack spacing={1} direction="row" sx={{ alignSelf: 'stretch', p: 1 }}>
        {Object.entries(errors).map(([error, { description, color }]) => (
          <Stack
            key={error}
            direction="row"
            spacing={0.5}
            sx={{ alignItems: 'center' }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                bgcolor: color,
                borderRadius: 6,
              }}
            />
            <Typography variant="caption">{description}</Typography>
          </Stack>
        ))}
      </Stack>
    );
  }

  function renderBottomToolbar() {
    return (
      <Stack
        direction="row"
        sx={{ pl: 1, justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box>
          <Tooltip title="Download data">
            <IconButton size="small" onClick={handleDownloadClick}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <MRT_TablePagination table={table} />
      </Stack>
    );
  }

  function handleCancel() {
    cancel();
    setState((state) => ({ ...state, query: {} }));
  }

  function handleTimeChange(value) {
    setState((state) => ({ ...state, time: value }));
  }

  const handleStateChange = (name) => (value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  const handleStateChangeWithEvent = (name) => (_, value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  function getRowStyle(error) {
    return {
      bgcolor: (theme) =>
        getBackgroundColor(errors[error]?.color, theme.palette.mode),
      '&:hover': {
        bgcolor: (theme) =>
          getHoverBackgroundColor(errors[error]?.color, theme.palette.mode),
      },
      '&:last-child td, &:last-child th': { border: 0 },
    };
  }

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      sorting: [{ id: 'registrationNumber' }],
      pagination: {
        pageIndex: 0,
        pageSize: 25,
      },
    },
    state: { density: 'compact', isLoading: isFetching },
    defaultColumn: { size: 0, enableEditing: false },
    renderTopToolbarCustomActions,
    renderBottomToolbar,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableTopToolbar: true,
    enableSorting: true,
    enableBottomToolbar: true,
    enablePagination: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiTablePaperProps: { elevation: 0, variant: 'elevation' },
    muiTableContainerProps: {
      sx: {
        width: 'calc(100vw - 280px)',
        maxHeight: 'calc(100vh - 160px)', // 48 app header + 56 top bar + 56 bottom bar
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      hover: !row.original.error,
      sx: getRowStyle(row.original.error),
    }),
  });

  return (
    <Stack direction="row">
      <Box
        sx={{
          width: 280,
          height: 'calc(100vh - 48px)',
          borderRight: 1,
          borderColor: 'divider',
          flexShrink: 0,
        }}
      >
        <Parameters
          dateOnly
          hideTimePeriod
          collection={collection}
          onFetch={handleStateChangeWithEvent('query')}
          onCancel={handleCancel}
          isFetching={isFetching}
          value={parameters}
          onChange={handleStateChange('parameters')}
          sx={{ pt: 1, height: 1 }}
          vehicle
        >
          <Typography variant="subtitle2" color="textSecondary">
            Date
          </Typography>
          <DatePicker
            value={time}
            onChange={handleTimeChange}
            clearable
            disableFuture
          />
        </Parameters>
      </Box>
      <MaterialReactTable table={table} />
    </Stack>
  );
}
