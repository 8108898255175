import {
  AliasPickerController,
  AvatarController,
  BoundaryEditorController,
  CategoryPickerController,
  DateTimePickerController,
  GroupAncestors,
  GroupCodesController,
  TagField,
  TextFieldController,
} from '@/components/controls';
import { ConfirmationDialog } from '@/components/dialogs';
import { doesIdExist } from '@/utils';
import { locationForm } from '@/utils/config';
import { Button, Divider, Stack, SvgIcon, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';

export function LocationForm({
  defaultValues,
  canEdit,
  onSubmit,
  onDelete,
  locationType,
  typeOptions,
  locationGroups,
  locationAttributes,
  locationTypes,
}) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const {
    control,
    formState: { dirtyFields, isSubmitting },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues,
  });
  const isDirty = Object.keys(dirtyFields).length > 0;

  function handleCancel() {
    reset();
  }

  function toggleDeleteDialog() {
    setDeleteOpen((prev) => !prev);
  }

  function isDisabled(fieldName) {
    return !(
      canEdit &&
      (locationForm[fieldName]?.editable || !defaultValues?._id)
    );
  }

  async function codeDoesNotExist(code) {
    return !defaultValues?._id && (await doesIdExist('locations', code))
      ? 'Exists'
      : true;
  }

  return (
    <Fragment>
      <form id="location-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <AvatarController
              name="picture"
              control={control}
              icon={
                <SvgIcon>
                  <path d={locationTypes[locationType]?.d} />
                </SvgIcon>
              }
              disabled={isDisabled('picture')}
            />
            <Typography variant="h6" sx={{ p: 1 }}>
              {defaultValues?._id
                ? defaultValues.name
                : `New ${locationTypes[locationType]?.label}`}
            </Typography>
          </Stack>
          <Stack spacing={1} direction="row" sx={{ flexWrap: 'wrap' }}>
            <TextFieldController
              name="code"
              label="Code"
              control={control}
              disabled={isDisabled('code')}
              rules={{
                required: 'Required',
                maxLength: 30,
                pattern: {
                  value: /^[a-z0-9 ]*$/i,
                  message: 'Only alphanumeric',
                },
                validate: codeDoesNotExist,
              }}
            />
            <TextFieldController
              name="name"
              label="Name"
              control={control}
              disabled={isDisabled('name')}
              rules={{ required: 'Required', maxLength: 50 }}
            />
            <TextFieldController
              name="subtype"
              label="Type"
              options={locationTypes[locationType]?.values}
              control={control}
              disabled={isDisabled('subtype')}
            />
          </Stack>
          <GroupCodesController
            name="groupCodes"
            control={control}
            label="Groups & Areas"
            disabled={isDisabled('groupCodes')}
          />
          {defaultValues?.groupAncestors?.length > 0 && (
            <Fragment>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Ancestor Groups & Areas
                </Typography>
              </Divider>
              <GroupAncestors
                groupAncestors={defaultValues?.groupAncestors}
                typeOptions={typeOptions}
              />
            </Fragment>
          )}
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Aliases
            </Typography>
          </Divider>
          <AliasPickerController
            name="aliases"
            control={control}
            disabled={isDisabled('aliases')}
          />
          {locationAttributes.length > 0 && (
            <Fragment>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Attributes
                </Typography>
              </Divider>
              <Stack spacing={1} direction="row" sx={{ flexWrap: 'wrap' }}>
                {locationAttributes.map(({ value, label, values }) => (
                  <TextFieldController
                    key={value}
                    name={`attributes.${value}`}
                    label={label}
                    control={control}
                    disabled={!canEdit}
                    options={values}
                  />
                ))}
              </Stack>
            </Fragment>
          )}
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Categegories
            </Typography>
          </Divider>
          <CategoryPickerController
            name="groups"
            control={control}
            categories={locationGroups}
            disabled={isDisabled('groups')}
          />
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Active Time
            </Typography>
          </Divider>
          <Stack spacing={1} direction="row" sx={{ flexWrap: 'wrap' }}>
            <DateTimePickerController
              name="startTime"
              label="Start Time"
              control={control}
              disabled={isDisabled('startTime')}
              rules={{ required: 'Required' }}
              minDate={new Date('1900-01-01')}
              maxDate={watch('endTime') ?? new Date('1900-01-01')}
            />
            <DateTimePickerController
              name="endTime"
              label="End Time"
              control={control}
              disabled={isDisabled('endTime')}
              rules={{ required: 'Required' }}
              minDate={watch('startTime') ?? new Date('2100-01-01')}
              maxDate={new Date('2100-01-01')}
            />
          </Stack>
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Tags
            </Typography>
          </Divider>
          <TagField
            collection="locations"
            id={defaultValues?._id}
            linkPrefix="/tags"
            sx={{ pb: 1 }}
            disabled={!defaultValues?._id}
          />
          <Divider>
            <Typography variant="caption" color="textSecondary">
              Boundary
            </Typography>
          </Divider>
          <BoundaryEditorController
            name="boundary"
            control={control}
            rules={{ required: 'Required' }}
          />
          <Stack spacing={1} direction="row">
            <Button
              color="primary"
              type="submit"
              disabled={!isDirty || isSubmitting}
            >
              Save
            </Button>
            <Button
              color="primary"
              disabled={!isDirty || isSubmitting}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {/* <Button
              color="primary"
              aria-owns={anchorEl ? 'events-menu' : undefined}
              aria-haspopup="true"
              onClick={handleEventsClick}
              disabled={isDirty || isSubmitting || !defaultValues?._id}
            >
              Events
            </Button> */}
            {/* <Menu
              id="events-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleEventsClose}
            >
              <MenuItem
                onClick={navigateToEvents(
                  'vehicleLocationVisits',
                  'locationvisits',
                  'vehicles',
                )}
              >
                Vehicle Visits
              </MenuItem>
              <MenuItem
                onClick={navigateToEvents(
                  'personLocationVisits',
                  'locationvisits',
                  'people',
                )}
              >
                Person Visits
              </MenuItem>
            </Menu> */}
            {canEdit && (
              <Button
                color="error"
                onClick={toggleDeleteDialog}
                disabled={!watch('_id')}
              >
                Delete
              </Button>
            )}
          </Stack>
        </Stack>
      </form>
      <ConfirmationDialog
        action="Delete"
        open={deleteOpen}
        itemId={defaultValues?.name || defaultValues?.code}
        onOk={onDelete}
        onCancel={() => setDeleteOpen(false)}
      />
    </Fragment>
  );
}
