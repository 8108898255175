import {
  CREATE_VEHICLE,
  CREATE_VEHICLE_FAILURE,
  CREATE_VEHICLE_SUCCESS,
  DELETE_VEHICLE,
  DELETE_VEHICLE_FAILURE,
  DELETE_VEHICLE_SUCCESS,
  FETCH_VEHICLE,
  FETCH_VEHICLES,
  FETCH_VEHICLES_FAILURE,
  FETCH_VEHICLES_SUCCESS,
  FETCH_VEHICLE_FAILURE,
  FETCH_VEHICLE_SUCCESS,
  REMOVE_VEHICLE_TELEMATICS_BOX,
  REMOVE_VEHICLE_TELEMATICS_BOX_FAILURE,
  REMOVE_VEHICLE_TELEMATICS_BOX_SUCCESS,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_FAILURE,
  UPDATE_VEHICLE_SUCCESS,
} from '@/actions';
import { fromAjax } from '@/apis';
import { getHeaders, log, round } from '@/utils';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

export function fetchVehiclesEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLES),
    mergeMap(() =>
      fromAjax('/vehicles', {
        params: {
          pipeline: [
            {
              $graphLookup: {
                from: 'groups',
                startWith: '$groupCodes',
                connectFromField: 'parentCodes',
                connectToField: 'code',
                as: 'groupAncestorCodes',
              },
            },
            {
              $project: {
                identificationNumber: true,
                registrationNumber: true,
                fleetNumber: true,
                telematicsBoxImei: true,
                lastPollTime: true,
                disposalDate: true,
                picture: true,
                role: true,
                homeStation: true,
                driverIdLocation: true,
                installLocation: true,
                assignments: true,
                attributes: true,
                groupCodes: true,
                groupAncestorCodes: {
                  $map: {
                    input: '$groupAncestorCodes',
                    as: 'group',
                    in: '$$group.code',
                  },
                },
              },
            },
          ],
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          const payload = (response || []).map((vehicle) => {
            return {
              ...vehicle,
              searchString: [
                vehicle.identificationNumber,
                vehicle.registrationNumber,
                vehicle.fleetNumber,
              ]
                .join('+')
                .toLowerCase(),
            };
          });

          log('Read', 'Vehicles');

          return {
            type: FETCH_VEHICLES_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_VEHICLES_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

export function fetchVehicleEpic(action$) {
  return action$.pipe(
    ofType(FETCH_VEHICLE),
    mergeMap(({ payload: id }) => {
      return fromAjax('/vehicles', {
        params: {
          pipeline: [
            { $match: { identificationNumber: id } },
            {
              $graphLookup: {
                from: 'groups',
                startWith: '$groupCodes',
                connectFromField: 'parentCodes',
                connectToField: 'code',
                as: 'groupAncestors',
                depthField: 'depth',
              },
            },
            {
              $project: {
                identificationNumber: true,
                registrationNumber: true,
                fleetNumber: true,
                picture: true,
                role: true,
                type: true,
                make: true,
                model: true,
                keyNumber: true,
                colour: true,
                marked: true,
                homeStation: true,
                equipment: true,
                telematicsBoxImei: true,
                lastPollTime: true,
                odometerReadings: {
                  $sortArray: {
                    input: {
                      $map: {
                        input: '$odometerReadings',
                        as: 'reading',
                        in: {
                          time: '$$reading.time',
                          miles: {
                            $round: [
                              {
                                $multiply: ['$$reading.kilometres', 0.62137119],
                              },
                              3,
                            ],
                          },
                        },
                      },
                    },
                    sortBy: { time: -1 },
                  },
                },
                fuelType: true,
                // disposedTime: true,
                disposalDate: true,
                driverIdLocation: true,
                installLocation: true,
                commissionDate: true,
                notes: true,
                groups: true,
                assignments: true,
                visibleTo: true,
                attributes: true,
                groupCodes: true,
                groupAncestors: {
                  $map: {
                    input: {
                      $sortArray: {
                        input: {
                          $filter: {
                            input: '$groupAncestors',
                            cond: {
                              $not: [
                                { $in: ['$$ancestor.code', '$groupCodes'] },
                              ],
                            },
                            as: 'ancestor',
                          },
                        },
                        sortBy: { depth: -1, type: 1, name: 1 },
                      },
                    },
                    as: 'group',
                    in: {
                      code: '$$group.code',
                      name: '$$group.name',
                      type: '$$group.type',
                    },
                  },
                },
              },
            },
          ],
        },
        headers: getHeaders(),
      }).pipe(
        map(({ response: payload }) => {
          log('Read', 'Vehicles', { id });

          return {
            type: FETCH_VEHICLE_SUCCESS,
            payload,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_VEHICLE_FAILURE,
            payload,
          }),
        ),
      );
    }),
  );
}

export function createVehicleEpic(action$) {
  return action$.pipe(
    ofType(CREATE_VEHICLE),
    mergeMap(
      ({
        payload: {
          redirect,
          groupAncestors: _,
          odometerReadings = [],
          ...payload
        },
        navigate,
      }) =>
        fromAjax('/vehicles', {
          body: {
            ...payload,
            odometerReadings: odometerReadings.map(({ time, miles }) => ({
              time,
              kilometres: round(miles / 0.62137119, 3),
            })),
          },
          method: 'POST',
          headers: { ...getHeaders(), 'content-type': 'application/json' },
        }).pipe(
          map(({ response: payload }) => {
            log('Create', 'Vehicle', payload);

            if (redirect) {
              navigate(`../${payload.identificationNumber}`, {
                replace: true,
                state: { created: true },
              });
            }

            return {
              type: CREATE_VEHICLE_SUCCESS,
              payload,
            };
          }),
          catchError(({ message: payload }) =>
            of({
              type: CREATE_VEHICLE_FAILURE,
              payload,
            }),
          ),
        ),
    ),
  );
}

export function updateVehicleEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_VEHICLE),
    mergeMap(
      ({ payload: { groupAncestors: _, odometerReadings = [], ...payload } }) =>
        fromAjax(`/vehicles/${payload.identificationNumber}`, {
          body: {
            ...payload,
            odometerReadings: odometerReadings.map(({ time, miles }) => ({
              time,
              kilometres: round(miles / 0.62137119, 3),
            })),
          },
          method: 'PATCH',
          headers: {
            ...getHeaders(),
            'content-type': 'application/merge-patch+json',
          },
        }).pipe(
          mergeMap(({ response: payload }) => {
            return of(
              { type: UPDATE_VEHICLE_SUCCESS, payload },
              { type: FETCH_VEHICLES },
            );
          }),
          catchError(({ message: payload }) =>
            of({
              type: UPDATE_VEHICLE_FAILURE,
              payload,
            }),
          ),
        ),
    ),
  );
}

export function removeVehicleTelematicsBoxEpic(action$) {
  return action$.pipe(
    ofType(REMOVE_VEHICLE_TELEMATICS_BOX),
    mergeMap(({ payload: values }) =>
      fromAjax(`/vehicles/${values.identificationNumber}`, {
        body: {
          identificationNumber: values.identificationNumber,
          telematicsBoxImei: values.telematicsBoxImei,
        },
        method: 'PATCH',
        headers: {
          ...getHeaders(),
          'content-type': 'application/merge-patch+json',
        },
      }).pipe(
        // map(({ response: payload }) => ({
        //   type: REMOVE_VEHICLE_TELEMATICS_BOX_SUCCESS,
        //   payload: { ...payload, previousImei: values.previousImei },
        // })),
        mergeMap((payload) =>
          of(
            {
              type: REMOVE_VEHICLE_TELEMATICS_BOX_SUCCESS,
              payload: {
                ...payload,
                previousImei: values.previousImei,
                identificationNumber: values.identificationNumber,
              },
            },
            {
              type: FETCH_VEHICLE,
              payload: values.identificationNumber,
            },
          ),
        ),
        catchError(({ message: payload }) =>
          of({
            type: REMOVE_VEHICLE_TELEMATICS_BOX_FAILURE,
            payload: { ...payload, previousImei: values.previousImei },
          }),
        ),
      ),
    ),
  );
}

export function deleteVehicleEpic(action$) {
  return action$.pipe(
    ofType(DELETE_VEHICLE),
    mergeMap(({ payload: id, navigate }) =>
      fromAjax(`/vehicles/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      }).pipe(
        map(({ response }) => {
          navigate('..', { replace: true });

          return {
            type: DELETE_VEHICLE_SUCCESS,
            payload: response.identificationNumber,
          };
        }),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_VEHICLE_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}
