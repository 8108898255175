import {
  Business as BusinessIcon,
  DataUsage as DataUsageIcon,
  DirectionsCar as DirectionsCarIcon,
  GroupWork as GroupWorkIcon,
  NewReleases,
  Person as PersonIcon,
  Tag as TagIcon,
} from '@mui/icons-material';
import { atom } from 'jotai';
import { AlertBox as AlertBoxIcon } from 'mdi-material-ui';

export const dateFormat = 'dd/MM/yyyy';
export const dateTimeFormat = 'dd/MM/yyyy HH:mm';
export const dateTimeSecondsFormat = 'dd/MM/yyyy HH:mm:ss';

export const types = {
  vehicles: {
    label: 'Vehicles',
    Icon: DirectionsCarIcon,
    idField: 'imei',
    zIndex: 2,
  },
  people: { label: 'People', Icon: PersonIcon, idField: 'ssi', zIndex: 3 },
  locations: {
    label: 'Locations',
    Icon: BusinessIcon,
    idField: 'code',
    zIndex: 0,
  },
  incidents: {
    label: 'Incidents',
    Icon: AlertBoxIcon,
    idField: 'number',
    zIndex: 5,
  },
  events: {
    label: 'Events',
    Icon: NewReleases,
    idField: 'identifier',
    zIndex: 4,
  },
  objectives: {
    label: 'Objectives',
    Icon: DataUsageIcon,
    idField: 'identifier',
    zIndex: 1,
  },
  callSigns: {
    label: 'Call Signs',
    Icon: GroupWorkIcon,
    idField: 'code',
  },
  tags: { label: 'Tags', Icon: TagIcon, idField: 'identifier' },
};

export const sortOptions = {
  vehicles: [
    { label: 'Registration', value: 'vehicle.registrationNumber' },
    { label: 'Fleet Number', value: 'vehicle.fleetNumber' },
    { label: 'Poll Time', value: 'time' },
    { label: 'Speed', value: 'speedKilometresPerHour' },
  ],
  people: [
    { label: 'Collar Number', value: 'collarNumber' },
    { label: 'Poll Time', value: 'time' },
  ],
  locations: [{ label: 'Name', value: 'name' }],
  incidents: [
    { label: 'Opened Time', value: 'openedTime' },
    { label: 'Grade', value: 'grade' },
  ],
  events: [
    { label: 'Time', value: 'startTime' },
    { label: 'Magnitude', value: 'mamaximumForces.absolute' },
  ],
  objectives: [
    { label: 'Title', value: 'title' },
    { label: 'Start Time', value: 'startTime' },
  ],
  callSigns: [{ label: 'Code', value: 'code' }],
  tags: [{ label: 'Code', value: 'identifier' }],
};

export const replayActionsAtom = atom({
  scrollTo: () => {},
  panTo: () => {},
});
