import { useOptions } from '@/hooks';
import { Stack } from '@mui/material';
import { FilterField } from './FilterField';

export function VehicleFilter({ value = {}, onChange, filterValues = {} }) {
  const { data: vehicleGroups } = useOptions('vehicleGroup');

  const handleFilterChange = (field) => (event, update) => {
    onChange({
      ...value,
      [field]: update,
    });
  };

  const handleGroupFilterChange = (field) => (event, update) => {
    onChange({
      ...value,
      groups: {
        ...value.groups,
        [field]: update,
      },
    });
  };

  return (
    <Stack spacing={1} sx={{ maxHeight: 238, overflow: 'scroll', pt: 0.75 }}>
      <FilterField
        label="Registration"
        value={value.registrationNumber}
        onChange={handleFilterChange('registrationNumber')}
        options={filterValues['properties.vehicle.registrationNumber'] ?? []}
      />
      <FilterField
        label="Fleet #"
        value={value.fleetNumber}
        onChange={handleFilterChange('fleetNumber')}
        options={filterValues['properties.vehicle.fleetNumber'] ?? []}
      />
      <FilterField
        label="Role"
        value={value.role}
        onChange={handleFilterChange('role')}
        options={filterValues['properties.vehicle.role'] ?? []}
      />
      <FilterField
        label="Home Station"
        value={value.homeStation}
        onChange={handleFilterChange('homeStation')}
        options={filterValues['properties.vehicle.homeStation'] ?? []}
      />
      {vehicleGroups.map(({ value: type, label }) => (
        <FilterField
          key={type}
          label={label}
          value={value.groups?.[type]}
          onChange={handleGroupFilterChange(type)}
          options={filterValues[`properties.vehicle.groups.${type}`] ?? []}
        />
      ))}
    </Stack>
  );
}
