import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function useLegacyAudits(
  startTime,
  endTime,
  users = [],
  sections = [],
  pages = [],
  operations = [],
  types = [],
  sorting,
  pagination,
) {
  const queryKey = [
    'legacyAudits',
    startTime,
    endTime,
    users,
    sections,
    pages,
    operations,
    types,
    sorting,
    pagination,
  ];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const json = [
        {
          $match: {
            LocalAuditDate: { $gte: startTime, $lt: endTime },
            UserName: users.length > 0 ? { $in: users } : undefined,
            Section: sections.length > 0 ? { $in: sections } : undefined,
            WebPage: pages.length > 0 ? { $in: pages } : undefined,
            Operation: operations.length > 0 ? { $in: operations } : undefined,
            EntityTypeName: types.length > 0 ? { $in: types } : undefined,
          },
        },
        sorting.length > 0 && {
          $sort: sorting.reduce(
            (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
            {},
          ),
        },
        { $skip: pagination.pageIndex * pagination.pageSize },
        { $limit: pagination.pageSize },
      ].filter(Boolean);

      log('Read', 'Audit Log Entries', {
        startTime,
        endTime,
        users,
        sections,
        pages,
        operations,
        types,
        sorting,
        pagination,
      });

      return api.post('pipeline/legacyAudits', { json, signal }).json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !!startTime && !!endTime,
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
