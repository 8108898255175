import { Key as KeyIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { amber } from '@mui/material/colors';
import { format, startOfDay, subDays } from 'date-fns';
import { SignalStrengthIcon } from './SignalStrengthIcon';

export const pipelineFn = (lookbackDays) => [
  {
    $set: {
      'mostRecentPoll.time': { $toDate: '$mostRecentPoll.time' },
    },
  },
  {
    $match: {
      'mostRecentPoll.time': {
        $lt: subDays(startOfDay(new Date()), lookbackDays),
      },
    },
  },
  {
    $lookup: {
      from: 'vehicles',
      localField: 'imei',
      foreignField: 'telematicsBoxImei',
      as: 'vehicle',
      pipeline: [
        {
          $project: {
            _id: false,
            fleetNumber: true,
            registrationNumber: true,
            disposalDate: true,
            groupCodes: true,
          },
        },
      ],
    },
  },
  {
    $unwind: {
      path: '$vehicle',
      preserveNullAndEmptyArrays: true,
    },
  },
  {
    $graphLookup: {
      from: 'groups',
      startWith: '$vehicle.groupCodes',
      connectFromField: 'parentCodes',
      connectToField: 'code',
      as: 'groupAncestors',
    },
  },
  {
    $project: {
      _id: false,
      imei: true,
      mostRecentTime: '$mostRecentPoll.time',
      disposalDate: '$vehicle.disposalDate',
      fleetNumber: '$vehicle.fleetNumber',
      registrationNumber: '$vehicle.registrationNumber',
      ignitionOn: '$mostRecentPoll.ignitionOn',
      batteryVoltage: '$mostRecentPoll.deviceProperties.batteryVoltage',
      signalStrength: '$mostRecentPoll.deviceProperties.deviceSignalStrength',
      gpsValidPollPercentage: {
        $multiply: [
          { $divide: ['$gpsValidPollCount.valid', '$gpsValidPollCount.total'] },
          100,
        ],
      },
      location: { $first: '$mostRecentPoll.locations' },
      lastPosition: '$mostRecentPoll.position',
      groupAncestors: {
        $map: {
          input: '$groupAncestors',
          as: 'group',
          in: {
            name: '$$group.name',
            type: '$$group.type',
          },
        },
      },
    },
  },
];

export const columnsFn = (typeOptions = []) => [
  {
    header: 'IMEI',
    accessorKey: 'imei',
    filterVariant: 'multi-select',
  },
  {
    header: 'Last Contact Time',
    accessorKey: 'mostRecentTime',
    Cell: ({ cell }) =>
      cell.getValue() ? format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Disposal Date',
    accessorKey: 'disposalDate',
    Cell: ({ cell }) =>
      cell.getValue() ? format(cell.getValue(), 'dd/MM/yyyy') : '',
  },
  {
    header: 'Fleet Number',
    id: 'fleetNumber',
    accessorFn: ({ fleetNumber }) => fleetNumber ?? '',
    filterVariant: 'multi-select',
  },
  {
    header: 'Registration',
    id: 'registrationNumber',
    accessorFn: ({ registrationNumber }) => registrationNumber ?? '',
    filterVariant: 'multi-select',
  },
  {
    header: 'Ignition',
    accessorKey: 'ignitionOn',
    Cell: ({ cell }) => (
      <KeyIcon sx={{ color: cell.getValue() ? amber[700] : 'text.disabled' }} />
    ),
  },
  {
    header: 'Battery',
    accessorKey: 'batteryVoltage',
    Cell: ({ cell }) =>
      cell.getValue() ? (
        <Box
          component="span"
          sx={{ color: cell.getValue() < 8.5 ? 'error' : '' }}
        >
          {`${cell.getValue()}v`}
        </Box>
      ) : (
        <Box component="span" sx={{ color: 'warning.main' }}>
          N/A
        </Box>
      ),
  },
  {
    header: 'Signal Strength',
    accessorKey: 'signalStrength',
    Cell: ({ cell }) => (
      <SignalStrengthIcon signalStrength={cell.getValue()} isDecoded={true} />
    ),
  },
  {
    header: 'Valid GPS Rate',
    accessorKey: 'gpsValidPollPercentage',
    Cell: ({ cell }) =>
      cell.getValue() !== null ? (
        <Box
          component="span"
          sx={{ color: cell.getValue() < 60 ? 'error.main' : '' }}
        >
          {`${cell.getValue()}%`}
        </Box>
      ) : (
        <Box component="span" sx={{ color: 'warning.main' }}>
          N/A
        </Box>
      ),
  },
  {
    header: 'Location Type',
    id: 'location.type',
    accessorFn: ({ location: { type } = {} }) => type ?? '',
  },
  {
    header: 'Location Name',
    id: 'location.name',
    accessorFn: ({ location: { name } = {} }) => name ?? '',
  },
  ...(typeOptions.map(({ label, value }) => ({
    key: value,
    header: label,
    id: `groupAncestors.${value}`,
    accessorFn: ({ groupAncestors }) => groupAncestors?.[value]?.join(', '),
    filterVariant: 'multi-select',
  })) ?? []),
];
