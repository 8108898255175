import { shortHumanizer } from '@/utils';
import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  boundary: null,
  query: {},
  parameters: {},
});

export const columnsFn = (footer) => [
  {
    header: 'Staff ID',
    id: 'properties.person.code',
    accessorFn: ({ properties: { person: { code } = {} } = {} } = {}) =>
      code ?? '',
  },
  {
    header: 'Forenames',
    id: 'properties.person.forenames',
    accessorFn: ({ properties: { person: { forenames } = {} } = {} } = {}) =>
      forenames ?? '',
  },
  {
    header: 'Surname',
    id: 'properties.person.surname',
    accessorFn: ({ properties: { person: { surname } = {} } = {} } = {}) =>
      surname ?? '',
  },
  {
    header: 'Role',
    id: 'properties.person.role',
    accessorFn: ({ properties: { person: { role } = {} } = {} } = {}) =>
      role ?? '',
  },
  {
    header: 'Collar Number',
    id: 'properties.person.collarNumber',
    accessorFn: ({ properties: { person: { collarNumber } = {} } = {} } = {}) =>
      collarNumber ?? '',
  },
  {
    header: 'Rank',
    id: 'properties.person.rank.code',
    accessorFn: ({
      properties: { person: { rank: { code } = {} } = {} } = {},
    } = {}) => code ?? '',
  },
  {
    header: 'SSI',
    id: 'properties.ssi',
    accessorFn: ({ properties: { ssi } = {} } = {}) => ssi ?? '',
  },
  {
    header: 'Start Time',
    accessorKey: 'properties.startTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'End Time',
    accessorKey: 'properties.endTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Duration',
    accessorKey: 'properties.durationSeconds',
    Cell: ({ cell }) =>
      Number.isNaN(cell.getValue())
        ? ''
        : shortHumanizer(cell.getValue() * 1000),
    footer: Number.isNaN(footer.durationSeconds)
      ? ''
      : shortHumanizer(footer.durationSeconds * 1000),
  },
];
