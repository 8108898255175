import { shortHumanizer } from '@/utils';
import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  sorting: [{ id: 'startTime', desc: false }],
  pagination: {
    pageIndex: 0,
    pageSize: 25,
  },
  query: {},
  parameters: {},
});

export const pipelineFn = (pagination, query, sorting) =>
  [
    {
      $project: {
        _id: false,
        identifier: true,
        incident: {
          reference: true,
          date: {
            $dateFromString: { dateString: '$incident.date', timezone: 'GB' },
          },
          grade: true,
        },
        callSign: true,
        person: true,
        attended: true,
        startTime: true,
        attendedTime: true,
        endTime: true,
        durationSeconds: true,
        responseSeconds: {
          $round: [
            {
              $divide: [{ $subtract: ['$attendedTime', '$startTime'] }, 1000],
            },
            0,
          ],
        },
        attendedSeconds: {
          $round: [
            { $divide: [{ $subtract: ['$endTime', '$attendedTime'] }, 1000] },
            0,
          ],
        },
      },
    },
    { $match: query },
    sorting.length > 0 && {
      $sort: sorting.reduce(
        (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
        {},
      ),
    },
    { $skip: pagination.pageIndex * pagination.pageSize },
    { $limit: pagination.pageSize },
  ].filter(Boolean);

export const totalsPipelineFn = (query) => [
  {
    $project: {
      _id: false,
      incident: {
        reference: true,
        grade: true,
      },
      callSign: true,
      person: true,
      attended: true,
      startTime: true,
      attendedTime: true,
      endTime: true,
      durationSeconds: true,
      responseSeconds: {
        $round: [
          {
            $divide: [{ $subtract: ['$attendedTime', '$startTime'] }, 1000],
          },
          0,
        ],
      },
      attendedSeconds: {
        $round: [
          { $divide: [{ $subtract: ['$endTime', '$attendedTime'] }, 1000] },
          0,
        ],
      },
    },
  },
  { $match: query },
  {
    $group: {
      _id: null,
      total: { $sum: 1 },
      durationSeconds: { $sum: '$durationSeconds' },
      responseSeconds: { $sum: '$responseSeconds' },
      attendedSeconds: { $sum: '$attendedSeconds' },
    },
  },
];

export const downloadPipelineFn = (query) => [
  {
    $project: {
      _id: false,
      identifier: true,
      incident: {
        reference: true,
        date: {
          $dateFromString: { dateString: '$incident.date', timezone: 'GB' },
        },
        grade: true,
      },
      callSign: true,
      person: true,
      attended: { $cond: ['$attended', 'Yes', 'No'] },
      startTime: true,
      attendedTime: true,
      endTime: true,
      durationSeconds: true,
      responseSeconds: {
        $round: [
          {
            $divide: [{ $subtract: ['$attendedTime', '$startTime'] }, 1000],
          },
          0,
        ],
      },
      attendedSeconds: {
        $round: [
          { $divide: [{ $subtract: ['$endTime', '$attendedTime'] }, 1000] },
          0,
        ],
      },
    },
  },
  { $match: query },
];

export const columnsFn = (footer) => [
  {
    header: 'Number',
    id: 'incident.reference',
    accessorFn: ({ incident: { reference } = {} } = {}) => reference ?? '',
  },
  {
    header: 'Date',
    accessorKey: 'incident.date',
    Cell: ({ cell }) =>
      cell.getValue() ? format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Grade',
    id: 'incident.grade',
    accessorFn: ({ incident: { grade } = {} } = {}) => grade ?? '',
  },
  {
    header: 'Call Sign',
    id: 'callSign',
    accessorFn: ({ callSign }) => callSign ?? '',
  },
  {
    header: 'Staff ID',
    id: 'person.code',
    accessorFn: ({ person: { code } = {} } = {}) => code ?? '',
  },
  {
    header: 'Forenames',
    id: 'person.forenames',
    accessorFn: ({ person: { forenames } = {} } = {}) => forenames ?? '',
  },
  {
    header: 'Surname',
    id: 'person.surname',
    accessorFn: ({ person: { surname } = {} } = {}) => surname ?? '',
  },
  {
    header: 'Collar Number',
    id: 'person.collarNumber',
    accessorFn: ({ person: { collarNumber } = {} } = {}) => collarNumber ?? '',
  },
  {
    header: 'Role',
    id: 'person.role',
    accessorFn: ({ person: { role } = {} } = {}) => role ?? '',
  },
  {
    header: 'Attended',
    id: 'attended',
    accessorFn: ({ attended }) => (attended ? 'Yes' : 'No'),
  },
  {
    header: 'Assigned Time',
    accessorKey: 'startTime',
    Cell: ({ cell }) =>
      cell.getValue() ? format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Attended Time',
    accessorKey: 'attendedTime',
    Cell: ({ cell }) =>
      cell.getValue() ? format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Unassigned Time',
    accessorKey: 'endTime',
    Cell: ({ cell }) =>
      cell.getValue() ? format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Duration',
    accessorKey: 'durationSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    footer: shortHumanizer(footer.durationSeconds * 1000),
  },
  {
    header: 'Response Duration',
    accessorKey: 'responseSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    footer: shortHumanizer(footer.responseSeconds * 1000),
  },
  {
    header: 'Attended Duration',
    accessorKey: 'attendedSeconds',
    Cell: ({ cell }) => shortHumanizer(cell.getValue() * 1000),
    footer: shortHumanizer(footer.attendedSeconds * 1000),
  },
];
