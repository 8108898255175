import { useController } from 'react-hook-form';
import { GroupsPicker } from '..';

export function CategoryPickerController({
  name,
  control,
  disabled,
  categories,
}) {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  return (
    <GroupsPicker
      value={value ?? {}}
      onChange={onChange}
      disabled={disabled}
      groups={categories}
    />
  );
}
