import {
  GazetteerSearch,
  MapButton,
  MapLayerSwitcher,
} from '@/components/controls';
import { useEffectOnce } from '@/hooks/useEffectOnce';
import { debounce, hexToRgba } from '@/utils';
import { mapExtent, mapLayers, mapView } from '@/utils/config';
import { retrospectiveTypeGlyphs } from '@/utils/constants';
import { getBaseLayers, getMapView } from '@/utils/mapStyles';
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@mui/icons-material';
import { Box, Stack, useTheme } from '@mui/material';
import { dequal } from 'dequal';
import _ from 'lodash';
import { Feature, Map } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { click, pointerMove } from 'ol/events/condition';
import { applyTransform, createEmpty, extend, getSize } from 'ol/extent';
import { GeoJSON } from 'ol/format';
import { Point } from 'ol/geom';
import {
  DoubleClickZoom as DoubleClickZoomInteraction,
  Draw as DrawInteraction,
  Select as SelectInteraction,
  defaults as defaultInteractions,
} from 'ol/interaction';
import {
  Heatmap as HeatmapLayer,
  Group as LayerGroup,
  VectorImage as VectorImageLayer,
  Vector as VectorLayer,
} from 'ol/layer';
import { getTransform } from 'ol/proj';
import { Cluster as ClusterSource, Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { getUid } from 'ol/util';
import { useEffect, useRef, useState } from 'react';
import { orderAndFilterFeatures, tooManyMapItems } from './constants';

const { maxZoom } = mapView;

function getStyle({ feature, primary, secondary }) {
  const geometry = feature.getGeometry();
  const count = feature.get('count');
  const source =
    feature.get('source') || feature.get('features')?.[0]?.get('source');

  let geometries = [geometry];
  if (geometry.getType() === 'GeometryCollection') {
    geometries = geometry.getGeometriesArray();
  }

  // const imgSrc = retrospectiveTypeGlyphs[source];
  const defaultSrc = retrospectiveTypeGlyphs.default;

  return geometries.flatMap((geometry) => {
    const coordinates = geometry.getCoordinates();
    const type = geometry ? geometry.getType() : null;

    switch (type) {
      case 'Point': {
        const features = feature.get('features');

        if (!features?.length) {
          return [
            new Style({
              image: new Circle({
                radius: 8,
                fill: new Fill({
                  color: primary,
                }),
              }),
              text: count
                ? new Text({
                    font: '10px Roboto,sans-serif',
                    fill: new Fill({
                      color: '#000000',
                    }),
                    stroke: new Stroke({
                      color: '#FFFFFF',
                    }),
                    text: `${count}`,
                    textAlign: 'left',
                    offsetX: 10,
                  })
                : undefined,
            }),
            //imgSrc &&
            new Style({
              image: new Icon({
                src: retrospectiveTypeGlyphs[source] ?? defaultSrc,
                color: 'rgb(0,0,0)',
                scale: 0.5,
              }),
            }),
          ];
        } else {
          const radius = Math.max(feature.get('radius') || 0, 8);

          if (features.length === 1) {
            return [
              new Style({
                image: new Circle({
                  radius: 8,
                  fill: new Fill({
                    color: primary,
                  }),
                }),
              }),
              //imgSrc &&
              new Style({
                image: new Icon({
                  src:
                    retrospectiveTypeGlyphs[features[0].get('source')] ??
                    defaultSrc,
                  color: 'rgb(0,0,0)',
                  scale: 0.5,
                }),
              }),
            ];
          } else {
            return new Style({
              image: new Circle({
                radius,
                fill: new Fill({
                  color: primary,
                }),
              }),
              text: new Text({
                font: '10px Roboto,sans-serif',
                fill: new Fill({
                  color: '#000000',
                }),
                stroke: new Stroke({
                  color: '#FFFFFF',
                }),
                text: `${features.length}`,
                textAlign: 'center',
              }),
            });
          }
        }
      }
      case 'LineString':
        return [
          new Style({
            stroke: new Stroke({
              color: secondary,
              width: 4,
            }),
          }),
          new Style({
            image: new Circle({
              radius: 8,
              fill: new Fill({
                color: 'rgba(76,175,80,1)',
              }),
            }),
            geometry: new Point(coordinates[0]),
          }),
          // imgSrc &&
          new Style({
            image: new Icon({
              src: retrospectiveTypeGlyphs.pathStart ?? defaultSrc,
              color: 'rgb(255,255,255)',
              scale: 0.5,
            }),
            geometry: new Point(coordinates[0]),
          }),
          new Style({
            image: new Circle({
              radius: 8,
              fill: new Fill({
                color: 'rgba(244,67,54,1)',
              }),
            }),
            geometry: new Point(coordinates[coordinates.length - 1]),
          }),
          // imgSrc &&
          new Style({
            image: new Icon({
              src: retrospectiveTypeGlyphs.pathEnd ?? defaultSrc,
              color: 'rgb(255,255,255)',
              scale: 0.5,
            }),
            geometry: new Point(coordinates[coordinates.length - 1]),
          }),
        ];
      case 'MultiPolygon':
      case 'Polygon':
        return new Style({
          fill: new Fill({
            color: secondary,
          }),
          stroke: new Stroke({
            color: primary,
            width: 2,
          }),
        });
      default:
        return null;
    }
  });
}

function getSelectStyle(feature) {
  return getStyle({
    feature,
    primary: 'rgb(33,150,243)',
    secondary: 'rgba(33,150,243,0.5)',
  });
}

function getHoverStyle(feature) {
  return getStyle({
    feature,
    primary: 'rgb(255,235,59)',
    secondary: 'rgba(255,235,59,0.5)',
  });
}

function getBoundaryStyle(feature) {
  const colors = feature.get('colors');
  const boundaryColor = feature.get('boundaryColor');
  const color = boundaryColor?.[0] || colors?.[0] || 'white';

  return new Style({
    stroke: new Stroke({
      color,
      width: 2,
      lineDash: boundaryColor ? [4, 8] : undefined,
    }),
  });
}

function heatWeightForCollection(featureCollection) {
  const maxCount = featureCollection.features.reduce(
    (max, { properties: { count = 0 } = {} }) => (max >= count ? max : count),
    0,
  );

  function heatWeight(feature) {
    const min = 0.1;
    // return min + ((feature.get('count') || 0) / maxCount)*(1-min);
    return (
      min + -Math.log2(1 - (feature.get('count') || 0) / maxCount) * (1 - min)
    );
  }

  return maxCount ? heatWeight : undefined;
}

function toMapFeature(feature, id) {
  let { geometry, properties } = feature;
  if (!geometry) {
    geometry = {
      type: 'Point',
      coordinates: [0, 0],
    };
  }

  feature = new Feature({
    ...properties,
    geometry: new GeoJSON().readGeometry(geometry, {
      featureProjection: 'EPSG:3857',
    }),
  });

  feature.setId(id);

  return feature;
}

export function RetrospectiveMap({
  layers,
  layerOrder,
  onHover,
  hoveredItemIndex,
  onSelect,
  selectedItemIndex,
  drawIndex,
  onDrawEnd,
  expandedLayerIndex,
}) {
  const [zoomInDisabled, setZoomInDisabled] = useState(false);
  const [zoomOutDisabled, setZoomOutDisabled] = useState(false);
  // const [showLabels, setShowLabels] = useState(false);
  const [geometry, setGeometry] = useState(null);
  const [layerCache, setLayerCache] = useState({});
  const mapDiv = useRef(null);
  const map = useRef(null);
  const boundaryLayer = useRef(new VectorLayer({ style: getBoundaryStyle }));
  const itemLayerGroup = useRef(new LayerGroup());
  const select = useRef(null);
  const hover = useRef(null);
  const hoverSource = useRef(null);
  const selectSource = useRef(null);
  const draw = useRef(null);
  const doubleClickZoom = useRef(null);
  const styleCache = useRef({});
  // const layers = useDebounce(layers, 1000);

  const fitOptions = { maxZoom, padding: [30, 30, 30, 30] };

  const theme = useTheme();

  useEffect(() => {
    let cacheChange = false;

    function getCachedLayer(layer, index) {
      if (!layer.featureCollection || layer.hidden) {
        return new VectorLayer();
      }

      // certain properties won't change a map layer such as the label, server-side filters
      // so omit these and compare the rest when deciding to create a new map layer
      const mapParams = _.pick(layer, [
        'virtualize',
        'window',
        'featureCollection',
        'clientFilters',
        'sort',
        'searchText',
        'type',
        'colors',
        'customBoundaryColor',
        'boundaryColor',
        'distance',
        'growth',
        'scale',
        'blur',
        'radius',
      ]);
      if (!dequal(layerCache[index]?.mapParams, mapParams)) {
        const orderedFilteredFeatures = orderAndFilterFeatures(layer);
        layerCache[index] = {
          mapParams,
          mapLayer: getLayer(layer, index, orderedFilteredFeatures),
          orderedFilteredFeatures,
        };
        cacheChange = true;
      }

      return layerCache[index].mapLayer;
    }

    function getLayer(layer, index, orderedFilteredFeatures) {
      const zIndex = layers.length - (layerOrder?.indexOf(index) ?? index);
      const properties = { layerIndex: index };
      const shouldVirtualize =
        layer.virtualize === undefined
          ? tooManyMapItems(layer)
          : layer.virtualize;

      const featureCollection = {
        type: layer.featureCollection.type,
        features:
          // if we're virtualizing and have a window use that
          shouldVirtualize && layer.window?.length > 0
            ? layer.window
            : orderedFilteredFeatures
                // if we are virtualising but don't have a window yet, pick the top 10
                .slice(
                  shouldVirtualize ? 0 : undefined,
                  shouldVirtualize ? 10 : undefined,
                )
                .map((feature, index) => ({ ...feature, id: index })),
      };

      switch (layer.type) {
        case 'shape':
        case 'file':
          try {
            return new VectorImageLayer({
              source: new VectorSource({
                features: new GeoJSON().readFeatures(featureCollection, {
                  featureProjection: 'EPSG:3857',
                }),
              }),
              style: new Style({
                image: new Circle({
                  radius: 4,
                  fill: new Fill({
                    color: layer.colors[0],
                  }),
                }),
                fill: new Fill({
                  color: hexToRgba(layer.colors[0], 0.5),
                }),
                stroke: new Stroke({
                  color: layer.colors[0],
                  width: 2,
                }),
              }),
              zIndex,
              properties,
            });
          } catch (_) {
            return new VectorImageLayer();
          }
        case 'bubble': {
          const tryParseFloat = (value) => {
            const parsed = parseFloat(value);
            return isNaN(parsed) ? undefined : parsed;
          };

          const distancePower = Math.min(
            10,
            Math.max(tryParseFloat(layer.distance) || 7, 2),
          );
          const distance = Math.pow(2, distancePower);

          const source = new ClusterSource({
            source: new VectorSource({
              features: new GeoJSON().readFeatures(featureCollection, {
                featureProjection: 'EPSG:3857',
              }),
            }),
            distance,
            geometryFunction: (feature) => feature?.getGeometry() || null,
          });

          // this is used to lock cluster map distances to powers of 2
          // otherwise it really jumps around as you zoom in/out
          const floorToPow2 = (value) => {
            let lastPow2 = 1;
            let pow2 = 1;
            if (value === 1) {
              return 1;
            } else if (value > 1) {
              while (pow2 < value && pow2 < Number.MAX_VALUE / 2) {
                lastPow2 = pow2;
                pow2 *= 2;
              }

              return lastPow2;
            } else {
              while (pow2 > value && pow2 > Number.MIN_VALUE * 2) {
                pow2 /= 2;
              }

              return pow2;
            }
          };

          // snap x to a grid of size s
          const snap = (x, s) => {
            return x - (x < 1 ? s + (x % s) : x % s);
          };

          // maybe improving their cluster function
          source.cluster = () => {
            if (source.resolution === undefined || !source.source) {
              return;
            }
            const extent = createEmpty();
            const mapDistance =
              source.distance * floorToPow2(source.resolution);
            const features = source.source.getFeatures();

            const clustered = {};

            for (let i = 0, ii = features.length; i < ii; i++) {
              const feature = features[i];
              if (!(getUid(feature) in clustered)) {
                const geometry = source.geometryFunction(feature);
                if (geometry) {
                  const coords = geometry.getCoordinates();
                  extent[0] = snap(coords[0], mapDistance);
                  extent[1] = snap(coords[1], mapDistance);
                  extent[2] = extent[0] + mapDistance;
                  extent[3] = extent[1] + mapDistance;

                  const neighbors = source.source
                    .getFeaturesInExtent(extent)
                    .filter(function (neighbor) {
                      const uid = getUid(neighbor);
                      if (uid in clustered) {
                        return false;
                      }
                      clustered[uid] = true;
                      return true;
                    });

                  let neighbourExtent = createEmpty();
                  neighbors.forEach((feature) => {
                    const geom = feature.getGeometry();
                    if (geom) {
                      extend(neighbourExtent, geom.getExtent());
                    }
                  });

                  let cluster = source.createCluster(neighbors, extent);
                  cluster.set('neighbourExtent', neighbourExtent, true);
                  source.features.push(cluster);

                  //source.features.push(source.createCluster(neighbors, extent));
                  // source.features.push(new Feature({
                  //   geometry: new Point(getCenter(extent)),
                  //   features: neighbors,
                  //   neighbourExtent,
                  // }));

                  // debug - show the grid
                  // let poly = new Polygon([[
                  //   [extent[0], extent[1]],
                  //   [extent[2], extent[1]],
                  //   [extent[2], extent[3]],
                  //   [extent[0], extent[3]],
                  //   [extent[0], extent[1]],
                  // ]]);
                  // source.features.push(new Feature({
                  //   geometry: poly
                  // }));
                }
              }
            }
          };

          const calculateClusterRadii = function (resolution) {
            const features = source.getFeatures();
            const maxFeatureCount = features
              .filter((f) => f.get('neighbourExtent'))
              .reduce((t, f) => Math.max(t, f.get('features')?.length || t), 0);
            const maxExtent = features
              .filter((f) => f.get('neighbourExtent'))
              .reduce(
                (t, f) =>
                  Math.max(t, Math.max(...getSize(f.get('neighbourExtent')))),
                0,
              );
            features
              .filter((f) => f.get('neighbourExtent'))
              .forEach((feature) =>
                feature.set(
                  'radius',
                  (0.5 *
                    (feature.get('features').length / maxFeatureCount) *
                    maxExtent) /
                    resolution,
                  // distance
                  // (scale * (distance * feature.get('features').length)) /
                  //   maxFeatureCount
                ),
              );
          };

          let currentResolution;
          const styleFunction = (feature, resolution) => {
            if (resolution !== currentResolution) {
              calculateClusterRadii(resolution);
              currentResolution = resolution;
            }
            let style;
            const size = feature.get('features')?.length;
            const min = 8;
            const radius = feature.get('radius');

            style = size
              ? new Style({
                  image: new Circle({
                    radius: Math.max(2, radius),
                    fill: new Fill({
                      color:
                        layer.colors[0].length === 7
                          ? layer.colors[0] + 'AA'
                          : layer.colors[0],
                    }),
                  }),
                  text:
                    radius > min
                      ? new Text({
                          text: size.toString(),
                          fill: new Fill({
                            color: '#000000',
                          }),
                          stroke: new Stroke({
                            color: '#FFFFFF',
                          }),
                        })
                      : undefined,
                })
              : new Style({
                  stroke: new Stroke({
                    color: '#3399CC',
                    width: 1.25,
                  }),
                });

            return style;
          };

          return new VectorLayer({
            source,
            style: styleFunction,
            zIndex,
            properties,
          });
        }
        case 'heat':
          return new HeatmapLayer({
            source: new VectorSource({
              features: new GeoJSON().readFeatures(featureCollection, {
                featureProjection: 'EPSG:3857',
              }),
            }),
            blur: layer.blur ? Math.max(parseInt(layer.blur), 0) : 15,
            radius: layer.radius ? Math.max(parseInt(layer.radius), 0) : 8,
            // weight: () => Math.random(), //heatWeightForCollection(featureCollection),
            weight: heatWeightForCollection(featureCollection),
            gradient:
              layer.colors && layer.colors.length > 1
                ? layer.colors
                : ['white', 'black'],
            zIndex,
            properties,
          });
        case 'area':
          return new VectorLayer({
            source: new VectorSource({
              features: new GeoJSON().readFeatures(featureCollection, {
                featureProjection: 'EPSG:3857',
              }),
            }),
            style: (feature) => {
              const quantile = feature.get('quantile');
              if (!styleCache.current[layer.colors[0]]) {
                styleCache.current[layer.colors[0]] = {};
              }

              if (!styleCache.current[layer.colors[0]][quantile]) {
                styleCache.current[layer.colors[0]][quantile] = new Style({
                  fill: new Fill({
                    color: hexToRgba(layer.colors[0], quantile),
                  }),
                  stroke: new Stroke({
                    color: hexToRgba(layer.colors[0], quantile),
                    width: 2,
                  }),
                });
              }

              return styleCache.current[layer.colors[0]][quantile];
            },
            zIndex,
            properties,
          });
        default:
          return null;
      }
    }

    const boundaryFeatureCollection = {
      type: 'FeatureCollection',
      // double the boundary lines so we can contrast dash on top
      features: layers
        .flatMap((l) => [l, l])
        .map((layer, index) => ({
          type: 'Feature',
          id: index,
          geometry:
            !layer.hidden && !layer.hideBoundary
              ? layer.boundaryGeometry
              : undefined,
          properties: {
            colors: layer.colors,
            // every second boundary will be contrast dashed
            boundaryColor: index % 2 && [
              theme.palette.getContrastText(
                layer.colors[Math.floor(layer.colors.length / 2)] ||
                  theme.palette.grey[500],
              ),
            ],
          },
        })),
    };

    boundaryLayer.current.setSource(
      new VectorSource({
        features: new GeoJSON().readFeatures(boundaryFeatureCollection, {
          featureProjection: 'EPSG:3857',
        }),
      }),
    );

    // const itemLayers = layers.map(getLayer);
    const itemLayers = layers.map(getCachedLayer);

    itemLayerGroup.current.getLayers().clear();

    if (itemLayers.length > 0) {
      itemLayerGroup.current.getLayers().extend(itemLayers);
    }

    if (cacheChange) {
      setLayerCache({ ...layerCache });
    }

    // heat layers always need a refresh with ol
    itemLayerGroup.current.getLayers().forEach((layer) => {
      if (layer instanceof HeatmapLayer) {
        layer.getSource()?.changed();
      }
    });
  }, [layers, layerOrder, layerCache, theme]);

  useEffect(() => {
    if (layerOrder) {
      itemLayerGroup.current
        .getLayers()
        .forEach((layer, index) =>
          layer.setZIndex(layerOrder.length - layerOrder.indexOf(index)),
        );
    }
  }, [layerOrder]);

  useEffectOnce(() => {
    select.current = new SelectInteraction({
      layers: itemLayerGroup.current.getLayers().getArray(),
      style: getSelectStyle,
      condition: click,
    });
    select.current.on('select', handleSelect);

    hover.current = new SelectInteraction({
      layers: itemLayerGroup.current.getLayers().getArray(),
      style: getHoverStyle,
      condition: pointerMove,
    });
    const debouncedHover = debounce(handleHover, 150);
    hover.current.on('select', debouncedHover);

    hoverSource.current = new VectorSource();
    const hoverLayer = new VectorLayer({
      source: hoverSource.current,
      style: getHoverStyle,
      zIndex: 100,
    });
    selectSource.current = new VectorSource();
    const selectLayer = new VectorLayer({
      source: selectSource.current,
      style: getSelectStyle,
      zIndex: 100,
    });

    doubleClickZoom.current = new DoubleClickZoomInteraction();

    draw.current = new DrawInteraction({
      // source: new VectorSource({wrapX: false}),
      type: 'Polygon',
    });
    draw.current.on('drawend', handleDrawEnd);

    const baseLayers = getBaseLayers(mapLayers);

    map.current = new Map({
      target: mapDiv.current,
      layers: [
        ...baseLayers,
        itemLayerGroup.current,
        hoverLayer,
        selectLayer,
        boundaryLayer.current,
      ],
      interactions: defaultInteractions({
        doubleClickZoom: false,
        pinchRotate: false,
        altShiftDragRotate: false,
      }).extend([doubleClickZoom.current, select.current, hover.current]),
      view: getMapView(),
      controls: defaultControls({
        attribution: false,
        rotate: false,
        zoom: false,
      }),
    });

    fitMap();
  });

  useEffect(() => {
    selectSource.current.clear();

    const { layerIndex, itemIndex } = selectedItemIndex;

    if (
      selectedItemIndex.itemIndex &&
      itemLayerGroup.current.getLayers().getLength() > 0 &&
      layerCache[layerIndex] &&
      !layerCache[layerIndex].hidden
    ) {
      const feature = layerCache[layerIndex].orderedFilteredFeatures[itemIndex];

      if (feature) {
        selectSource.current.addFeature(toMapFeature(feature, itemIndex));
      }
    }
  }, [selectedItemIndex, layerCache]);

  useEffect(() => {
    hoverSource.current.clear();

    const { layerIndex, itemIndex } = hoveredItemIndex;

    if (
      Number.isInteger(hoveredItemIndex.itemIndex) &&
      itemLayerGroup.current.getLayers().getLength() > 0 &&
      layerCache[layerIndex] &&
      !layerCache[layerIndex].hidden
    ) {
      const feature = layerCache[layerIndex].orderedFilteredFeatures[itemIndex];

      if (feature) {
        hoverSource.current.addFeature(toMapFeature(feature, itemIndex));
      }
    }
  }, [hoveredItemIndex, layerCache]);

  useEffect(() => {
    if (geometry !== null) {
      onDrawEnd(drawIndex, geometry);
      setGeometry(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geometry]);

  useEffect(() => {
    if (Number.isInteger(drawIndex)) {
      select.current.getFeatures().clear();
      hover.current.getFeatures().clear();
      selectSource.current.clear();
      hoverSource.current.clear();
      map.current.removeInteraction(select.current);
      map.current.removeInteraction(hover.current);
      map.current.removeInteraction(doubleClickZoom.current);
      map.current.addInteraction(draw.current);
    }
  }, [drawIndex]);

  function handleDrawEnd(event) {
    const geometry = new GeoJSON().writeGeometryObject(
      event.feature.getGeometry(),
      {
        featureProjection: 'EPSG:3857',
        rightHanded: true,
      },
    );

    setGeometry(geometry);

    setTimeout(() => {
      map.current.removeInteraction(draw.current);
      map.current.addInteraction(select.current);
      map.current.addInteraction(hover.current);
      map.current.addInteraction(doubleClickZoom.current);
    }, 0);
  }

  function fitMap() {
    try {
      if (selectedItemIndex.itemIndex) {
        map.current
          .getView()
          .fit(
            itemLayerGroup.current
              .getLayers()
              .item(parseInt(selectedItemIndex.layerIndex))
              .getSource()
              .getFeatureById(parseInt(selectedItemIndex.itemIndex))
              .getGeometry()
              .getExtent(),
            { maxZoom: maxZoom },
          );
      } else if (selectedItemIndex.layerIndex) {
        map.current
          .getView()
          .fit(
            itemLayerGroup.current
              .getLayers()
              .item(parseInt(selectedItemIndex.layerIndex))
              .getSource()
              .getExtent(),
            { maxZoom: maxZoom },
          );
      } else if (Number.isInteger(expandedLayerIndex)) {
        // if the layer has a boundaryGeometry it'll be in boundaryLayer
        // otherwise use its own features as the extent
        const boundaryGeometry = boundaryLayer.current
          ?.getSource()
          ?.getFeatureById(expandedLayerIndex)
          ?.getGeometry()
          ?.getExtent();

        const expandedLayerGeometry = itemLayerGroup.current
          ?.getLayers()
          ?.item(expandedLayerIndex)
          ?.getSource()
          ?.getExtent();

        map.current.getView().fit(boundaryGeometry || expandedLayerGeometry, {
          maxZoom: maxZoom,
        });
      } else if (
        layers.filter(
          (layer) =>
            'featureCollection' in layer || 'boundaryGeometry' in layer,
        ).length > 0
      ) {
        const itemsExtent = itemLayerGroup.current
          .getLayers()
          .getArray()
          .reduce((accumulator, layer) => {
            const source = layer.getSource();

            if (source) {
              if (accumulator) {
                accumulator = extend(accumulator, source.getExtent());
              } else {
                accumulator = source.getExtent();
              }
            }

            return accumulator;
          }, createEmpty());

        const extent = extend(
          itemsExtent,
          boundaryLayer.current.getSource().getExtent(),
        );

        map.current.getView().fit(extent, { maxZoom: maxZoom });
      } else {
        map.current
          .getView()
          .fit(
            applyTransform(mapExtent, getTransform('EPSG:4326', 'EPSG:3857')),
          );
      }
    } catch {
      map.current
        .getView()
        .fit(applyTransform(mapExtent, getTransform('EPSG:4326', 'EPSG:3857')));
    }

    if (
      zoomInDisabled !==
        (map.current.getView().getZoom() ===
          map.current.getView().getMaxZoom()) ||
      zoomOutDisabled !==
        (map.current.getView().getZoom() === map.current.getView().getMinZoom())
    ) {
      setZoomInDisabled(
        map.current.getView().getZoom() === map.current.getView().getMaxZoom(),
      );
      setZoomOutDisabled(
        map.current.getView().getZoom() === map.current.getView().getMinZoom(),
      );
    }
  }

  function handleZoomInClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() + 1);
      setZoomInDisabled(
        map.current.getView().getZoom() === map.current.getView().getMaxZoom(),
      );
      setZoomOutDisabled(
        map.current.getView().getZoom() === map.current.getView().getMinZoom(),
      );
    }
  }

  function handleZoomOutClick() {
    if (map.current) {
      map.current.getView().setZoom(map.current.getView().getZoom() - 1);
      setZoomInDisabled(
        map.current.getView().getZoom() === map.current.getView().getMaxZoom(),
      );
      setZoomOutDisabled(
        map.current.getView().getZoom() === map.current.getView().getMinZoom(),
      );
    }
  }

  function handleSelect(event) {
    // hover.current.getFeatures().clear();
    // select.current.getFeatures().clear();
    hoverSource.current.clear();
    selectSource.current.clear();

    if (event.selected.length > 0) {
      const id = event.selected[0].getId();
      const layer = select.current.getLayer(event.selected[0]);
      const layerIndex = layer.getProperties()?.layerIndex;

      if (Number.isInteger(id)) {
        setTimeout(() => {
          onSelect({
            layerIndex,
            itemIndex: id,
          });
        }, 0);
      } else {
        const features = event.selected[0].get('features');

        if (features.length === 1) {
          setTimeout(() => {
            onSelect({
              layerIndex,
              itemIndex: features[0].getId(),
            });
          }, 0);
        } else {
          const source = new VectorSource({ features });
          map.current.getView().fit(source.getExtent());
        }
      }
    } else {
      setTimeout(() => {
        onSelect({});
      }, 0);
    }
  }

  function handleHover(event) {
    if (event.selected.length > 0) {
      const id = event.selected[0].getId();

      if (Number.isInteger(id)) {
        const layer = hover.current.getLayer(event.selected[0]);

        if (layer) {
          const layerIndex = layer.getProperties()?.layerIndex;

          onHover({
            layerIndex,
            itemIndex: id,
          });
        }
      }
    } else {
      onHover({});
    }
  }

  function fitMapToGazetteerSelection(selection) {
    if (selection.extent) {
      let extent = applyTransform(
        selection.extent,
        getTransform('EPSG:4326', 'EPSG:3857'),
      );
      map.current.getView().fit(extent, fitOptions);
    }
  }

  function handleGazetteerChange(selection) {
    fitMapToGazetteerSelection(selection);
  }

  function handleGazetteerHighlightChange(selection) {
    fitMapToGazetteerSelection(selection);
  }

  return (
    <Box
      sx={{ position: 'relative', width: 1, height: 1, color: 'common.white' }}
    >
      <Box sx={{ width: 1, height: 1 }} ref={mapDiv} />
      <Stack sx={{ position: 'absolute', top: 8, left: 8 }}>
        <MapButton
          title="Zoom In"
          disabled={zoomInDisabled}
          onClick={handleZoomInClick}
        >
          <ZoomInIcon />
        </MapButton>
        <MapButton
          title="Zoom Out"
          disabled={zoomOutDisabled}
          onClick={handleZoomOutClick}
        >
          <ZoomOutIcon />
        </MapButton>
        <MapButton title="Fit" onClick={fitMap}>
          <ZoomOutMapIcon />
        </MapButton>
      </Stack>
      <Box
        sx={{
          top: 8,
          right: 240,
          left: 240,
          minWidth: 240,
          position: 'absolute',
          // justifyContent: 'center',
          // flexDirection: 'column',
          // backgroundColor: 'rgba(255,255,255,.8)',
        }}
      >
        <GazetteerSearch
          fullWidth
          onChange={handleGazetteerChange}
          onHighlightChange={handleGazetteerHighlightChange}
        />
      </Box>
      <MapLayerSwitcher mapRef={map} />
    </Box>
  );
}
