import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  startTime: null,
  endTime: null,
  users: [],
  dataTypes: [],
  actions: [],
  sorting: [{ id: 'time', desc: true }],
  pagination: {
    pageIndex: 0,
    pageSize: 25,
  },
  errors: {
    startTime: null,
    endTime: null,
  },
});

export const columns = [
  { header: 'User', accessorKey: 'user' },
  {
    header: 'Time',
    accessorKey: 'time',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  { header: 'Data Type', accessorKey: 'dataType' },
  { header: 'Action', accessorKey: 'action' },
];

export const csvColumns = columns.map(({ header, id, accessorKey }) => ({
  header,
  key: id ?? accessorKey,
}));

export const downloadPipelineFn = (
  startTime,
  endTime,
  users = [],
  dataTypes = [],
  actions = [],
) => [
  {
    $match: {
      time: { $gte: startTime, $lt: endTime },
      user: users.length > 0 ? { $in: users } : undefined,
      dataType: dataTypes.length > 0 ? { $in: dataTypes } : undefined,
      action: actions.length > 0 ? { $in: actions } : undefined,
    },
  },
  {
    $project: {
      user: true,
      dataType: true,
      time: true,
      action: true,
      parameters: true,
    },
  },
];

export function getErrorDescription(reason) {
  switch (reason) {
    case 'maxDate':
      return 'After end';
    case 'minDate':
      return 'Before start';
    case 'disableFuture':
      return 'Future';
    case 'invalidDate':
      return 'Invalid';
    default:
      return null;
  }
}
