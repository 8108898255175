import { useOptions } from '@/hooks';
import { Stack } from '@mui/material';
import { FilterField } from './FilterField';

export function PersonFilter({ value = {}, onChange, filterValues = {} }) {
  const { data: personGroups } = useOptions('personGroup');

  const handleFilterChange = (field) => (event, update) => {
    onChange({
      ...value,
      [field]: update,
    });
  };

  const handleGroupFilterChange = (field) => (event, update) => {
    onChange({
      ...value,
      groups: {
        ...value.groups,
        [field]: update,
      },
    });
  };

  return (
    <Stack spacing={1} sx={{ maxHeight: 238, overflow: 'scroll', pt: 0.75 }}>
      <FilterField
        label="Staff #"
        value={value.staffNumber}
        onChange={handleFilterChange('staffNumber')}
        options={filterValues['properties.person.code'] ?? []}
      />
      <FilterField
        label="Collar #"
        value={value.collarNumber}
        onChange={handleFilterChange('collarNumber')}
        options={filterValues['properties.person.collarNumber'] ?? []}
      />
      <FilterField
        label="Rank"
        value={value.rank}
        onChange={handleFilterChange('rank')}
        options={filterValues['properties.person.rank.code'] ?? []}
      />
      <FilterField
        label="Role"
        value={value.role}
        onChange={handleFilterChange('role')}
        options={filterValues['properties.person.role'] ?? []}
      />
      {personGroups.map(({ value: type, label }) => (
        <FilterField
          key={type}
          label={label}
          value={value.groups?.[type]}
          onChange={handleGroupFilterChange(type)}
          options={filterValues[`properties.person.groups.${type}`] ?? []}
        />
      ))}
    </Stack>
  );
}
