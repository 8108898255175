import { useDeleteTag, useDocumentTitle, useTag, useUpsertTag } from '@/hooks';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { TagForm } from './TagForm';

export function Tag() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: tag } = useTag(id);
  const { mutate: upsertTag } = useUpsertTag();
  const { mutate: deleteTag } = useDeleteTag();
  useDocumentTitle(
    ['IR3', 'Tags', tag?._id ? tag.title : 'New Tag']
      .filter(Boolean)
      .join(' | '),
  );

  function handleDelete() {
    deleteTag(id, {
      onSuccess: () => {
        navigate('..', { replace: true });
      },
    });
  }

  function handleSubmit(values) {
    upsertTag(values, {
      onSuccess: (data) => {
        navigate(`../${data.identifier}`, { replace: true });
      },
    });
  }

  return (
    <Box sx={{ flexGrow: 1, p: 1, bgcolor: 'background.paper' }}>
      <TagForm
        key={[tag?._id, tag?.lastEdit?.time]}
        defaultValues={tag}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
      />
    </Box>
  );
}
