import { NavLink, SearchBox, SortField } from '@/components/controls';
import {
  useDocumentTitle,
  useOptionLookup,
  useTelematicsBoxes,
  useUserInfo,
} from '@/hooks';
import { downloadCSV, downloadJSON, get, isAuthorised } from '@/utils';
import {
  Add as AddIcon,
  Router as RouterIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { format } from 'date-fns';
import _ from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';

export function TelematicsBoxes() {
  useDocumentTitle('IR3 | TelematicsBoxes');
  const { id } = useParams();
  const { data: telematicsBoxes } = useTelematicsBoxes();
  const telematicsBoxTypes = useOptionLookup('telematicsBoxType');
  const [searchText, setSearchText] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [sortBy, setSortBy] = useState('imei');
  const [sortDesc, setSortDesc] = useState(false);
  const [filter, setFilter] = useState({
    type: '',
    assignments: '',
  });
  const parentRef = useRef();
  const { data: userInfo } = useUserInfo();
  const canCreate = isAuthorised(userInfo?.authorisation, 'vehicles', true);

  const sortOptions = [
    { label: 'IMEI', value: 'imei' },
    { label: 'Type', value: 'type' },
    { label: 'Label', value: 'label' },
    { label: 'Last Poll Time', value: 'lastPoll.time' },
    { label: 'First Contact Time', value: 'firstContactTime' },
    { label: 'Assignments', value: 'assignmentCount' },
  ];

  const filteredList = useMemo(() => {
    return _.orderBy(
      telematicsBoxes
        .filter(
          (item) =>
            item.imei.indexOf(searchText.toLowerCase()) > -1 ||
            searchText === '',
        )
        .filter((item) => !filter.type || filter.type === item.type)
        .filter(
          (item) =>
            !filter.assignments ||
            (filter.assignments === 'none' && item.assignmentCount === 0) ||
            (filter.assignments === 'multiple' && item.assignmentCount > 1),
        ),
      [sortBy],
      [sortDesc ? 'desc' : 'asc'],
    );
  }, [telematicsBoxes, sortBy, sortDesc, searchText, filter]);

  const rowVirtualizer = useVirtualizer({
    count: filteredList.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 56,
    overscan: 10,
  });

  function handleSettingsToggle() {
    setShowSettings(!showSettings);
  }

  function handleSearchChange(event) {
    setSearchText(event.target.value);
  }

  function handleSortByChange(event) {
    setSortBy(event.target.value);
  }

  function handleSortToggle() {
    setSortDesc(!sortDesc);
  }
  const handleFilterChange = (field) => (event) => {
    setFilter({ ...filter, [field]: event.target.value });
  };

  function handleCsvClick() {
    downloadCSV(filteredList, 'telematics-boxes.csv', [
      { label: 'IMEI', key: 'imei' },
      { label: 'Type', key: 'type' },
      { label: 'Label', key: 'label' },
      { label: 'Last Poll Time', key: 'lastPoll.time' },
      { label: 'First Contact Time', key: 'firstContactTime' },
      { label: 'Assignments', key: 'assignmentCount' },
    ]);
  }

  function handleJsonClick() {
    downloadJSON(filteredList, 'telematics-boxes.json');
  }

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 48px)' }}>
      <Stack
        sx={{
          width: 280,
          borderRight: 1,
          borderColor: 'divider',
          flexShrink: 0,
          height: 1,
        }}
      >
        <Box>
          <Stack
            direction="row"
            spacing={1}
            sx={{ p: 1, alignItems: 'center' }}
          >
            <SearchBox
              value={searchText}
              onChange={handleSearchChange}
              count={`${filteredList.length}/${telematicsBoxes.length}`}
              sx={{ flexGrow: 1 }}
            />
            <Tooltip title={showSettings ? 'Hide settings' : 'Show settings'}>
              <IconButton size="small" onClick={handleSettingsToggle}>
                <SettingsIcon
                  fontSize="inherit"
                  color={showSettings ? 'primary' : 'inherit'}
                />
              </IconButton>
            </Tooltip>
            {canCreate && (
              <Tooltip title="Add new">
                <IconButton component={Link} to="new" size="small">
                  <Avatar
                    sx={{
                      color: 'secondary.contrastText',
                      backgroundColor: 'secondary.main',
                      width: 24,
                      height: 24,
                      fontSize: 16,
                    }}
                  >
                    <AddIcon fontSize="inherit" />
                  </Avatar>
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          <Collapse in={showSettings} unmountOnExit>
            <Stack sx={{ p: 1 }} spacing={1}>
              <SortField
                sortBy={sortBy}
                onSortByChange={handleSortByChange}
                sortDesc={sortDesc}
                onSortDescToggle={handleSortToggle}
                options={sortOptions}
              />
            </Stack>
            <Stack sx={{ px: 1, pb: 1 }} spacing={1}>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Filters
                </Typography>
              </Divider>
              <TextField
                size="small"
                fullWidth
                select
                label="Type"
                value={filter.type}
                onChange={handleFilterChange('type')}
              >
                <MenuItem value="">
                  <em>Any</em>
                </MenuItem>
                {Object.entries(telematicsBoxTypes).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                size="small"
                fullWidth
                select
                label="Assignments"
                value={filter.assignments}
                onChange={handleFilterChange('assignments')}
              >
                <MenuItem value="">
                  <em>Any</em>
                </MenuItem>
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="multiple">Multiple</MenuItem>
              </TextField>
              <Divider>
                <Typography variant="caption" color="textSecondary">
                  Export
                </Typography>
              </Divider>
              <Stack
                direction="row"
                // sx={{ p: 1 }}
                spacing={1}
                sx={{ justifyContent: 'center' }}
              >
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={handleCsvClick}
                >
                  CSV
                </Button>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  disableElevation
                  onClick={handleJsonClick}
                >
                  JSON
                </Button>
              </Stack>
            </Stack>
          </Collapse>
          <Divider />
        </Box>
        <Box ref={parentRef} sx={{ overflow: 'auto', height: 1 }}>
          <Box
            sx={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {rowVirtualizer.getVirtualItems().map(({ index, size, start }) => {
              const item = filteredList[index];

              function getBadgeColor(count) {
                return count === 0
                  ? 'warning'
                  : count === 1
                    ? 'success'
                    : 'error';
              }

              function getSecondaryText() {
                if (!get(item, sortBy)) {
                  return '';
                }

                switch (sortBy) {
                  case 'imei':
                  case 'assignmentCount':
                  case 'type':
                    return telematicsBoxTypes[item.type] ?? item.type;
                  case 'lastPoll.time':
                  case 'firstContactTime':
                    return format(
                      new Date(get(item, sortBy)),
                      'dd/MM/yyyy HH:mm:ss',
                    );
                  default:
                    return get(item, sortBy);
                }
              }

              return (
                <ListItemButton
                  dense
                  key={index}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${size}px`,
                    transform: `translateY(${start}px)`,
                  }}
                  component={NavLink}
                  to={`/resources/telematicsboxes/${item.imei}`}
                  selected={id === item.imei}
                >
                  <ListItemAvatar>
                    <Badge
                      showZero
                      badgeContent={item.assignmentCount}
                      color={getBadgeColor(item.assignmentCount)}
                    >
                      <Avatar>
                        <RouterIcon />
                      </Avatar>
                    </Badge>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.imei}
                    secondary={getSecondaryText()}
                  />
                </ListItemButton>
              );
            })}
          </Box>
        </Box>
      </Stack>
      <Box sx={{ overflow: 'auto', flex: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
}
