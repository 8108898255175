import { getLocations } from '@/utils';
import { geojsonToWKT } from '@terraformer/wkt';
import { format } from 'date-fns';

const locationHeaders = [
  { label: 'Code', key: 'code' },
  { label: 'Name', key: 'name' },
  { label: 'Type', key: 'type' },
  { label: 'Subtype', key: 'subtype' },
  { label: 'Start Time', key: 'startTime' },
  { label: 'End Time', key: 'endTime' },
  { label: 'Boundary WKT', key: 'boundaryWkt' },
];

export async function getLocationsAndHeaders(type, typeOptions = {}) {
  const data = await getLocations(type);

  const groupTypes = Array.from(
    new Set(
      [].concat(
        ...data.map((record) =>
          record.groupAncestors.map((group) => group.type),
        ),
      ),
    ),
  );

  const locations = data.map(
    ({ groupAncestors, boundary, startTime, endTime, ...vehicle }) => ({
      ...vehicle,
      boundaryWkt: boundary ? geojsonToWKT(boundary) : '',
      startTime: startTime
        ? format(new Date(startTime), 'dd/MM/yyyy HH:mm:ss')
        : '',
      endTime: endTime ? format(new Date(endTime), 'dd/MM/yyyy HH:mm:ss') : '',
      ...(groupAncestors || []).reduce((accumulator, group) => {
        if (!accumulator[group.type]) {
          accumulator[group.type] = [];
        }

        accumulator[group.type].push(group.name);

        return accumulator;
      }, {}),
    }),
  );

  return {
    locations,
    headers: [
      ...locationHeaders,
      ...groupTypes.map((type) => ({
        label: typeOptions[type] ?? type,
        key: type,
        type: 'text',
      })),
    ],
  };
}
