import { useAggregate } from '@/hooks';
import { Stack, Typography } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useMemo } from 'react';
import { PointsMap } from './PointsMap';
import { diagnosticColumns, diagnosticsPipelineFn } from './utils';

export function MalfunctionIndicatorLightContext({ event }) {
  const collection = 'onBoardDiagnostics';
  const pipeline = useMemo(
    () =>
      diagnosticsPipelineFn(
        event.vehicle?.identificationNumber,
        event.startTime,
        event.endTime,
      ),
    [event.vehicle?.identificationNumber, event.startTime, event.endTime],
  );
  const { data, isFetching } = useAggregate(
    collection,
    pipeline,
    event.vehicle?.identificationNumber && event.startTime && event.endTime,
    1000 * 60 * 60,
  );
  const featureCollection = useMemo(
    () => ({
      type: 'FeatureCollection',
      features:
        event?.activations?.map(({ point: geometry, time }) => ({
          type: 'Feature',
          geometry,
          properties: {
            time,
          },
        })) ?? [],
    }),
    [event],
  );

  const table = useMaterialReactTable({
    data,
    columns: diagnosticColumns,
    isFetching,
    enableStickyHeader: true,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: false,
    defaultColumn: { size: 0 },
    initialState: { density: 'compact' },
    muiTableBodyRowProps: {
      sx: { '&:last-child td, &:last-child th': { border: 0 } },
    },
    muiTableContainerProps: { sx: { maxHeight: 320 } },
  });

  return (
    <Stack spacing={2} direction="row">
      {(event?.activations ?? []).length > 0 && (
        <PointsMap pointsFeatureCollection={featureCollection} />
      )}
      <Stack spacing={1}>
        <Typography variant="subtitle2">DTCs</Typography>
        <MaterialReactTable table={table} />
      </Stack>
    </Stack>
  );
}
