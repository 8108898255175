import { PointMap } from '@/components/controls';
import { Box } from '@mui/material';

export function IdleDetail({ idle }) {
  return (
    <Box sx={{ width: 320, height: 320 }}>
      {idle.point && <PointMap point={idle.point} />}
    </Box>
  );
}
