import { get, round } from '@/utils';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { format } from 'date-fns';
import { Fragment, useState } from 'react';

function descendingComparator(a, b, orderBy) {
  if (get(b, orderBy, '') < get(a, orderBy, '')) {
    return -1;
  }
  if (get(b, orderBy, '') > get(a, orderBy, '')) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const rightAlignedTypes = ['number'];

function TableHead({
  headers,
  order,
  orderBy,
  onSort,
  numSelected,
  rowCount,
  selectMode,
  onSelectAllClick,
  data,
}) {
  const handleSortClick = (key) => (event) => {
    onSort(event, key);
  };

  return (
    <MuiTableHead>
      <TableRow>
        {selectMode === 'multi' && (
          <TableCell padding="checkbox" sx={{ bgcolor: 'background.paper' }}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headers.map((header) => (
          <TableCell
            key={header.key}
            align={
              header.align ??
              (rightAlignedTypes.includes(header.type) ? 'right' : 'left')
            }
            sortDirection={orderBy === header.key ? order : false}
            sx={
              header.type === 'expand' && data.length < 0
                ? { display: 'none' }
                : { bgcolor: 'background.paper' }
            }
          >
            {!order || header.type === 'component' ? (
              header.label
            ) : (
              <TableSortLabel
                active={orderBy === header.key}
                direction={orderBy === header.key ? order : 'asc'}
                onClick={handleSortClick(header.key)}
              >
                {header.label}
                {orderBy === header.key ? (
                  <Box
                    component="span"
                    sx={{
                      border: 0,
                      clip: 'rect(0 0 0 0)',
                      height: '1px',
                      m: '-1px',
                      overflow: 'hidden',
                      p: 0,
                      position: 'absolute',
                      top: 20,
                      width: '1px',
                    }}
                  >
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
}

function Value({ header, row }) {
  if (header.type !== 'component' && get(row, header.key) === undefined) {
    return '';
  }

  switch (header.type) {
    case 'text': {
      const value = get(row, header.key);

      return header.format
        ? header.format(value)
        : Array.isArray(value)
          ? value.join(', ')
          : value;
    }
    case 'number':
      return header.format
        ? header.format(get(row, header.key))
        : round(get(row, header.key), 2);
    case 'date':
      return header.format
        ? header.format(get(row, header.key))
        : format(new Date(get(row, header.key)), 'dd/MM/yyyy HH:mm:ss');
    case 'dateonly':
      return header.format
        ? header.format(get(row, header.key))
        : format(new Date(get(row, header.key)), 'dd/MM/yyyy');
    case 'boolean':
      return header.format
        ? header.format(get(row, header.key))
        : get(row, header.key)
          ? 'Yes'
          : 'No';
    case 'component':
      return header.component ? <header.component entry={row} /> : '';
    default:
      return '';
  }
}

function Row({ row, headers, selectMode, onClick, keyName, selected }) {
  const [expandedKey, setExpandedKey] = useState(null);

  const handleClick = (key) => () => {
    onClick(key);
  };

  const Expanded = expandedKey
    ? headers.find((h) => h.key === expandedKey).component
    : () => '';

  const selectableProps = selectMode
    ? {
        hover: true,
        onClick: handleClick(keyName ? row[keyName] : row),
        role: 'checkbox',
        tabIndex: -1,
        selected,
      }
    : {};

  return (
    <Fragment>
      <TableRow {...selectableProps}>
        {selectMode === 'multi' && (
          <TableCell padding="checkbox" sx={{ borderBottom: 'unset' }}>
            <Checkbox checked={selected} />
          </TableCell>
        )}
        {headers.map((header) => (
          <TableCell
            sx={{ borderBottom: 'unset' }}
            key={header.key}
            align={
              header.align ??
              (rightAlignedTypes.includes(header.type) ? 'right' : 'left')
            }
          >
            {header.type === 'expand' ? (
              <IconButton
                size="small"
                onClick={() => {
                  if (expandedKey === header.key) {
                    setExpandedKey(null);
                  } else {
                    setExpandedKey(header.key);
                  }
                }}
              >
                {expandedKey === header.key
                  ? header.closeIcon || <KeyboardArrowUpIcon />
                  : header.openIcon || <KeyboardArrowDownIcon />}
              </IconButton>
            ) : (
              <Value header={header} row={row} />
            )}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headers.length + (selectMode === 'multi' ? 1 : 0)}
        >
          <Collapse in={expandedKey !== null} timeout="auto" unmountOnExit>
            <Expanded entry={row} />
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export function Table({
  data,
  headers,
  totals,
  page,
  rowsPerPage,
  selectMode,
  classes = {},
  styles,
  keyName,
  selectedKeys = [],
  onSelectAllClick,
  onSelectClick,
  order,
  orderBy,
  onOrderChange,
  onOrderByChange,
  disableSticky,
}) {
  function handleSort(event, key) {
    if (key === orderBy) {
      onOrderChange(order === 'asc' ? 'desc' : 'asc');
    } else {
      onOrderByChange(key);
    }
  }

  const headerProps =
    selectMode === 'multi'
      ? {
          numSelected: selectedKeys.length,
          rowCount: data.length,
          onSelectAllClick: onSelectAllClick,
        }
      : {};

  return (
    <TableContainer
      style={styles?.tableContainer}
      className={classes.tableContainer}
    >
      <MuiTable
        size="small"
        style={{
          borderCollapse: 'separate',
          ...styles?.table,
        }}
        className={classes.table}
        stickyHeader={disableSticky ? false : true}
      >
        <TableHead
          headers={headers}
          order={order}
          orderBy={orderBy}
          onSort={handleSort}
          selectMode={selectMode}
          data={data}
          {...headerProps}
        />
        <TableBody>
          {stableSort(data, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const selectableProps = selectMode
                ? {
                    selectMode,
                    keyName: keyName,
                    selected: selectedKeys.includes(row[keyName]),
                    onClick: onSelectClick,
                  }
                : {};
              return (
                <Row
                  key={row[keyName] || index}
                  row={row}
                  headers={headers}
                  {...selectableProps}
                />
              );
            })}
          <TableRow />
        </TableBody>
        {data.length > 0 && totals && (
          <TableFooter>
            <TableRow>
              {headers.map((header, index) => {
                if (index === 0) {
                  return (
                    <Fragment key={index}>
                      <TableCell sx={{ position: 'sticky', left: 0 }}>
                        Totals
                      </TableCell>
                      {selectMode === 'multi' && <TableCell />}
                    </Fragment>
                  );
                } else {
                  return (
                    <TableCell key={index} align="right">
                      {header.format
                        ? header.format(get(totals, header.key))
                        : get(totals, header.key)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableFooter>
        )}
      </MuiTable>
    </TableContainer>
  );
}
