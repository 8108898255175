import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  sorting: [{ id: 'time', desc: false }],
  pagination: {
    pageIndex: 0,
    pageSize: 25,
  },
  query: {},
  parameters: {},
});

export const pipelineFn = (pagination, query, sorting) =>
  [
    { $match: query },
    {
      $project: {
        identifier: true,
        area: true,
        class: true,
        code: true,
        time: true,
        isConfirmed: true,
        description: true,
        vehicle: true,
      },
    },
    sorting.length > 0 && {
      $sort: sorting.reduce(
        (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
        {},
      ),
    },
    { $skip: pagination.pageIndex * pagination.pageSize },
    { $limit: pagination.pageSize },
  ].filter(Boolean);

export const totalsPipelineFn = (query) => [
  { $match: query },
  {
    $group: {
      _id: null,
      total: { $sum: 1 },
    },
  },
  { $unset: '_id' },
];

export const downloadPipelineFn = (query) => [
  { $match: query },
  {
    $project: {
      identifier: true,
      area: true,
      class: true,
      code: true,
      time: true,
      isConfirmed: { $cond: ['$isConfirmed', 'Yes', 'No'] },
      description: true,
      vehicle: true,
    },
  },
];

export const columns = [
  {
    header: 'Registration',
    id: 'vehicle.registrationNumber',
    accessorFn: ({ vehicle: { registrationNumber } = {} } = {}) =>
      registrationNumber ?? '',
  },
  {
    header: 'Fleet Number',
    id: 'vehicle.fleetNumber',
    accessorFn: ({ vehicle: { fleetNumber } = {} } = {}) => fleetNumber ?? '',
  },
  {
    header: 'Role',
    id: 'vehicle.role',
    accessorFn: ({ vehicle: { role } = {} } = {}) => role ?? '',
  },
  {
    header: 'Division',
    id: 'vehicle.groups.division',
    accessorFn: ({ vehicle: { groups: { division } = {} } = {} } = {}) =>
      division ?? '',
  },
  {
    header: 'Type',
    id: 'vehicle.type',
    accessorFn: ({ vehicle: { type } = {} } = {}) => type ?? '',
  },
  {
    header: 'Time',
    accessorKey: 'time',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Confirmed',
    id: 'isConfirmed',
    accessorFn: ({ isConfirmed }) => (isConfirmed ? 'Yes' : 'No'),
  },
  {
    header: 'Class',
    id: 'class',
    accessorFn: ({ class: value }) => value ?? '',
  },
  {
    header: 'Code',
    id: 'code',
    accessorFn: ({ code }) => code ?? '',
  },
  {
    header: 'Area',
    id: 'area',
    accessorFn: ({ area }) => area ?? '',
  },
  {
    header: 'Description',
    id: 'description',
    accessorFn: ({ description }) => description ?? '',
  },
];
