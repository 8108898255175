import { Divider, Stack, Typography } from '@mui/material';
import { Fragment } from 'react';

export function DetailsPanel({ title, entries, rightHeader }) {
  return (
    <Stack>
      {(title || rightHeader) && (
        <Stack
          spacing={1}
          direction="row"
          sx={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          {title && (
            <Typography variant="caption" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          )}
          {rightHeader}
        </Stack>
      )}
      <Stack
        spacing={0.5}
        sx={{
          px: 1,
          py: 0.5,
          border: 1,
          borderColor: 'divider',
          borderRadius: 1,
          flexGrow: 1,
        }}
      >
        {entries.map(({ label, value }, index) => (
          <Fragment key={index}>
            {index > 0 && <Divider />}
            <Stack
              key={label}
              spacing={1}
              direction="row"
              sx={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography
                noWrap
                variant="caption"
                sx={{ color: 'text.secondary' }}
              >
                {label}
              </Typography>
              {typeof value === 'string' || typeof value === 'number' ? (
                <Typography noWrap variant="caption">
                  {value}
                </Typography>
              ) : (
                value
              )}
            </Stack>
          </Fragment>
        ))}
      </Stack>
    </Stack>
  );
}
