import { api } from '@/apis';
import { isEmpty, log } from '@/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function usePersonDailyActivities(
  match,
  days,
  offsets = ['all'],
  groupBy,
) {
  const queryKey = ['personDailyActivities', match, days, offsets, groupBy];
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      const json = [
        {
          $match: {
            ...match,
            ...(days
              ? {
                  $expr: {
                    $in: [{ $dayOfWeek: '$time' }, days.map((day) => day + 1)],
                  },
                }
              : {}),
          },
        },
        {
          $project: {
            time: true,
            person: true,
            loggedInSeconds: {
              $sum: offsets.map(
                (offset) => `$loggedIn.${offset}.durationSeconds`,
              ),
            },
            baseSeconds: {
              $sum: offsets.map((offset) => `$visiting.${offset}.baseSeconds`),
            },
            respondingSeconds: {
              $sum: offsets.map(
                (offset) => `$responding.${offset}.durationSeconds`,
              ),
            },
            attendingSeconds: {
              $sum: offsets.map(
                (offset) => `$attending.${offset}.durationSeconds`,
              ),
            },
            drivingSeconds: {
              $sum: offsets.map(
                (offset) => `$driving.${offset}.durationSeconds`,
              ),
            },
            homeWardSeconds: {
              $sum: offsets.map(
                (offset) => `$visiting.${offset}.homeWardSeconds`,
              ),
            },
            doubleCrewingSeconds: {
              $sum: offsets.map(
                (offset) => `$doubleCrewing.${offset}.durationSeconds`,
              ),
            },
            distanceKilometres: {
              $sum: offsets.map(
                (offset) => `$driving.${offset}.distanceKilometres`,
              ),
            },
            baseVisits: {
              $sum: offsets.map((offset) => `$visiting.${offset}.baseStarts`),
            },
          },
        },
        (groupBy ?? '').toString().startsWith('$person.groups.') && {
          $unwind: groupBy,
        },
        {
          $group: {
            _id: groupBy,
            codes: { $addToSet: '$person.code' },
            loggedInSeconds: { $avg: '$loggedInSeconds' },
            baseSeconds: { $avg: '$baseSeconds' },
            respondingSeconds: { $avg: '$respondingSeconds' },
            attendingSeconds: { $avg: '$attendingSeconds' },
            drivingSeconds: { $avg: '$drivingSeconds' },
            homeWardSeconds: { $avg: '$homeWardSeconds' },
            doubleCrewingSeconds: { $avg: '$doubleCrewingSeconds' },
            totalKilometres: { $sum: '$distanceKilometres' },
            dailyKilometres: { $avg: '$distanceKilometres' },
            totalBaseVisits: { $sum: '$baseVisits' },
            dailyBaseVisits: { $avg: '$baseVisits' },
          },
        },
        {
          $project: {
            _id: false,
            group: '$_id',
            resourceCount: { $size: '$codes' },
            loggedInHours: {
              $round: [{ $divide: ['$loggedInSeconds', 3600] }, 2],
            },
            baseHours: {
              $round: [{ $divide: ['$baseSeconds', 3600] }, 2],
            },
            respondingHours: {
              $round: [{ $divide: ['$respondingSeconds', 3600] }, 2],
            },
            attendingHours: {
              $round: [{ $divide: ['$attendingSeconds', 3600] }, 2],
            },
            drivingHours: {
              $round: [{ $divide: ['$drivingSeconds', 3600] }, 2],
            },
            homeWardHours: {
              $round: [{ $divide: ['$homeWardSeconds', 3600] }, 2],
            },
            doubleCrewingHours: {
              $round: [{ $divide: ['$doubleCrewingSeconds', 3600] }, 2],
            },
            totalMiles: {
              $round: [{ $multiply: ['$totalKilometres', 0.62137119] }, 2],
            },
            averageMiles: {
              $round: [
                {
                  $divide: [
                    { $multiply: ['$totalKilometres', 0.62137119] },
                    { $size: '$codes' },
                  ],
                },
                2,
              ],
            },
            dailyMiles: {
              $round: [{ $multiply: ['$dailyKilometres', 0.62137119] }, 2],
            },
            totalBaseVisits: { $round: ['$totalBaseVisits', 2] },
            averageBaseVisits: {
              $round: [
                { $divide: ['$totalBaseVisits', { $size: '$codes' }] },
                2,
              ],
            },
            dailyBaseVisits: { $round: ['$dailyBaseVisits', 2] },
          },
        },
      ].filter(Boolean);

      log('Read', 'Person Daily Activities', {
        match,
        days,
        offsets,
        groupBy,
      });

      return api
        .post('pipeline/personDailyActivities', { json, signal })
        .json();
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !isEmpty(match),
  });

  return {
    ...query,
    cancel: () => queryClient.cancelQueries({ queryKey }),
  };
}
