import { useInterval } from '@/hooks';
import {
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  SkipNext as SkipNextIcon,
  SkipPrevious as SkipPreviousIcon,
} from '@mui/icons-material';
import {
  FormLabel,
  IconButton,
  Slider,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { addSeconds, differenceInSeconds, format } from 'date-fns';
import { useState } from 'react';

const MAX_FPS = 30;
export function EventReplayControls({ onTick, start, end, frames }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(1.0);
  const [seconds, setSeconds] = useState(0);

  const totalSeconds = start && end ? differenceInSeconds(end, start) : 1;
  const time = start ? addSeconds(start, seconds) : new Date();

  const marks = (frames || []).map((frame) => {
    return {
      value: differenceInSeconds(frame.time, start),
      label: '', //format(time, 'HH:mm:ss'),
    };
  });

  useInterval(advanceReplayFrame, isPlaying ? 1000 / MAX_FPS : null);

  function advanceReplayFrame() {
    if (time >= new Date(end)) {
      setIsPlaying(false);
    } else {
      const newSeconds = seconds + speed / MAX_FPS;
      setSeconds(newSeconds);
      onTick(~~newSeconds); // ticks are in whole seconds so floor this value
    }
  }

  function handlePlayPauseClick() {
    if (time >= new Date(end) && !isPlaying) {
      setSeconds(0);
      onTick(0);
    }
    setIsPlaying(!isPlaying);
  }

  function handleFpsChange(event) {
    const value = event.target.value;
    const newSpeed =
      value === '' ? value : isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    setSpeed(newSpeed);
  }

  function handleSliderChange(event, value) {
    setSeconds(value);
    onTick(value);
  }

  function handleSkipNextClick() {
    const nextChangeSeconds =
      marks.find((m) => m.value > seconds)?.value ?? seconds;
    setSeconds(nextChangeSeconds);
    onTick(nextChangeSeconds);
  }

  function handleSkipPreviousClick() {
    const prevChangeSeconds =
      marks.reverse().find((m) => m.value < seconds)?.value ?? seconds;
    setSeconds(prevChangeSeconds);
    onTick(prevChangeSeconds);
  }

  return (
    <Toolbar sx={{ pl: 0 }} variant="dense">
      <IconButton onClick={handleSkipPreviousClick} size="large">
        <SkipPreviousIcon />
      </IconButton>
      <IconButton onClick={handlePlayPauseClick} size="large">
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
      <IconButton onClick={handleSkipNextClick} size="large">
        <SkipNextIcon />
      </IconButton>
      <FormLabel>
        <Typography variant="caption">Speed</Typography>
      </FormLabel>
      <TextField
        size="small"
        variant="standard"
        type="number"
        margin="dense"
        id="fps"
        sx={{
          p: 1,
          width: 70,
          minWidth: 70,
          '&::before': {
            content: `"${String.fromCharCode(10005)}"`,
            position: 'absolute',
            top: '6px',
            transition: 'all .05s ease-in-out',
          },
        }}
        defaultValue={speed}
        onChange={handleFpsChange}
        slotProps={{
          htmlInput: {
            sx: { textAlign: 'right', fontSize: '0.80rem' },
            max: 100,
            min: 1,
          },
        }}
      />
      <Typography variant="caption" sx={{ minWidth: 140 }}>
        {start
          ? format(addSeconds(new Date(start), seconds), 'dd/MM/yyyy HH:mm:ss')
          : ''}
      </Typography>
      <Slider
        sx={{
          '& .MuiSlider-mark': {
            bgcolor: '#bfbfbf',
            height: 6,
            width: '1px',
            '&.MuiSlider-markActive': {
              opacity: 1,
              bgcolor: 'currentColor',
            },
          },
        }}
        value={seconds}
        step={1}
        min={0}
        max={totalSeconds}
        onChange={handleSliderChange}
        marks={marks}
      />
    </Toolbar>
  );
}
