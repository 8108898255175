import {
  useDeleteVehicle,
  useDocumentTitle,
  useLocations,
  useOptions,
  useOptionValues,
  useTelematicsBoxes,
  useUpsertVehicle,
  useUserInfo,
  useVehicle,
  useVehicles,
} from '@/hooks';
import { isAuthorised } from '@/utils';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { VehicleForm } from './VehicleForm';

export function Vehicle() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: vehicles } = useVehicles();
  const { data: vehicle } = useVehicle(id);
  const { data: telematicsBoxes } = useTelematicsBoxes();
  const { mutate: upsertVehicle } = useUpsertVehicle();
  const { mutate: deleteVehicle } = useDeleteVehicle();
  const { data: homeStationNames } = useLocations({ homeStations: true });
  const typeOptions = useOptionValues('groupType');
  const vehicleGroups = useOptionValues('vehicleGroup');
  const { data: vehicleRoles } = useOptions('vehicleRole');
  const { data: vehicleTypes } = useOptions('vehicleType');
  const { data: vehicleFuelTypes } = useOptions('vehicleFuelType');
  const { data: vehicleEquipment } = useOptions('vehicleEquipment');
  const { data: activeDirectoryGroups } = useOptions('activeDirectoryGroup');
  const { data: vehicleAttributes } = useOptions('vehicleAttribute');
  const { data: userInfo } = useUserInfo();
  const canEdit = isAuthorised(userInfo?.authorisation, 'vehicles', true);
  const installLocations = useMemo(
    () =>
      [
        ...new Set(
          vehicles
            .map((item) => item.installLocation)
            .filter((value) => typeof value === 'string'),
        ),
      ]
        .sort()
        .map((item) => ({ label: item, value: item })),
    [vehicles],
  );
  const driverIdLocations = useMemo(
    () =>
      [
        ...new Set(
          vehicles
            .map((item) => item.driverIdLocation)
            .filter((value) => typeof value === 'string')
            .concat('Not installed'),
        ),
      ]
        .sort()
        .map((item) => ({ label: item, value: item })),
    [vehicles],
  );
  const imeis = useMemo(
    () =>
      [
        ...new Set(
          telematicsBoxes
            .map((box) => box.imei)
            .filter((value) => typeof value === 'string'),
        ),
      ]
        .sort()
        .map((item) => ({ label: item, value: item })),
    [telematicsBoxes],
  );
  useDocumentTitle(
    `IR3 | Vehicles | ${
      vehicle?._id ? vehicle.registrationNumber : 'New Vehicle'
    }`,
  );

  function handleDelete() {
    deleteVehicle(id, {
      onSuccess: () => {
        navigate('..', { replace: true });
      },
    });
  }

  function handleSubmit(values) {
    upsertVehicle(values, {
      onSuccess: (data) => {
        navigate(`../${data.identificationNumber}`, { replace: true });
      },
    });
  }

  return (
    <Box sx={{ flexGrow: 1, p: 1, bgcolor: 'background.paper' }}>
      <VehicleForm
        key={[vehicle?._id, vehicle?.lastEdit?.time]}
        defaultValues={vehicle}
        canEdit={canEdit}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        homeStationNames={homeStationNames}
        typeOptions={typeOptions}
        installLocations={installLocations}
        driverIdLocations={driverIdLocations}
        imeis={imeis}
        vehicleAttributes={vehicleAttributes}
        vehicleRoles={vehicleRoles}
        vehicleTypes={vehicleTypes}
        vehicleFuelTypes={vehicleFuelTypes}
        vehicleEquipment={vehicleEquipment}
        vehicleGroups={vehicleGroups}
        activeDirectoryGroups={activeDirectoryGroups}
      />
    </Box>
  );
}
