import { shortHumanizer } from '@/utils';
import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  sorting: [{ id: 'startTime', desc: false }],
  pagination: {
    pageIndex: 0,
    pageSize: 25,
  },
  query: {},
  parameters: {},
});

export const pipelineFn = (pagination, query, sorting) =>
  [
    { $match: query },
    {
      $project: {
        _id: false,
        tripIdentifier: '$parentEvent.identifier',
        activation: {
          point: '$point',
          time: '$time',
        },
      },
    },
    {
      $group: {
        _id: '$tripIdentifier',
        activations: { $push: '$activation' },
      },
    },
    {
      $lookup: {
        from: 'trips',
        localField: '_id',
        foreignField: 'identifier',
        pipeline: [
          {
            $project: {
              _id: false,
              vehicle: {
                identificationNumber: true,
                registrationNumber: true,
                fleetNumber: true,
                role: true,
                type: true,
              },
              startTime: true,
              endTime: true,
              durationSeconds: true,
            },
          },
        ],
        as: 'trip',
      },
    },
    { $set: { trip: { $first: '$trip' } } },
    {
      $project: {
        _id: false,
        tripIdentifier: '$_id',
        vehicle: '$trip.vehicle',
        startTime: '$trip.startTime',
        endTime: '$trip.endTime',
        durationSeconds: '$trip.durationSeconds',
        activations: true,
      },
    },
    sorting.length > 0 && {
      $sort: sorting.reduce(
        (acc, { id, desc }) => ({ ...acc, [id]: desc ? -1 : 1 }),
        {},
      ),
    },
    { $skip: pagination.pageIndex * pagination.pageSize },
    { $limit: pagination.pageSize },
  ].filter(Boolean);

export const totalsPipelineFn = (query) => [
  { $match: query },
  {
    $group: {
      _id: '$parentEvent.identifier',
      activations: { $sum: 1 },
    },
  },
  {
    $lookup: {
      from: 'trips',
      localField: '_id',
      foreignField: 'identifier',
      pipeline: [
        {
          $project: {
            _id: false,
            durationSeconds: true,
          },
        },
      ],
      as: 'trip',
    },
  },
  { $set: { trip: { $first: '$trip' } } },
  {
    $group: {
      _id: null,
      total: { $sum: 1 },
      durationSeconds: { $sum: '$trip.durationSeconds' },
      activations: { $sum: '$activations' },
    },
  },
];

export const downloadPipelineFn = (query) => [
  { $match: query },
  {
    $project: {
      _id: false,
      tripIdentifier: '$parentEvent.identifier',
    },
  },
  {
    $group: {
      _id: '$tripIdentifier',
      activations: { $sum: 1 },
    },
  },
  {
    $lookup: {
      from: 'trips',
      localField: '_id',
      foreignField: 'identifier',
      pipeline: [
        {
          $project: {
            _id: false,
            vehicle: {
              registrationNumber: true,
              fleetNumber: true,
              role: true,
              type: true,
            },
            startTime: true,
            endTime: true,
            durationSeconds: true,
          },
        },
      ],
      as: 'trip',
    },
  },
  { $set: { trip: { $first: '$trip' } } },
  {
    $project: {
      _id: false,
      vehicle: '$trip.vehicle',
      startTime: '$trip.startTime',
      endTime: '$trip.endTime',
      durationSeconds: { $divide: ['$trip.durationSeconds', 86400] },
      activations: true,
      link: {
        $concat: [`${window.location.origin}/eventreplay/trips/`, '$_id'],
      },
    },
  },
];

export const columnsFn = (footer) => [
  {
    header: 'Registration',
    id: 'vehicle.registrationNumber',
    accessorFn: ({ vehicle: { registrationNumber } = {} } = {}) =>
      registrationNumber ?? '',
  },
  {
    header: 'Fleet Number',
    id: 'vehicle.fleetNumber',
    accessorFn: ({ vehicle: { fleetNumber } = {} } = {}) => fleetNumber ?? '',
  },
  {
    header: 'Role',
    id: 'vehicle.role',
    accessorFn: ({ vehicle: { role } = {} } = {}) => role ?? '',
  },
  {
    header: 'Type',
    id: 'vehicle.type',
    accessorFn: ({ vehicle: { type } = {} } = {}) => type ?? '',
  },
  {
    header: 'Start Time',
    accessorKey: 'startTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'End Time',
    accessorKey: 'endTime',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Duration',
    accessorKey: 'durationSeconds',
    Cell: ({ cell }) =>
      Number.isNaN(cell.getValue())
        ? ''
        : shortHumanizer(cell.getValue() * 1000),
    footer: Number.isNaN(footer.durationSeconds)
      ? ''
      : shortHumanizer(footer.durationSeconds * 1000),
  },
  {
    header: 'Activations',
    accessorKey: 'activations',
    Cell: ({ cell }) => cell.getValue().length,
    footer: footer.activations,
  },
];

export const diagnosticsPipelineFn = (
  identificationNumber,
  startTime,
  endTime,
) => [
  {
    $match: {
      'vehicle.identificationNumber': identificationNumber,
      time: { $gte: startTime, $lte: endTime },
    },
  },
  {
    $project: {
      _id: false,
      time: true,
      isConfirmed: true,
      class: true,
      code: true,
      area: true,
      description: true,
    },
  },
  {
    $sort: { time: 1 },
  },
];

export const diagnosticColumns = [
  {
    header: 'Time',
    accessorKey: 'time',
    Cell: ({ cell }) => format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss'),
  },
  {
    header: 'Confirmed',
    id: 'isConfirmed',
    accessorFn: ({ isConfirmed }) => (isConfirmed ? 'Yes' : 'No'),
  },
  {
    header: 'Class',
    id: 'class',
    accessorFn: ({ class: value }) => value ?? '',
  },
  {
    header: 'Code',
    id: 'code',
    accessorFn: ({ code }) => code ?? '',
  },
  {
    header: 'Area',
    id: 'area',
    accessorFn: ({ area }) => area ?? '',
  },
  {
    header: 'Description',
    id: 'description',
    accessorFn: ({ description }) => description ?? '',
  },
];
