import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers';

export function DateTimePicker({
  value,
  minDate,
  maxDate,
  error,
  helperText,
  clearable,
  className,
  sx,
  ...props
}) {
  return (
    <MuiDateTimePicker
      {...props}
      value={value ? new Date(value) : null}
      minDate={minDate ? new Date(minDate) : null}
      maxDate={maxDate ? new Date(maxDate) : null}
      className={className}
      sx={sx}
      ampm={false}
      views={['year', 'month', 'day', 'hours', 'minutes']}
      slotProps={{
        textField: { size: 'small', error, helperText },
        field: { clearable },
      }}
    />
  );
}
