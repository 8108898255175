import { useOptionValues, useUpdateOption } from '@/hooks';
import {
  AddCircle as AddIcon,
  PlaylistAdd as PlaylistAddIcon,
  RemoveCircle as RemoveIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { ManageTypeValuesDialog } from './ManageTypeValuesDialog';

export function AliasPicker({ value, onChange, disabled }) {
  const aliasTypes = useOptionValues('aliasType');
  const { mutate: updateOption } = useUpdateOption();
  const [manageType, setManageType] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleValuesChange = (type) => (event, selections) => {
    onChange({
      ...value,
      [type]: selections,
    });
  };

  const handleDeleteClick = (type) => () => {
    const { [type]: _, ...newValue } = value;
    onChange({ ...newValue });
  };

  const handleAddMenuClicked = (type) => () => {
    onChange({ ...value, [type]: [] });
    setAnchorEl(null);
  };

  function handleAddClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleAddMenuClose() {
    setAnchorEl(null);
  }

  const handleManageClick = (type) => () => {
    setManageType(type);
  };

  function handleManageClose(values) {
    if (values) {
      updateOption({ name: 'aliasType', value: manageType, values });
    }
    setManageType(null);
  }

  return (
    <Box sx={{ width: 1 }}>
      {Object.entries(value).map(([type, values]) => (
        <Box sx={{ display: 'flex', pb: 1 }} key={type}>
          <Autocomplete
            fullWidth
            size="small"
            multiple
            options={[...(aliasTypes[type]?.values ?? [])].sort()}
            value={values}
            onChange={handleValuesChange(type)}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                label={aliasTypes[type]?.label ?? type}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    startAdornment: (
                      <Fragment>
                        <InputAdornment position="end">
                          <Tooltip title="Manage values">
                            <IconButton
                              onClick={handleManageClick(type)}
                              edge="start"
                              size="small"
                            >
                              <PlaylistAddIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </Fragment>
                    ),
                  },
                }}
              />
            )}
          />
          {!disabled && (
            <Tooltip title="Remove">
              <IconButton
                sx={{ ml: 1 }}
                onClick={handleDeleteClick(type)}
                size="small"
              >
                <RemoveIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ))}
      {(Object.keys(aliasTypes) || []).filter(
        (type) => !(type in (value || {})),
      ).length > 0 && (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Add">
            <Box component="span">
              <IconButton
                onClick={handleAddClick}
                size="small"
                disabled={disabled}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleAddMenuClose}
          >
            {(Object.entries(aliasTypes) || [])
              .filter(([type]) => !(type in (value || {})))
              .sort((a, b) => a[1].label.localeCompare(b[1].label))
              .map(([code, { label }]) => (
                <MenuItem
                  key={code}
                  onClick={handleAddMenuClicked(code)}
                  value={code}
                >
                  {label}
                </MenuItem>
              ))}
          </Menu>
        </Box>
      )}
      {manageType && (
        <ManageTypeValuesDialog
          open={!!manageType}
          name={aliasTypes[manageType]?.label ?? manageType}
          initialValues={aliasTypes[manageType]?.values || []}
          onClose={handleManageClose}
        />
      )}
    </Box>
  );
}
