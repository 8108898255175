import {
  ADD_TAG_ITEM,
  ADD_TAG_ITEM_FAILURE,
  ADD_TAG_ITEM_SUCCESS,
  CREATE_TAG,
  CREATE_TAG_FAILURE,
  CREATE_TAG_SUCCESS,
  DELETE_TAG,
  DELETE_TAG_FAILURE,
  DELETE_TAG_ITEM,
  DELETE_TAG_ITEM_FAILURE,
  DELETE_TAG_ITEM_SUCCESS,
  DELETE_TAG_SUCCESS,
  FETCH_TAG,
  FETCH_TAGS,
  FETCH_TAGS_FAILURE,
  FETCH_TAGS_SUCCESS,
  FETCH_TAG_FAILURE,
  FETCH_TAG_SUCCESS,
  UPDATE_TAG,
  UPDATE_TAG_FAILURE,
  UPDATE_TAG_SUCCESS,
} from '@/actions';
import { api } from '@/apis';
import { encodeParams, getHeaders, log } from '@/utils';
import _ from 'lodash';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

async function fetchTagsRequest() {
  const response = await api
    .get('tags', {
      searchParams: encodeParams({
        projection: {
          identifier: true,
          title: true,
          type: true,
          description: true,
          items: true,
          created: true,
        },
        sort: { type: 1, title: 1 },
      }),
    })
    .json();

  return response;
}

export function fetchTagsEpic(action$) {
  return action$.pipe(
    ofType(FETCH_TAGS),
    mergeMap(() =>
      from(fetchTagsRequest()).pipe(
        map((payload) => ({
          type: FETCH_TAGS_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_TAGS_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function fetchTagRequest(id) {
  const response = await api
    .get(`tags/${id}`, {
      searchParams: encodeParams({
        projection: {
          identifier: true,
          description: true,
          created: true,
          lastEdit: true,
          items: true,
        },
      }),
    })
    .json();

  log('Read', 'Tags', { id });

  return response;
}

export function fetchTagEpic(action$) {
  return action$.pipe(
    ofType(FETCH_TAG),
    mergeMap(({ payload: id }) =>
      from(fetchTagRequest(id)).pipe(
        map((payload) => ({
          type: FETCH_TAG_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: FETCH_TAG_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function createTagRequest(values, navigate) {
  const response = await api.post('tags', { json: values }).json();

  // redirect when tag created via tag link
  if (!_.has(values, 'items')) {
    navigate(`../${response.identifier}`, {
      replace: true,
      state: { created: true },
    });
  }

  return response;
}

export function createTagEpic(action$) {
  return action$.pipe(
    ofType(CREATE_TAG),
    mergeMap(({ payload: values, navigate }) =>
      from(createTagRequest(values, navigate)).pipe(
        map((payload) => ({
          type: CREATE_TAG_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: CREATE_TAG_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function updateTagRequest(values) {
  await api.patch(`tags/${encodeURIComponent(values.identifier)}`, values, {
    headers: {
      ...getHeaders(),
      'content-type': 'application/merge-patch+json',
    },
  });

  return values;
}

export function updateTagEpic(action$) {
  return action$.pipe(
    ofType(UPDATE_TAG),
    mergeMap(({ payload: values }) =>
      from(updateTagRequest(values)).pipe(
        map((payload) => ({
          type: UPDATE_TAG_SUCCESS,
          payload,
        })),
        catchError(({ message: payload }) =>
          of({
            type: UPDATE_TAG_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function deleteTagRequest(id, navigate) {
  await api.delete(`tags/${id}`, {
    searchParams: encodeParams({
      permanent: true,
    }),
  });

  navigate('..', { replace: true });

  return id;
}

export function deleteTagEpic(action$) {
  return action$.pipe(
    ofType(DELETE_TAG),
    mergeMap(({ payload: id, navigate }) =>
      from(deleteTagRequest(id, navigate)).pipe(
        map((payload) => ({
          type: DELETE_TAG_SUCCESS,
          payload: decodeURIComponent(payload),
        })),
        catchError(({ message: payload }) =>
          of({
            type: DELETE_TAG_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function addTagItemRequest(id, item, value) {
  const response = await api
    .patch(`tags/${id}/${item}/${value}`, {
      headers: {
        'content-type': 'application/merge-patch+json',
      },
    })
    .json();

  return response;
}

export function addTagTypeItemEpic(action$) {
  return action$.pipe(
    ofType(ADD_TAG_ITEM),
    mergeMap(({ payload: { id, item, value, type } }) =>
      from(addTagItemRequest(id, item, value)).pipe(
        map(() => ({
          type: ADD_TAG_ITEM_SUCCESS,
          payload: { id, type, value },
        })),
        catchError(({ message: payload }) =>
          of({
            type: ADD_TAG_ITEM_FAILURE,
            payload,
          }),
        ),
      ),
    ),
  );
}

async function deleteTagItemRequest(id, item, value) {
  const response = await api.delete(`tags/${id}/${item}/${value}`).json();

  return response;
}

export function deleteTagTypeItemEpic(action$) {
  return action$.pipe(
    ofType(DELETE_TAG_ITEM),
    mergeMap(({ payload: { id, item, value, updatedItems, type } }) =>
      from(deleteTagItemRequest(id, item, value)).pipe(
        map((payload) => ({
          type: DELETE_TAG_ITEM_SUCCESS,
          payload: { ...payload, id, type, updatedItems },
        })),
        catchError(({ message }) =>
          of({
            type: DELETE_TAG_ITEM_FAILURE,
            payload: {
              op: 'deleting',
              message,
              tag: id,
              type,
              id,
            },
          }),
        ),
      ),
    ),
  );
}
