import { api } from '@/apis';
import { log } from '@/utils';
import { useQuery } from '@tanstack/react-query';

function addType(result) {
  result.display_name = `${result?.display_name} (${result?.type})`;
}

export function useGazetteer(search) {
  return useQuery({
    queryKey: ['gazetteer', search],
    queryFn: async () => {
      log('Read', 'Gazetteer', { search });

      const results = await api
        .get('nominatim/search', {
          searchParams: { q: search, format: 'json' },
        })
        .json();

      let names = {};
      results.forEach((result, index) => {
        if (result.display_name in names) {
          // don't add type multiple times to the first one
          if (names[result.display_name] !== -1) {
            // addType(response[names[result.display_name]]);
            names[result.display_name] = -1;
          }

          addType(result);
        } else {
          names[result.display_name] = index;
        }
      });

      return results;
    },
    placeholderData: [],
    staleTime: 1000 * 60 * 60,
    enabled: !!search,
  });
}
