import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  query: {},
  parameters: {},
});

export function formatLabel(epoch) {
  return epoch ? format(new Date(epoch), 'dd/MM HH:mm:ss') : '';
}
