import { api } from '@/apis';
import { log } from '@/utils';
import { useDallasKeys } from '@/utils/config';
import { useQuery } from '@tanstack/react-query';

export function useRfidCard(id) {
  return useQuery({
    queryKey: ['rfidCards', id],
    queryFn: async () => {
      if (id === 'new') {
        return {
          reference: '',
        };
      }

      const json = [
        {
          $match: {
            reference: id,
          },
        },
        {
          $project: {
            reference: true,
            category: true,
            type: true,
            label: true,
            time: true,
            lastEdit: true,
          },
        },
        {
          $lookup: {
            from: 'people',
            localField: 'reference',
            foreignField: 'rfidCards.reference',
            as: 'people',
          },
        },
        {
          $set: {
            people: {
              $filter: {
                input: '$people',
                as: 'person',
                cond: { $ne: ['$$person.deleted', true] },
              },
            },
          },
        },
      ];

      const cards = await api.post('pipeline/rfidCards', { json }).json();

      log('Read', useDallasKeys ? 'Dallas Keys' : 'RFID Cards', { id });

      return cards[0] ?? null;
    },
    staleTime: 1000 * 60 * 60,
    enabled: !!id,
  });
}
