import { Box, Tooltip } from '@mui/material';
import {
  LanCheck as LanCheckIcon,
  LanConnect as LanConnectIcon,
  LanDisconnect as LanDisconnectIcon,
  LanPending as LanPendingIcon,
} from 'mdi-material-ui';
import { ReadyState } from 'react-use-websocket';

function getStateDescription(readyState) {
  switch (readyState) {
    case ReadyState.OPEN:
      return 'Socket connected';
    case ReadyState.CLOSED:
      return 'Socket disconnected';
    case ReadyState.CONNECTING:
      return 'Socket connecting';
    default:
      return 'Socket pending';
  }
}

function StatusIcon({ readyState, ...props }) {
  switch (readyState) {
    case ReadyState.OPEN:
      return <LanCheckIcon color="success" {...props} />;
    case ReadyState.CLOSED:
      return <LanDisconnectIcon color="error" {...props} />;
    case ReadyState.CONNECTING:
      return <LanConnectIcon color="warning" {...props} />;
    default:
      return <LanPendingIcon color="disabled" {...props} />;
  }
}

export function SocketStatus({ readyState, ...props }) {
  return (
    <Tooltip title={getStateDescription(readyState)}>
      <Box>
        <StatusIcon readyState={readyState} {...props} />
      </Box>
    </Tooltip>
  );
}
