import { ExportPicker } from '@/components/controls';
import {
  getVehiclePolls,
  shortVehicleHeaders,
  telematicsBoxPollHeaders,
} from '@/utils';

export function DownloadPollsLink({ entry }) {
  async function fetchDataToConvert() {
    const polls = await getVehiclePolls(
      entry.properties.imei,
      entry.properties.startTime,
      entry.properties.endTime,
    );
    const data = polls.map((poll) => ({
      ...poll,
      identificationNumber: entry.properties.vehicle.identificationNumber,
      registrationNumber: entry.properties.vehicle.registrationNumber,
      fleetNumber: entry.properties.vehicle.fleetNumber,
      time: new Date(poll.time),
      vehicleRole: entry.properties.vehicle.role,
      longitude: poll.position ? poll.position.coordinates[0] : 0,
      latitude: poll.position ? poll.position.coordinates[1] : 0,
    }));

    return data;
  }

  return (
    <ExportPicker
      fetchDataToConvert={fetchDataToConvert}
      filename="vehicle-boundary-visit-polls"
      headers={[...shortVehicleHeaders, ...telematicsBoxPollHeaders]}
    />
  );
}
