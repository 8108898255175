import { round } from '@/utils';
import { format } from 'date-fns';
import { atom } from 'jotai';

export const stateAtom = atom({
  query: {},
  parameters: {},
  time: null,
});

export const pipelineFn = (query, time) => [
  { $match: query },
  {
    $set: {
      odometerReading: {
        $first: {
          $sortArray: {
            input: {
              $filter: {
                input: '$odometerReadings',
                cond: {
                  $lte: [
                    '$$this.time',
                    time ?? new Date('2100-01-01T00:00:00Z'),
                  ],
                },
              },
            },
            sortBy: { time: -1, kilometres: -1 },
          },
        },
      },
    },
  },
  {
    $lookup: {
      from: 'telematicsBoxPolls',
      localField: 'telematicsBoxImei',
      foreignField: 'imei',
      pipeline: [
        {
          $match: { time: { $lte: time ?? new Date('2100-01-01T00:00:00Z') } },
        },
        { $sort: { time: -1 } },
        { $limit: 1 },
        {
          $project: {
            _id: false,
            time: true,
            miles: { $multiply: ['$distanceKilometres', 0.62137119] },
          },
        },
      ],
      as: 'latestPoll',
    },
  },
  {
    $lookup: {
      from: 'telematicsBoxPolls',
      localField: 'telematicsBoxImei',
      foreignField: 'imei',
      let: { time: '$odometerReading.time' },
      pipeline: [
        {
          $match: {
            $expr: {
              $and: [
                { $gte: ['$time', '$$time'] },
                {
                  $lt: [
                    '$time',
                    {
                      $dateAdd: {
                        startDate: '$$time',
                        unit: 'day',
                        amount: 7,
                      },
                    },
                  ],
                },
              ],
            },
          },
        },
        { $sort: { time: 1 } },
        { $limit: 1 },
        {
          $project: {
            _id: false,
            time: true,
            miles: { $multiply: ['$distanceKilometres', 0.62137119] },
          },
        },
      ],
      as: 'odometerPoll',
    },
  },
  {
    $project: {
      _id: false,
      registrationNumber: true,
      fleetNumber: true,
      role: true,
      type: true,
      odometerReading: {
        $cond: {
          if: { $eq: ['$odometerReading', null] },
          then: '$$REMOVE',
          else: {
            time: '$odometerReading.time',
            miles: {
              $multiply: ['$odometerReading.kilometres', 0.62137119],
            },
          },
        },
      },
      latestPoll: { $first: '$latestPoll' },
      odometerPoll: { $first: '$odometerPoll' },
    },
  },
  {
    $set: {
      gpsMileage: {
        $subtract: ['$latestPoll.miles', '$odometerPoll.miles'],
      },
      calculatedMileage: {
        $add: [
          '$odometerReading.miles',
          {
            $subtract: ['$latestPoll.miles', '$odometerPoll.miles'],
          },
        ],
      },
      error: {
        $switch: {
          branches: [
            {
              case: {
                $or: [
                  { $in: ['$odometerReading.time', [null, '']] },
                  { $in: ['$odometerReading.miles', [null, '']] },
                ],
              },
              then: 'NO_ODOMETER_READING',
            },
            {
              case: { $eq: [{ $type: '$latestPoll' }, 'missing'] },
              // case: { $eq: ['$latestPoll', null] },
              then: 'NO_POLLS',
            },
            {
              case: { $eq: [{ $type: '$odometerPoll' }, 'missing'] },
              // case: { $eq: ['$odometerPoll', null] },
              then: 'NO_POLL_WITHIN_7_DAYS_OF_ODOMETER_READING',
            },
          ],
          default: null,
        },
      },
    },
  },
];

export const columns = [
  {
    header: 'Registration',
    id: 'registrationNumber',
    accessorFn: ({ registrationNumber }) => registrationNumber ?? '',
  },
  {
    header: 'Fleet Number',
    id: 'fleetNumber',
    accessorFn: ({ fleetNumber }) => fleetNumber ?? '',
  },
  {
    header: 'Role',
    id: 'role',
    accessorFn: ({ role }) => role ?? '',
  },
  {
    header: 'Type',
    id: 'type',
    accessorFn: ({ type }) => type ?? '',
  },
  {
    header: 'Odometer Time',
    id: 'odometerReading.time',
    accessorFn: ({ odometerReading: { time } = {} }) => time ?? '',
    Cell: ({ cell }) =>
      cell.getValue() ? format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Odometer Mileage',
    id: 'odometerReading.miles',
    accessorFn: ({ odometerReading: { miles } = {} }) => miles ?? '',
    Cell: ({ cell }) =>
      cell.getValue() ? `${round(cell.getValue(), 0)} mi` : '',
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
  },
  {
    header: 'Next Poll Time',
    id: 'odometerPoll.time',
    accessorFn: ({ odometerPoll: { time } = {} }) => time ?? '',
    Cell: ({ cell }) =>
      cell.getValue() ? format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Next Poll Mileage',
    id: 'odometerPoll.miles',
    accessorFn: ({ odometerPoll: { miles } = {} }) => miles ?? '',
    Cell: ({ cell }) =>
      cell.getValue() ? `${round(cell.getValue(), 0)} mi` : '',
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
  },
  {
    header: 'Latest Poll Time',
    id: 'latestPoll.time',
    accessorFn: ({ latestPoll: { time } = {} }) => time ?? '',
    Cell: ({ cell }) =>
      cell.getValue() ? format(cell.getValue(), 'dd/MM/yyyy HH:mm:ss') : '',
  },
  {
    header: 'Latest Poll Mileage',
    id: 'latestPoll.miles',
    accessorFn: ({ latestPoll: { miles } = {} }) => miles ?? '',
    Cell: ({ cell }) =>
      cell.getValue() ? `${round(cell.getValue(), 0)} mi` : '',
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
  },
  {
    header: 'GPS Mileage',
    accessorKey: 'gpsMileage',
    Cell: ({ cell }) =>
      cell.getValue() ? `${round(cell.getValue(), 0)} mi` : '',
  },
  {
    header: 'Calculated Mileage',
    accessorKey: 'calculatedMileage',
    Cell: ({ cell }) =>
      cell.getValue() ? `${round(cell.getValue(), 0)} mi` : '',
    muiTableHeadCellProps: { align: 'right' },
    muiTableBodyCellProps: { align: 'right' },
  },
];
