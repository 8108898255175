import { createTheme } from '@mui/material';
import { blue, yellow } from '@mui/material/colors';

function container() {
  // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
  return document.fullscreenElement ?? document.body;
}

export const theme = createTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: blue,
        secondary: yellow,
        background: {
          primary: blue[100],
        },
      },
    },
    dark: {
      palette: {
        primary: blue,
        secondary: yellow,
        background: {
          primary: blue[900],
        },
      },
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiMenu: {
      defaultProps: {
        container,
      },
    },
    MuiPopper: {
      defaultProps: {
        container,
      },
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
  },
});
